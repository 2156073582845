import React from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'

import CheckPermission from '@/atoms/CheckPermission/CheckPermission'
import DoLogout from '@/atoms/DoLogout/DoLogout'
import PrivateRoute from '@/atoms/PrivateRoute/PrivateRoute'
import Tab from '@/atoms/Tab/Tab'
import Tabs from '@/atoms/Tabs/Tabs'
import { FeaturePermit } from '@/common/feature-permit'
import { useTranslation } from '@/lib/i18n/i18n'
import { Routes } from '@/lib/route-utils'
import { RestrictedMobileAppRoute } from '@/molecules/RestrictedMobileAppContent/RestrictedMobileAppRoute'
import LoginWithSeo from '@/pages/Login'
import { OrderCallbackVerification } from '@/pages/OrderCallbackVerification/OrderCallbackVerification'
import styled from '@/styles'
import { useIdentifyUser } from '@/tracking/useIdentifyUser'
import { useUserTracking } from '@/tracking/useUserTracking'
import { FeatureScope, PageProps, UserPermissionsTypes } from '@/travelsuit'
import { trackEvent } from '@/travelsuit/analytics'

import {
	ActivateInvoiceProfile,
	BookConsultant,
	CallbackVerification,
	ChangePlan,
	ConfirmCompanyPage,
	CorporateDiscountsAndRewardPrograms,
	CreateCompanyInvoices,
	CreateInvoiceProfileWizard,
	CreateTravelerProfile,
	CreditCardCallbackVerification,
	CurrentPlan,
	EditDailyAllowance,
	EditDailyAllowanceInReport,
	EditExpenseReport,
	EditMileage,
	EditMileageInReport,
	EditStandardExpense,
	EditStandardExpenseInReport,
	ExpenseReports,
	ExpenseReportsSubmitted,
	GeneralSettings,
	HomePage,
	HotelResultPage,
	InvoiceProfiles,
	Itinerary,
	ManageDepartments,
	ManageExpenses,
	ManageLocations,
	ManagePolicy,
	ManageTravelers,
	NewDailyAllowance,
	NewExpenseReport,
	NewMileage,
	NewStandardExpense,
	PasswordChangeResult,
	ReportSettings,
	ReportsRouter,
	Safety,
	SelectExpensesInReport,
	Support,
	TravelerProfile,
	TripBuilder,
	TripProduct,
	TripsList,
	UsageDetails,
} from './lazyRoutes'

const StyledTabs = styled(Tabs)`
	margin-top: ${(p) => p.theme.spacing(8)}px;

	${(p) => p.theme.breakpoints.down('md')} {
		margin-top: 0;
	}
`

const AppRouter: React.FC<PageProps> = (props) => {
	const userTrackingClient = useUserTracking()
	React.useEffect(() => {
		const listener = props.history.listen(() => {
			trackEvent({ action: 'Page View' })
			userTrackingClient?.reload()
		})

		return () => {
			listener?.()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userTrackingClient])

	useIdentifyUser()

	const { t } = useTranslation()

	return (
		<Switch>
			<PrivateRoute path={Routes.LiveMap} exact render={() => <Redirect to={Routes.Home} />} />
			<PrivateRoute path={Routes.NewTrip} exact render={() => <Redirect to={Routes.Home} />} />
			<PrivateRoute path={Routes.Home} exact component={HomePage} />
			<PrivateRoute path={Routes.Safety} exact component={Safety} />
			<PrivateRoute
				path={Routes.Admin}
				render={(props) => (
					<CheckPermission permissions={[UserPermissionsTypes.Admin]}>
						<Switch>
							<PrivateRoute path={Routes.ReportSettings} exact component={ReportSettings} />
							<PrivateRoute
								path={Routes.CorporateDiscountsAndRewardPrograms}
								exact
								component={CorporateDiscountsAndRewardPrograms}
							/>
							<StyledTabs e2eForTabStrips="AppRouter.AdminSubmenu" active={props.location.pathname}>
								<Tab tabKey={Routes.GeneralSettings} label={t('app-router.admin.general', 'General')}>
									<GeneralSettings {...props} />
								</Tab>
								<Tab
									data-test={'TravelersTabElement'}
									tabKey={Routes.ManageTravelers}
									label={t('app-router.admin.travelers', 'Travelers')}
								>
									<ManageTravelers {...props} />
								</Tab>
								<Tab tabKey={Routes.ManageDepartments} label={t('app-router.admin.departments', 'Departments')}>
									<ManageDepartments {...props} />
								</Tab>
								<Tab
									tabKey={Routes.ManageLocations}
									label={t('app-router.admin.locations', 'Offices &amp; Destinations')}
								>
									<ManageLocations {...props} />
								</Tab>
							</StyledTabs>
						</Switch>
					</CheckPermission>
				)}
			/>

			<PrivateRoute
				path={Routes.ManagePolicy}
				render={(props) => (
					<CheckPermission permissions={[UserPermissionsTypes.Admin]}>
						<ManagePolicy {...props} />
					</CheckPermission>
				)}
			/>

			<PrivateRoute
				path={Routes.Reports}
				render={(props) => (
					<CheckPermission
						permissions={[
							UserPermissionsTypes.Admin,
							UserPermissionsTypes.ViewAllReports,
							UserPermissionsTypes.ViewManagedGroupReports,
						]}
					>
						<ReportsRouter {...props} />
					</CheckPermission>
				)}
			/>

			<PrivateRoute
				path={Routes.Trips}
				render={() => (
					<Switch>
						<PrivateRoute path={Routes.NewSearch} exact component={HomePage} />
						<PrivateRoute
							path={Routes.Itinerary}
							exact
							render={({ history, location, ...rest }) => (
								<Itinerary {...rest} key={props.match.params.id} history={history as any} location={location as any} />
							)}
						/>
						<PrivateRoute path={Routes.HotelResult} exact component={HotelResultPage} />
						<PrivateRoute path={Routes.TripProduct} component={TripProduct} />
						<PrivateRoute path={Routes.TripBuilder} component={TripBuilder} />
						<PrivateRoute path={Routes.Trips} component={TripsList} />
					</Switch>
				)}
			/>
			<PrivateRoute
				path={Routes.Expenses}
				render={(props) => (
					<FeaturePermit requiredFeature={FeatureScope.TravelExpense}>
						{({ isLoading, isPermitted }) => {
							if (isLoading || !isPermitted) {
								return <></>
							}

							return (
								<Switch>
									<PrivateRoute path={Routes.Expenses} exact render={() => <Redirect to={Routes.ManageExpenses} />} />
									<PrivateRoute path={Routes.NewStandardExpense} exact component={NewStandardExpense} />
									<PrivateRoute path={Routes.EditStandardExpense} exact component={EditStandardExpense} />
									<PrivateRoute path={Routes.EditExpenseInReport} exact component={EditStandardExpenseInReport} />
									<PrivateRoute path={Routes.EditMileageInReport} exact component={EditMileageInReport} />
									<PrivateRoute path={Routes.EditDailyAllowanceInReport} exact component={EditDailyAllowanceInReport} />
									<PrivateRoute path={Routes.NewExpenseReport} exact component={NewExpenseReport} />
									<PrivateRoute path={Routes.EditExpenseReport} exact component={EditExpenseReport} />
									<PrivateRoute path={Routes.ExpenseReportsSubmitted} exact component={ExpenseReportsSubmitted} />
									<PrivateRoute path={Routes.SelectExpensesInReport} exact component={SelectExpensesInReport} />
									<PrivateRoute path={Routes.NewMileage} exact component={NewMileage} />
									<PrivateRoute path={Routes.EditMileage} exact component={EditMileage} />
									<PrivateRoute path={Routes.NewDailyAllowance} exact component={NewDailyAllowance} />
									<PrivateRoute path={Routes.EditDailyAllowance} exact component={EditDailyAllowance} />
									<StyledTabs active={props.location.pathname}>
										<Tab tabKey={Routes.ManageExpenses} label={t('app-router.expenses.manage', 'Manage expenses')}>
											<ManageExpenses />
										</Tab>
										<Tab tabKey={Routes.ExpenseReports} label={t('app-router.expenses.reports', 'Expense Reports')}>
											<ExpenseReports {...props} />
										</Tab>
									</StyledTabs>
								</Switch>
							)
						}}
					</FeaturePermit>
				)}
			/>

			<PrivateRoute path={Routes.CreateTravelerProfile} exact component={CreateTravelerProfile} />
			<PrivateRoute path={Routes.TravelerProfile} component={TravelerProfile} />
			<RestrictedMobileAppRoute path={Routes.BillingChangePlan} component={ChangePlan} exact />
			<RestrictedMobileAppRoute path={Routes.ActivateInvoiceProfile} exact component={ActivateInvoiceProfile} />

			<RestrictedMobileAppRoute
				path={Routes.Billing}
				render={(props) => (
					<CheckPermission permissions={[UserPermissionsTypes.Admin]}>
						<Switch>
							<PrivateRoute path={Routes.CreateInvoiceProfile} exact component={CreateInvoiceProfileWizard} />
							<StyledTabs e2eForTabStrips={'AppRouter.BillingSubmenu'} active={props.location.pathname}>
								<Tab tabKey={Routes.Billing} label={t('app-router.billing.invoice-profiles', 'Invoice Profiles')}>
									<InvoiceProfiles {...props} />
								</Tab>
								<Tab tabKey={Routes.BillingCurrent} label={t('app-router.billing.plans', 'Packages')}>
									<PrivateRoute path={Routes.BillingCurrent} component={CurrentPlan} />
								</Tab>
								<Tab tabKey={Routes.BillingUsage} label={t('app-router.billing.usage', 'Usage Details')}>
									<PrivateRoute path={Routes.BillingUsage} component={UsageDetails} />
								</Tab>
							</StyledTabs>
						</Switch>
					</CheckPermission>
				)}
			/>
			<RestrictedMobileAppRoute
				path={Routes.CreateCompanyInvoices}
				exact
				render={(matchProps) => <CreateCompanyInvoices {...matchProps} />}
			/>
			<RestrictedMobileAppRoute path={Routes.OnboardingConfirmation} exact component={ConfirmCompanyPage} />

			<PrivateRoute path={Routes.CallbackVerification} component={CallbackVerification} />
			<PrivateRoute path={Routes.CreditCardCallbackVerification} component={CreditCardCallbackVerification} />
			<PrivateRoute path={Routes.WordlineOrderCallbackVerification} component={OrderCallbackVerification} />
			<PrivateRoute path={Routes.BookCustomerSuccessConsultant} component={BookConsultant} />

			<Route path={Routes.Login} exact component={LoginWithSeo} />
			<Route path={Routes.Logout} exact component={DoLogout} />
			<Route path={Routes.PasswordChangeResult} exact component={PasswordChangeResult} />
			<PrivateRoute path={Routes.Support} exact component={Support} />
		</Switch>
	)
}

export default withRouter(AppRouter)
