import { StylesProvider as MUIStylesProvider, ThemeProvider } from '@material-ui/core/styles'
import React from 'react'

import { GlobalStyle, theme } from '@/styles'

import { StyledThemeProvider } from './styledComponents'

export function StylesProvider({ children }: React.PropsWithChildren<{}>) {
	return (
		<ThemeProvider theme={theme}>
			<MUIStylesProvider injectFirst>
				<StyledThemeProvider theme={theme}>
					<GlobalStyle />
					{children}
				</StyledThemeProvider>
			</MUIStylesProvider>
		</ThemeProvider>
	)
}
