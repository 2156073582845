import { AxiosError } from 'axios'

import { enrichWithBrowserData } from '@/lib/requests/enrichWithBrowserData'
import { objDiff } from '@/lib/utils'
import { BookTripDetails } from '@/organisms/ItinerarySubmit/ItinerarySubmit.hooks'
import { getStoreState } from '@/redux/stores'
import { Callback, Itinerary, Trip, TripNote, TripStatus, User, VoidCallback } from '@/travelsuit'
import { ManageItinerarySeatData } from '@/travelsuit/seatmaps'
import { BookingSource, prepareTripJson, TripBookingStatusResponse } from '@/travelsuit/trips'

import { IAction } from './action-helpers'
import { SeatmapTypes, TripTypes as Types } from './actions.types'

export enum TimeFrame {
	Past = 'past',
	Current = 'current',
	All = 'all',
}

export interface TripCreateParams {
	travelers: User[]
	parent_trip_id?: number
	pre_trip_request_id?: number
}

export const Actions = {
	removeSelectedSeats(
		seatKeys: Record<'seat_keys', string[]>,
		trip_id: Number,
		callback?: VoidCallback<Itinerary>,
		errCallback?: VoidCallback<AxiosError>,
	) {
		return {
			type: SeatmapTypes.DeleteSeats.REQUEST,
			params: { trip_id },
			payload: seatKeys,
			callback,
			errCallback,
		}
	},
	manageItinerarySeat(
		seats: Record<'seats', ManageItinerarySeatData[]>,
		callback?: VoidCallback<Itinerary>,
		errCallback?: VoidCallback<AxiosError>,
	) {
		return {
			type: SeatmapTypes.ManageItinerarySeat.REQUEST,
			payload: seats,
			callback,
			errCallback,
		}
	},
	getAllTrips(
		{ statuses = [], time_frame }: { statuses?: TripStatus[]; time_frame: TimeFrame },
		callback?: VoidCallback<Trip[]>,
		errCallback?: VoidCallback<AxiosError>,
	) {
		return {
			type: Types.GetAllTrips.REQUEST,
			params: { statuses: statuses.join(','), time_frame },
			callback,
			errCallback,
		}
	},
	clearTrips(ids?: number[], callback?: Callback, errCallback?: VoidCallback<AxiosError>) {
		return {
			type: Types.ClearTrips,
			payload: ids,
			callback,
			errCallback,
		}
	},
	getTrip(tripId: number, callback?: Callback, errCallback?: VoidCallback<AxiosError>) {
		return {
			type: Types.GetTrip.REQUEST,
			payload: { tripId },
			callback,
			errCallback,
		}
	},
	getTripRailRedirectLink(tripId: number, lang?: string, callback?: Callback, errCallback?: VoidCallback<AxiosError>) {
		return {
			type: Types.GetTripRailRedirectLink.REQUEST,
			payload: { tripId, lang },
			callback,
			errCallback,
		}
	},
	createTrip(params: TripCreateParams, callback?: VoidCallback<Trip>, errCallback?: VoidCallback<AxiosError>) {
		return {
			type: Types.CreateTrip.REQUEST,
			payload: {
				...params,
				travelers: undefined,
				users_ids: params.travelers.map(({ id }) => id),
			},
			callback,
			errCallback,
		}
	},
	updateTrip(trip: Trip, callback?: VoidCallback<Trip>, errCallback?: VoidCallback<AxiosError>) {
		const originalTrip = getStoreState().trips.byId.get(trip.id!)
		const diff = objDiff(originalTrip, trip)
		return {
			type: Types.UpdateTrip.REQUEST,
			payload: { trip, originalTrip },
			body: prepareTripJson(diff),
			callback,
			errCallback,
		}
	},
	removeTrip(tripId: number, callback?: Callback) {
		return {
			type: Types.RemoveTrip.REQUEST,
			payload: { tripId },
			callback,
		}
	},
	addTraveler(tripId: number, userId: number, callback?: Callback, errCallback?: VoidCallback<AxiosError>) {
		return {
			type: Types.AddTraveler.REQUEST,
			payload: { tripId, userId },
			callback,
			errCallback,
		}
	},
	removeTraveler(tripId: number, userId: number, callback?: Callback, errCallback?: VoidCallback<AxiosError>) {
		return {
			type: Types.RemoveTraveler.REQUEST,
			payload: { tripId, userId },
			callback,
			errCallback,
		}
	},
	addNote(tripId: number, note: Partial<TripNote>, callback?: Callback, errCallback?: VoidCallback<AxiosError>) {
		return {
			type: Types.AddNote.REQUEST,
			payload: { tripId },
			body: note,
			callback,
			errCallback,
		}
	},
	editNote(
		tripId: number,
		noteId: number,
		note: Partial<TripNote>,
		callback?: Callback,
		errCallback?: VoidCallback<AxiosError>,
	) {
		return {
			type: Types.EditNote.REQUEST,
			payload: { tripId, noteId },
			body: note,
			callback,
			errCallback,
		}
	},
	removeNote(tripId: number, noteId: number, callback?: Callback, errCallback?: VoidCallback<AxiosError>) {
		return {
			type: Types.RemoveNote.REQUEST,
			payload: { tripId, noteId },
			callback,
			errCallback,
		}
	},
	getBookingStatus(
		tripId: number,
		callback?: VoidCallback<TripBookingStatusResponse>,
		errCallback?: VoidCallback<AxiosError>,
	) {
		return {
			type: Types.GetBookingStatus.REQUEST,
			payload: { tripId },
			callback,
			errCallback,
		}
	},
	submitTrip(
		tripId: number,
		source: BookingSource,
		details: Partial<BookTripDetails>,
		callback?: VoidCallback<TripBookingStatusResponse>,
		errCallback?: VoidCallback<AxiosError>,
	) {
		return {
			type: source === BookingSource.Book ? Types.SubmitTrip.REQUEST : Types.SubmitTripForApproval.REQUEST,
			payload: { tripId },
			body: enrichWithBrowserData(details),
			callback,
			errCallback,
		}
	},
	approveTrip(tripId: number, callback?: VoidCallback<string>, errCallback?: VoidCallback<AxiosError>) {
		return {
			type: Types.ApproveTrip.REQUEST,
			payload: { tripId },
			callback,
			errCallback,
		}
	},
	rejectTrip(
		tripId: number,
		rejectionNote: string,
		reasonType: Array<string>,
		callback?: VoidCallback<any>,
		errCallback?: VoidCallback<AxiosError>,
	) {
		return {
			type: Types.RejectTrip.REQUEST,
			payload: { tripId, rejectionNote, reasonType },
			body: { message: rejectionNote, rejection_reason_type: reasonType },
			callback,
			errCallback,
		}
	},
	notifyTripPlannerOfPriceChange(
		tripId: number,
		oldCost: number,
		newCost: number,
		callback?: Callback,
		errCallback?: VoidCallback<AxiosError>,
	) {
		return {
			type: Types.NotifyTripPlannerPriceChange.REQUEST,
			payload: { tripId, old_total_cost: oldCost, new_total_cost: newCost },
			callback,
			errCallback,
		}
	},
	mergeTrip(tripIds: number[], callback?: Callback, errCallback?: VoidCallback<AxiosError>) {
		return {
			type: Types.MergeTrip.REQUEST,
			payload: { trip_ids: tripIds },
			callback,
			errCallback,
		}
	},
	verifyBookingPayment({
		tripId,
		bookingSegmentToTravelerId,
		verificationId,
		callback,
		errCallback,
	}: {
		tripId: number
		bookingSegmentToTravelerId: number
		verificationId: string
		callback?: VoidCallback<TripBookingStatusResponse>
		errCallback?: VoidCallback<AxiosError>
	}) {
		return {
			type: Types.VerifyBookingPayment.REQUEST,
			body: { verification_id: verificationId },
			payload: { tripId, bookingSegmentToTravelerId },
			callback,
			errCallback,
		}
	},

	cancelBookingVerificationForSegment({
		tripId,
		bookingSegmentId,
		callback,
		errCallback,
	}: {
		tripId: number
		bookingSegmentId: number
		callback?: VoidCallback<TripBookingStatusResponse>
		errCallback?: VoidCallback<AxiosError>
	}) {
		return {
			type: Types.CancelBookingVerificationForSegment.REQUEST,
			payload: { tripId, bookingSegmentId },
			callback,
			errCallback,
		}
	},
} as const satisfies Record<string, (...args: any[]) => IAction>
