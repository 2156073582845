import React, { createContext, useCallback, useEffect, useState } from 'react'

import { ScreenSizes } from '@/css-helpers/media-query'

interface IScreenSizeContext {
	screenWidth: number
}

export const ScreenSizeContext = createContext<IScreenSizeContext>({ screenWidth: 0 })

function defineScreenSize(): number {
	return window.innerWidth
}

export function WindowSizeContextProvider({ children }: React.PropsWithChildren<{}>) {
	const [screenSizeContext, setScreenSizeContext] = useState<IScreenSizeContext>(() => ({
		screenWidth: defineScreenSize(),
	}))

	const setScreenSize = useCallback(() => {
		setScreenSizeContext({ screenWidth: defineScreenSize() })
	}, [setScreenSizeContext])

	useEffect(() => {
		window.addEventListener('resize', setScreenSize)

		return () => window.removeEventListener('resize', setScreenSize)
	}, [setScreenSize])

	return <ScreenSizeContext.Provider value={screenSizeContext}>{children}</ScreenSizeContext.Provider>
}

function useScreenSize() {
	return React.useContext(ScreenSizeContext)
}

export const getBreakPoints = (screenWidth: number) => ({
	isSmallMobile: screenWidth <= ScreenSizes.SmallMobile,
	isMediumMobile: screenWidth <= ScreenSizes.MediumMobile,
	isMobile: screenWidth <= ScreenSizes.Mobile,
	isTablet: screenWidth < ScreenSizes.Desktop,
	isDesktop: screenWidth >= ScreenSizes.Desktop,
	isWideDesktop: screenWidth >= ScreenSizes.DesktopWide,
	isLargeDesktop: screenWidth >= ScreenSizes.LargeDesktop,
})

export const useBreakPoints = () => {
	const { screenWidth } = useScreenSize()

	return getBreakPoints(screenWidth)
}
