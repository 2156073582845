import { useMemo } from 'react'

import { useTranslation } from '@/lib/i18n/i18n'
import { Locales } from '@/types/locale'

export const useLanguageSelectorTranslation = () => {
	const { t } = useTranslation()

	const translation = useMemo(
		() => ({
			title: {
				[Locales.enGB]: t('language-selector.language-labels.en-GB'),
				[Locales.enUS]: t('language-selector.language-labels.en-US'),
				[Locales.deDE]: t('language-selector.language-labels.de-DE'),
			},
			label: {
				[Locales.enGB]: t('language-selector.short-country-labels.en-GB'),
				[Locales.enUS]: t('language-selector.short-country-labels.en-US'),
				[Locales.deDE]: t('language-selector.short-country-labels.de-DE'),
			},
		}),
		[t],
	)

	return translation
}
