import CurrencyAustralianDollarlingIcon from '@/refactor/assets/currencies/AUD.svg'
import CurrencyEuroIcon from '@/refactor/assets/currencies/EUR.svg'
import CurrencyPoundSterlingIcon from '@/refactor/assets/currencies/GBP.svg'
import CurrencyIsraeliNewShekelIcon from '@/refactor/assets/currencies/ILS.svg'
import CurrencySwedishKronaIcon from '@/refactor/assets/currencies/SEK.svg'
import CurrencyDollarIcon from '@/refactor/assets/currencies/USD.svg'
import { Currency } from '@/types/common'

export const CurrencyIcons: Record<Currency, string> = {
	[Currency.USD]: CurrencyDollarIcon,
	[Currency.EUR]: CurrencyEuroIcon,
	[Currency.GBP]: CurrencyPoundSterlingIcon,
	[Currency.AUD]: CurrencyAustralianDollarlingIcon,
	[Currency.SEK]: CurrencySwedishKronaIcon,
	[Currency.ILS]: CurrencyIsraeliNewShekelIcon,
}
