import React, { useState } from 'react'

import UserLoginMenu from '@/atoms/UserLoginMenu/UserLoginMenu'
import { CircleUserPhoto } from '@/atoms/UserPhoto/UserPhoto'
import { useStoreValue } from '@/lib/react-hooks/generic-hooks'
import { backgroundGray, fontBlack } from '@/refactor/colors'
import { font, FontType } from '@/refactor/fonts'
import styled, { mediaQuery } from '@/styles'

interface OwnProps {
	className?: string
	onClickMenuItem?(): void
}

type IProps = OwnProps

const UserLoginContainer = styled.div`
	position: relative;
	color: ${fontBlack};
	${font(FontType.Comfortaa, { size: 12, weight: 'bold' })}
	padding: 15px 20px;
	border-top: 1px solid ${backgroundGray};
	${mediaQuery.above('desktop')`
		padding: 0;
		border: none;
	`}
`

const Avatar = styled(CircleUserPhoto)`
	${mediaQuery.below('desktop')`
		margin-right: 20px;
	`}

	${mediaQuery.above('desktop')`
		margin-left: 5px;
	`}

	cursor: pointer;
`

export function UserLogin(props: IProps) {
	const { user, loggedIn } = useStoreValue(({ auth }) => ({
		user: auth.get('internalUser'),
		loggedIn: Boolean(auth.get('internalUser')),
	}))

	const { className, onClickMenuItem } = props
	const [shouldShowPopup, setShouldShowPopup] = useState(false)

	if (!user) {
		return null
	}

	return (
		<UserLoginContainer
			className={className}
			onClick={() => setShouldShowPopup((prevState) => !prevState)}
			data-test="UserLogin"
		>
			{loggedIn ? <Avatar user={user} size={28} /> : null}

			<UserLoginMenu
				open={shouldShowPopup}
				user={user}
				onClickMenuItem={() => onClickMenuItem?.()}
				onClickOutside={() => setShouldShowPopup(false)}
			/>
		</UserLoginContainer>
	)
}

export default styled(UserLogin)``
