import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { accentPrimary, headerHeight } from '@/_vars'
import Box from '@/atoms/Box/Box'
import Button from '@/atoms/Button/Button'
import Checkbox from '@/atoms/Checkbox/Checkbox'
import { centeredAbsoluteCss, Link } from '@/atoms/GenericComponents/GenericComponents'
import { acceptTermsOfService, requestProviderLogout } from '@/lib/auth'
import { Trans } from '@/lib/i18n/i18n'
import { getCurrentLocale } from '@/lib/i18n/locale-utils'
import { redirectTo, Routes } from '@/lib/route-utils'
import styled from '@/styles'
import { PageProps } from '@/travelsuit'
import { PrivacyPolicyLinkByLanguage } from '@/travelsuit/locations'

import { ButtonContainer, CheckboxText, Header, Subheading, TermsSection, TextBlock } from './TermsOfService.components'

const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: ${(p) => p.theme.spacing(9)}px !important;

	& > h3 {
		color: ${accentPrimary};
		font-weight: bold;
		font-size: 2em;
		margin-bottom: ${(p) => p.theme.spacing(3)}px;
	}

	& > h4 {
		color: ${accentPrimary};
		font-size: 1.4em;
		margin-bottom: ${(p) => p.theme.spacing(4)}px;
	}

	${centeredAbsoluteCss()}

	${(p) => p.theme.breakpoints.down('md')} {
		padding: ${(p) => p.theme.spacing(8)}px ${(p) => p.theme.spacing(3)}px !important;
		overflow: auto;
		text-align: center;
		margin-bottom: 0 !important;
		font-size: 0.7rem;

		top: calc(${headerHeight}px + ${(p) => p.theme.spacing(3)}px);
		right: ${(p) => p.theme.spacing(3)}px;
		bottom: ${(p) => p.theme.spacing(3)}px;
		left: ${(p) => p.theme.spacing(3)}px;
		transform: none;
	}
`

interface IProps extends PageProps {}

const TermsOfService: React.FC<IProps> = ({ className }) => {
	const { t } = useTranslation()

	const [checked, setChecked] = useState(false)

	const onToggle = (value: boolean) => {
		setChecked(value)
	}
	const acceptTermsOfServiceHandler = async () => {
		await acceptTermsOfService()
		redirectTo(Routes.Home, undefined, { replace: true })
	}

	const declineTermsOfServiceHandler = () => {
		requestProviderLogout()
	}

	return (
		<Container className={className} style={{ borderRadius: 6 }}>
			<Header>{t('term-of-service.title', 'Welcome to GetGoing')}</Header>
			<Subheading>
				{t('term-of-service.subtitle', 'Your journey to the future of business travel starts now!')}
			</Subheading>
			<TextBlock>
				<Trans i18nKey="term-of-service.text">
					GetGoing is a commercial brand and registered trademark of BCD Travel. By way of accessing and/or utilizing
					any of our services, you agree that your personal data will be used in accordance with{' '}
					<Link href={PrivacyPolicyLinkByLanguage[getCurrentLocale()]} target="_blank">
						BCD's Global Privacy Policy
					</Link>
					.
				</Trans>
			</TextBlock>
			<TermsSection>
				<Checkbox checked={checked} onToggle={onToggle} />
				<CheckboxText>
					<Trans i18nKey="term-of-service.privacy-policy">
						I confirm that I have read{' '}
						<Link href={PrivacyPolicyLinkByLanguage[getCurrentLocale()]} target="_blank">
							BCD's Global Privacy Policy
						</Link>
						.
					</Trans>
				</CheckboxText>
			</TermsSection>

			<ButtonContainer>
				<Button color="secondary" paddingSize="lg" onClick={declineTermsOfServiceHandler}>
					{t('term-of-service.decline', 'Decline')}
				</Button>
				<Button color="primary" disabled={!checked} paddingSize="lg" onClick={acceptTermsOfServiceHandler}>
					{t('term-of-service.proceed', 'Proceed')}
				</Button>
			</ButtonContainer>
		</Container>
	)
}

export default TermsOfService
