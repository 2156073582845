import React from 'react'
import { useDispatch } from 'react-redux'

import { getCurrentLocale } from '@/lib/i18n/locale-utils'
import { useUser } from '@/lib/react-hooks/generic-hooks'
import { useUserPOS } from '@/lib/react-hooks/useUserPOS'
import { AdminUsersActions } from '@/redux/actions'
import { LocaleToCurrency, VoidCallback } from '@/travelsuit'
import { Currency } from '@/types/common'

export function useCurrencySelector() {
	const my = useUser()
	const dispatch = useDispatch()
	const [currency, setCurrency] = React.useState(my?.currency_code ?? LocaleToCurrency[getCurrentLocale()])
	const userExists = Boolean(my)

	const userPOS = useUserPOS({})

	const updateCurrency = (currency: Currency, cb?: VoidCallback, errCb?: VoidCallback) =>
		dispatch(AdminUsersActions.updateUserCurrency(currency, cb, errCb))

	const passOnChange = () => {
		window.location.reload()
	}

	const onChange = (updatedCurrency: Currency) => {
		setCurrency(updatedCurrency)

		if (userExists) {
			if (updatedCurrency !== currency) {
				updateCurrency(updatedCurrency, passOnChange)
			}
		} else {
			passOnChange()
		}
	}

	return {
		companyCurrency: userPOS?.country.currency,

		onChange,
		currency,
	}
}
