import { getStoreState } from '@/redux/stores'

import { PermissionRole, UserRole, UserRoleNames } from '.'

export function prepareUserRoleJson(userRole: UserRole) {
	const copy = { ...userRole }
	delete copy.id
	return copy
}

export function getRole(name: UserRoleNames): PermissionRole | undefined {
	const allRoles = getStoreState().roles.getWithFallback('roles', []) as PermissionRole[]
	return allRoles.find((ur: PermissionRole) => ur.name === name)
}
