import { ErrorCode } from '@/types/error/ErrorCode'
import { isSpecificError } from '@/types/error/utils'

export type AppErrorOptions = { code: ErrorCode; message?: string } & ErrorOptions

export class AppError extends Error {
	static from(code: ErrorCode, message?: string) {
		return new AppError({ code, message })
	}

	static is(error?: unknown, code?: ErrorCode | ErrorCode[]): error is AppError {
		return isSpecificError(AppError, error, code)
	}

	code: ErrorCode

	constructor(options?: AppErrorOptions) {
		super(options?.message ?? options?.code ?? ErrorCode.unknown, options)
		this.code = options?.code ?? ErrorCode.unknown
	}
}
