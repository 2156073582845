import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { HotelResult, SearchResult, User } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { SearchResultsTypes } from '../actions/actions.types'

export type SearchResultsState = ImmutableMap<number, SearchResult>

interface HotelResultSet {
	result: SearchResult
	hotel: HotelResult
	idx: number
}

function getHotel(state: SearchResultsState, searchId: number, hotelId: string): HotelResultSet | null {
	const result = state.get(searchId)

	if (!result || !result.hotel_results) {
		return null
	}

	const idx = (result.hotel_results || { results: [] }).results.findIndex((r) => r.id === hotelId)!

	if (idx < 0) {
		return null
	}

	return { result, idx, hotel: { ...result.hotel_results.results[idx] } }
}

function searchResultsReducer(
	state: SearchResultsState = new ImmutableMap<number, SearchResult>(),
	action: IAction,
): SearchResultsState {
	let user: User
	let r: HotelResultSet | null
	let idx: number
	let payload: { searchId: number; id: string; user: User }
	switch (action.type) {
		case SearchResultsTypes.UnsetSearchResult:
			return state.delete(action.payload.searchId)
		case SearchResultsTypes.SetSearchResult:
			return state.set(action.payload.id, action.payload)
		case SearchResultsTypes.LikeHotel.REQUEST:
		case SearchResultsTypes.UnlikeHotel.FAILED:
			payload = action.type === SearchResultsTypes.LikeHotel.REQUEST ? action.payload : action.requestPayload
			r = getHotel(state, payload.searchId, payload.id)
			user = payload.user

			if (!r) {
				return state
			}

			if (!r.hotel.liked_users.find((u) => u.id === user.id)) {
				r.hotel.liked_users.push(user)
			}

			// @ts-expect-error - todo fix type
			r.result.hotel_results![r.idx] = r.hotel

			return state.set(payload.searchId, r.result)
		case SearchResultsTypes.UnlikeHotel.REQUEST:
		case SearchResultsTypes.LikeHotel.FAILED:
			payload = action.type === SearchResultsTypes.UnlikeHotel.REQUEST ? action.payload : action.requestPayload
			r = getHotel(state, payload.searchId, payload.id)
			user = payload.user

			if (!r) {
				return state
			}

			idx = r.hotel.liked_users.findIndex((u) => u.id === user.id)

			if (idx > -1) {
				r.hotel.liked_users.splice(idx, 1)
			}

			// @ts-expect-error todo if you see this please remove this comment and fix the type error
			r.result.hotel_results![r.idx] = r.hotel

			return state.set(payload.searchId, r.result)
	}
	return state
}

export default searchResultsReducer
