import { carrierToAirline } from '@/lib/flight-utils'
import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { Airline, MinimalSegment } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { AirlinesTypes, ItineraryTypes } from '../actions/actions.types'

export type AirlinesState = ImmutableMap<string, Airline>

function airlinesReducer(state: AirlinesState = new ImmutableMap<string, Airline>(), action: IAction): AirlinesState {
	switch (action.type) {
		case AirlinesTypes.SetAirlines:
			for (const airline of action.payload ?? []) {
				state = state.set(airline.iata, airline)
			}
			return state
		case ItineraryTypes.GetItinerary.SUCCESS:
			action.payload.flights_bookings.forEach((booking: any) => {
				booking.flights.forEach((flight: any) => {
					flight.segments.forEach((seg: MinimalSegment) => {
						state = state.set(seg.carrier.code, carrierToAirline(seg.carrier))
						if (seg.operating_carrier) {
							state = state.set(seg.operating_carrier.code, carrierToAirline(seg.operating_carrier))
						}
					})
				})
			})
			return state
	}
	return state
}

export default airlinesReducer
