import { boxShadowBig } from '@/_vars'
import { ButtonBase } from '@/atoms/Button'
import FloatingContainer from '@/atoms/FloatingContainer/FloatingContainer'
import { RelativeContainer } from '@/atoms/GenericComponents/GenericComponents'
import { SvgIcon } from '@/atoms/SvgIcon/SvgIcon'
import { size } from '@/css-helpers/positioning'
import { backgroundGray, fontBlack, fontSecondary, hoverBlue } from '@/refactor/colors'
import { font } from '@/refactor/fonts'
import styled from '@/styles'
import { VoidCallback } from '@/travelsuit'
import { Currency } from '@/types/common'

export interface StateProps {
	currency: Currency
	companyCurrency?: Currency | null
	userExists: boolean
}

export interface DispatchProps {
	updateCurrency(currency: Currency, cb?: VoidCallback, errCb?: VoidCallback): void
}

export interface LocaleUpdateDetails {
	currency: Currency
}

export const Title = styled.h3`
	${font({ size: 14, weight: 'bold' })}
	margin-bottom: 20px;
`

export const LocaleCurrencySelectorContainer = styled(RelativeContainer)``

export const CurrencySelectorButton = styled(ButtonBase)`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	transition: all 150ms ease-in-out;
	padding: 5px;
	margin: -5px;
	${font({ size: 18, weight: 600 })}

	border: none;
	background: none;

	&:hover {
		background: ${hoverBlue};
	}
`
export const Menu = styled(FloatingContainer).attrs(() => ({
	background: true,
	dropShadow: true,
	animation: 'slideInTop',
	animationDuration: 300,
}))`
	${size(220, 'auto')}
	padding: 20px;
	border-radius: 10px;
	box-shadow: ${boxShadowBig};
`

export const CurrencyDisclaimer = styled.div`
	${font({ size: 12 })}
	color: ${fontSecondary};
	padding: 10px;
	padding-right: 20px;
	background: ${backgroundGray};
	border-radius: 10px;
	margin: 15px 0 0;

	& b {
		font-weight: bold;
		color: ${fontBlack};
		text-transform: uppercase;
	}
`

export const CurrencyIcon = styled(SvgIcon)`
	line-height: 20px;
`
