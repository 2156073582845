import React from 'react'

import { createLazyComponentWithSuspense } from '@/lib/createLazyComponentWithSuspense'
import { FontFamily } from '@/refactor/fonts'
import styled, { css } from '@/styles'

import { centeredAbsoluteCss } from '../GenericComponents'
import { Stack } from '../System'

interface IProps {
	className?: string
	style?: React.CSSProperties
	label?: React.ReactNode
	color?: 'primary' | 'light' | 'secondary' | 'contrast'
	variant?: 'animated' | 'circle'
	centered?: boolean
	fixed?: boolean
}

type IPropsNoVariant = Omit<IProps, 'variant'>

const AnimationLabel = styled.h2`
	font-family: ${FontFamily.Comfortaa};
	font-size: ${(p) => p.theme.typography.pxToRem(20)};
	text-align: center;
	margin-top: -80px;

	${(p) => p.theme.breakpoints.down('md')} {
		max-width: 100vw;
		padding: 0 ${(p) => p.theme.spacing(3)}px;
	}
`

const LazyAnimation = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "LottieAnimation" */ './SvgAnimation'),
	({ height }) => <div style={{ height: `${height}px` }} />,
)

const StyledStack = styled(Stack)<{ centered?: boolean; fixed?: boolean }>`
	${(p) => (p.centered ? centeredAbsoluteCss() : '')}
	${(p) =>
		p.fixed
			? css`
					position: fixed;
				`
			: ''}
`

export const AnimatedLoader: React.FunctionComponent<IPropsNoVariant> = (props) => {
	const { label, ...rest } = props
	return (
		<StyledStack justifyContent="center" {...rest}>
			<LazyAnimation height={500} />
			{label ? <AnimationLabel>{label}</AnimationLabel> : null}
		</StyledStack>
	)
}

export default AnimatedLoader
