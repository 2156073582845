import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { getSeatMapsResultIdentity, parseSeatMaps } from '@/lib/seatmap'
import { ParsedSeatMap } from '@/travelsuit/seatmaps'

import { IAction } from '../actions/action-helpers'
import { SeatmapTypes } from '../actions/actions.types'

type FlightsSeatMap = ImmutableMap<string, ParsedSeatMap[]>

export interface SeatmapState {
	flights: FlightsSeatMap
}

const emptySeatmapState: SeatmapState = {
	flights: new ImmutableMap(),
}

function seatmapReducer(state: SeatmapState = emptySeatmapState, action: IAction): SeatmapState {
	switch (action.type) {
		case SeatmapTypes.GetForFlight.UPDATE:
		case SeatmapTypes.GetForFlight.SUCCESS:
			state.flights = state.flights.set(
				getSeatMapsResultIdentity(action.requestPayload),
				parseSeatMaps(action.payload.seat_maps),
			)
			return { ...state }
	}
	return state
}

export default seatmapReducer
