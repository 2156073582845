import { diff } from 'deep-object-diff'
import { TFunction } from 'i18next'
import { isNil } from 'lodash'
import memoizeOne from 'memoize-one'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { stringify } from 'qs'
import React from 'react'
import { PartialDeep } from 'type-fest'

import { headerHeight } from '@/_vars'
import {
	Amenity,
	BindableLabel,
	CabinClassCodeLabels,
	CabinClassLabels,
	CabinClassName,
	Callback,
	CancellationStatus,
	ComparisonFn,
	GeoPoint,
	ItineraryFareData,
	ProductType,
	Segment,
	SelectOption,
	SortingFn,
	WithCancellationStatus,
} from '@/travelsuit'
import { Currency } from '@/types/common'
import { FlightFareGroupKey, FlightOptionKey, FlightPolicyDeviation } from '@/types/flights'

import { range } from './array-utils'
import { isIOS } from './browser'
import { getPriceLabel } from './getPriceLabel'
import { noop } from './noop'
import { Maybe } from './types'

export { range, noop }
momentDurationFormatSetup(moment)

export const MINUTE_IN_MILLISECONDS = 60 * 1000
export const HOUR_IN_MILLISECONDS = 60 * MINUTE_IN_MILLISECONDS
export const MINIMUM_PHONE_NUMBER_LENGTH = 7

export const EMPTY_ARRAY = Object.freeze([] as unknown[]) as unknown[]

/**
 * This function takes functions that receive callbacks as the last parameter
 * and causes them to behave like a Promise, allowing them to be chained
 * @param fn function to wrap
 * @param args... extra arguments that will be passed to the function when it fires
 */

enum validationFieldName {
	postal_code = 'postal_code',
	tax_number = 'tax_number',
}

type ValidationFieldName = keyof typeof validationFieldName

interface ValidationConstraint {
	regExp: RegExp
	validationMessage: string
}

type ValidationFields = {
	[key in ValidationFieldName]: ValidationConstraint
}

interface CapitalizeOptions {
	forceCaseOnAllChars: boolean
	capitalization: Capitalization
	limit: number
}

enum Capitalization {
	Letters = 'letters',
	Words = 'words',
	Sentences = 'sentences',
}

const getCapitalizeOptions = entityGenerator<CapitalizeOptions>({
	forceCaseOnAllChars: false,
	capitalization: Capitalization.Words,
	limit: 0,
})

export function capitalize(str: string, options: Partial<CapitalizeOptions> = {}) {
	const config = getCapitalizeOptions(options)
	const sep =
		config.capitalization === Capitalization.Words
			? /\s+/g
			: config.capitalization === Capitalization.Sentences
				? /\\n+|\.+\s?/g
				: ''
	const join =
		config.capitalization === Capitalization.Words
			? ' '
			: config.capitalization === Capitalization.Sentences
				? '. '
				: ''
	str = String(str ?? '')
	let final = str
		.split(sep)
		.filter(Boolean)
		.map((s, i) => {
			s = s.trim()
			if ((config.limit && i >= config.limit) || !s.length) {
				return s
			}
			if (config.forceCaseOnAllChars) {
				return s[0].toLocaleUpperCase() + s.slice(1).toLocaleLowerCase()
			}
			return s[0].toLocaleUpperCase() + s.slice(1)
		})
		.join(join)
	if (str.trim().endsWith('.')) {
		final += '.'
	}
	return final
}

export function smartJoin(strs: string[], limit = 0, othersExpression = ' more', separator = ' and ') {
	if (strs.length < 2) {
		return strs.join(separator).trim()
	}

	const diff = strs.length - limit
	if (limit > 0 && diff > 0) {
		strs.length = Math.min(strs.length, limit)
		return (strs.join(', ') + separator + diff + othersExpression).trim()
	}

	return (strs.slice(0, -1).join(', ') + separator + strs.slice(-1)).trim()
}

function splitOnce(string: string, separator = ''): string[] {
	const components = string.split(separator)
	return [components.shift()!, components.join(separator)]
}

export function debounce<T extends Function = (...a: any[]) => void>(cb: T, delay: number): T {
	let timeout: number | undefined

	const paddedCb = (...args: any[]) => {
		return new Promise((resolve) => {
			if (timeout) {
				clearTimeout(timeout)
			}

			timeout = window.setTimeout(() => {
				resolve(cb(...args))
			}, delay)
		})
	}

	return paddedCb as unknown as T
}

export function objDiff<T = object>(obj1: Partial<T> = {}, obj2: Partial<T> = {}): Partial<T> {
	return diff(obj1, obj2)
}

export function pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
	return keys.reduce(
		(slice, k) => ({
			...slice,
			[k]: obj[k],
		}),
		{} as unknown as Pick<T, K>,
	)
}

export function exclude<T, K extends string | number | symbol>(
	obj: Exclude<T, undefined | null> | null | undefined,
	keys: K[],
): Omit<T, K> {
	if ([null, undefined].includes(obj as any)) {
		return obj as any
	}
	return Object.keys(obj!).reduce(
		(slice, k) => {
			if (!keys.includes(k as K)) {
				// @ts-expect-error todo if you see this please remove this comment and fix the type error
				slice[k] = obj![k]
			}
			return slice
		},
		{} as unknown as Omit<T, K>,
	)
}

export function composeComparators<T>(...comparators: SortingFn<T>[]): SortingFn<T> {
	return (a: T, b: T) => {
		for (const comparator of comparators) {
			const result = comparator(a, b)
			if (result !== 0) {
				return result
			}
		}
		return 0
	}
}

const NON_ALPHA = /[^a-z]/gi
const NON_NUMERIC = /[^0-9]/g

/** @deprecated Use `(a, b) => a.localeCompare(b, 'en', { numeric: true })` instead */
export function sortAlphaNum<T = any>(map?: (a: T) => string): SortingFn<T> {
	return (a: T, b: T) => {
		const mappedA = String(map ? map(a) : a)
		const mappedB = String(map ? map(b) : b)
		const alphaA = mappedA.replace(NON_ALPHA, '')
		const alphaB = mappedB.replace(NON_ALPHA, '')

		if (alphaA === alphaB) {
			const numericA = Number(mappedA.replace(NON_NUMERIC, ''))
			const numericB = Number(mappedB.replace(NON_NUMERIC, ''))
			return numericA === numericB ? 0 : numericA > numericB ? 1 : -1
		} else {
			return alphaA > alphaB ? 1 : -1
		}
	}
}

export function sortGetter<O = any>(key: string): SortingFn<O>
export function sortGetter<O = any>(key: string, a: O, b: O, defaultValue?: number): number
export function sortGetter<O = any>(key: string, a?: O, b?: O, def?: number): number | SortingFn<O> {
	if (a !== undefined && b !== undefined) {
		// @ts-expect-error todo if you see this please remove this comment and fix the type error
		return (a[key] || def) - (b[key] || def)
	}
	// @ts-expect-error todo if you see this please remove this comment and fix the type error
	return (_a, _b) => _a[key] - _b[key]
}

export function geoDistanceKm(point1: GeoPoint, point2: GeoPoint): number {
	//taken from https://www.movable-type.co.uk/scripts/latlong.html
	const { latitude: lat1, longitude: lon1 } = point1
	const { latitude: lat2, longitude: lon2 } = point2

	const R = 6371e3
	const phi1 = (lat1 * Math.PI) / 180
	const phi2 = (lat2 * Math.PI) / 180
	const deltaPhi = ((lat2 - lat1) * Math.PI) / 180
	const deltaLambda = ((lon2 - lon1) * Math.PI) / 180

	const a =
		Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
		Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

	return (R * c) / 1000
}

interface TSScrollToOptions {
	x?: number
	y?: number
	scrollBehavior?: ScrollBehavior
	scrollOffsetX?: number
	scrollOffsetY?: number
	scrollElement?: HTMLElement
}

export function scrollTo({
	x,
	y,
	scrollBehavior = 'smooth',
	scrollOffsetY = headerHeight,
	scrollOffsetX = 0,
	scrollElement,
}: TSScrollToOptions) {
	const conf: ScrollToOptions = {
		behavior: scrollBehavior,
	}

	if (x !== undefined) {
		conf.left = x - scrollOffsetX
	}

	if (y !== undefined) {
		conf.top = y - scrollOffsetY
	}

	if (scrollElement) {
		scrollElement.scrollTo(conf)
	} else {
		window.scrollTo(conf)
	}
}

export type MapDelegate<T, R> = (k: T, i: number) => R

function isSelectOption(arg: any): arg is SelectOption {
	return arg.value !== undefined && arg.label !== undefined
}

export function isSelectOptionsArray(arr: any[] = []): arr is SelectOption[] {
	return arr.every(isSelectOption)
}

export function mapSelectOptions<T, R = T, L = React.ReactNode>(
	arr: readonly T[] = [],
	{ mapLabel, mapValue }: { mapLabel?: MapDelegate<T, L>; mapValue?: MapDelegate<T, R> } = {},
): Array<SelectOption<R, L>> {
	return (arr as T[]).map((t, i) => mapSelectOption(t, i, { mapLabel, mapValue }))
}

function mapSelectOption<T, R = T, L = React.ReactNode>(
	t: T,
	i: number,
	{ mapLabel, mapValue }: { mapLabel?: MapDelegate<T, L>; mapValue?: MapDelegate<T, R> } = {},
): SelectOption<R, L> {
	mapLabel = mapLabel ?? (((o: any) => String(o)) as unknown as MapDelegate<T, L>)
	mapValue = mapValue ?? (noop as any)
	return { label: mapLabel!(t, i), value: mapValue!(t, i) }
}

export function resolveBindableLabel<T extends BindableLabel<unknown>>(thisArg: object, label: T): React.ReactNode {
	return typeof label === 'function' ? label.call(thisArg) : label
}

export function getDuration(from: Date | string, to: Date | string) {
	const fromTime = new Date(from).getTime()
	const toTime = new Date(to).getTime()

	const millisecondsDiff = fromTime > toTime ? fromTime - toTime : toTime - fromTime

	return moment.duration({ milliseconds: millisecondsDiff })
}

export const getDurationFormat = (t: TFunction) => ({
	Short: {
		/**	En key: `h[h] m[m] s[s]`, En result: `7h 45m 3s` */
		HoursToSeconds: t('duration-formats.hours-to-seconds', 'h[h] m[m] s[s]'),
		/**	En key: `w[w] d[d] h[h] m[m]`, En result: `3w 2d 7h 45m 3s` */
		WeeksToMinutes: t('duration-formats.weeks-to-minutes', 'w[w] d[d] h[h] m[m]'),
		/**	En key: `w[w] d[d]`, En result: `3w 2d` */
		WeeksToDays: t('duration-formats.weeks-to-days', 'w[w] d[d]'),
	},
})

export function getHumanizedDuration(t: TFunction, from: Date | string, to: Date | string, format?: string) {
	if (!format) {
		format = getDurationFormat(t).Short.WeeksToMinutes
	}
	const duration = getDuration(from, to)
	return humanizedDurationLabel(t, duration, format)
}

export function humanizedDurationLabel(
	t: TFunction,
	duration: moment.Duration | moment.DurationInputArg1,
	format?: string,
) {
	if (!format) {
		format = getDurationFormat(t).Short.WeeksToMinutes
	}
	if (!moment.isDuration(duration)) {
		duration = moment.duration(duration)
	}
	return duration.format(format!).replace(/\b0\s?[wdhmsy][a-z]*\b/g, '')
}

export function cabinClassNameAndLetter(
	cabinClass: Segment['cabin_class'],
	brandName?: string,
	letter?: string,
): { cabinClass: keyof ReturnType<typeof getCabinClassNameTranslations>; letter?: string } {
	const name = getCabinClassName(cabinClass)

	if (!letter) {
		// @ts-expect-error todo if you see this please remove this comment and fix the type error
		return { cabinClass: name }
	}

	// @ts-expect-error todo if you see this please remove this comment and fix the type error
	return { cabinClass: brandName ? fareBrandName(brandName) : name, letter }
}

export function getSegmentMeal(seg: { amenities: Amenity[] | null }): boolean {
	return (seg.amenities || []).some((amenity) => amenity.amenity === 'fresh_food' && amenity.exists)
}

export function getCabinClassName(cabinClass: Segment['cabin_class']): CabinClassName {
	const className = CabinClassCodeLabels.hasOwnProperty(cabinClass)
		? // @ts-expect-error todo if you see this please remove this comment and fix the type error
			CabinClassCodeLabels[cabinClass]
		: CabinClassLabels[cabinClass]

	return className
}

export function getCabinClassNameTranslations(t: TFunction) {
	return {
		'flights.cabin-class-name.premium-first': t('flights.cabin-class-name.premium-first', 'Premium First'),
		'flights.cabin-class-name.first': t('flights.cabin-class-name.first', 'First'),
		'flights.cabin-class-name.premium-business': t('flights.cabin-class-name.premium-business', 'Premium Business'),
		'flights.cabin-class-name.business': t('flights.cabin-class-name.business', 'Business'),
		'flights.cabin-class-name.premium-economy': t('flights.cabin-class-name.premium-economy', 'Premium Economy'),
		'flights.cabin-class-name.economy': t('flights.cabin-class-name.economy', 'Economy'),
		'flights.cabin-class-name.first-class-train': t('flights.cabin-class-name.first-class-train', '1st class'),
		'flights.cabin-class-name.second-class-train': t('flights.cabin-class-name.second-class-train', '2nd class'),
	}
}

export function fareBrandName(fareName: string) {
	return capitalize(fareName.toLocaleLowerCase())
}

export function minMax<T = any>(arr: T[], key?: keyof T | ((item: T) => number)) {
	let min = 0
	let max = 0
	arr.forEach((item) => {
		const value = (() => {
			if (typeof key === 'string') {
				const _item: any = item
				return _item?.hasOwnProperty(key) ? item[key] : item
			} else if (typeof key === 'function') {
				return key(item)
			}
			return item
		})()

		// @ts-expect-error todo if you see this please remove this comment and fix the type error
		if ((!min && value) || (value && value! < min)) {
			min = value! as number
		}

		// @ts-expect-error todo if you see this please remove this comment and fix the type error
		if ((!max && value) || (value && value! > max)) {
			max = value! as number
		}
	})
	return [min!, max!]
}

export function minMaxItem<T = any>(arr: T[], key?: keyof T | ((item: T) => number)) {
	let minItem: T | null = null
	let maxItem: T | null = null
	let min = 0
	let max = 0
	arr.forEach((item) => {
		const value = (() => {
			if (typeof key === 'string') {
				const _item: any = item
				return _item?.hasOwnProperty(key) ? item[key] : item
			} else if (typeof key === 'function') {
				return key(item)
			}
			return item
		})()

		// @ts-expect-error todo if you see this please remove this comment and fix the type error
		if ((!min && value) || (value && value! < min)) {
			min = value! as number
			minItem = item
		}

		// @ts-expect-error todo if you see this please remove this comment and fix the type error
		if ((!max && value) || (value && value! > max)) {
			max = value! as number
			maxItem = item
		}
	})

	return [minItem!, maxItem!]
}

export type HotelThumbResolutions = '64x64' | '75x75' | '669x414' | '210x144'

export const HotelThumbSizeMap: Record<HotelThumbResolutions, string> = {
	'64x64': '64x64',
	'75x75': '75x75',
	'669x414': '669x414',
	'210x144': '210x144',
}

/**
 * Time formats for data transformation only, not for displaying to the user because these are not multi locale.
 * @see {@link TimeFormatKeys} and {@link MultiLocaleTimeFormats} for time formats that are safe to be displayed.
 */
export const TimeFormatsLocaleUnsafe = {
	'24HoursWithSeconds': 'HH:mm:ss',
	'24Hours': 'HH:mm',
	DateOnly: 'YYYY-MM-DD',
	FullDateAndTime: 'YYYY-MM-DD[T]HH:mm',
}

export const getTimeFormat = (t: TFunction) => ({
	/**	En key: `ddd, MMM D`, En result: `Tue, Mar 17` for date: 17-03-2020 */
	DayNameAndDayMonthName: t('date-time-formats.day-name-and-day-month-name', 'ddd, MMM D'),
	/**	En key: `MMM D`, En result: `Mar 17` for date: 17-03-2020 */
	DayAndMonthName: t('date-time-formats.day-and-month-name', 'MMM D'),
	/**  En key: `MMM D LT`, En result: `Mar 17 12:30 PM` for date 17-03-2020 */
	MonthNameDayAndTime: t('date-time-formats.month-name-day-and-hour', 'MMM D LT'),
	/**  En key: `DD/MM`, En result: `17/03` for date 17-03-2020 */
	DayAndMonth: t('date-time-formats.day-and-month', 'DD/MM'),
	/**  En key: `MMM DD, YYYY, HH:mm`, En result: `Mar 17, 2020, 12:30 PM, ` for date 17-03-2020 */
	DateShortMonthYearAndTime: 'MMM DD, YYYY, hh:mm A',
})

/**
 * Time formats that support all locales, you can safely use them to display time and dates to the user.
 */
export const MultiLocaleTimeFormats = {
	/** En result: `8:30 PM` */
	Time: 'LT',
	/** En result: `8:30:25 PM` */
	TimeWithSeconds: 'LTS',
	/** En result: `09/04/1986` */
	DateZeroPadding: 'L',
	/** En result: `9/4/1986` */
	Date: 'l',
	/** En result: `September 4, 1986` */
	FullReadableDate: 'LL',
	/** En result: `Sep 4, 1986` */
	FullReadableDateShortMonth: 'll',
	/** En result: `Thursday, September 4, 1986 8:30 PM` */
	FullReadableDateWithTime: 'LLLL',
	/** En result: `Thu, Sep 4, 1986 8:30 PM` */
	FullReadableDateWithTimeShort: 'llll',
	/** En result: `Thursday` */
	DayName: 'dddd',
	/** En result: `September` */
	MonthName: 'MMMM',
	/** En result: `09/20` */
	CreditCardExpiration: 'MM / YY',
	/** En result: 22 Jul 2021, 19:00 */
	FullReadableDateShortMonthWithTime: 'DD MMM YYYY, LT',
	/** En result: Jul 31, 2023, 2:00 AM */
	FullReadableDateMonthFirstWithTime: 'MMM DD, YYYY, LT',
}

function getKeyAsReturnValue(obj: any, key?: string) {
	if (!key) {
		return obj
	}

	if (!obj) {
		return
	}

	if (obj.hasOwnProperty(key)) {
		if (typeof obj[key] === 'function') {
			return obj[key]()
		}

		return obj[key]
	}

	return
}

export function unionArrays<T = any>(arr1: T[] = [], arr2: T[] = [], key?: keyof T): T[] {
	return [...arr1, ...arr2].reduce((unioned, item) => {
		if (
			unioned.map((u) => getKeyAsReturnValue(u, key as string)).indexOf(getKeyAsReturnValue(item, key as string)) === -1
		) {
			unioned.push(item)
		}
		return unioned
	}, [] as T[])
}

export function unique<T = any, R = any>(arr: T[] = [], comp?: Callback<R, T>) {
	const compFn = comp || ((a: T) => a)
	const cache: Array<ReturnType<typeof compFn>> = []
	const out: T[] = []
	for (const i of arr) {
		const v = compFn(i)
		if (!cache.includes(v)) {
			out.push(i)
			cache.push(v)
		}
	}
	return out
}

export function getFormattedDateRange(
	startTime: moment.MomentInput,
	endTime: moment.MomentInput,
	longFormat = MultiLocaleTimeFormats.FullReadableDateShortMonth,
): string[] {
	return [moment(startTime).format(longFormat), moment(endTime).format(longFormat)]
}

export function smartDateRange(
	start: moment.MomentInput,
	end: moment.MomentInput,
	options?: {
		includeYear: boolean
	},
): string[] {
	const curYear = new Date().getFullYear()
	const from = moment(start)
	const to = moment(end)

	const includeMonth = from.month() !== to.month() || from.year() !== to.year()
	const includeYear =
		options?.includeYear || from.year() !== to.year() || from.year() !== curYear || to.year() !== curYear
	const isSameYear = from.year() === to.year()
	let fromFormat = 'MMM D'
	let toFormat = 'D'
	if (includeMonth) {
		toFormat = 'MMM ' + toFormat
	}
	if (includeYear) {
		if (!isSameYear) {
			fromFormat += ', YYYY'
		}
		toFormat += ', YYYY'
	}

	return [from.format(fromFormat), to.format(toFormat)]
}

export function parseQueryString<K extends string = string>(text: string): Record<K, string> {
	const obj: Record<K, string> = {} as any
	text = text.startsWith('#') || text.startsWith('?') ? text.slice(1) : text
	text.split('&').forEach((kv) => {
		const [key, value] = splitOnce(kv, '=')
		// @ts-expect-error todo if you see this please remove this comment and fix the type error
		obj[key] = value ? decodeURIComponent(value) : 'true'
	})

	return obj
}

export function dumpQueryString(obj: Record<string, any>) {
	let str = ''
	for (const key of Object.keys(obj)) {
		if (key && obj.hasOwnProperty(key) && obj[key] !== undefined) {
			str += `&${key}=${encodeURIComponent(obj[key])}`
		}
	}
	return str.slice(1)
}

export function getNightCount(from: moment.MomentInput, to: moment.MomentInput) {
	return Math.floor(
		moment.duration({ from: moment(from).startOf('day'), to: moment(to).startOf('day') }, 'days').asDays(),
	)
}

export function isDaysPeriodFromTodaytoDate(days: number, endDate: moment.MomentInput) {
	return getNightCount(moment().startOf('day').toDate(), endDate) === days
}

const DEFAULT_DAYS_QUANTITY = 1

export const getCarNightCount = (from: moment.MomentInput, to: moment.MomentInput) =>
	getNightCount(from, to) || DEFAULT_DAYS_QUANTITY

export function getFlightNightCount(from: string, to: string) {
	return Math.floor(
		moment
			.duration(
				{ from: moment(from.split('T')[0]).startOf('day'), to: moment(to.split('T')[0]).startOf('day') },
				'days',
			)
			.asDays(),
	)
}

export const paramsSerializer = (prms: any) => {
	return stringify(prms, { arrayFormat: 'repeat' })
}

export const validateEmail = (email: string) => {
	// eslint-disable-next-line no-useless-escape
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLocaleLowerCase())
}

type Directions = 'top' | 'bottom' | 'left' | 'right'

export type AttachDirection = {
	[key in Directions]?: boolean
}

export interface Coords<T = number | string> {
	x: T
	y: T
}

export class Mathf {
	public static clamp(value: number, min: number, max: number) {
		return Math.min(Math.max(value, min), max)
	}
	public static clamp01(value: number) {
		return this.clamp(value, 0, 1)
	}
	public static lerp(value: number, min: number, max: number) {
		return this.clamp01(value) * (max - min) + min
	}
	public static inverseLerp(value: number, min: number, max: number) {
		return (value - min) / (max - min)
	}
}

/** Method decorator to memoize the arguments and result */
export function memoized<A = any>(isEqual?: ComparisonFn<A>): MethodDecorator {
	return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
		return {
			configurable: true,
			enumerable: descriptor.enumerable,
			get: function getter() {
				Object.defineProperty(this, propertyKey, {
					configurable: true,
					enumerable: descriptor.enumerable,
					value: memoizeOne(descriptor.value.bind(this), isEqual),
				})
				// @ts-expect-error todo if you see this please remove this comment and fix the type error
				return this[propertyKey]
			},
		}
	}
}

/** Sorts by each element in the `fns` array, left to right, breaking on the first non-equal result.
 * @param fns Array of sorting functions, same as the input for `Array.prototype.sort`
 * @returns the first comparison result that isn't equal, or the last comparison if all before are equal.
 */
export function multipleSort<T = any>(fns: Array<SortingFn<T> | undefined | null>) {
	return (a: T, b: T) => {
		let r = 0
		for (const fn of fns.filter(Boolean)) {
			r = fn!(a, b)
			if (r !== 0) {
				return r
			}
		}

		return r
	}
}

/**
 * Predicate for getting a [Boolean] out of a wider range of value types.
 * Regular boolean conversion is checked first, and if that is true, also validates against common "falsy" values.
 * @param obj any object to be compared
 * @returns [Boolean]
 */
export function isBoolPredicate(obj: any): boolean {
	return Boolean(obj) && !['0', 'f', 'off', 'false', 'null', 'undefined'].includes(String(obj).toLocaleLowerCase())
}

/** Method decorator to debounce the call with a delay */
export function debounced(ms: number): MethodDecorator {
	return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
		return {
			configurable: true,
			enumerable: descriptor.enumerable,
			get: function getter() {
				Object.defineProperty(this, propertyKey, {
					configurable: true,
					enumerable: descriptor.enumerable,
					value: debounce(descriptor.value.bind(this), ms),
				})
				// @ts-expect-error todo if you see this please remove this comment and fix the type error
				return this[propertyKey]
			},
		}
	}
}

export function existsInDOMParents(current: HTMLElement, target: HTMLElement): boolean {
	if (current === target) {
		return true
	}

	if (!current.parentElement) {
		return false
	}

	return existsInDOMParents(current.parentElement!, target)
}

/**
 * Returns a function, which should be called when trying to pass callbacks.
 * It is similar to [Promis.all], where the callback will only run when all the awaiters are called
 * @param fn A callback function when the counter reaches 0
 */
export function createCountedAwaiter(fn: (...a: any[]) => void) {
	let count = 0
	return () => {
		count++
		return (...args: any[]) => {
			count--
			if (count <= 0) {
				fn(...args)
			}
		}
	}
}

export function entityGenerator<R, O = R>(input: R): (overrides?: Partial<O>) => R
export function entityGenerator<R>(input: R): (overrides?: Partial<R>) => R {
	return (overrides?: any) => {
		return { ...input, ...overrides }
	}
}

export interface RangeLabelConfig {
	t: TFunction
	beforeBelow?: string
	beforeAbove?: string
	afterBelow?: string
	afterAbove?: string
	showAll?: boolean
}

export function rangeLabel(
	vals: number[],
	min: number,
	max: number,
	defaultString: string,
	mapItem: (i: number) => string,
	config: RangeLabelConfig = { t: noop },
) {
	const aboveMin = vals[0] > min
	const belowMax = vals[1] < max
	const val0 = mapItem(vals[0])
	const val1 = mapItem(vals[1])
	const priceRange = `${val0} - ${val1}`

	return aboveMin && belowMax
		? priceRange
		: aboveMin
			? `${config.beforeAbove ?? config.t('range-labels.above', 'Above')} ${val0} ${config.afterAbove ?? ''}`.trim()
			: belowMax
				? `${config.beforeBelow ?? config.t('range-labels.below', 'Under')} ${val1} ${config.afterBelow ?? ''}`.trim()
				: !config.showAll
					? defaultString
					: priceRange
}

interface EventStopperParams<E extends EventStopperEvent> {
	stopPropagation?: boolean
	preventDefault?: boolean
	condition?(e: E): boolean
}

type EventStopperEvent = React.SyntheticEvent
type EventStopperCb<E extends EventStopperEvent> = Callback<void, E>
type EventStopperCbOrParams<E extends EventStopperEvent> = EventStopperCb<E> | EventStopperParams<E>
type EventStopper<E extends EventStopperEvent> = (e: E) => void
type EventStopperCreateFn = <E extends EventStopperEvent>(
	paramsOrCb?: EventStopperCbOrParams<E>,
	cb?: EventStopperCb<E>,
) => EventStopper<E>

interface EventStopperFn {
	<E extends EventStopperEvent>(paramsOrCb?: EventStopperCbOrParams<E>, cb?: EventStopperCb<E>): EventStopper<E>
	<E extends EventStopperEvent>(cb?: EventStopperCb<E>): EventStopper<E>
	stopPropagation: EventStopperCreateFn
	preventDefault: EventStopperCreateFn
}

function isEventStoppedParams<T extends EventStopperEvent>(params: any): params is EventStopperParams<T> {
	return typeof params === 'object'
}

function createEventStopper(
	defaultParams: EventStopperParams<EventStopperEvent> = { preventDefault: true, stopPropagation: true },
): EventStopperCreateFn {
	return <E extends EventStopperEvent>(
		paramsOrCb?: EventStopperCbOrParams<E>,
		cb?: EventStopperCb<E>,
	): EventStopper<E> => {
		let params: EventStopperParams<E>
		let callback: EventStopperCb<E> | undefined

		if (isEventStoppedParams(paramsOrCb)) {
			params = paramsOrCb
			callback = cb
		} else if (isEventStoppedParams(cb)) {
			params = cb
		} else {
			callback = paramsOrCb
			params = {}
		}

		const { stopPropagation = true, preventDefault = true, condition } = { ...defaultParams, ...params }

		return (e: E) => {
			if (e) {
				if (!condition || condition(e)) {
					if (stopPropagation) {
						e.stopPropagation()
					}
					if (preventDefault) {
						e.preventDefault()
					}
				}
				e?.persist()
			}
			if (callback) {
				callback(e)
			}
		}
	}
}

export const eventStopper: EventStopperFn = Object.assign(createEventStopper(), {
	stopPropagation: createEventStopper({ preventDefault: false, stopPropagation: true }),
	preventDefault: createEventStopper({ preventDefault: true, stopPropagation: false }),
})

export function handleIOSInput() {
	if (isIOS) {
		scrollTo({ y: 0 })
	}
}

export enum Key {
	Enter = 'Enter',
	Up = 'ArrowUp',
	Down = 'ArrowDown',
	Tab = 'Tab',
}

interface ElementMatcherOptions<R extends HTMLElement = HTMLElement> {
	selector?: string
	match?(element: R): boolean
}

function elementMatcher<R extends HTMLElement = HTMLElement>(element: R, options: ElementMatcherOptions<R>): boolean {
	if (!element) {
		console.warn('No element specified for `elementMatcher`', { options })
		return false
	}
	if (options.selector) {
		return element.matches(options.selector)
	}

	if (options.match) {
		return options.match(element)
	}

	throw new Error('One of: [`selector` | `match`] must be specified')
}

export function getClosestParent<R extends HTMLElement = HTMLElement, T extends HTMLElement = HTMLElement>(
	el: T,
	options: ElementMatcherOptions<T>,
): R | null {
	const parent = el?.parentElement
	if (!el || !parent) {
		return null
	}

	if (!options.selector && !options.match) {
		return parent as unknown as R
	}

	return getClosestElement(parent!, options)
}

export function getClosestElement<R extends HTMLElement = HTMLElement, T extends HTMLElement = HTMLElement>(
	el: T | null,
	options: ElementMatcherOptions<T>,
): R | null {
	if (!el) {
		return null
	}
	if (elementMatcher(el, options)) {
		return el as unknown as R
	}
	return getClosestElement(el.parentElement!, options)
}

export function passArgsIfExists<T extends Function>(cb?: T, ...args: any[]) {
	cb?.(...args)
}

export function passArgsIfExistsCb<T extends Function>(cb?: T, ...args: any[]) {
	return (...moreArgs: any[]) => {
		cb?.(...args, ...moreArgs)
	}
}

export function autoCompleteOff(): string {
	return Math.round(Math.random() * 1000000).toString()
}

export function triggerInputChangeEvent(input: HTMLInputElement, newValue: string) {
	const oldValue = input.value
	input.value = newValue

	const ev = simulateEvent('change', { bubbles: true, cancelable: true })
	const tracker = (input as any)._valueTracker
	if (tracker) {
		tracker.setValue(oldValue)
	}
	input.dispatchEvent(ev)
}

function simulateEvent(type: string, init?: EventInit) {
	const ev: any = new Event(type, init)
	ev.simulated = true
	return ev
}

export function randomBetween(min: number, max: number): number
export function randomBetween(max: number): number
export function randomBetween(min: number, max?: number): number {
	if (max === undefined) {
		max = min
		min = 0
	}
	return Math.floor(Math.random() * max!) + min
}

export function isNotNil<T>(value: T): value is Exclude<T, null | undefined> {
	return !isNil(value)
}

export const timeFormatter = new Intl.DateTimeFormat('en-GB', {
	hour: 'numeric',
	minute: 'numeric',
	hour12: false,
})

export class TimeObject {
	public hours: number
	public minutes: number

	public get h() {
		return this.hours
	}
	public get hour() {
		return this.hours
	}

	public get m() {
		return this.minutes
	}
	public get minute() {
		return this.minutes
	}

	constructor(hours: number, minutes: number) {
		this.hours = hours
		this.minutes = minutes
	}

	public toTimeValueString() {
		const date = new Date()
		date.setHours(this.hours)
		date.setMinutes(this.minutes)
		return timeFormatter.format(date)
	}

	/** Returns a string representation of this object. */
	public toString() {
		return moment(this).format(MultiLocaleTimeFormats.Time)
	}

	public toJSON() {
		return this.toString()
	}

	static fromString(time?: string | TimeObject | null): TimeObject | undefined {
		if (!time) {
			return undefined
		}

		if (this.isTimeObject(time)) {
			return time
		}

		if (time.length < 5 || !time.includes(':')) {
			throw new Error('time string is invalid')
		}

		const d = moment(time, MultiLocaleTimeFormats.Time)
		return new TimeObject(d.get('hours'), d.get('minutes'))
	}

	static isTimeObject(time: any): time is TimeObject {
		return time instanceof TimeObject
	}
}

export function policyDeviationExtractor(policyDeviation: FlightPolicyDeviation[], inPolicy: boolean) {
	const policyReason = !inPolicy && policyDeviation ? policyDeviation.map((reason) => `${reason.name}`) : []

	return policyReason
}

export function getItineraryFlightKeysFromStore(str: string | null, id: number): ItineraryFareData {
	const allData = str?.split(';')
	const fareGroupKey = allData?.[0].split(':')[1].trim()
	const fareOptionKeys = allData?.[1].split(':')[1].trim().split(',')
	return {
		id: id,
		fare_group_key: fareGroupKey as FlightFareGroupKey,
		fare_options_keys: fareOptionKeys as FlightOptionKey[],
	}
}

export const isFlightsAndRailsModeActive = (mode: ProductType | null) => {
	if (mode === null) {
		return false
	}
	return [ProductType.Flights, ProductType.Rails].includes(mode)
}

export const removeEmpty = <T extends object>(rawObject: T): PartialDeep<T> => {
	const object = { ...rawObject }
	return Object.fromEntries(
		Object.entries(object)
			.filter(([_, v]) => v !== null && v !== undefined)
			.map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v]),
	) as PartialDeep<T>
}

const getConstraintsByCountryCode = (t: TFunction): Record<string, Partial<ValidationFields>> => ({
	DE: {
		postal_code: {
			regExp: /^\d{5}$/,
			validationMessage: t('onboarding.validation-messages.postal-code-DE', 'Postal Code should be 5 digits long'),
		},
		tax_number: {
			regExp: /^DE\d{9}$/i,
			validationMessage: t(
				'onboarding.validation-messages.tax-number-DE',
				'Tax Number for DE should be in format DE000000000',
			),
		},
	},
	US: {
		postal_code: {
			regExp: /^\d{5}?$/,
			validationMessage: t('onboarding.validation-messages.postal-code-US', 'Postal Code should be 5 digits long'),
		},
	},
})

const isDependentFieldValid = (
	value: Maybe<string>,
	countryCode: Maybe<string>,
	validationFieldName: ValidationFieldName,
	t: TFunction,
) => {
	if (!value || !countryCode) {
		return true
	}

	const constrainsByCountryCode = getConstraintsByCountryCode(t)
	const validationDependentField = constrainsByCountryCode[countryCode]?.[validationFieldName]

	return !validationDependentField || value.match(validationDependentField.regExp)
		? true
		: validationDependentField.validationMessage
}

export const isPostalCodeValid = (value: Maybe<string>, countryCode: Maybe<string>, t: TFunction) => {
	return isDependentFieldValid(value, countryCode, validationFieldName.postal_code, t)
}

export const isTaxNumberValid = (value: string | null, countryCode: string, t: TFunction) => {
	return isDependentFieldValid(value, countryCode, validationFieldName.tax_number, t)
}

export function mergeMaps<K, V>(...args: Map<K, V>[]): Map<K, V> {
	return new Map(args.reduce((entriesArray, currentArg) => [...entriesArray, ...currentArg.entries()], []))
}

export function sortMap<K, V>(map: Map<K, V>, compareFn: ((a: [K, V], b: [K, V]) => number) | undefined): Map<K, V> {
	return new Map([...map.entries()].sort(compareFn))
}

export function isBookingSegmentActive(bookingSegment: WithCancellationStatus) {
	return (
		bookingSegment.cancellation_status === null ||
		bookingSegment.cancellation_status === CancellationStatus.CancellationFailed
	)
}

export const emptyStringsToNull = (values: Record<string, string | null>) => {
	return [...Object.keys(values)].reduce((result, key) => {
		return {
			...result,
			[key]: values[key]?.trim() === '' ? null : values[key],
		}
	}, {})
}

export const formatPrice = (price: number, currency: Currency) => {
	return getPriceLabel({ currency, price, keepZeroes: true })
}

export function shallowCopy<T>(value: T) {
	if (!value) {
		return value
	}

	return { ...value }
}

// ES2024 -- Promise.withResolvers
// Copy from https://github.com/microsoft/TypeScript/blob/1d96eb489e559f4f61522edb3c8b5987bbe948af/src/harness/util.ts#L121
export function promiseWithResolvers<T = void>() {
	let resolve!: (value: T | PromiseLike<T>) => void
	let reject!: (reason: unknown) => void
	const promise = new Promise<T>((res, rej) => {
		resolve = res
		reject = rej
	})

	return { promise, resolve, reject }
}

export function flattenObject(obj: any) {
	const toReturn = {}

	for (const i in obj) {
		if (!obj.hasOwnProperty(i)) {
			continue
		}

		if (
			typeof obj[i] === 'object' &&
			obj[i] !== null &&
			(!Array.isArray(obj[i]) || obj[i].some((item: any) => typeof item !== 'string'))
		) {
			const flatObject = flattenObject(obj[i])
			for (const x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) {
					continue
				}

				// @ts-expect-error todo if you see this please remove this comment and fix the type error
				toReturn[i + '.' + x] = flatObject[x]
			}
		} else {
			// @ts-expect-error todo if you see this please remove this comment and fix the type error
			toReturn[i] = obj[i]
		}
	}
	return toReturn
}

export function narrow<T, R extends T>(value: T, constraint: (value: T) => value is R): R | undefined
export function narrow<T, R extends T, O>(value: T, constraint: (value: T) => value is R, otherwise: O): R | O
export function narrow<T, R extends T, O>(
	value: T,
	constraint: (value: T) => value is R,
	otherwise?: O,
): R | O | undefined {
	return constraint(value) ? value : otherwise
}
