import { ApplicationState, getStoreState } from '@/redux/stores'
import { Company, TripApproval, User, UserRole, UserRoleNames } from '@/travelsuit'
import { UserStatus } from '@/types/user'

/**
 * This will not work properly if you try to use it on internalUser (the user that is logged in)
 * Because we do not get user_roles on the user object recieved from login process.
 * If you want to get roles for current user, don't pass a `user` argument at all.
 */
export function isAdminUser(user?: User) {
	const roles = (!user ? getStoreState().myUserRoles : user.user_roles) ?? []
	return roles.some((us) => Boolean(us.role?.is_admin))
}

export function getRoleFromUser(user: User, roleName: UserRoleNames): UserRole | null | undefined {
	return user?.user_roles?.length ? user.user_roles.find((us) => Boolean(us.role && us.role.name === roleName)) : null
}

export function doesUserHaveRole(user: User, roleName: UserRoleNames) {
	return Boolean(getRoleFromUser(user, roleName))
}

/**
 * This will not work properly if you try to use it on internalUser (the user that is logged in)
 * Because we do not get user_roles on the user object recieved from login process
 */
export function isCanBookForOthers(user: User) {
	return doesUserHaveRole(user, UserRoleNames.ManageBookings)
}

export function selectInternalUserFromState(state: ApplicationState): User {
	return state.auth.get('internalUser')
}

export function getInternalUser(): User {
	return selectInternalUserFromState(getStoreState())
}

export function isApprover(currentUser: User, approvers: User[] | undefined, tripApprovals: TripApproval[]) {
	return (
		(approvers ?? []).some((a) => a.id === currentUser.id) ||
		(tripApprovals ?? []).some((a) => a.user.id === currentUser.id)
	)
}

export function isUserMissingImportantDetails(user: User) {
	return !(user.bookable && !!user.invoice_profile)
}

export function isCompanyContactPersonUser(user: User, company: Company) {
	return user.id === company.company_contact_person?.id
}

export const isUserDeactivated = (status: UserStatus) => {
	return status === UserStatus.Deactivated
}

export const isUserCanBeInvited = (status: UserStatus) => {
	return status === UserStatus.Created || status === UserStatus.Invited
}
