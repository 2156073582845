import { track as _trackOriginal, TrackingProp, useTracking } from 'react-tracking'

import { matches, Routes } from '@/lib/route-utils'
import { User } from '@/travelsuit'

export { TrackingProp, _trackOriginal as track, useTracking }

/** Gets the type for the 4th parameter of the @track decorator */
export type AsyncTrackResult<F extends (...args: any[]) => any> = [Awaited<ReturnType<F>>] | [undefined, unknown]

type EnrichedAnalytics<T> = T & Record<'event' | 'pathname' | 'url' | 'page' | 'eventAction' | 'eventCategory', string>

function enrichTrackingInfo<T extends { action: string }>(trackingInfo?: T): EnrichedAnalytics<T> {
	const page = _getPageFromPath(document.location.pathname)
	const { action, ...data } = trackingInfo ?? {}

	return {
		event: 'ts-event',
		pathname: document.location.pathname,
		url: document.location.href,
		page,
		action,
		eventAction: action ?? '',
		eventCategory: page,
		...data,
	} as EnrichedAnalytics<T>
}

export type AnalyticsResponse<T = any> = { action: string } & { [K in keyof T]: T[K] }

export function trackEvent<T extends { action: string }>(trackingInfo: T): void {
	const w: Window & { dataLayer: any[] } = window as any
	const data = enrichTrackingInfo(trackingInfo)
	w.dataLayer = w.dataLayer ?? []
	w.dataLayer.push(data)
}

export type WithTracking<P = {}, S = any> = P & { tracking: TrackingProp<S> }

const _PAGE_MAPPING: Record<Routes, string> = {
	[Routes.Admin]: 'Admin',
	[Routes.Safety]: 'Safety',
	[Routes.Billing]: 'Billing',
	[Routes.BillingChangePlan]: 'Billing Change Plan',
	[Routes.BillingCurrent]: 'Billing Current',
	[Routes.BillingUsage]: 'Billing Usage',
	[Routes.BrowseTrip]: 'Browse Product',
	[Routes.BuildTrip]: 'Search Product',
	[Routes.CreateInvoiceProfile]: 'Invoice Profile',
	[Routes.CreateTravelerProfile]: 'Traveler Profile',
	[Routes.TravelerProfile]: 'Traveler Profile',
	[Routes.TravelerProfileUnusedTickets]: 'Traveler Profile Unused Tickets',
	[Routes.TravelerProfileUnpaidFee]: 'UnpaidFeeTravelerProfile',
	[Routes.EditDepartment]: 'Department',
	[Routes.EditTravelerProfile]: 'Traveler Profile',
	[Routes.Expenses]: 'Expenses',
	[Routes.ManageExpenses]: 'Manage Expenses',
	[Routes.NewStandardExpense]: 'Create Expense',
	[Routes.EditStandardExpense]: 'Review Expense',
	[Routes.NewMileage]: 'Create Mileage',
	[Routes.EditMileage]: 'Review Mileage',
	[Routes.NewDailyAllowance]: 'Create Daily Allowance',
	[Routes.EditDailyAllowance]: 'Review Daily Allowance',
	[Routes.EditExpenseInReport]: 'Review Expense',
	[Routes.EditMileageInReport]: 'Review Mileage',
	[Routes.EditDailyAllowanceInReport]: 'Review Daily Allowance',
	[Routes.ExpenseReports]: 'Expense Reports',
	[Routes.ExpenseReportsSubmitted]: 'Expense Reports',
	[Routes.NewExpenseReport]: 'New Expense Report',
	[Routes.EditExpenseReport]: 'Review Expense Report',
	[Routes.SelectExpensesInReport]: 'Select Expenses',
	[Routes.GeneralSettings]: 'General Settings',
	[Routes.ReportSettings]: 'Report Settings',
	[Routes.CorporateDiscountsAndRewardPrograms]: 'Corporate Discounts',
	[Routes.Home]: 'Home',
	[Routes.HotelResult]: 'Hotel Page',
	[Routes.Invoices]: 'Invoices',
	[Routes.Itinerary]: 'Itinerary',
	[Routes.LiveMap]: 'Live Map',
	[Routes.Login]: 'Login',
	[Routes.Logout]: 'Logout',
	[Routes.ManageLocations]: 'Manage Locations',
	[Routes.ManageTravelers]: 'Manage Travelers',
	[Routes.ManageDepartments]: 'Manage Departments',
	[Routes.ManagePolicy]: 'Manage Policy',
	[Routes.NewSearch]: 'New Search',
	[Routes.NewTrip]: 'New Trip',
	[Routes.PasswordChangeResult]: 'Password Change Result',
	[Routes.Reports]: 'Reports',
	[Routes.ReportsCars]: 'Reports Cars',
	[Routes.ReportsHotels]: 'Reports Hotels',
	[Routes.ReportsFlights]: 'Reports Flights',
	[Routes.ReportsOverview]: 'Reports Overview',
	[Routes.ReportsRails]: 'Reports Trains',
	[Routes.ReportsTravelers]: 'Reports Travelers',
	[Routes.ReportsUnusedTickets]: 'Reports Unused Tickets',
	[Routes.Support]: 'Support',
	[Routes.Trips]: 'Trips',
	[Routes.TripBuilder]: 'Search',
	[Routes.TripProduct]: 'Itinerary',
	[Routes.TripProductSearch]: 'Search',
	[Routes.TripProductSearchWithStep]: 'Search',
	[Routes.CreateCompanyInvoices]: 'CreateCompanyInvoices',
	[Routes.OnboardingConfirmation]: 'OnboardingConfirmation',
	[Routes.CallbackVerification]: 'CallbackVerification',
	[Routes.CreditCardCallbackVerification]: 'CreditCardCallbackVerification',
	[Routes.WordlineOrderCallbackVerification]: 'WordlineOrderCallbackVerification',
	[Routes.ActivateInvoiceProfile]: 'ActivateInvoiceProfile',
	[Routes.BookCustomerSuccessConsultant]: 'BookCustomerSuccessConsultant',
}

function _getPageFromPath(path: string): string {
	for (const i of matches(path, { exact: true }) ?? []) {
		if (Object.getOwnPropertyNames(_PAGE_MAPPING).includes(i)) {
			// @ts-expect-error todo if you see this please remove this comment and fix the type error
			return _PAGE_MAPPING[i]
		}
	}
	return path
}

export function sendUserFetchedEvent(user: User) {
	trackEvent({ event: 'ts-user-fetched', user, action: 'User Fetched' })
}
