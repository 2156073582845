import React from 'react'

import SvgIcon, { IProps as SvgIconProps } from '@/atoms/SvgIcon/SvgIcon'
import RoleBadge from '@/atoms/UserPhoto/RoleBadge'
import { E2E } from '@/lib/e2e-utils'
import { makeAbsoluteUrl } from '@/lib/staticFiles'
import ManAvatar from '@/refactor/assets/people/man.svg'
import WomanAvatar from '@/refactor/assets/people/woman.svg'
import { backgroundGray, darkBlue, mainBlack, white } from '@/refactor/colors'
import styled, { mediaQuery, padPx, size } from '@/styles'
import { Role, User } from '@/travelsuit'

type ComponentSizes = 28 | 36 | 40 | 50 | 100 | 152

export interface IProps extends E2E {
	className?: string
	user?: Partial<User> | null
	size?: ComponentSizes
	mobileSize?: ComponentSizes
	rounded?: boolean | 'left' | 'right' | string | number
	roleBadge?: Role
	src?: string
	color?: string
	backgroundColor?: string
}

const defaultProps: Partial<IProps> = {
	size: 36,
	mobileSize: 28,
	rounded: true,
}

interface StyleProps {
	size: number | string
	color?: string
	backgroundColor?: string
}

type SizedStyleMap = Record<ComponentSizes, StyleProps>

const iconSizeMap: SizedStyleMap = {
	28: { size: 15, color: white },
	36: { size: 20, color: white },
	40: { size: 22, color: white },
	50: { size: 28, color: white },
	100: { size: 32, color: mainBlack },
	152: { size: 50, color: mainBlack },
}

export const containerSizeMap: SizedStyleMap = {
	28: { size: 28, backgroundColor: darkBlue },
	36: { size: 36, backgroundColor: darkBlue },
	40: { size: 40, backgroundColor: darkBlue },
	50: { size: 50, backgroundColor: darkBlue },
	100: { size: 100, backgroundColor: backgroundGray },
	152: { size: 152, backgroundColor: backgroundGray },
}

const roundedStyles = (rounded: IProps['rounded']) => `
	border-radius:
		${
			(['left', 'right', true] as Array<IProps['rounded']>).includes(rounded)
				? `
			${([true, 'left'] as Array<IProps['rounded']>).includes(rounded) ? 2 : 0}px
			${([true, 'right'] as Array<IProps['rounded']>).includes(rounded) ? 2 : 0}px
			${([true, 'right'] as Array<IProps['rounded']>).includes(rounded) ? 2 : 0}px
			${([true, 'left'] as Array<IProps['rounded']>).includes(rounded) ? 2 : 0}px
		`
				: padPx(rounded)
		}
	;
`

const mappedSizeStyle = (
	map: SizedStyleMap,
	_size: ComponentSizes = defaultProps.size!,
	mobileSize: ComponentSizes = defaultProps.mobileSize!,
	backgroundColor?: string,
	color?: string,
) => `
	${size(map[_size].size, map[_size].size)}
  	background-color: ${backgroundColor || map[_size].backgroundColor};
	color: ${color || map[_size].color};
	${mobileSize !== _size ? mediaQuery.mobileOnly`${size(map[mobileSize].size, map[mobileSize].size)}`.join('') : ''}
`

const UserPhotoContainer = styled.div<IProps>`
	position: relative;
	flex-shrink: 0;
	cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`

const UserPhotoImage = styled.div<IProps>`
	background-image: url(${(props) => makeAbsoluteUrl(props.user!.image_url)});
	background-size: cover;
	background-position: center;
	${(props) => roundedStyles(props.rounded!)}
	${(props) => mappedSizeStyle(containerSizeMap, props.size!, props.mobileSize!, props.backgroundColor)}
`

const BlankUserPhotoContainer = styled.div<IProps>`
	display: block;
	position: relative;
	flex-shrink: 0;
	${(props) => roundedStyles(props.rounded!)}
	${(props) => mappedSizeStyle(containerSizeMap, props.size!, props.mobileSize!, props.backgroundColor, props.color)}
	cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`

const BlankUserPhoto = styled(SvgIcon).attrs((p) => ({ size: p.size ?? 60 }))<
	SvgIconProps & {
		color: IProps['color']
		photoSize: IProps['size']
		mobilePhotoSize: IProps['mobileSize']
	}
>`
	${(props) => mappedSizeStyle(iconSizeMap, props.photoSize!, props.mobilePhotoSize!, props.color)}
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

export const UserPhoto: React.FunctionComponent<IProps> = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
	const {
		className,
		src,
		user,
		size = defaultProps.size,
		mobileSize = defaultProps.mobileSize,
		rounded = defaultProps.rounded,
		roleBadge,
		e2e = 'UserPhoto',
		...rest
	} = props
	return user?.image_url ? (
		<UserPhotoContainer className={className} size={size!} mobileSize={mobileSize} {...rest} ref={ref}>
			<UserPhotoImage data-test={e2e} user={user} rounded={rounded} size={size!} mobileSize={mobileSize} {...rest} />
			{roleBadge && <RoleBadge role={roleBadge} />}
		</UserPhotoContainer>
	) : (
		<BlankUserPhotoContainer
			className={className}
			size={size}
			mobileSize={mobileSize}
			rounded={rounded}
			{...rest}
			ref={ref}
		>
			<BlankUserPhoto
				data-test={e2e}
				photoSize={size! as any}
				mobilePhotoSize={mobileSize}
				src={src || (user?.traveler_profile?.gender === 'female' ? WomanAvatar : ManAvatar)}
				color={rest.color}
			/>
			{roleBadge && <RoleBadge role={roleBadge} />}
		</BlankUserPhotoContainer>
	)
})

export const CircleUserPhoto = styled(UserPhoto).attrs(() => ({ rounded: '50%' }))``

export default UserPhoto
