import { MutableRefObject, useEffect } from 'react'

import { wchatRightOffset } from '@/_vars'
import { useDelayedAction } from '@/lib/react-hooks/useDelayedAction'

interface IParams {
	refContainer: MutableRefObject<HTMLDivElement | null>
	trigger: boolean
}

export const useChatPosition = ({ refContainer, trigger }: IParams) => {
	const executeAction = useDelayedAction()
	useEffect(() => {
		const root: HTMLElement = document.querySelector(':root')!

		if (!trigger || !root) {
			return undefined
		}

		executeAction(() => {
			const containerWidth = refContainer?.current?.getBoundingClientRect().width || 0
			root.style.setProperty('--wchat-right', containerWidth + wchatRightOffset + 'px')
		})

		return () => {
			root.style.setProperty('--wchat-right', `${wchatRightOffset}px`)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trigger, refContainer.current, executeAction])

	return null
}
