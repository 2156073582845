import { PackageUsageData } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { PackagesUsagesTypes } from '../actions/actions.types'

export type PackagesUsagesState = PackageUsageData[]

function packagesUsagesReducer(state: PackagesUsagesState = [], action: IAction): PackagesUsagesState {
	switch (action.type) {
		case PackagesUsagesTypes.GetUsageDetails.SUCCESS:
			return action.payload
	}
	return state
}

export default packagesUsagesReducer
