import { makeStyles } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MUIInput from '@material-ui/core/Input'
import { InputBaseComponentProps } from '@material-ui/core/InputBase'
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel'
import MUITextField from '@material-ui/core/TextField'
import { TextFieldProps as MUITextFieldProps } from '@material-ui/core/TextField'
import React, { useEffect } from 'react'

import { AutosizeTextArea, AutosizeTextAreaResizeProps } from '@/atoms/AutosizeTextArea'
import { E2E, passTestId } from '@/lib/e2e-utils'
import { removeProps } from '@/lib/react'
import { autoCompleteOff, randomBetween } from '@/lib/utils'
import { backgroundGray, mainBlack, secondaryBlack } from '@/refactor/colors'
import { css } from '@/styles'
import styled, { font, padPx, textOverflow } from '@/styles'
import { DefaultProps, MUIComponentClass, MUIFunctionComponent } from '@/travelsuit'

type InputDefaultKeys = 'onChange' | 'onFocus' | 'onBlur' | 'readOnly' | 'disabled'

export type InputComponentType = React.ComponentProps<typeof MUIInput>['inputComponent']

export type TextFieldProps = DefaultProps &
	Omit<
		MUITextFieldProps & AutosizeTextAreaResizeProps,
		'ref' | 'color' | 'onChange' | 'onFocus' | 'onBlur' | 'variant' | 'width'
	> &
	Pick<React.InputHTMLAttributes<HTMLInputElement>, InputDefaultKeys>

export interface IProps extends TextFieldProps, E2E {
	multiline?: boolean
	error?: boolean
	leading?: React.ReactNode
	trailing?: React.ReactNode
	disableSelectOnFocus?: boolean
	label?: React.ReactNode
	labelOffset?: number | string
	labelOffsetShrink?: number | string
	inputRef?:
		| ((ref: HTMLInputElement | HTMLTextAreaElement) => void)
		| React.MutableRefObject<HTMLInputElement>
		| React.RefObject<HTMLInputElement>
		| React.Ref<HTMLInputElement | HTMLTextAreaElement>
	disabled?: boolean
	hideLabel?: boolean
	shrinkLabel?: boolean
	name?: string
	inputComponent?: InputComponentType
	inputProps?: TextFieldProps['inputProps']
	variant?: 'filled' | 'filled-rounded'
	hideStepperArrows?: boolean
	allowNegativeNumbers?: boolean
}

const useMuiStyles = makeStyles(() => ({
	inputRoot: {},
	inputRootWithIcon: {
		marginLeft: -15,
		paddingLeft: 46,
	},
	labelRootWithIcon: {
		left: 46,
	},
	labelShrinkWithIcon: {
		left: 0,
	},
}))

const StyledMUITextField = styled(
	removeProps(MUITextField)<
		Pick<IProps, 'variant' | 'hideStepperArrows'> & {
			labelOffset?: number | string
			labelOffsetShrink?: number | string
			hideLabel?: boolean
			hasLeadingIcon?: boolean
		}
	>('labelOffset', 'labelOffsetShrink', 'hideLabel', 'variant', 'hideStepperArrows'),
)`
	& .MuiInputLabel-root {
		left: ${(props) => (props.labelOffset !== undefined ? padPx(props.labelOffset) : '')};
	}

	.MuiInputLabel-root {
		display: ${({ hideLabel, value }) => (hideLabel && value ? 'none' : '')};
	}

	& .MuiInputLabel-root {
		white-space: nowrap;
	}

	.MuiInputLabel-root:not(.MuiFormLabel-filled) {
		max-width: calc(100% - ${(p) => (p.hasLeadingIcon ? 50 : 30)}px);
		${textOverflow()}
	}

	.MuiInput-formControl {
		margin-top: ${({ hideLabel }) => (hideLabel ? '0' : '')};
	}

	.MuiInputLabel-root {
		margin-top: ${({ hideLabel }) => (hideLabel ? '-15px' : '')};
	}

	& .MuiInputLabel-shrink {
		left: ${(props) => (props.labelOffsetShrink !== undefined ? padPx(props.labelOffsetShrink) : '')};
	}

	.MuiInputBase-input {
		border-radius: ${(p) => (p.variant?.endsWith('-rounded') ? '2em' : undefined)};
		height: 1.2855em;
	}

	${(props) =>
		props.hideStepperArrows &&
		css`
			.MuiInputBase-input {
				appearance: textfield;

				::-webkit-inner-spin-button,
				::-webkit-outer-spin-button {
					appearance: none;
				}
			}
		`}

	${(p) => p.theme.breakpoints.down('md')} {
		.MuiInputBase-input:hover:not(:disabled):not(.Mui-disabled) {
			box-shadow: none;
		}

		.MuiInputBase-input:focus {
			background: ${backgroundGray};
		}
	}
`

const StyledInputLabel = styled(({ offset, offsetShrink, multiline, ...rest }) => <InputLabel {...rest} />)<{
	offset?: number | string
	offsetShrink?: number | string
	multiline?: boolean
}>`
	&.MuiInputLabel-root {
		left: ${(props) => (props.offset !== undefined ? padPx(props.offset) : '')};
	}

	&.MuiInputLabel-shrink {
		left: ${(props) => (props.offsetShrink !== undefined ? padPx(props.offsetShrink) : '')};
	}

	&.MuiInputLabel-formControl:not(.MuiInputLabel-shrink) {
		top: ${(props) => (props.multiline ? '12px' : '')};
	}
`

const StyledMUIInput = styled(MUIInput)`
	& input,
	& textarea {
		padding: 13px 20px;
	}

	flex-direction: column;
	align-items: flex-start;
`

export const IconContainer = styled.div`
	position: relative;
	left: 20px;
	z-index: 1;
`

const TextField: MUIFunctionComponent<IProps & E2E> = (props) => {
	const {
		className,
		multiline,
		leading,
		trailing,
		disableSelectOnFocus,
		onClick,
		inputRef,
		children,
		error,
		onFocus,
		onBlur,
		onChange,
		required,
		labelOffset,
		labelOffsetShrink,
		readOnly,
		rows,
		defaultValue,
		e2e: _e2e,
		'data-test': _dataTest,
		inputProps,
		hideLabel,
		shrinkLabel,
		inputComponent: InputComponent = AutosizeTextArea,
		hideStepperArrows,
		fullWidth = true,
		...rest
	} = props
	const [shrink, setShrink] = React.useState<boolean>(Boolean(typeof rest.value === 'string' && rest.value?.length))
	const [dirty, setDirty] = React.useState<boolean>(false)
	const value = (rest.value || defaultValue) as string
	const shrinkVal = Boolean(shrink || value?.toString().length || shrinkLabel)
	const isRequired = dirty && required
	const textareaId = rest.id ?? 'txtra-' + randomBetween(1000000).toString()

	useEffect(() => {
		if (shrink && !value) {
			setShrink(false)
		}
	}, [shrink, value])

	const classes = useMuiStyles()

	const finalInputLabelProps: Partial<InputLabelProps> = {
		classes: leading
			? {
					root: classes.labelRootWithIcon,
					shrink: classes.labelShrinkWithIcon,
				}
			: undefined,
		shrink: shrinkVal,
		required,
		error,
	}

	const finalInputProps: Partial<Omit<InputBaseComponentProps, 'color'>> = {
		classes: { input: leading ? classes.inputRootWithIcon : classes.inputRoot },
		startAdornment: leading ? <IconContainer>{leading}</IconContainer> : undefined,
		endAdornment: trailing,
		required: isRequired,
		readOnly,
		error,
	}

	const wrappedInputHandlers = {
		inputRef:
			typeof inputRef === 'function'
				? (newRef: HTMLInputElement | HTMLTextAreaElement) => {
						inputRef?.(newRef)
					}
				: inputRef,
		onFocus(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
			e.persist()
			if (!disableSelectOnFocus) {
				e.target.select()
			}
			setShrink(true)
			if (onFocus) {
				onFocus(e as any)
			}
		},
		onBlur(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
			e.persist()
			setTimeout(() => {
				if (e.target.value.length === 0) {
					setShrink(false)
				}
			}, 70)
			if (onBlur) {
				onBlur(e as any)
			}
		},
		onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
			e.persist()
			setShrink(e.target.value.length !== 0)
			setDirty(true)
			if (onChange) {
				onChange(e as any)
			}
		},
		onClick,
	}

	const input = !multiline ? (
		<StyledMUITextField
			hasLeadingIcon={!!leading}
			hideLabel={hideLabel}
			className={className}
			{...wrappedInputHandlers}
			onPaste={(e) => {
				if (rest.type === 'number') {
					e.persist()

					const value = e.clipboardData
						.getData('Text')
						.replace(props.allowNegativeNumbers ? /[^0-9.,-]/g : /[^0-9.,]/g, '')

					if (value !== e.clipboardData.getData('Text')) {
						e.preventDefault()
						e.stopPropagation()

						if (onChange) {
							onChange({
								target: {
									value,
								},
							} as any)
						}

						return false
					}
				}
				return true
			}}
			onKeyDown={(e) => {
				if (rest.type === 'number') {
					e.persist()

					const keyCodesOfNonnumericSymbols = [187, 173, 107, 109, 69]

					if ([...keyCodesOfNonnumericSymbols, ...(props.allowNegativeNumbers ? [] : [189])].includes(e.keyCode)) {
						e.preventDefault()
						e.stopPropagation()
						return false
					}
				}

				return true
			}}
			autoComplete={autoCompleteOff()}
			error={error}
			hideStepperArrows={hideStepperArrows ?? false}
			InputProps={finalInputProps}
			inputProps={{ ...inputProps, 'data-test': passTestId(props) }}
			InputLabelProps={finalInputLabelProps}
			labelOffset={labelOffset}
			labelOffsetShrink={labelOffsetShrink}
			defaultValue={defaultValue}
			data-test={passTestId(props, 'Container')}
			{...rest}
			fullWidth={fullWidth}
		/>
	) : (
		<FormControl fullWidth={fullWidth}>
			{rest.label ? (
				<StyledInputLabel
					htmlFor={textareaId}
					offset={labelOffset}
					offsetShrink={labelOffsetShrink}
					multiline
					{...finalInputLabelProps}
				>
					{rest.label}
				</StyledInputLabel>
			) : null}
			<StyledMUIInput
				inputComponent={InputComponent}
				className={className}
				id={textareaId}
				{...wrappedInputHandlers}
				autoComplete={autoCompleteOff()}
				error={error}
				fullWidth={fullWidth}
				required={isRequired}
				inputProps={{
					...finalInputProps,
					'data-test': passTestId(props),
					...(rest as InputBaseComponentProps),
					fullWidth,
				}}
				value={value}
				rows={rows}
				defaultValue={defaultValue}
				multiline
			>
				{(rest.value as any) ?? children}
			</StyledMUIInput>
		</FormControl>
	)

	return input
}

TextField.muiName = (MUITextField as MUIComponentClass<any, any>).muiName

export const DashTextField = styled(TextField).attrs(() => ({ multiline: true }))<{
	dashColor?: string
	dashWidth?: number
}>`
	& .MuiInputBase-input {
		background: white;
		border-bottom: ${(props) => `${padPx(props.dashWidth ?? 1)} dashed ${props.dashColor ?? mainBlack}`};

		&,
		&:hover,
		&:active,
		&:focus {
			box-shadow: none !important;
		}
	}
`

export const TrailingText = styled.span`
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	color: ${secondaryBlack};
	${font({ size: 14 })}
`

export default TextField
