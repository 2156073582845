import { Location } from 'history'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { matchPath, Route, Router } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Dispatch } from 'redux'
import { QueryParamProvider } from 'use-query-params'

import { appConfig } from '@/app-config/appConfig'
import CompanyTypeModal from '@/atoms/CompanyTypeModal/CompanyTypeModal'
import { ModalContextProvider } from '@/atoms/Modal/Modal'
import { RouteAwareModalApiProvider } from '@/atoms/ModalApi'
import { ShowSessionTimeoutModal } from '@/atoms/SessionTimeoutModal/ShowSessionTimeoutModal'
import { internalLogin } from '@/lib/auth'
import { initFreshChat } from '@/lib/fresh-chat.utils'
import history from '@/lib/history'
import { getCurrentLocale, getRegionFromLocale } from '@/lib/i18n/locale-utils'
import { Routes } from '@/lib/route-utils'
import AppBar from '@/organisms/AppBar/AppBar'
import AppRouter from '@/pages/App/AppRouter'
import { CompanyTypeModalActions } from '@/redux/actions'
import { ApplicationState } from '@/redux/stores'
import { Company, CompanyTypeModal as ICompanyTypeModal, User } from '@/travelsuit'
import { track, trackEvent } from '@/travelsuit/analytics'
import { Locales } from '@/types/locale'

import { WindowSizeContextProvider } from '../../lib/react-hooks/useBreakPoints'
import { AuthenticatedAppStateFetch } from './AuthenticatedAppStateFetch'

interface StateProps {
	myCompany: Company | null
	companyTypeModal: ICompanyTypeModal | null
	my: User
}

interface DispatchProps {
	showModalWindow(): void
	hideModalWindow(): void
}

type IProps = StateProps &
	DispatchProps & {
		className?: string
	}
@track<string>({}, { dispatch: trackEvent })
// TASK migrate to React.FunctionComponent OR remove this if not possible
class App extends React.Component<IProps> {
	private isFreshChatInit = false

	public componentDidMount() {
		const locale = getCurrentLocale()
		this.addGoogleAutocompleteScript(locale)
		this.setMomentLocale(locale)
		this.refreshUserLoginDetails()
		this.trackInitialPageView()
		this.applyGenericRedirect()
	}

	componentDidUpdate() {
		if (this.props.my && !this.isFreshChatInit) {
			initFreshChat(this.props.my)
			this.isFreshChatInit = true
		}
	}

	private applyGenericRedirect() {
		const { search: qsStr } = document.location
		if (qsStr.startsWith('?redir=')) {
			const url = qsStr.substr(qsStr.indexOf('?redir=') + 7)
			history.push(url)
		}
	}

	private addGoogleAutocompleteScript(locale: Locales) {
		const region = getRegionFromLocale(locale)
		const script = document.querySelector<HTMLScriptElement>('#google-maps')!

		script.src =
			'https://maps.googleapis.com/maps/api/js?libraries=places&key=' +
			`${appConfig.PLACES_API_KEY}&region=${region}&language=${locale}`
		script.async = true
	}

	private setMomentLocale(locale: Locales) {
		moment.locale(locale)
	}

	private refreshUserLoginDetails() {
		if (![Routes.Login, Routes.Logout].includes(document.location.pathname as Routes)) {
			internalLogin()
		}
	}

	@track({ action: 'Page View' })
	private trackInitialPageView() {
		//
	}

	public render() {
		const { companyTypeModal, myCompany, hideModalWindow } = this.props
		const shouldShowModalWindow = myCompany?.company_type !== 'normal' && companyTypeModal && companyTypeModal.showModal

		return (
			<WindowSizeContextProvider>
				<ModalContextProvider>
					<Router history={history}>
						<QueryParamProvider adapter={ReactRouter4Adapter}>
							<RouteAwareModalApiProvider>
								<div id="wrapper" className={this.props.className}>
									<React.Suspense fallback={<></>}>
										<AuthenticatedAppStateFetch />
										<Route
											render={({ location: { pathname } }) => {
												const isCallbackVerificationPath = matchPath(pathname, {
													path: Routes.CallbackVerification,
												})
												return (
													pathname !== Routes.Login &&
													!isCallbackVerificationPath && <AppBar key={pathname} route={pathname} />
												)
											}}
										/>

										<div>
											<AppRouter />
										</div>
										{myCompany && shouldShowModalWindow && (
											<CompanyTypeModal companyType={myCompany.company_type} onClose={hideModalWindow} />
										)}
									</React.Suspense>
									<ReactTooltip type="dark" />
								</div>
							</RouteAwareModalApiProvider>
						</QueryParamProvider>
					</Router>
					<ShowSessionTimeoutModal />
				</ModalContextProvider>
			</WindowSizeContextProvider>
		)
	}
}

const mapStateToProps = (state: ApplicationState): StateProps => {
	const { companyTypeModal, myCompany, auth } = state
	const internalUser = auth.get('internalUser')

	return {
		companyTypeModal,
		myCompany,
		my: internalUser,
	}
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
	return {
		showModalWindow: () => {
			dispatch(CompanyTypeModalActions.showMyCompanyModal())
		},
		hideModalWindow: () => {
			dispatch(CompanyTypeModalActions.hideMyCompanyModal())
		},
	}
}

export default connect<StateProps, DispatchProps, {}, ApplicationState>(mapStateToProps, mapDispatchToProps)(App)

function ReactRouter4Adapter({ children }: any) {
	const adapter = {
		replace(location: Location) {
			history.replace(location.search || '?', location.state)
		},
		push(location: Location) {
			history.push(location.search || '?', location.state)
		},
		get location() {
			return history.location
		},
	}
	return children(adapter)
}
