import React from 'react'

import { all200msEasyInOut, fgContrast, spacing } from '@/_vars'
import Button, { IProps as ButtonOrigProps } from '@/atoms/Button/Button'
import SvgIcon from '@/atoms/SvgIcon/SvgIcon'
import Tooltip from '@/atoms/Tooltip/Tooltip'
import { isIE } from '@/lib/browser'
import { WithTranslation, withTranslation } from '@/lib/i18n/i18n'
import { eventStopper } from '@/lib/utils'
import Trash from '@/refactor/assets/actions/delete.svg'
import Details from '@/refactor/assets/actions/details.svg'
import Download from '@/refactor/assets/actions/download.svg'
import Edit from '@/refactor/assets/actions/edit.svg'
import Save from '@/refactor/assets/actions/save.svg'
import Show from '@/refactor/assets/actions/show.svg'
import Cross from '@/refactor/assets/icons/cross.svg'
import { disabledGray, hoverBlue, linkBlue, secondaryBlack } from '@/refactor/colors'
import styled, { mediaQuery } from '@/styles'
import { DefaultProps } from '@/travelsuit'

import Loader from '../Loader/Loader'

interface IProps extends DefaultProps, WithTranslation {
	// display
	visible?: boolean
	contrast?: boolean
	showDisabled?: boolean

	// disabled
	editDisabled?: boolean
	saveDisabled?: boolean
	deleteDisabled?: boolean
	downloadDisabled?: boolean
	detailsDisabled?: boolean

	// tooltips
	editTooltip?: string
	saveTooltip?: string
	deleteTooltip?: string
	downloadTooltip?: string
	showTooltip?: string
	closeTooltip?: string
	detailsTooltip?: string

	// extra buttons
	leading?: Array<ButtonDef | undefined | null>
	trailing?: Array<ButtonDef | undefined | null>

	// callbacks
	onCancel?(e: React.MouseEvent<HTMLElement>): void
	onEdit?(e: React.MouseEvent<HTMLElement>): void
	onSave?(e: React.MouseEvent<HTMLElement>): void
	onDelete?(e: React.MouseEvent<HTMLElement>): void
	onDownload?(e: React.MouseEvent<HTMLElement>): void
	onShow?(e: React.MouseEvent<HTMLElement>): void
	onDetails?(e: React.MouseEvent<HTMLElement>): void

	// e2e locators
	e2eForButtons?: {
		e2eForEdit?: string
		e2eForSave?: string
		e2eForDelete?: string
		e2eForDownload?: string
		e2eForShow?: string
		e2eForClose?: string
		e2eForDetails?: string
	}
}

interface ButtonDef {
	icon: string
	tooltipText: string
	disabled?: boolean
	loading?: boolean
	primary?: boolean
	onClick?(e: React.MouseEvent<HTMLElement>): void
}

const borderSize = 10

const Toolbar = styled.div`
	position: absolute;
	top: ${spacing * 2 - borderSize}px;
	right: ${spacing * 2 - borderSize}px;
	color: ${fgContrast};

	${mediaQuery.mobileOnly`
			top: 0;
			right: ${borderSize}px;
	`}
`

const ToolbarIcon = styled(SvgIcon)``

const EditIcon = styled(ToolbarIcon).attrs(() => ({ src: Edit }))``
const SaveIcon = styled(ToolbarIcon).attrs(() => ({ src: Save }))`
	width: 18px;
	height: 18px;
`
const CloseIcon = styled(ToolbarIcon).attrs(() => ({ src: Cross }))`
	font-size: 1.8em;
`
const TrashIcon = styled(ToolbarIcon).attrs(() => ({ src: Trash }))`
	margin-top: -1px;
`
const DownloadIcon = styled(ToolbarIcon).attrs(() => ({ src: Download }))``
const ShowIcon = styled(ToolbarIcon).attrs(() => ({ src: Show }))``
const DetailsIcon = styled(ToolbarIcon).attrs(() => ({ src: Details }))``

type ButtonProps = ButtonOrigProps & Partial<Pick<IProps, 'contrast' | 'visible'> & { primary?: boolean }>

export const ToolbarButton = styled(Button).attrs(() => ({ rounded: '50%' }))<ButtonProps>`
	width: 26px;
	height: 26px;
	border-radius: 50%;
	border: none;
	position: relative;
	display: inline-block;
	background: transparent;
	cursor: pointer;
	opacity: ${(props) => (props.visible ? 1 : 0)};
	transform: scale(${(props) => (props.visible ? 1 : 0)});
	color: ${(props) => (props.disabled ? disabledGray : linkBlue)};
	padding: 0 !important;
	transition: ${all200msEasyInOut};
	box-shadow: none;

	& > * {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:hover {
		background: ${(props) => (props.disabled ? 'transparent' : hoverBlue)};
	}

	&:not(:last-child) {
		margin-right: 10px;
	}

	${isIE
		? `
		opacity: 1;
		transform: initial;
	`
		: ''}

	${(props) => mediaQuery.below('desktop')`
		color: ${props.disabled ? disabledGray : secondaryBlack};
	`}

	${mediaQuery.mobileOnly`
		opacity: 1;
		transform: initial;
	`}
`

// TASK migrate to React.FunctionComponent OR remove this if not possible
class FloatingControlToolbar extends React.Component<IProps> {
	public static defaultProps: Partial<IProps> = {
		visible: true,
	}

	private ref = React.createRef<HTMLDivElement>()
	private origParentStyle: React.CSSProperties['position']

	public componentDidMount() {
		if (this.ref.current) {
			const { position } = this.ref.current.parentElement!.style
			this.origParentStyle = position as any
			if ([null, undefined, 'static'].includes(position)) {
				this.ref.current.parentElement!.style.position = 'relative'
			}
		}
	}

	public componentWillUnmount() {
		Tooltip.hide()
		if (this.ref.current && this.origParentStyle) {
			this.ref.current.parentElement!.style.position = this.origParentStyle
		}
	}

	public render() {
		const {
			className,
			onDelete,
			onEdit,
			onSave,
			onCancel,
			onDownload,
			onShow,
			onDetails,
			editDisabled,
			saveDisabled,
			deleteDisabled,
			downloadDisabled,
			showDisabled,
			detailsDisabled,
			visible,
			contrast,
			style,
			trailing,
			leading,
			e2eForButtons,
			t,
		} = this.props

		const {
			editTooltip = t('floating-control-toolbar.edit-tooltip', 'Edit'),
			saveTooltip = t('floating-control-toolbar.save-tooltip', 'Save'),
			deleteTooltip = t('floating-control-toolbar.delete-tooltip', 'Delete'),
			downloadTooltip = t('floating-control-toolbar.download-tooltip', 'Download'),
			showTooltip = t('floating-control-toolbar.show-tooltip', 'Show'),
			closeTooltip = t('floating-control-toolbar.close-tooltip', 'Close'),
			detailsTooltip = t('floating-control-toolbar.details-tooltip', 'Details'),
		} = this.props

		return (
			<Toolbar className={className} style={style}>
				{leading?.length ? leading.map((button, i) => this.renderCustomButton(button, i)) : null}
				{onCancel ? (
					<Tooltip title={closeTooltip}>
						<ToolbarButton
							data-test={e2eForButtons?.e2eForClose ?? 'Cancel'}
							onClick={eventStopper.stopPropagation(onCancel)}
							visible={visible}
							contrast={contrast}
						>
							<CloseIcon />
						</ToolbarButton>
					</Tooltip>
				) : null}
				{onDownload ? (
					<Tooltip title={downloadTooltip}>
						<ToolbarButton
							onClick={eventStopper.stopPropagation(onDownload)}
							visible={visible}
							contrast={contrast}
							disabled={downloadDisabled}
							data-test={e2eForButtons?.e2eForDownload ?? 'Download'}
						>
							<DownloadIcon />
						</ToolbarButton>
					</Tooltip>
				) : null}
				{onEdit ? (
					<Tooltip title={editTooltip}>
						<ToolbarButton
							onClick={eventStopper.stopPropagation(onEdit)}
							visible={visible}
							contrast={contrast}
							disabled={editDisabled}
							data-test={e2eForButtons?.e2eForEdit ?? 'Edit'}
						>
							<EditIcon />
						</ToolbarButton>
					</Tooltip>
				) : null}
				{onDelete ? (
					<Tooltip title={deleteTooltip}>
						<ToolbarButton
							onClick={eventStopper.stopPropagation(onDelete)}
							visible={visible}
							contrast={contrast}
							disabled={deleteDisabled}
							data-test={e2eForButtons?.e2eForDelete ?? 'Delete'}
						>
							<TrashIcon />
						</ToolbarButton>
					</Tooltip>
				) : null}
				{onSave ? (
					<Tooltip title={saveTooltip}>
						<ToolbarButton
							primary
							visible={visible}
							contrast={contrast}
							onClick={eventStopper.stopPropagation(onSave)}
							disabled={saveDisabled}
							data-test={e2eForButtons?.e2eForSave ?? 'Save'}
						>
							<SaveIcon />
						</ToolbarButton>
					</Tooltip>
				) : null}
				{onShow ? (
					<Tooltip title={showTooltip}>
						<ToolbarButton
							primary
							visible={visible}
							contrast={contrast}
							onClick={eventStopper.stopPropagation(onShow)}
							disabled={showDisabled}
							data-test={e2eForButtons?.e2eForShow ?? 'Show'}
						>
							<ShowIcon />
						</ToolbarButton>
					</Tooltip>
				) : null}
				{onDetails ? (
					<Tooltip title={detailsTooltip}>
						<ToolbarButton
							primary
							visible={visible}
							contrast={contrast}
							onClick={eventStopper.stopPropagation(onDetails)}
							disabled={detailsDisabled}
							data-test={e2eForButtons?.e2eForDetails ?? 'Details'}
						>
							<DetailsIcon />
						</ToolbarButton>
					</Tooltip>
				) : null}
				{trailing?.length ? trailing.map((button, i) => this.renderCustomButton(button, i)) : null}
			</Toolbar>
		)
	}

	private renderCustomButton(button: ButtonDef | null | undefined, i: number) {
		if (!button) {
			return null
		}
		const { visible, contrast } = this.props
		return (
			<Tooltip title={button.tooltipText} key={i}>
				<ToolbarButton
					primary={button.primary}
					visible={visible}
					contrast={contrast}
					onClick={eventStopper.stopPropagation(button.onClick)}
					disabled={button.disabled}
				>
					{!button.loading ? (
						<ToolbarIcon src={button.icon} />
					) : (
						<Loader color={contrast ? 'contrast' : 'primary'} size={16} style={{ position: 'absolute' }} />
					)}
				</ToolbarButton>
			</Tooltip>
		)
	}
}

export default withTranslation()(FloatingControlToolbar)
