import { BillingPackage } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { PackagesTypes } from '../actions/actions.types'

export type PackagesState = BillingPackage[]

function packagesReducer(state: PackagesState = [], action: IAction): PackagesState {
	switch (action.type) {
		case PackagesTypes.GetPackages.SUCCESS:
			return action.payload
	}
	return state
}

export default packagesReducer
