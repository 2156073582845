import { createLazyComponentWithSuspense } from '@/lib/createLazyComponentWithSuspense'

export const BookConsultant = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "BookConsultant" */ '@/pages/BookConsultant/BookConsultant'),
)

export const ReportSettings = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ReportSettings" */ '@/pages/ReportSettings/ReportSettings'),
)

export const CorporateDiscountsAndRewardPrograms = createLazyComponentWithSuspense(
	() =>
		import(/* webpackChunkName: "CorporateDiscountsAndRewardPrograms" */ '@/pages/CorporateDiscountsAndRewardPrograms'),
)

export const HomePage = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "HomePage" */ '@/pages/HomePage/HomePage'),
)

export const Safety = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "Safety" */ '@/pages/Safety/Safety'),
)

export const GeneralSettings = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "GeneralSettings" */ '@/pages/GeneralSettings/GeneralSettings'),
)

export const ManageTravelers = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ManageTravelers" */ '@/pages/ManageTravelers/ManageTravelers'),
)

export const ManageDepartments = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ManageDepartments" */ '@/pages/ManageDepartments/ManageDepartments'),
)

export const ManageLocations = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ManageLocations" */ '@/pages/ManageLocations/ManageLocations'),
)

export const ManagePolicy = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ManagePolicy" */ '@/pages/ManagePolicy/ManagePolicy'),
)

export const ManageExpenses = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ManageExpenses" */ '@/pages/ManageExpenses/ManageExpenses'),
)

export const NewStandardExpense = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "NewStandardExpense" */ '@/pages/NewStandardExpense/NewStandardExpense'),
)

export const EditStandardExpense = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditStandardExpense" */ '@/pages/EditExpense/EditStandardExpense'),
)
export const NewMileage = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "NewMileage" */ '@/pages/NewMileage/NewMileage'),
)

export const EditMileage = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditMileage" */ '@/pages/NewMileage/EditMileage'),
)

export const EditStandardExpenseInReport = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditStandardExpenseInReport" */ '@/pages/EditExpense/EditStandardExpenseInReport'),
)

export const EditMileageInReport = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditMileageInReport" */ '@/pages/EditExpense/EditMileageInReport'),
)
export const EditDailyAllowanceInReport = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditDailyAllowanceInReport" */ '@/pages/EditExpense/EditDailyAllowanceInReport'),
)

export const NewDailyAllowance = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "NewDailyAllowance" */ '@/pages/NewDailyAllowance/NewDailyAllowance'),
)

export const EditDailyAllowance = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditDailyAllowance" */ '@/pages/EditDailyAllowance/EditDailyAllowance'),
)

export const ReportsRouter = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ReportsRouter" */ '@/pages/ReportsRouter/ReportsRouter'),
)

export const Itinerary = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "Itinerary" */ '@/pages/Itinerary/Itinerary'),
)

export const HotelResultPage = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "HotelResultPage" */ '@/pages/HotelResultPage/HotelResultPage'),
)

export const TripBuilder = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "TripBuilder" */ '@/pages/TripBuilder/TripBuilder'),
)

export const TripProduct = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "TripProduct" */ '@/pages/TripBuilder/TripProduct'),
)

export const TripsList = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "Trips" */ '@/pages/Trips/Trips'),
)

export const ExpenseReports = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ExpenseReports" */ '@/pages/ExpenseReports/ExpenseReports'),
)

export const ExpenseReportsSubmitted = createLazyComponentWithSuspense(
	() =>
		import(/* webpackChunkName: "ExpenseReportsSubmitted" */ '@/pages/ExpenseReportsSubmitted/ExpenseReportsSubmitted'),
)

export const NewExpenseReport = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "NewExpenseReport" */ '@/pages/NewExpenseReport/NewExpenseReport'),
)

export const EditExpenseReport = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditExpenseReport" */ '@/pages/EditExpenseReport/EditExpenseReport'),
)

export const SelectExpensesInReport = createLazyComponentWithSuspense(
	() =>
		import(/* webpackChunkName: "SelectExpensesInReports" */ '@/pages/SelectExpensesInReports/SelectExpensesInReports'),
)

export const EditTraveler = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "EditTraveler" */ '@/organisms/EditTraveler/EditTraveler'),
)

export const TravelerUnusedTickets = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "TravelerUnusedTickets" */ '@/pages/TravelerProfileUnusedTickets'),
)

export const ChangePlan = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ChangePlan" */ '@/pages/ChangePlan/ChangePlan'),
)

export const ActivateInvoiceProfile = createLazyComponentWithSuspense(
	() =>
		import(/* webpackChunkName: "ActivateInvoiceProfile" */ '@/pages/ActivateInvoiceProfile/ActivateInvoiceProfile'),
)

export const CreateInvoiceProfileWizard = createLazyComponentWithSuspense(
	() =>
		import(
			/* webpackChunkName: "CreateInvoiceProfileWizard" */ '@/pages/CreateInvoiceProfile/CreateInvoiceProfileWizard'
		),
)

export const InvoiceProfiles = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "InvoiceProfiles" */ '@/pages/InvoiceProfiles/InvoiceProfiles'),
)

export const CurrentPlan = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "CurrentPlan" */ '@/pages/CurrentPlan/CurrentPlan'),
)

export const UsageDetails = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "UsageDetails" */ '@/pages/UsageDetails/UsageDetails'),
)

export const CreateCompanyInvoices = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "CreateCompanyInvoices" */ '@/pages/CompanyOnboarding/CreateCompanyInvoices'),
)

export const ConfirmCompanyPage = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "ConfirmCompany" */ '@/pages/CompanyOnboarding/ConfirmCompany'),
)

export const CallbackVerification = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "CallbackVerification" */ '@/pages/CallbackVerification'),
)

export const CreditCardCallbackVerification = createLazyComponentWithSuspense(
	() =>
		import(
			/* webpackChunkName: "CreditCardCallbackVerification" */ '@/pages/CreditCardCallbackVerification/CreditCardCallbackVerification'
		),
)

export const PasswordChangeResult = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "PasswordChangeResult" */ '@/pages/PasswordChangeResult/PasswordChangeResult'),
)

export const Support = createLazyComponentWithSuspense(
	() => import(/* webpackChunkName: "Support" */ '@/pages/Support/Support'),
)

export const CreateTravelerProfile = createLazyComponentWithSuspense(
	() => import('@/pages/App/TravelerProfileUnpaidFee/CreateTravelerProfileRoute'),
)

export const TravelerProfile = createLazyComponentWithSuspense(
	() => import('@/pages/App/TravelerProfileUnpaidFee/TravellerProfileRoutes'),
)
