import { padPx } from '@/css-helpers/units'
import { disabledGray, hoverBlue, linkBlue } from '@/refactor/colors'
import styled from '@/styles'

const SelectorButton = styled.button<{ selected: boolean; width?: number | string }>`
	border-radius: 10px;
	text-align: left;
	border: ${(props) => (props.selected ? 2 : 1)}px solid ${(props) => (props.selected ? linkBlue : disabledGray)};
	padding: ${(props) => (props.selected ? 9 : 10)}px;
	transition: all 150ms ease-in-out;
	cursor: pointer;
	background: white;
	${(props) => (props.width !== undefined ? `width: ${padPx(props.width)};` : '')}

	&:hover {
		background: ${hoverBlue};
		border-color: ${linkBlue};
	}
`

export default SelectorButton
