import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { Alliance } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { AlliancesTypes } from '../actions/actions.types'

export type AlliancesState = ImmutableMap<number, Alliance>

function alliancesStateReducer(state: AlliancesState = new ImmutableMap(), action: IAction): AlliancesState {
	switch (action.type) {
		case AlliancesTypes.SetAlliances:
			for (const alliance of action.payload ?? []) {
				state = state.set(Number(alliance.id), alliance)
			}
			return state
	}
	return state
}

export default alliancesStateReducer
