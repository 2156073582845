import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import ReactSidebar from 'react-sidebar'

import { ZIndexContext } from '@/common/z-index'
import { hideScrollbar } from '@/css-helpers/helpers'
import { white } from '@/refactor/colors'
import styled, { padPx, size } from '@/styles'
import { DefaultProps, HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '@/travelsuit'

interface IProps extends DefaultProps {
	open?: boolean
	onToggle?(open: boolean): void
}

const SidebarContent = styled.div`
	${size('100vw', `calc(100vh - ${HEADER_HEIGHT}px)`)}
	background: ${white};
	overflow-y: auto;

	${(p) => p.theme.breakpoints.down('md')} {
		${size('100vw', `calc(100vh - ${MOBILE_HEADER_HEIGHT}px)`)}
		${hideScrollbar()}
	}
`

const Z_INDEX = 100000

export const Sidebar: React.FC<IProps> = ({ open, onToggle, children }) => {
	const shouldUseMobileStyles = useMediaQuery((theme) => theme.breakpoints.down('md'))

	const getContent = () => (
		<SidebarContent>
			<ZIndexContext.Provider value={Z_INDEX}>{children}</ZIndexContext.Provider>
		</SidebarContent>
	)

	return (
		<ReactSidebar
			open={open}
			onSetOpen={onToggle}
			pullRight
			touchHandleWidth={0}
			dragToggleDistance={100}
			sidebar={getContent()}
			touch={!shouldUseMobileStyles}
			styles={{
				root: {
					position: 'fixed',
					top: padPx(shouldUseMobileStyles ? MOBILE_HEADER_HEIGHT : HEADER_HEIGHT),
					zIndex: String(Z_INDEX),
					pointerEvents: open ? 'all' : 'none',
				},
				overlay: { width: open ? undefined : '20px', background: 'transparent' },
			}}
		>
			{''}
		</ReactSidebar>
	)
}
