import { Maybe } from '@/lib/types'

import { AppConfig } from './types'

function isTrueConfigValue(value: Maybe<string>) {
	return value === 'true'
}

function getAppConfig(): AppConfig {
	const appConfig = (window as any)._GG
	const isLocalDevelopment = location.hostname === 'localhost'
	return {
		WEB_ORIGIN: isLocalDevelopment
			? // TODO: come up with a better way to handle this for local development
				(appConfig.WEBAPP.ENV.AUTH0_CALLBACK_URL || '').replace('/auth', '')
			: location.origin,
		BUILD_MODE: appConfig.WEBAPP.DEPLOY_ENVIRONMENT,
		ENV_NAME: isLocalDevelopment ? 'localhost' : appConfig.WEBAPP.ENV.ENV_NAME,
		API_DOMAIN: appConfig.WEBAPP.API_DOMAIN,
		RESULTS_DEBUG_MODE: isTrueConfigValue(appConfig.WEBAPP.FEATURE.RESULTS_DEBUG_MODE),
		DEFAULT_LANGUAGE: appConfig.WEBAPP.FEATURE.DEFAULT_LANGUAGE,

		FEATURE_FAKE_BOOKING: isTrueConfigValue(appConfig.WEBAPP.FEATURE.FAKE_BOOKING),
		FEATURE_BOOKING_MOCKS: isTrueConfigValue(appConfig.WEBAPP.FEATURE.BOOKING_MOCKS),
		FEATURE_DEBUG_INFO: isTrueConfigValue(appConfig.WEBAPP.FEATURE.DEBUG_INFO),
		FEATURE_EXTERNAL_BOOKING: isTrueConfigValue(appConfig.WEBAPP.FEATURE.EXTERNAL_BOOKING),
		FEATURE_VIRTUAL_PAYMENT_AUTOMATION: isTrueConfigValue(appConfig.WEBAPP.FEATURE.VIRTUAL_PAYMENT_AUTOMATION),
		FEATURE_AUTOMATIC_DBI_AIRPLUS_LOOKUP: isTrueConfigValue(appConfig.WEBAPP.FEATURE.AUTOMATIC_DBI_AIRPLUS_LOOKUP),

		// Sentry
		SENTRY_DSN: appConfig.WEBAPP.ENV.SENTRY_DSN,
		SENTRY_SAMPLE_RATE: appConfig.WEBAPP.ENV.SENTRY_SAMPLE_RATE || 0.01,
		SENTRY_ENABLED: Boolean(appConfig.WEBAPP.ENV.SENTRY_DSN),

		MOBILE_APP_PACKAGE_NAME: appConfig.WEBAPP.ENV.MOBILE_APP_PACKAGE_NAME,
		MOBILE_APP_START_URL_HASH: appConfig.WEBAPP.ENV.MOBILE_APP_START_URL_HASH,

		// MapBox
		MAPBOX_API_KEY: appConfig.WEBAPP.ENV.MAPBOX_API_KEY!,

		// Google Places API
		PLACES_API_KEY: appConfig.WEBAPP.ENV.PLACES_API_KEY!,

		// Auth0
		AUTH0_AUDIENCE: appConfig.WEBAPP.ENV.AUTH0_AUDIENCE,
		AUTH0_CLIENT_ID: appConfig.WEBAPP.ENV.AUTH0_CLIENT_ID,
		AUTH0_DOMAIN: appConfig.WEBAPP.ENV.AUTH0_DOMAIN,
		AUTH0_DB_CONNECTION: appConfig.WEBAPP.ENV.AUTH0_DB_CONNECTION,
		AUTH0_CALLBACK_URL: appConfig.WEBAPP.ENV.AUTH0_CALLBACK_URL,
		TIME_TO_NOTIFY_SESSION_EXPIRATION: appConfig.WEBAPP.TIME_TO_NOTIFY_SESSION_EXPIRATION || 5 * 60 * 1000,

		USERPILOT_KEY: appConfig.WEBAPP.ENV.USERPILOT_KEY,
	}
}

export const appConfig = getAppConfig()
