import { ArrayOr, Callback } from '@/travelsuit'

import { isDefinedValue } from './isDefinedValue'
import { noop } from './noop'
import { Maybe } from './types'

export function forceArray<T>(obj: ArrayOr<T>, filter?: any[]): Exclude<T, undefined | null>[]
export function forceArray<T>(obj: ArrayOr<T>, filter: false): T[]
export function forceArray<T>(
	obj: T | T[],
	filter: any[] | false = [null, undefined],
): Exclude<T, undefined | null>[] | T[] {
	if (filter === false) {
		return (Array.isArray(obj) ? obj : [obj]) as Array<Exclude<T, undefined | null>>
	}

	return filter.includes(obj)
		? []
		: ((Array.isArray(obj) ? obj : [obj]) as Exclude<T, undefined | null>[]).filter((i) => !filter.includes(i))
}

interface FilterJoinConfig<T = any, O = T> {
	sep: string
	filterFn(element: any): boolean
	mapFn(element: T): O
}

type FilterMapJoinFn<K extends keyof FilterJoinConfig = never> = <T, O>(
	list: T[],
	conf?: Partial<Omit<FilterJoinConfig<T, O>, K>>,
) => string

function filterMapJoinWrapper<K extends keyof FilterJoinConfig>(
	defaults: Pick<FilterJoinConfig, K>,
): FilterMapJoinFn<K> {
	return (list, conf = {}): string => {
		const { sep = ' ', filterFn, mapFn } = { ...defaults, ...conf } as FilterJoinConfig
		let copy = [...(list ?? [])]

		if (filterFn) {
			copy = copy.filter(filterFn)
		}

		if (mapFn) {
			copy = copy.map(mapFn)
		}

		return copy.join(sep)
	}
}

export const filterMapJoin = Object.assign(filterMapJoinWrapper({}), {
	filterBoolean: filterMapJoinWrapper({ filterFn: Boolean }),
})

type DifferenceType<T> = [T[], T[]] & {
	added: T[]
	removed: T[]
}

export function getArrayDifference<T>(source: T[], target: T[], matcher: Callback<any, T> = noop): DifferenceType<T> {
	const added = target.filter((el) => !source.some((t) => matcher(t) === matcher(el)))
	const removed = source.filter((el) => !target.some((t) => matcher(t) === matcher(el)))

	return Object.assign([added, removed] as [T[], T[]], {
		added,
		removed,
	})
}

/**
 * Updates each {target} in {source}, or creates if it does not exist.
 * @param source Original array
 * @param target Item(s) to upsert
 * @param matcher Equivalence match predicate (optional). When omitted, uses direct equality operator.
 * @returns The updated array
 */
export function upsertInto<T>(source: T[] | undefined | null, target: T | T[], matcher: Callback<any, T> = noop): T[] {
	const targetArr: T[] = Array.isArray(target) ? target : [target]
	const output: T[] = [...(source ?? [])]

	if (!source?.length) {
		output.push(...targetArr)
	} else {
		for (const p of targetArr) {
			const idx = source.findIndex((p2) => matcher(p) === matcher(p2)) ?? -1
			if (idx >= 0) {
				output[idx] = p
			} else {
				output.push(p)
			}
		}
	}
	return output
}

/**
 * Removes each {target} from {source}, if it exists.
 * @param source Original array
 * @param target Item(s) to upsert
 * @param matcher Equivalence match predicate (optional). When omitted, uses direct equality operator.
 * @returns The updated array
 */
export function removeFrom<T>(source: T[] | null | undefined, target: T | T[], matcher: Callback<any, T> = noop): T[] {
	const targetArr: T[] = Array.isArray(target) ? target : [target]
	if (!source?.length) {
		return []
	}

	const output: T[] = [...source]

	for (const p of targetArr) {
		const idx = source.findIndex((p2) => matcher(p) === matcher(p2)) ?? -1
		if (idx >= 0) {
			output.splice(idx, 1)
		}
	}
	return output
}

export function arrayLast<T>(arr: Maybe<T[]>): T | undefined {
	if (!arr?.length) {
		return undefined
	}
	return arr[arr.length - 1]
}

/**
 * Creates a range of numbers between [start] and [end]. If [end] is omitted, range will start at 0 and end at [start].
 * @param {number} start - number to start range from. Inclusive
 * @param {number} end - number to end range in. Exclusive
 */
export function range(start: number, end?: number): number[] {
	if (!end) {
		end = start
		start = 0
	}
	const len = Math.abs(end - start)
	if (!len) {
		return []
	}

	return new Array(len).fill(0).map((_, i) => i + start)
}

export function filterEmptyValues<T>(values: T[]) {
	return values.filter(isDefinedValue)
}

export function areAllElementEqual<T>(array: T[]) {
	return array.every((element) => element === array[0])
}

export function areAllElementDefined<T>(array: T[]) {
	return array.every(isDefinedValue)
}
