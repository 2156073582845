import React from 'react'
import { connect } from 'react-redux'

import CloseIcon from '@/assets/cross.svg'
import MenuIcon from '@/assets/hamburger.svg'
import CheckPermission from '@/atoms/CheckPermission/CheckPermission'
import CompanyLogo from '@/atoms/CompanyLogo/CompanyLogo'
import { Expanded } from '@/atoms/GenericComponents/GenericComponents'
import { withWindowResize } from '@/atoms/HOC/windowResize'
import { Sidebar } from '@/atoms/Sidebar/Sidebar'
import { SupportSummary } from '@/atoms/SupportSummary/SupportSummary'
import { SupportSummaryContent } from '@/atoms/SupportSummary/SupportSummaryContent'
import Tooltip from '@/atoms/Tooltip/Tooltip'
import TravelSuitLogo from '@/atoms/TravelSuitLogo/TravelSuitLogo'
import { UserLoginMenu } from '@/atoms/UserLoginMenu'
import { CircleUserPhoto } from '@/atoms/UserPhoto/UserPhoto'
import { FeaturePermit, useFeaturePermit } from '@/common/feature-permit'
import { isDesktop } from '@/lib/browser'
import history from '@/lib/history'
import { WithTranslation, withTranslation } from '@/lib/i18n/i18n'
import { isLaunchedAsStandaloneApp } from '@/lib/mobile-app'
import { matches, routeFor, Routes } from '@/lib/route-utils'
import { eventStopper, existsInDOMParents, scrollTo } from '@/lib/utils'
import { UserGreeting } from '@/molecules/UserLogin/UserGreeting'
import UserLogin from '@/molecules/UserLogin/UserLogin'
import { CurrencySelectorCollapse, CurrencySelectorNormal } from '@/organisms/CurrencySelector'
import { LanguageSwitcherMobile, LanguageSwitcherNormal } from '@/organisms/LanguageSwitcher'
import { ApplicationState } from '@/redux/stores'
import {
	FeatureScope,
	GroupTypes,
	PermissionDefs,
	SafetyLabels,
	TripStatus,
	User,
	UserPermissionsTypes,
	UserRoleNames,
} from '@/travelsuit'
import { AsyncTrackResult, track } from '@/travelsuit/analytics'
import { hasUserRole } from '@/travelsuit/my-user-roles'

import {
	AppBarCollapse,
	AppBarContainer,
	AppBarPlaceholder,
	CompanyContainer,
	LoginContainer,
	LogoContainer,
	MobileTitle,
	Route,
	RoutesContainer,
	Separator,
	SidebarButton,
	SidebarContent,
	SupportIcon,
	SupportItem,
} from './AppBar.components'

interface OwnProps extends WithTranslation {
	className?: string
	route: string
}

type IProps = OwnProps & StateProps

interface IState {
	currentRoute: string
	sidebarOpen: boolean
	supportMenuOpen: boolean
}

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>

interface RouteLink {
	label: React.ReactNode
	path: string
	matchPaths?: string[]
	permissions: UserPermissionsTypes[] | Partial<PermissionDefs>
	props?: AnchorProps | ((mobile: boolean) => AnchorProps)
	extras?: any
	component?: string | React.ComponentClass
	analyticsKey: string
	renderChildren?(routeLink: RouteLink, mobile: boolean): React.ReactNode
	shouldHide?: boolean
	e2e?: string
}

const SPACER = 10

@track({})
// TASK migrate to React.FunctionComponent OR remove this if not possible
class AppBar extends React.Component<IProps, IState> {
	public static getDerivedStateFromProps(props: IProps, state: IState) {
		if (props.route !== state.currentRoute) {
			return {
				currentRoute: props.route,
			}
		}

		return null
	}

	public state: IState = {
		currentRoute: this.props.route,
		sidebarOpen: false,
		supportMenuOpen: false,
	}

	private supportLinkRef = React.createRef<HTMLDivElement>()

	private getRouteList(expensePermission?: ReturnType<typeof useFeaturePermit>): Array<RouteLink | number> {
		const { t } = this.props
		const isLaunchedAsMobileApp = isLaunchedAsStandaloneApp()

		return [
			SPACER,
			{
				label: t('navigation.new-trip', 'Home'),
				path: Routes.Home,
				matchPaths: [Routes.Home],
				permissions: { every: [UserPermissionsTypes.Default] },
				analyticsKey: 'Home',
				extras: {
					shouldRefreshOnSamePath: true,
				},
				e2e: 'HomeLink',
			},
			{
				label: t('navigation.trips', 'Trips'),
				path: routeFor(Routes.Trips, { status: TripStatus.Booked }),
				matchPaths: [Routes.Itinerary, Routes.Trips, Routes.TripBuilder, Routes.HotelResult],
				permissions: { every: [UserPermissionsTypes.Default] },
				analyticsKey: 'Trips',
				e2e: 'TripsLink',
			},
			{
				label: t('navigation.expenses', 'Expenses'),
				path: Routes.Expenses,
				matchPaths: [
					Routes.EditStandardExpense,
					Routes.EditMileage,
					Routes.EditDailyAllowance,
					Routes.EditExpenseInReport,
					Routes.EditExpenseReport,
					Routes.ExpenseReports,
					Routes.ExpenseReportsSubmitted,
					Routes.ManageExpenses,
					Routes.NewStandardExpense,
					Routes.NewMileage,
					Routes.NewDailyAllowance,
					Routes.NewExpenseReport,
					Routes.SelectExpensesInReport,
				],
				permissions: { every: [UserPermissionsTypes.Default] },
				shouldHide: expensePermission ? !expensePermission.isPermitted : true,
				analyticsKey: 'Expenses',
			},
			{
				label: t('navigation.safety', 'Safety'),
				path: routeFor(Routes.Safety, { tab: SafetyLabels.LiveMap }),
				matchPaths: [Routes.Safety],
				permissions: { every: [UserPermissionsTypes.Admin] },
				analyticsKey: 'Safety',
				e2e: 'SafetyLink',
			},
			{
				label: t('navigation.administration', 'Admin'),
				path: Routes.GeneralSettings,
				matchPaths: [
					Routes.ManageTravelers,
					Routes.ManageLocations,
					Routes.EditTravelerProfile,
					Routes.TravelerProfileUnusedTickets,
					Routes.CreateTravelerProfile,
					Routes.TravelerProfileUnusedTickets,
					Routes.ManageDepartments,
					Routes.GeneralSettings,
					Routes.LiveMap,
					Routes.BookCustomerSuccessConsultant,
				],
				permissions: { every: [UserPermissionsTypes.Admin] },
				analyticsKey: 'Admin',
				e2e: 'AdminLink',
			},
			{
				label: t('navigation.policy', 'Policy'),
				path: routeFor(Routes.ManagePolicy, { type: GroupTypes.Company }),
				permissions: { every: [UserPermissionsTypes.Admin] },
				matchPaths: [Routes.ManagePolicy],
				analyticsKey: 'Policy',
				e2e: 'PolicyLink',
			},
			{
				label: t('navigation.reports', 'Reports'),
				path: Routes.ReportsOverview,
				permissions: {
					oneOf: [UserPermissionsTypes.ViewAllReports, UserPermissionsTypes.ViewManagedGroupReports],
				},
				matchPaths: [
					Routes.Reports,
					Routes.ReportsOverview,
					Routes.ReportsFlights,
					Routes.ReportsHotels,
					Routes.ReportsRails,
					Routes.ReportsTravelers,
					Routes.ReportsCars,
					Routes.ReportsUnusedTickets,
				],
				analyticsKey: 'Reports',
				e2e: 'ReportsLink',
			},
			{
				label: t('navigation.billing', 'Billing'),
				path: Routes.Billing,
				permissions: { every: [UserPermissionsTypes.Admin] },
				matchPaths: [
					Routes.Billing,
					Routes.Invoices,
					Routes.BillingCurrent,
					Routes.BillingChangePlan,
					Routes.CreateInvoiceProfile,
				],
				analyticsKey: 'Billing',
				e2e: 'BillingLink',
				shouldHide: isLaunchedAsMobileApp,
			},
			SPACER,
		]
	}

	public render() {
		const { t, user, className, isSupportUser, afterOnboarding } = this.props
		const { sidebarOpen, supportMenuOpen } = this.state
		const { currentRoute } = this
		const isDesktopSize = isDesktop()

		const hasInactiveAccount = !afterOnboarding && isLaunchedAsStandaloneApp()

		return (
			<FeaturePermit requiredFeature={FeatureScope.TravelExpense}>
				{(expensePermission) => {
					return (
						<>
							<AppBarContainer className={className} support={isSupportUser} data-test="HomeBar">
								<LogoContainer to={Routes.Home} data-test="Logo">
									<TravelSuitLogo />
								</LogoContainer>
								{!hasInactiveAccount && (
									<MobileTitle>{currentRoute && user ? currentRoute.label : 'GetGoing'}</MobileTitle>
								)}
								{!hasInactiveAccount && isDesktopSize && (
									<>
										<RoutesContainer key={afterOnboarding.valueOf().toString()} data-test="NavigationContainer">
											{afterOnboarding ? this.routes(expensePermission, false) : null}
										</RoutesContainer>
										<LanguageSwitcherNormal />
										<Separator gapRight gapLeft />
										{user && <CurrencySelectorNormal />}
										<Separator gapRight gapLeft />
										<Tooltip title={t('navigation.support', 'Live Support')}>
											<SupportItem
												ref={this.supportLinkRef}
												onClick={eventStopper.preventDefault(() =>
													this.setState((s) => ({ supportMenuOpen: !s.supportMenuOpen })),
												)}
												data-test="SupportLink"
											>
												<SupportIcon size={24} active={supportMenuOpen} />
											</SupportItem>
										</Tooltip>
										<Separator gapLeft />
										<CompanyContainer data-test="Company">
											<CompanyLogo />
										</CompanyContainer>

										<Separator />

										<LoginContainer>
											<UserLogin />
										</LoginContainer>

										{supportMenuOpen ? (
											<SupportSummary
												onClickItem={() => this.setState({ sidebarOpen: false, supportMenuOpen: false })}
												onClickOutside={(e) =>
													!existsInDOMParents(e.target as HTMLElement, this.supportLinkRef.current as HTMLElement) &&
													this.setState({ supportMenuOpen: false })
												}
											/>
										) : null}
									</>
								)}

								{!hasInactiveAccount && !isDesktopSize && (
									<SidebarButton
										onClick={() => this.setState({ sidebarOpen: !sidebarOpen })}
										src={!sidebarOpen ? MenuIcon : CloseIcon}
									/>
								)}
							</AppBarContainer>
							<AppBarPlaceholder />

							{user && !isDesktopSize ? (
								<div tabIndex={-1}>
									<Sidebar open={sidebarOpen} onToggle={(open) => this.setState({ sidebarOpen: open })}>
										<SidebarContent tabIndex={-1}>
											{afterOnboarding ? this.routes(expensePermission, true) : null}

											<LanguageSwitcherMobile />
											<CurrencySelectorCollapse />

											<AppBarCollapse
												title={
													<>
														<SupportIcon />
														{t('navigation.support', 'Live Support')}
													</>
												}
											>
												<SupportSummaryContent mobile />
											</AppBarCollapse>

											{user ? (
												<AppBarCollapse
													title={
														<>
															<CircleUserPhoto user={user} size={28} />
															<UserGreeting user={user} />
														</>
													}
												>
													<UserLoginMenu open user={user} />
												</AppBarCollapse>
											) : null}
										</SidebarContent>
									</Sidebar>
								</div>
							) : null}
						</>
					)
				}}
			</FeaturePermit>
		)
	}

	@track((_p, _s, _a, [result]: AsyncTrackResult<InstanceType<typeof AppBar>['goTo']>) =>
		result
			? {
					action: 'Navigation Click',
					'Navigate To': result.key,
					'New Tab': result.newTab,
				}
			: false,
	)
	private async goTo(
		route: RouteLink,
		e: React.MouseEvent<HTMLAnchorElement>,
	): Promise<{ key: string; newTab: boolean }> {
		const openInNewTab = e.ctrlKey || e.metaKey || e.button === 1
		const resp = {
			key: route.analyticsKey,
			newTab: openInNewTab,
		}

		if (openInNewTab) {
			window.open(route.path, '_blank')
			return resp
		}

		const oldRoute = this.state.currentRoute

		return new Promise((res, _rej) => {
			this.setState({ currentRoute: route.path, sidebarOpen: false }, () => {
				if (route.extras?.shouldRefreshOnSamePath && this.isRouteSelected(route, [oldRoute])) {
					window.location.href = route.path
					return res(resp)
				} else {
					history.push(route.path)
					scrollTo({ y: 0 })
					return res(resp)
				}
			})
		})
	}

	private routeCls(route: RouteLink, mobile: boolean) {
		const props = this.getRouteProps(route, mobile)
		return props?.className
	}

	private isRouteSelected(route: RouteLink, curRoutes: string[]) {
		curRoutes = curRoutes ?? []
		return Boolean(
			curRoutes.some((p) => matches(route.path)?.includes(p)) ||
				route.matchPaths?.some((p) => curRoutes.some((i) => matches(i)?.includes(p))),
		)
	}

	private routes(expensePermission: ReturnType<typeof useFeaturePermit>, mobile = false) {
		if (expensePermission.isLoading) {
			return null
		}

		const curRoute = matches(this.props.route)!

		return this.getRouteList(expensePermission).map((route, i) => {
			if (route === SPACER) {
				return <Expanded key={`spacer-${i}`} tabIndex={-1} />
			}
			const obj = route as RouteLink
			if (obj.shouldHide) {
				return null
			}
			const props = this.getRouteProps(obj, mobile)
			return (
				<CheckPermission key={obj.path} permissions={obj.permissions}>
					<Route
						key={obj.path}
						tabIndex={mobile ? -1 : 0}
						onClick={eventStopper.preventDefault((e) => this.goTo(obj, e))}
						onAuxClick={eventStopper.preventDefault((e) => this.goTo(obj, e))}
						href={obj.path}
						{...props}
						active={this.isRouteSelected(obj, curRoute)}
						className={this.routeCls(obj, mobile)}
						data-test={obj.e2e}
					>
						{obj.label}
						{obj.renderChildren ? obj.renderChildren(obj, mobile) : null}
					</Route>
				</CheckPermission>
			)
		})
	}

	private getRouteProps(route: RouteLink, mobile: boolean) {
		return typeof route.props === 'function' ? route.props(mobile) : route.props
	}

	private get currentRoute(): RouteLink {
		const curRoute = matches(this.props.route)!
		return this.getRouteList().find(
			(route) => typeof route !== 'number' && this.isRouteSelected(route, curRoute),
		) as any
	}
}

const mapStateToProps = ({ auth, myCompany }: ApplicationState) => {
	return {
		user: auth.get('internalUser') as User | null,
		isSupportUser: hasUserRole(UserRoleNames.SupportUser),
		afterOnboarding: Boolean(myCompany?.is_after_onboarding || auth.get('user_can_operate')),
	}
}

type StateProps = ReturnType<typeof mapStateToProps>

export default withWindowResize(
	withTranslation()(connect<StateProps, never, OwnProps, ApplicationState>(mapStateToProps)(AppBar)),
)
