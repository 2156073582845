import React from 'react'

import CompanyStatusIcon from '@/assets/company-status-ico.svg'
import { SvgIcon } from '@/atoms/SvgIcon/SvgIcon'
import { darkRed, mainBlack, yellow } from '@/refactor/colors'
import styled from '@/styles'
import { DefaultProps } from '@/travelsuit'

type LabelColorType = {
	normal: string
	demo: string
	restricted: string
}

const colorMap: LabelColorType = {
	normal: mainBlack,
	demo: yellow,
	restricted: darkRed,
}
interface IProps extends DefaultProps {
	companyType: keyof LabelColorType
	label?: string
}

const StyledIcon = styled(SvgIcon)`
	fill: ${(props) => props.color};
	min-width: 12px;
	line-height: 12px !important;
`
const Label = styled.div<{ color: string }>`
	height: 100%;
	width: auto;
	margin: 0 5px;
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 5px;
	font-weight: 700;
	color: ${(props) => props.color};
`

export const CompanyTypeIndicator = ({ companyType, label }: IProps) => {
	const color = colorMap[companyType]

	if (companyType === 'normal') {
		return null
	}

	return (
		<Label color={color}>
			<StyledIcon src={CompanyStatusIcon} size={12} color={color} />
			{label}
		</Label>
	)
}
