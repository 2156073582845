import GermanyFlagSvgUrl from '@/assets/country-flags/germany.svg'
import GreatBritainFlagSvgUrl from '@/assets/country-flags/great_britain.svg'
import USAFlagSvgUrl from '@/assets/country-flags/USA.svg'
import { CountriesCode } from '@/types/locale'

export const countryFlag = {
	svgUrl: {
		[CountriesCode.GB]: GreatBritainFlagSvgUrl,
		[CountriesCode.US]: USAFlagSvgUrl,
		[CountriesCode.DE]: GermanyFlagSvgUrl,
	},
}
