import { TravelPolicyActions, TravelPolicyTypes } from '@/redux/actions'
import { User } from '@/travelsuit'

import { createAPIHook } from './generic-hooks'

export const useTravelPolicyForUsers = createAPIHook({
	loadAction: (users: User[], { onLoad, onError }) => TravelPolicyActions.getPolicyForUsers(users, onLoad, onError),
	loadingSelectors: TravelPolicyTypes.GetPolicyForUsers,
	storeMapper: ({ travelPolicy }, params) =>
		travelPolicy.byUser.filter((_v, k) => params.map((u) => u.id!).includes(k!)).toArray(),
})
