import React from 'react'
import { useSelector } from 'react-redux'

import { isUsingStorageFallback } from '@/lib/browserStorage'
import { changeLanguage } from '@/lib/i18n/locale-utils'
import { getCurrentLocale } from '@/lib/i18n/locale-utils'
import { useAsyncDispatch, useUser } from '@/lib/react-hooks/generic-hooks'
import { AdminUsersActions } from '@/redux/actions'
import { ApplicationState } from '@/redux/stores'
import { Locales } from '@/types/locale'

export function useLanguageSwitcher() {
	const countryLanguages = useSelector((state: ApplicationState) => state.countryLanguages)
	const locales = React.useMemo(
		() =>
			countryLanguages
				.map((language) => language.code)
				.slice()
				.sort((a, b) => b.localeCompare(a)),
		[countryLanguages],
	)

	const my = useUser()
	const asyncDispatch = useAsyncDispatch()

	const [locale, setLocale] = React.useState(my?.language_code ?? getCurrentLocale())

	const userExists = !!my

	const updateLanguage = async (language: Locales) => {
		await changeLanguage(language)
		await asyncDispatch(AdminUsersActions.updateUserLanguage, language)
	}

	const passOnChange = () => {
		if (!isUsingStorageFallback()) {
			window.location.reload()
		}
	}

	const onChange: (newLocale: Locales) => void = (newLocale) => {
		setLocale(newLocale)

		if (userExists && newLocale !== locale) {
			updateLanguage(newLocale).then(passOnChange)
		} else {
			passOnChange()
		}
	}

	return {
		onChange,
		locale,
		countryLanguages: locales,
	}
}
