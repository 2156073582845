import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import React from 'react'

import Button, { IProps as ButtonProps } from '@/atoms/Button/Button'
import { Flex } from '@/atoms/GenericComponents/GenericComponents'
import { animations } from '@/css-helpers/animated'
import { useTranslation } from '@/lib/i18n/i18n'
import { CardVerificationHint } from '@/molecules/CardVerificationHint/CardVerificationHint'
import { backgroundGray, disabledGray, linkBlue } from '@/refactor/colors'
import styled, { flex, FlexProps, font, FontType } from '@/styles'
import { DefaultProps } from '@/travelsuit'

import { PopperTooltip } from '../Tooltip'

interface IProps extends Omit<DefaultProps, 'title' | 'e2e'> {
	active?: boolean
	canContinue?: boolean
	canReturn?: boolean
	title?: React.ReactNode
	proceedButtonProps?: { label?: React.ReactNode; mobileShrink?: boolean; key?: string } & ButtonProps
	proceedButtonTooltip?: string
	isLoading?: boolean
	secondaryButton?: React.ReactNode
	bottomBarEnabled?: boolean
	bottomBarRemark?: React.ReactNode
	stepperDetails?: { step: number; total: number }
	onProceed?(e: React.MouseEvent<HTMLElement>): void
	onPrev?(e: React.MouseEvent<HTMLElement>): void
	showSCAIcon?: boolean
}

const WizardPageContainer = styled.div<{ active: boolean }>`
	${(props) =>
		!props.active
			? 'display: none;'
			: `
		${animations.slideInRight.animate(500)}
		${flex({ direction: 'column', justify: 'space-between' })}
	`}
	width: 100%;
	background: white;
	height: 100%;
`

const PageTitle = styled.div`
	${font(FontType.Comfortaa, { size: 20, weight: 'bold' })}

	padding-right: ${(p) => p.theme.spacing(5)}px;
`

export const PageSection = styled.div`
	padding: ${(p) => p.theme.spacing(4, 3, 0)};

	display: flex;
	flex-direction: column;

	${(p) => p.theme.breakpoints.up('lg')} {
		padding: ${(p) => p.theme.spacing(6, 8, 0)};
	}
`

const TopBarContainer = styled(PageSection)`
	padding-bottom: 0;

	& hr {
		margin-bottom: 0;
	}
`

export const BottomBarContainer = styled(PageSection)<FlexProps>`
	padding: ${(p) => p.theme.spacing(3)}px;

	box-shadow: 5px 0px 10px ${backgroundGray};
	${(props) => flex({ justify: props.justify, align: props.align ?? 'center' })}
	position: relative;
	gap: ${(p) => p.theme.spacing(3)}px;

	${(p) => p.theme.breakpoints.up('lg')} {
		padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(8)}px;
	}
`

export const ContentContainer = styled(PageSection)`
	flex-grow: 1;
	padding-top: ${(p) => p.theme.spacing(4)}px;
	overflow-y: auto;

	${(p) => p.theme.breakpoints.up('lg')} {
		padding-top: ${(p) => p.theme.spacing(8)}px;
	}
`

const PrevButton = styled.span`
	cursor: pointer;
	font-size: 1.5rem;
	margin-right: ${(p) => p.theme.spacing(4)}px;
`

const StyledStepper = styled(Stepper)`
	padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(4)}px;

	.MuiStepConnector-horizontal {
		.MuiStepConnector-lineHorizontal {
			border-color: ${linkBlue};
		}
	}

	${(p) => p.theme.breakpoints.up('lg')} {
		padding: ${(p) => p.theme.spacing(8)}px;
		padding-bottom: ${(p) => p.theme.spacing(2)}px;
	}
`

const SCADescription = styled.div`
	${font(FontType.OpenSans, { size: 12 })}
`

const StyledStepLabel = styled(StepLabel)`
	& .MuiStepLabel-iconContainer {
		.MuiStepIcon-root {
			color: ${disabledGray};
		}

		& .MuiStepIcon-text {
			fill: white;
			stroke: white;
		}

		& .MuiStepIcon-active,
		.MuiStepIcon-completed {
			color: ${linkBlue};
		}
	}
`

const StyledHr = styled.hr`
	${(p) => p.theme.breakpoints.down('md')} {
		margin: ${(p) => p.theme.spacing(4)}px 0;
	}
`

const CardVerificationContainer = styled(Flex)`
	gap: ${(p) => p.theme.spacing(1)}px;
	margin-right: auto;
`

export const WizardButton = styled(Button)<{ mobileShrink?: boolean }>`
	${(p) => p.theme.breakpoints.down('md')} {
		width: ${(p) => (p.mobileShrink ? '' : '100%')};
	}
`

const StyledTooltip = styled(PopperTooltip)`
	width: 100%;

	${(p) => p.theme.breakpoints.up('lg')} {
		max-width: 420px;
	}
`

const WizardPage: React.FunctionComponent<IProps> = (props) => {
	const {
		className,
		children,
		active,
		canContinue = true,
		secondaryButton,
		canReturn = true,
		onProceed,
		bottomBarEnabled = true,
		bottomBarRemark,
		title,
		onPrev,
		stepperDetails,
		proceedButtonProps,
		proceedButtonTooltip,
		showSCAIcon,
	} = props
	const { t } = useTranslation()

	return (
		<WizardPageContainer active={Boolean(active)} className={className} data-test="WizardPage">
			{title ? (
				<TopBarContainer>
					<Flex justify="flex-start" align="baseline">
						{/* eslint-disable-next-line i18next/no-literal-string */}
						{canReturn && onPrev ? <PrevButton onClick={(e) => onPrev(e)}>&larr;</PrevButton> : null}
						<PageTitle data-test="WizardPage.Title">{title}</PageTitle>
					</Flex>
					{!stepperDetails ? <StyledHr /> : null}
				</TopBarContainer>
			) : null}
			{stepperDetails ? (
				<StyledStepper activeStep={stepperDetails.step}>
					{Array.from(Array(stepperDetails.total).keys()).map((i) => (
						<Step key={i} completed={i < stepperDetails.step}>
							<StyledStepLabel>{null}</StyledStepLabel>
						</Step>
					))}
				</StyledStepper>
			) : null}
			<ContentContainer>{children}</ContentContainer>
			{bottomBarEnabled ? (
				<BottomBarContainer
					data-test="WizardPage.BottomBar"
					justify={!bottomBarRemark && !showSCAIcon ? 'flex-end' : 'space-between'}
				>
					{showSCAIcon && (
						<CardVerificationContainer>
							<CardVerificationHint />
							<SCADescription data-test="WizardPage.SCADescription">
								{t('wizard.sca-text', '3D Secure verification')}
							</SCADescription>
						</CardVerificationContainer>
					)}

					{bottomBarRemark}
					{secondaryButton}
					<StyledTooltip
						dark
						targetElement={
							<WizardButton
								color="bluePrimary"
								onClick={(e) => onProceed?.(e)}
								disabled={!canContinue}
								mobilePaddingSize="sm"
								data-test="WizardPage.ProceedButton"
								{...proceedButtonProps}
							>
								{proceedButtonProps?.label ?? t('wizard.next', 'Next')}
							</WizardButton>
						}
					>
						{proceedButtonTooltip}
					</StyledTooltip>
				</BottomBarContainer>
			) : null}
		</WizardPageContainer>
	)
}

export default WizardPage
