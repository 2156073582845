import React from 'react'

import { accentPrimary } from '@/_vars'
import { E2E } from '@/lib/e2e-utils'
import { eventStopper } from '@/lib/utils'
import { font } from '@/refactor/fonts'
import styled, { flex, mediaQuery } from '@/styles'
import { DefaultProps } from '@/travelsuit'

import { ExpandIcon } from '../SvgIcon/SvgIcon'

export interface IProps extends DefaultProps, E2E {
	disabled?: boolean
	/** Overrides the entire title line. If this is specified and is a function, `icon` prop is ignored and is expected
	 * to be inside the returning value. */
	title?: ((expand: React.ReactNode, isExpanded: boolean) => React.ReactNode) | React.ReactNode
	/** Determines if the title should be stretched across the width or left inline. */
	flexTitle?: boolean
	/** Overrides the component of the container. */
	component?: React.ComponentType
	/** Overrides the component of title container. */
	titleComponent?: React.ComponentType
	/** Overrides the component of content container. */
	contentComponent?: React.ComponentType
	/** Controls if the content is expanded or collapsed. */
	open?: boolean
	/** Which platforms should the content be collapsible on.
	 * Title is not visible at all when the content isn't collapsible on the current platform. */
	collapse?: Platfrom
	titleCollapse?: Platfrom
	/** Overrides the icon element. */
	icon?: React.ReactNode | ((expanded: boolean) => React.ReactNode)
	/** Callback for toggling the Collapse. */
	onToggle?(open: boolean): void
}

type Platfrom = 'never' | 'mobile' | 'above-mobile' | 'tablet' | 'below-desktop' | 'desktop' | 'always'

interface StyleProps {
	flex?: boolean
	open?: boolean
	collapse?: Platfrom
}

type ContentProps = Pick<StyleProps, 'open' | 'collapse'>

const isMobile = (props: StyleProps) => ['always', 'mobile', 'below-desktop'].includes(props.collapse!)
const isTablet = (props: StyleProps) => ['always', 'tablet', 'above-mobile', 'below-desktop'].includes(props.collapse!)
const isDesktop = (props: StyleProps) => ['always', 'desktop', 'above-mobile'].includes(props.collapse!)

const CollapseContainer = styled.div``

export const Title = styled.div<StyleProps>`
	display: none;
	transition: all 150ms ease-in-out;
	color: ${accentPrimary};
	cursor: pointer;
	${font({ size: 14, weight: 'bold' })}

	${(props) => (props.open ? `margin-bottom: 0;` : '')}

	${(props) =>
		isMobile(props)
			? mediaQuery.mobileOnly`
			${props.flex ? flex({ justify: 'space-between', align: 'center' }) : 'display: block;'}
		`
			: null}

	${(props) =>
		isTablet(props)
			? mediaQuery.tabletOnly`
			${props.flex ? flex({ justify: 'space-between', align: 'center' }) : 'display: block;'}
		`
			: null}

	${(props) =>
		isDesktop(props)
			? mediaQuery.desktopOnly`
			${props.flex ? flex({ justify: 'space-between', align: 'center' }) : 'display: block;'}
		`
			: null}
`

export const Content = styled.div<ContentProps>`
	${font({ size: 14 })}
	${(props) =>
		isMobile(props)
			? mediaQuery.mobileOnly`
			display: ${props.open ? 'block' : 'none'};
	`
			: null}

	${(props) =>
		isTablet(props)
			? mediaQuery.tabletOnly`
			display: ${props.open ? 'block' : 'none'};
	`
			: null}

	${(props) =>
		isDesktop(props)
			? mediaQuery.desktopOnly`
			display: ${props.open ? 'block' : 'none'};
	`
			: null}
`

export const Expand = styled(ExpandIcon)`
	margin-left: 1ex;
`

const CollapseBase: React.FunctionComponent<IProps> = (props) => {
	const {
		className,
		component,
		children,
		title,
		flexTitle,
		onToggle,
		collapse = 'always',
		style,
		titleComponent,
		contentComponent,
		icon,
		titleCollapse,
		e2e = 'CollapseBase',
		disabled,
	} = props
	const [isOpen, setOpen] = React.useState(props.open!)
	const collapsible = collapse !== 'never'

	React.useEffect(() => {
		setOpen((prevOpen) => {
			if (props.open !== undefined && props.open !== prevOpen) {
				return props.open
			}

			return prevOpen
		})
	}, [props.open])

	const expandIcon = icon ? (
		typeof icon === 'function' ? (
			(icon(isOpen) as React.ReactNode)
		) : (
			icon
		)
	) : (
		<Expand expanded={isOpen} data-test={`${e2e}.Expand`} />
	)

	const renderedTitle = typeof title === 'function' ? title(expandIcon, isOpen) : title

	return (
		<CollapseContainer className={className} style={style} as={component}>
			<Title
				data-test={`${e2e}.Title`}
				open={isOpen}
				flex={flexTitle}
				collapse={titleCollapse ?? collapse}
				onClick={
					collapsible && !disabled
						? eventStopper(() => {
								setOpen(!isOpen)
								onToggle?.(!isOpen)
							})
						: undefined
				}
				as={titleComponent}
			>
				{renderedTitle}
				{typeof title !== 'function' && collapsible && expandIcon}
			</Title>
			<Content collapse={collapse} open={isOpen} as={contentComponent} data-test={`${e2e}.Content`}>
				{children}
			</Content>
		</CollapseContainer>
	)
}

export default CollapseBase
