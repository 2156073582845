import { Country, VoidCallback } from '@/travelsuit'

import { IAction } from './action-helpers'
import { CountriesTypes as Types } from './actions.types'

export const Actions = {
	setCountries(countries: Country[], callback?: VoidCallback<Country[]>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.SetCountries,
			payload: countries,
			callback,
			errCallback,
		}
	},
	getCountries(callback?: VoidCallback<Country[]>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.GetCountries.REQUEST,
			callback,
			errCallback,
		}
	},
}
