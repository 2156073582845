import { Callback, VoidCallback } from '@/travelsuit'

import { IAction } from './action-helpers'
import { CountryPOSList as CountryPOSListTypes, UserPOS as Types } from './actions.types'

export const Actions = {
	getUserPOS(callback?: VoidCallback, errCallback?: Callback): IAction {
		return {
			type: Types.GetUserPOS.REQUEST,
			callback,
			errCallback,
		}
	},
	getCountryPOSList(callback?: VoidCallback, errCallback?: Callback): IAction {
		return {
			type: CountryPOSListTypes.GetCountryPOSList.REQUEST,
			callback,
			errCallback,
		}
	},
}
