import React from 'react'

import { capitalize } from '@/lib/utils'
import styled from '@/styles'

const UserName = styled.span`
	white-space: nowrap;
`

interface UserGreetingProps {
	user: { first_name: string }
}

export function UserGreeting({ user }: UserGreetingProps) {
	const userName = capitalize(user.first_name || '')

	return <UserName data-test="UserLogin.UserLogin">{userName}</UserName>
}
