import React from 'react'
import { Link } from 'react-router-dom'

import { mediaQuery } from '@/css-helpers/media-query'
import { brightTurquoise, fontBlack } from '@/refactor/colors'
import { font, FontType } from '@/refactor/fonts'
import styled from '@/styles'
import { DefaultProps } from '@/travelsuit'

type PropsBase = DefaultProps

interface IProps extends PropsBase {
	tabKey: string
	label: React.ReactNode
	title?: string
	component?: string | React.ComponentType<any>
	linkTo?: string
	active?: boolean
	variant?: 'normal' | 'condensed'
	onTabClick?(e: React.MouseEvent<any>): void
}

const Tab = styled.div
	.attrs<IProps>((p) => ({ component: p.component ?? Link }))
	.attrs<IProps>((props: IProps) => ({
		active: props.active ? '1' : undefined,
		// FIX: To prevent as={Link} and component={Link} same time weird behavior, revealed on react-route@5.3.4 upgrade
		component: props.component === Link && 'to' in props ? undefined : props.component,
	}))<IProps>`
	${font(FontType.Comfortaa, { weight: 'bold' })}
	border-bottom: 4px solid transparent;
	padding: 15px;
	padding-top: 19px;
	display: inline-block;
	cursor: pointer;
	transition: all 150ms ease-in-out;
	color: ${fontBlack};
	text-decoration: none;

	&:hover {
		color: ${brightTurquoise};
		text-decoration: none;
	}

	${(props) =>
		props.active
			? `
				color: ${brightTurquoise};
				border-bottom-color: ${brightTurquoise};
				font-weight: bold;
			`
			: ''}

	${(props) =>
		props.variant === 'condensed'
			? `
				padding-top: auto;
				padding: 7.5px;
			`
			: ''}

	${mediaQuery.mobileOnly`padding: 19px 22.5px 15px;`}
`

export default Tab
