import { getStorageValue, setStorageValue, storages } from '@/lib/browserStorage'

const VERIFICATION_REDIRECT_DATA_KEY = '3dsVerificationResult'

export interface VerificationRedirectData<TData = never> {
	returnToUrl: string
	state?: object
	data: TData
}

export function getVerificationRedirectData<T = never>() {
	return getStorageValue<VerificationRedirectData<T>>(storages.local, VERIFICATION_REDIRECT_DATA_KEY)
}

export function setVerificationRedirectData<TData>(data: VerificationRedirectData<TData | undefined>) {
	setStorageValue(storages.local, VERIFICATION_REDIRECT_DATA_KEY, data)
}

export function clearVerificationRedirectData() {
	storages.local.removeItem(VERIFICATION_REDIRECT_DATA_KEY)
}
