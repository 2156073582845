import React from 'react'
import { useTranslation } from 'react-i18next'

import PaperPlaneSmSrc from '@/assets/paper-airplane-sm.svg'
import { Button } from '@/atoms/Button'
import { FillFixed } from '@/atoms/GenericComponents'
import { ButtonsContainer } from '@/atoms/UnavailablePageInfo'
import { requestProviderLogout } from '@/lib/auth'
import { ErrorPageBase } from '@/molecules/ErrorPageBase'
import { AppBarPlaceholder } from '@/organisms/AppBar/AppBar.components'
import PaperPlaneSrc from '@/refactor/assets/empty_states/empty_state_paperplane.svg'
import styled, { flex } from '@/styles'

const StyledFillFixed = styled(FillFixed)`
	${flex({ direction: 'column' })}
	overflow: auto;
`

const StyledErrorPage = styled(ErrorPageBase)`
	flex-grow: 1;

	${ButtonsContainer} {
		${(p) => p.theme.breakpoints.down('md')} {
			flex-direction: column;
		}
	}

	gap: ${(p) => p.theme.spacing(12)}px;

	${(p) => p.theme.breakpoints.up('lg')} {
		gap: ${(p) => p.theme.spacing(24)}px;
	}
`

export function InactiveCompanyAccountPage() {
	const { t } = useTranslation()

	return (
		<StyledFillFixed>
			<AppBarPlaceholder />
			<StyledErrorPage
				title={t('inactive-account.account-not-active-yet', 'Your company account is not active yet')}
				subtitle={t(
					'inactive-account.availabile-for-active-accounts',
					'The mobile app is only available to active GetGoing accounts',
				)}
				buttons={
					<Button onClick={requestProviderLogout} color="bluePrimary" minWidth={160}>
						{t('inactive-account.come-back-later', 'Come Back Later')}
					</Button>
				}
				pictureProps={{ defaultSrc: PaperPlaneSrc, srcSets: { md: PaperPlaneSmSrc }, width: '100%' }}
			/>
		</StyledFillFixed>
	)
}
