import { Callback, PackagesAndBillingSettings, PosBillingSettings } from '@/travelsuit'

import { IAction } from './action-helpers'
import { PackagesAndBillingSettingsTypes as Types } from './actions.types'

export const Actions = {
	getPackagesAndBillingSettings(callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.GetPackagesAndBillingSettings.REQUEST,
			callback,
			errCallback,
		}
	},
	updatePaymentInfo(
		{ id, settings }: { id: number; settings: PosBillingSettings },
		callback?: Callback,
		errCallback?: Callback,
	): IAction {
		return {
			type: Types.UpdatePaymentInfo.REQUEST,
			body: settings,
			payload: { id },
			callback,
			errCallback,
		}
	},
	createPackagesAndBillingSettings(
		settings: PackagesAndBillingSettings,
		callback?: Callback,
		errCallback?: Callback,
	): IAction {
		return {
			type: Types.CreatePackagesAndBillingSettings.REQUEST,
			body: settings,
			callback,
			errCallback,
		}
	},
	updatePackagesAndBillingSettings(
		settings: PackagesAndBillingSettings,
		callback?: Callback,
		errCallback?: Callback,
	): IAction {
		return {
			type: Types.UpdatePackagesAndBillingSettings.REQUEST,
			body: settings,
			callback,
			errCallback,
		}
	},
}
