import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { CreditCardResponse } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { TripPaymentMethodsTypes } from '../actions/actions.types'

export type TripPaymentMethodsState = ImmutableMap<number, CreditCardResponse[]>

function tripPaymentMethodsReducer(
	state: TripPaymentMethodsState = new ImmutableMap(),
	action: IAction,
): TripPaymentMethodsState {
	switch (action.type) {
		case TripPaymentMethodsTypes.GetPaymentMethods.SUCCESS:
			return state.set(action.requestPayload.tripId, action.payload)
	}
	return state
}

export default tripPaymentMethodsReducer
