import { periodKey } from '@/lib/reports-utils'

import { IAction } from '../actions/action-helpers'
import { ReportsTypes } from '../actions/actions.types'
import { ReportsState as ReportsStateHelpers } from './reports.reducer.helpers'

export interface ReportsState extends ReportsStateHelpers {}

const defaultReportsState: ReportsState = {
	overview: {},
	flights: {},
	hotels: {},
	cars: {},
	rails: {},
	travelers: {},
	meta: {
		offices: [],
		departments: [],
		accounting_unit: [],
		action_code: [],
		billable: [],
		cost_center: [],
		countries: [],
		internal_account: [],
		invoice_profiles: [],
		project_code: [],
		purchase_order_number: [],
		trip_purpose: [],
	},
	filters: {
		period: 'past-3-months',
		office: null,
		accounting_unit: null,
		action_code: null,
		billable: null,
		cost_center: null,
		country_id: null,
		internal_account: null,
		invoice_profile_id: null,
		project_code: null,
		department: null,
		purchase_order_number: null,
		trip_purpose: null,
	},
}

function reportsReducer(state: ReportsState = defaultReportsState, action: IAction): ReportsState {
	let k: string
	switch (action.type) {
		case ReportsTypes.GetOverview.SUCCESS:
			state.overview = state.overview || {}
			state.overview[periodKey(state.filters.period)] = { ...action.payload[0] }
			break
		case ReportsTypes.GetMeta.SUCCESS:
			state.meta = { ...action.payload }
			break
		case ReportsTypes.SetFilters:
			state.filters = { ...action.payload }
			state.flights = {}
			state.hotels = {}
			state.rails = {}
			state.travelers = {}
			break
		case ReportsTypes.UpdateFilters:
			return { ...state, filters: { ...state.filters, ...action.payload } }
		case ReportsTypes.GetFlightDetails.SUCCESS:
			k = action.requestPayload!
			state.flights[k] = [...action.payload]
			break
		case ReportsTypes.GetHotelDetails.SUCCESS:
			k = action.requestPayload!
			state.hotels[k] = [...action.payload]
			break
		case ReportsTypes.GetCarDetails.SUCCESS:
			k = action.requestPayload!
			state.cars[k] = [...action.payload]
			break
		case ReportsTypes.GetRailDetails.SUCCESS:
			k = action.requestPayload!
			state.rails[k] = [...action.payload]
			break
		case ReportsTypes.GetTravelerDetails.SUCCESS:
			k = action.requestPayload!
			state.travelers[k] = { ...action.payload[0] }
			break
	}
	return state
}

export default reportsReducer
