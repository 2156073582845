import { isString } from 'lodash'
import { RouteComponentProps, StaticContext } from 'react-router'
import { matchPath } from 'react-router-dom'

import { CancellationFlightOrHotelDetails } from '@/travelsuit'

import history from './history'
import { dumpQueryString, parseQueryString } from './utils'

export { matchPath }

export enum Routes {
	Home = '/',
	Login = '/auth',
	Logout = '/logout',
	NewTrip = '/trips/new',
	NewSearch = '/trips/:tripId/search/new',
	TripBuilder = '/trips/:tripId/search/:searchId/:mode?',
	BuildTrip = '/trips/:tripId/search/:searchId/build',
	BrowseTrip = '/trips/:tripId/search/:searchId/view',
	HotelResult = '/trips/:tripId/search/:searchId/hotel/:hotelId',
	TripProduct = '/trips/:tripId/booking/:bookingSegmentId',
	TripProductSearch = '/trips/:tripId/booking/:bookingSegmentId/search/:searchId',
	TripProductSearchWithStep = '/trips/:tripId/booking/:bookingSegmentId/search/:searchId/step/:step',
	Itinerary = '/trips/:id/itinerary',
	CreateTravelerProfile = '/profile/create',
	TravelerProfile = '/profile/:id',
	EditTravelerProfile = '/profile/:id/edit',
	TravelerProfileUnusedTickets = '/profile/:id/unused-tickets',
	TravelerProfileUnpaidFee = '/profile/:id/unpaid-fee',
	EditDepartment = '/departments/:id',
	LiveMap = '/admin/live-map',
	Admin = '/admin',
	ManageLocations = '/admin/locations',
	ManageTravelers = '/admin/travelers',
	ManageDepartments = '/admin/departments',
	GeneralSettings = '/admin/general',
	ReportSettings = '/admin/report',
	CorporateDiscountsAndRewardPrograms = '/admin/corporate-discounts',
	ManagePolicy = '/policy/:type/:restParams?',
	Reports = '/reports',
	ReportsOverview = '/reports/overview',
	ReportsFlights = '/reports/flights',
	ReportsHotels = '/reports/hotels',
	ReportsCars = '/reports/cars',
	ReportsRails = '/reports/trains',
	ReportsTravelers = '/reports/travelers',
	ReportsUnusedTickets = '/reports/unused-tickets',
	PasswordChangeResult = '/password-changed',
	Support = '/support',
	Billing = '/billing',
	Invoices = '/billing/invoices',
	BillingCurrent = '/billing/current',
	BillingUsage = '/billing/usage',
	BillingChangePlan = '/billing/change-plan',
	CreateInvoiceProfile = '/billing/profile/create',
	ActivateInvoiceProfile = '/billing/profile/:id/activate',
	Trips = '/trips/:status?/:scrollToTrip?',
	Expenses = '/expenses',
	ManageExpenses = '/expenses/manage',
	NewStandardExpense = '/expenses/new',
	EditStandardExpense = '/expenses/:id/review',
	EditExpenseInReport = '/expenses/:id/standard-expense-review-in-report',
	EditMileageInReport = '/expenses/:id/mileage-review-in-report',
	EditDailyAllowanceInReport = '/expenses/:id/daily-allowance-review-in-report',
	NewMileage = '/expenses/mileage/new',
	EditMileage = '/expenses/mileage/:id/review',
	NewDailyAllowance = '/expenses/daily-allowance/new',
	EditDailyAllowance = '/expenses/daily-allowance/:id/review',
	ExpenseReports = '/expenses/reports',
	ExpenseReportsSubmitted = '/expenses/reports/submitted',
	NewExpenseReport = '/expenses/reports/new',
	EditExpenseReport = '/expenses/reports/:id/review',
	SelectExpensesInReport = '/expenses/reports/:id/select',
	Safety = '/safety/:tab?',
	CreateCompanyInvoices = '/company-onboarding',
	OnboardingConfirmation = '/company-onboarding-confirmation',
	CallbackVerification = '/callback-verification/:tripId/:bookingSegmentToTravelerId',
	CreditCardCallbackVerification = '/card-verification/:cardId/:cardType/:entityId',
	WordlineOrderCallbackVerification = '/order-verification/:orderId',
	BookCustomerSuccessConsultant = '/book-consultant',
}

type TripRouteState = {
	originalBookingSegmentId?: number
	next?: boolean
}

export type RouteState = {
	[Routes.BookCustomerSuccessConsultant]: {
		backPath?: string
	}
	[Routes.BrowseTrip]: TripRouteState
	[Routes.BuildTrip]: TripRouteState
	[Routes.CreateInvoiceProfile]: {
		invoiceProfileId?: number
		isAfterCreditCardVerification?: boolean
	}
	[Routes.HotelResult]: {
		originalBookingSegmentId?: number
	}
	[Routes.Itinerary]: {
		isBookingSidebarOpen?: boolean
		nameOfSuccessfullyCancelledService?: string
		cancellationFlightDetails?: CancellationFlightOrHotelDetails
	}
	[Routes.TripBuilder]: TripRouteState
}

export type GGRouteProps<
	Params extends Record<string, string> = {},
	Route extends Routes | false = false,
> = RouteComponentProps<Params, StaticContext, Route extends keyof RouteState ? RouteState[Route] | undefined : unknown>

const routeStateTypecheck: Partial<Record<Routes, Record<string, unknown>>> = {} as RouteState // eslint-disable-line @typescript-eslint/no-unused-vars

interface RouteForOptions {
	hash?: string | Record<string, any>
	queryString?: string | Record<string, any>
}

export function routeFor(
	route: string,
	params: Record<string, any> | undefined = undefined,
	{ hash, queryString }: RouteForOptions = {},
) {
	let routeStr = route
		.replace(/:([_a-z0-9]+)\??/gi, (_str, match) => String(params?.[match] ?? ''))
		.replace(/\/+/, '/')
		.replace(/\/$/, '')
	if (!routeStr.length) {
		routeStr = '/'
	}
	if (queryString) {
		routeStr += '?' + (typeof queryString === 'string' ? queryString : dumpQueryString(queryString))
	}
	if (hash) {
		routeStr += '#' + (typeof hash === 'string' ? hash : dumpQueryString(hash))
	}
	routeStr = routeStr.replace(/\/+/g, '/')
	return routeStr
}

export function matches(route: string, { exact = true }: { exact?: boolean } = {}): string[] | null {
	const routeNames = Object.keys(Routes).filter(
		(k) =>
			matchPath(route, {
				exact,
				path: Routes[k as keyof typeof Routes],
			}) !== null,
	)

	return routeNames.length ? routeNames.map((routeName: keyof typeof Routes) => Routes[routeName]) : null
}

interface RouteOptions {
	queryString?: Record<string, any> | string
	useReturnTo?: boolean
	forceReloadSameRoute?: boolean
	replace?: boolean
}

export type RouteWithParams<R extends string> = R extends `/:${string}`
	? R
	: R extends `/${string}/:${string}`
		? R
		: never
export type RouteWithoutParams<R extends string> = RouteWithParams<R> extends never ? R : never

type GetParamRecord<P extends string> = P extends `${infer Name}?`
	? { [K in Name]?: string | number }
	: { [K in P]: string | number }
type MergeIfDefined<
	P extends Record<string, string | number> | undefined,
	X extends Record<string, string | number> | undefined,
> = P extends undefined ? X : X extends undefined ? P : P & X

export type RouteParams<
	R extends string,
	P extends Record<string, string | number> | undefined = undefined,
> = R extends `/:${infer Name}/${infer Suffix}`
	? // @ts-expect-error todo if you see this please remove this comment and fix the type error
		MergeIfDefined<MergeIfDefined<P, GetParamRecord<Name>>, RouteParams<`/${Suffix}`>>
	: R extends `/:${infer Name}`
		? // @ts-expect-error todo if you see this please remove this comment and fix the type error
			MergeIfDefined<P, GetParamRecord<Name>>
		: R extends `/${string}/:${infer Suffix}`
			? MergeIfDefined<P, RouteParams<`/:${Suffix}`>>
			: MergeIfDefined<P, undefined>

export function redirectTo<R extends Routes>(
	route: RouteWithoutParams<R>,
	params?: undefined,
	opts?: RouteOptions,
): void
export function redirectTo<R extends Routes, P extends RouteParams<R>>(
	route: RouteWithParams<R>,
	params: P,
	opts?: RouteOptions,
): void
export function redirectTo<R extends keyof RouteState>(
	route: RouteWithoutParams<R>,
	params: undefined,
	opts: RouteOptions | undefined,
	state: RouteState[R],
): void
export function redirectTo<R extends keyof RouteState, P extends RouteParams<R>>(
	route: RouteWithParams<R>,
	params: P,
	opts: RouteOptions | undefined,
	state: RouteState[R],
): void
export function redirectTo<R extends Routes, P extends RouteParams<R>>(
	route: R,
	params: P | undefined = undefined,
	routeOptions: RouteOptions = {},
	state?: R extends keyof RouteState ? RouteState[R] : never,
): void {
	const { queryString = {}, useReturnTo = false, replace = false } = routeOptions

	const _route = useReturnTo
		? routeWithReturnTo(route, params, { queryString })
		: routeFor(route, params, { queryString })

	// FIX: Let clean `location.search` if `queryString` provided
	if (document.location.pathname === _route.replace(/\?$/, '') && !state && !('queryString' in routeOptions)) {
		return
	}
	if (replace) {
		history.replace(_route, state)
	} else {
		history.push(_route, state)
	}
}

export function redirectToLogin({ useReturnTo = true, queryString }: RouteOptions = {}): void {
	return redirectTo(Routes.Login, undefined, { useReturnTo, queryString })
}

export function createStateForRoute<R extends keyof RouteState>(
	state: R extends keyof RouteState ? RouteState[R] : never,
) {
	return state
}

export function routeWithReturnTo(
	route: Routes,
	params: Record<string, any> | undefined = undefined,
	{ queryString: _queryString = {} }: Omit<RouteOptions, 'useReturnTo'> = {},
): string {
	const currentPath = getCurrentPath()
	return routeFor(route, params, {
		queryString: isString(_queryString) ? _queryString : { returnTo: currentPath, ..._queryString },
	})
}

export function loginRoute({ useReturnTo = true, queryString: _queryString = {} }: RouteOptions = {}): string {
	if (!useReturnTo) {
		return routeFor(Routes.Login, undefined, { queryString: _queryString })
	}
	return routeWithReturnTo(Routes.Login, undefined, { queryString: _queryString })
}

function getCurrentPath({ removeReturnTo = true }: { removeReturnTo?: boolean } = {}): string {
	const curQs = parseQueryString(document.location.search)
	if (removeReturnTo) {
		delete curQs.returnTo
	}
	return [document.location.pathname, dumpQueryString(curQs)].filter(Boolean).join('?')
}
