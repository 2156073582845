import { z } from 'zod'

import { AbsoluteURLZ } from '@/types/common'
import { PersonNamesZ } from '@/types/user'

export const AirlineIdZ = z.number().brand<'AirlineId'>()

export type AirlineId = z.infer<typeof AirlineIdZ>

export const AirlineAllianceIdZ = z.number().brand<'AirlineAllianceId'>()

export type AirlineAllianceId = z.infer<typeof AirlineAllianceIdZ>

export const IATACodeZ = z.string().brand<'IATACode'>()

export type IATACode = z.infer<typeof IATACodeZ>

export const FlightCarrierCodeZ = z.string().brand<'FlightCarrierCode'>()

export type FlightCarrierCode = z.infer<typeof FlightCarrierCodeZ>

export const AirlineZ = z.strictObject({
	alliance_id: AirlineAllianceIdZ.optional(),
	baggage_policy_url: AbsoluteURLZ.nullish(),
	conditions_of_carriage_url: AbsoluteURLZ.nullish(),
	country: z.string().optional(),
	iata: FlightCarrierCodeZ,
	id: AirlineIdZ.optional(),
	is_lcc: z.boolean().nullable(),
	name: z.string(),
})

export type Airline = z.infer<typeof AirlineZ>

export const FlightBookingSegmentResIdZ = z.string().brand<'FlightBookingSegmentResId'>()

export type FlightBookingSegmentResId = z.infer<typeof FlightBookingSegmentResIdZ>

export enum FlightCabinClass {
	business = 'business',
	economy = 'economy',
	first = 'first',
	premium_business = 'premium_business',
	premium_economy = 'premium_economy',
	premium_first = 'premium_first',
}

export const FlightCabinClassZ = z.nativeEnum(FlightCabinClass)

export const FlightFareGroupKeyZ = z.string().brand<'FlightFareGroupKey'>()

export type FlightFareGroupKey = z.infer<typeof FlightFareGroupKeyZ>

export const FlightIdentifierZ = z.string().brand<'FlightIdentifier'>()

export type FlightIdentifier = z.infer<typeof FlightIdentifierZ>

export const FlightOptionKeyZ = z.string().brand<'FlightOptionKey'>()

export type FlightOptionKey = z.infer<typeof FlightOptionKeyZ>

export const FlightSegmentIdZ = z.number().brand<'FlightSegmentId'>()

export type FlightSegmentId = z.infer<typeof FlightSegmentIdZ>

export const FlightSearchSegmentIdZ = z.string().brand<'FlightSearchSegmentId'>()

export type FlightSearchSegmentId = z.infer<typeof FlightSearchSegmentIdZ>

export const FlightSegmentRefIdZ = z.string().brand<'FlightSegmentRefId'>()

export type FlightSegmentRefId = z.infer<typeof FlightSegmentRefIdZ>

export const FlightSliceIdZ = z.number().brand<'FlightSliceId'>()

export type FlightSliceId = z.infer<typeof FlightSliceIdZ>

export const FlightSearchSliceIdZ = z.string().brand<'FlightSearchSliceId'>()

export type FlightSearchSliceId = z.infer<typeof FlightSearchSliceIdZ>

export const FlightUniqueKeyZ = z.string().brand<'FlightUniqueKey'>()

export type FlightUniqueKey = z.infer<typeof FlightUniqueKeyZ>

export enum FlightProvider {
	Amadeus = 'amadeus',
	FareLogix = 'farelogix',
	Kiwi = 'kiwi',
	Sabre = 'sabre',
	SabreNDC = 'sabre-ndc',
	SabreUS = 'sabre_us',
	Travelfusion = 'travelfusion',
}

export const FlightProviderZ = z.nativeEnum(FlightProvider)

export const MissingFrequentFlyerInformationZ = z.record(z.string(), z.strictObject({ users: z.array(PersonNamesZ) }))

export type MissingFrequentFlyerInformation = z.infer<typeof MissingFrequentFlyerInformationZ>
