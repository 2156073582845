import React from 'react'

interface ErrorBoundaryProps {
	fallback: React.ReactNode
}

export class ErrorBoundary extends React.Component<
	React.PropsWithChildren<ErrorBoundaryProps>,
	{ hasError?: boolean }
> {
	constructor(props: ErrorBoundaryProps) {
		super(props)
		this.state = {}
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch() {}

	render() {
		const { fallback, children } = this.props
		return this.state.hasError ? fallback : children
	}
}
