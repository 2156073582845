import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { Airport } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { AirportsTypes, ItineraryTypes } from '../actions/actions.types'

export type AirportsState = ImmutableMap<number, Airport>

function airportsReducer(state: AirportsState = new ImmutableMap(), action: IAction): AirportsState {
	switch (action.type) {
		case AirportsTypes.SetAirports:
			for (const airport of action.payload ?? []) {
				state = state.set(Number(airport.id), airport)
			}
			return state
		case ItineraryTypes.GetItinerary.SUCCESS:
			action.payload.flights_bookings.forEach((booking: any) => {
				booking.flights.forEach((flight: any) => {
					flight.segments.forEach((seg: any) => {
						state = state.set(Number(seg.departure_airport.id), seg.departure_airport)
						state = state.set(Number(seg.arrival_airport.id), seg.arrival_airport)
					})
				})
			})
			return state
	}
	return state
}

export default airportsReducer
