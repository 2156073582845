import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'

import Drawer, { IProps as DrawerProps } from '@/atoms/Drawer/Drawer'
import { LoaderOverlay } from '@/atoms/Loader/Loader'
import WizardPage, { WizardButton } from '@/atoms/WizardPage/WizardPage'
import { getStorageValue, setStorageValue, storages } from '@/lib/browserStorage'
import { useLoadingSelector, useStoreValue } from '@/lib/react-hooks/generic-hooks'
import Wizard from '@/molecules/Wizard/Wizard'
import { MyCompanyActions, MyCompanyTypes } from '@/redux/actions'
import { CompanyFeatureFlagsZ } from '@/types/company'

export type FeatureFlagSidebarProps = Omit<DrawerProps, 'ref'> & {
	className?: string
}

const storageKey = 'company-feature-flag-overrides'

export const FeatureFlagSidebar = (props: FeatureFlagSidebarProps) => {
	const { className, onClose, ...rest } = props
	const dispatch = useDispatch()
	const isLoading = useLoadingSelector(MyCompanyTypes.GetMyCompany, MyCompanyTypes.UpdateMyCompanyDetails)

	const company = useStoreValue((s) => s.myCompany)
	const featureFlags = company?.feature_flag as Record<string, boolean> | undefined
	const getCurrentValue = (key: string) => Boolean(featureFlags?.[key])

	const handleToggle = (key: string) => () => {
		if (!company) {
			return
		}
		const current = getCurrentValue(key)
		setStorageValue(storages.local, storageKey, {
			...getStorageValue<Record<string, boolean>>(storages.local, storageKey),
			[key]: !current,
		})
		dispatch({
			type: MyCompanyTypes.GetMyCompany.SUCCESS,
			payload: company,
		})
	}

	return (
		<Drawer className={className} open {...rest}>
			<Wizard>
				<WizardPage
					title="Company Feature Flags"
					proceedButtonProps={{ label: 'Close' }}
					onProceed={onClose}
					secondaryButton={
						<WizardButton
							color="negativeTransparent"
							onClick={() => {
								setStorageValue(storages.local, storageKey, {})
								dispatch(MyCompanyActions.getMyCompany())
							}}
							disabled={isLoading}
						>
							{'Reset'}
						</WizardButton>
					}
				>
					{isLoading && <LoaderOverlay />}
					<List>
						{Object.keys(CompanyFeatureFlagsZ.shape).map((featureFlagId) => {
							const labelId = `ff-${featureFlagId}`

							return (
								<ListItem key={featureFlagId} role={undefined} dense button onClick={handleToggle(featureFlagId)}>
									<ListItemIcon>
										<Checkbox
											edge="start"
											color="primary"
											checked={getCurrentValue(featureFlagId)}
											tabIndex={-1}
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
									<ListItemText id={labelId} primary={featureFlagId} />
								</ListItem>
							)
						})}
					</List>
				</WizardPage>
			</Wizard>
		</Drawer>
	)
}
