import { boxShadowBig, boxShadowMedium, white } from '@/_vars'
import { Box, BoxProps } from '@/atoms/System'
import { animations } from '@/css-helpers/animated'
import { padPx } from '@/css-helpers/units'
import { mainBlack } from '@/refactor/colors'
import { font, FontType } from '@/refactor/fonts'
import styled from '@/styles'

type CardProps = BoxProps & {
	onClick?: () => void
}

export const Card = styled(Box)<CardProps>`
	background-color: ${white};
	color: ${mainBlack};

	border-radius: 20px;
	padding: ${(p) => p.theme.spacing(6)}px;
	margin-bottom: 0;

	${animations.fadeIn.animate(500)};
	transition:
		all 150ms ease-in-out,
		border-radius 300ms ease-in-out;

	${(p) => p.theme.breakpoints.down('md')} {
		padding: ${(p) => p.theme.spacing(3, 4, 4)};
		box-shadow: ${boxShadowMedium};
		border-left-width: ${(p) => p.theme.spacing(2)}px;
	}

	${(p) =>
		p.onClick &&
		`
	cursor: pointer;
	:hover {
		box-shadow: ${boxShadowBig};
	}`}
`

interface BoxTitleProps {
	bottomMargin?: number | string
}

export const CardTitle = styled.h3<BoxTitleProps>`
	${font(FontType.Comfortaa, { size: 20, weight: 'bold' })}
	color: ${mainBlack};
	margin-bottom: ${(props) => padPx(props.bottomMargin ?? 10)};
`

export const CardSection = styled.h4<BoxTitleProps>`
	${font(FontType.Comfortaa, { size: 14, weight: 'bold' })}
	color: ${mainBlack};
	margin-bottom: ${(props) => padPx(props.bottomMargin ?? 10)};
`

export const CardListContainer = styled.div`
	${Card}:not(:last-child) {
		margin-bottom: ${(p) => p.theme.spacing(4)}px;
	}
`
