import { appConfig } from '@/app-config/appConfig'

export function makeAbsoluteUrl(url?: string | null) {
	if (!url) {
		return ''
	}
	if (url.startsWith('http') || url.startsWith('/')) {
		return url
	}
	return `${appConfig.WEB_ORIGIN}/${url}`
}

export function airlineLogo(carrier: { code: string }): string {
	return makeAbsoluteUrl(`static/airline-logos/${carrier.code}.png`)
}

/**
 * Converts a string into a URL-friendly slug.
 * @param str The input string to be slugified.
 * @returns A slugified version of the input string.
 */
function slugify(str: string): string {
	return str
		.toLowerCase()
		.trim()
		.normalize('NFD') // Normalize to decomposed form for handling accents
		.replace(/[\u0300-\u036f]/g, '') // Remove accents
		.replace(/[^\w\s-]/g, '') // Remove non-word chars (except spaces and hyphens)
		.replace(/[\s_-]+/g, '-') // Replace spaces, underscores, and hyphens with a single hyphen
		.replace(/^-+|-+$/g, '') // Remove leading and trailing hyphens
}

export function railLogo(carrierDescription: string): string {
	return makeAbsoluteUrl(`static/rail-logos/${slugify(carrierDescription)}.svg`)
}

export function hotelLoyaltyProgramLogo(hotelLoyalty: { loyalty_program_logo: string | null }): string {
	return makeAbsoluteUrl(`static/hotel-loyalty-program-logos/${hotelLoyalty.loyalty_program_logo}`)
}
