import { captureException, Severity } from '@sentry/react'

import { appConfig } from '@/app-config/appConfig'

import { isProductionEnvironment } from './isProductionEnvironment'

export const shouldLogCapturedEvents = !isProductionEnvironment()

function severityToConsoleLevel(severity?: Severity) {
	if (severity === Severity.Critical || severity === Severity.Error || severity === Severity.Fatal) {
		return 'error'
	} else if (severity === Severity.Warning) {
		return 'warn'
	} else if (severity === Severity.Info) {
		return 'info'
	} else {
		return 'log'
	}
}

export const captureAndLogError: typeof captureException = (error, captureContext) => {
	if (shouldLogCapturedEvents) {
		const level = severityToConsoleLevel(
			(captureContext && 'level' in captureContext && captureContext.level) || Severity.Error,
		)
		console[level](error, captureContext) // eslint-disable-line no-console
	}

	if (appConfig.SENTRY_ENABLED) {
		return captureException(error, captureContext)
	}

	return Date.now().toString()
}
