import React from 'react'
import onClickOutside from 'react-onclickoutside'

import { E2E } from '@/lib/e2e-utils'
import { DefaultProps } from '@/travelsuit'

interface IProps<T extends HTMLElement = HTMLElement> extends DefaultProps, E2E {
	handleClickOutside?(e: React.MouseEvent<T>): void
}

class GenericClickOutside<T extends HTMLElement = HTMLElement> extends React.Component<IProps> {
	public handleClickOutside(e: React.MouseEvent<T>) {
		const { handleClickOutside } = this.props
		handleClickOutside?.(e)
	}

	public render() {
		const { children, className } = this.props
		if (className) {
			return (
				<div className={className} data-test={this.props.e2e}>
					{children}
				</div>
			)
		}
		return <>{children}</>
	}
}

export default onClickOutside<IProps>(GenericClickOutside)
