import React from 'react'

import Button from '@/atoms/Button/Button'
import styled from '@/styles'
import { UserRoleNames } from '@/travelsuit'
import { hasUserRole } from '@/travelsuit/my-user-roles'

import CompanyTooltipText from '../CompanyTooltipText/CompanyTooltipText'
import Modal from '../Modal/Modal'

interface IProps {
	companyType: string
	onClose: () => void
}

const CompanyModalBodyText = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;

	& > :first-child {
		padding: 0 0 5px 0;
	}

	& > :last-child {
		padding: 5px 0 0 0;
	}
`

const CompanyTypeModal = ({ companyType, onClose }: IProps) => {
	const isAdmin = hasUserRole(UserRoleNames.Admin)
	const isSupportUser = hasUserRole(UserRoleNames.SupportUser)

	return (
		<Modal
			title={'Company Status Notification'}
			variant={companyType === 'demo' ? 'warning' : 'alert'}
			onClose={onClose}
			hideBottomSeparator
			controlButtons={[
				<Button key="ok" width={160} color={companyType === 'demo' ? 'yellow' : 'negative'} onClick={onClose}>
					{'Ok'}
				</Button>,
			]}
		>
			<CompanyModalBodyText>
				<CompanyTooltipText companyType={companyType} isAdmin={isAdmin} isSupportUser={isSupportUser} />
			</CompanyModalBodyText>
		</Modal>
	)
}

export default CompanyTypeModal
