import { Link } from 'react-router-dom'

import { boxShadowHeader } from '@/_vars'
import { Collapse, Content as CollapseContent, Title as CollapseTitle, Expand } from '@/atoms/Collapse/Collapse'
import { SizedBox } from '@/atoms/GenericComponents/GenericComponents'
import { SvgButton } from '@/atoms/SvgButton'
import SvgIcon, { Icon } from '@/atoms/SvgIcon/SvgIcon'
import { HEADER_Z_INDEX } from '@/css-helpers/constants'
import { padPx } from '@/css-helpers/units'
import UserLogin from '@/molecules/UserLogin/UserLogin'
import Support from '@/refactor/assets/icons/support.svg'
import { brightTurquoise, fontBlack, mainBlack, white } from '@/refactor/colors'
import { font, FontType } from '@/refactor/fonts'
import styled, { css, flex, mediaQuery } from '@/styles'
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '@/travelsuit'

export const MobileTitle = styled.span`
	display: inline-block;
	width: 100%;
	text-align: center;
	margin-left: -15%;
	${mediaQuery.above('desktop')`
		display: none;
	`}

	${mediaQuery.smallMobile`
		margin-left: 0;
		padding-right: 15%;
	`}
`

export const LogoContainer = styled(Link)`
	${flex({ direction: 'row', align: 'center', justify: 'center' })}
	${font(FontType.Comfortaa, { size: 14, weight: 'bold' })}
	height: 100%;
	margin-right: 20px;
	text-decoration: none;
	color: ${fontBlack};
	${mediaQuery.mobileOnly`width: 15%;`}

	${mediaQuery.smallMobile`
		margin-right: 0;
	`}
`

export const RoutesContainer = styled.div`
	flex: 1;
	height: 100%;
	${flex({ align: 'center', justify: 'center' })}
	${mediaQuery.hideBelow('tablet')}
`

const HeaderItem = styled.div<{ $disabled?: boolean }>`
	height: 100%;
	color: ${fontBlack};
	${flex({ align: 'center', justify: 'center' })}
	${font({ size: 14 })}

	${mediaQuery.mobileOnly`
		${flex({ align: 'center', justify: 'flex-start' })}
	`}

	& ${Icon} {
		${font({ size: 16 }, { useDefaultBase: false })}
	}

	${(props) =>
		!props.$disabled
			? `cursor: pointer;
	&:hover {
		text-decoration: underline;
	}`
			: null}
`

export const CompanyContainer = styled(HeaderItem).attrs(() => ({ $disabled: true }))`
	padding: 0 10px;
	max-width: 160px;
	position: relative;
	${mediaQuery.hideBelow('tablet')}
`

export const LoginContainer = styled(HeaderItem)`
	height: 36px;
	padding-left: 10px;
	${mediaQuery.hideBelow('tablet')}
`

export const Route = styled.a<{ active: boolean }>`
	height: 100%;
	color: inherit;
	cursor: pointer;
	transition: all 150ms ease-in-out;
	text-decoration: none;
	${font(FontType.Comfortaa, { size: 14, weight: 'bold' })}

	${flex({ align: 'center', justify: 'flex-start' })}

	&:not(:last-child) {
		margin-right: 5px;
	}

	&:active,
	&:focus {
		outline: none;
	}

	&:hover {
		color: ${brightTurquoise};
	}

	padding: 15px 20px;

	${mediaQuery.above('desktop')`
		border-bottom: 4px solid transparent;
		padding: 0 10px;
		${flex({ align: 'center', justify: 'center' })}
	`}

	${mediaQuery.above('desktopWide')`
		&:not(:last-child) {
			margin-right: 20px;
		}
	`}

	${(props) =>
		props.active
			? mediaQuery.above('desktop')`
			border-bottom-color: ${brightTurquoise};
			color: ${brightTurquoise};
		`
					.concat(
						mediaQuery.below('desktop')`
			background: ${brightTurquoise};
			color: ${white};
		`,
					)
					.join('')
			: ''}

	${mediaQuery.smallMobile`
		&:not(:last-child) {
			margin-right: 0;
		}
	`}
`

export const SidebarButton = styled(SvgButton)`
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 999999;

	& path {
		fill: ${fontBlack} !important;
	}

	${mediaQuery.hideAbove('desktop')}

	${mediaQuery.smallMobile`
		height: 100%;
		${flex({ justify: 'center', align: 'center' })}
	`}
`

export const SupportItem = styled(HeaderItem)`
	${flex({ align: 'center', justify: 'flex-start' })}
`

export const SupportIcon = styled(SvgIcon).attrs(() => ({ src: Support }))<{ active?: boolean }>`
	color: ${(props) => (props.active ? brightTurquoise : '')};
	transition: all 150ms ease-in-out;
`

export const SidebarContent = styled.div``

export const Separator = styled(SizedBox).attrs(() => ({ height: 36, width: 1 }))<{
	gapRight?: number | string | boolean
	gapLeft?: number | string | boolean
}>`
	background: ${fontBlack};
	${(props) =>
		props.gapRight ? `margin-right: ${padPx(typeof props.gapRight === 'boolean' ? 10 : props.gapRight)};` : ''}
	${(props) => (props.gapLeft ? `margin-left: ${padPx(typeof props.gapLeft === 'boolean' ? 10 : props.gapLeft)};` : '')}
`

export const AppBarContainer = styled.header<{ support?: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 0 30px 0 24px;
	background: ${(props) => (props.support ? 'rgb(83, 109, 254)' : white)};
	${flex({ justify: 'space-between', align: 'center' })}
	height: ${HEADER_HEIGHT}px;
	box-shadow: ${boxShadowHeader};
	z-index: ${HEADER_Z_INDEX};
	${font(FontType.Comfortaa, { size: 14 })}

	${(p) => p.theme.breakpoints.down('md')} {
		height: ${MOBILE_HEADER_HEIGHT}px;
	}

	${(props) =>
		props.support
			? `
		& ${Route},
		& ${HeaderItem},
		& ${HeaderItem} ${Icon},
		& ${UserLogin}
		{
			color: ${white};
		}
	`
			: ''}
`

const AppBarCollapseTitle = styled(CollapseTitle)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	padding: 16px 20px 14px;
	height: unset;
	${font(FontType.Comfortaa, undefined, { useDefaultBase: false })}
	color: ${mainBlack};

	${(props) => (props.open ? `color: ${brightTurquoise};` : '')}

	& ${Expand} {
		margin-left: auto;
	}
`

export const AppBarCollapse = styled(Collapse).attrs(() => ({
	variant: 'wizard',
	titleComponent: AppBarCollapseTitle,
}))`
	position: relative;

	${CollapseContent} {
		padding: 0 ${(p) => p.theme.spacing(3)}px;
		color: ${fontBlack};
	}

	${(p) => p.theme.breakpoints.up('lg')} {
		${CollapseContent} {
			padding: 0 ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(3)}px;
		}
	}
`

const fixedHeight = (height: number) => css`
	height: ${height}px;
	min-height: ${height}px;
`

export const AppBarPlaceholder = styled.div`
	${fixedHeight(MOBILE_HEADER_HEIGHT)}

	${(p) => p.theme.breakpoints.up('lg')} {
		${fixedHeight(HEADER_HEIGHT)}
	}
`
