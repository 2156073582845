import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { GroupTypes, User } from '@/travelsuit'
import { TravelPolicy, TravelPolicyMinimal, TripTravelPolicy } from '@/travelsuit/policy'

import { IAction } from '../actions/action-helpers'
import { TravelPolicyTypes } from '../actions/actions.types'

export type Policies = ImmutableMap<number, TravelPolicy | TravelPolicyMinimal>
export type TravelPolicies = ImmutableMap<GroupTypes, TravelPolicyMinimal[]>
export type TripTravelPolicies = ImmutableMap<number, TripTravelPolicy>

export interface TravelPolicyState {
	policies: Policies
	list: TravelPolicies
	byUser: TripTravelPolicies
	ignoredUsers: User[] | null
}

const defaultState: TravelPolicyState = {
	policies: new ImmutableMap(),
	list: new ImmutableMap(),
	ignoredUsers: null,
	byUser: new ImmutableMap(),
}

function travelPolicyReducer(state = defaultState, action: IAction): TravelPolicyState {
	let policy: TravelPolicy
	let policyMinimal: TravelPolicyMinimal

	let list: TravelPolicyMinimal[]
	let groupType: GroupTypes
	let policyGroupId: number

	switch (action.type) {
		case TravelPolicyTypes.GetPolicy.SUCCESS:
			policy = action.payload
			if (action.requestPayload === 0) {
				state.policies = state.policies.set(0, policy)
			} else {
				state.policies = state.policies.set(policy.group_id, policy)
			}
			return state
		case TravelPolicyTypes.UpdatePolicy.SUCCESS:
			policy = action.payload
			if (action.requestPayload.type === GroupTypes.Company) {
				state.policies = state.policies.set(0, policy)
			} else {
				state.policies = state.policies.set(policy.group_id, policy)
			}
			return state
		case TravelPolicyTypes.GetAllPolicies.SUCCESS:
			list = action.payload ?? []
			groupType = action.requestPayload
			for (policyMinimal of list) {
				state.policies = state.policies.set(policyMinimal.group_id, policyMinimal)
			}
			return state
		case TravelPolicyTypes.GetPoliciesByType.SUCCESS:
			list = action.payload
			groupType = action.requestPayload
			state.list = state.list.set(groupType, list)
			return state
		case TravelPolicyTypes.DeletePolicy.SUCCESS:
			policyGroupId = action.requestPayload.groupIdForPolicy
			state.policies = state.policies.delete(policyGroupId)

			return state
		case TravelPolicyTypes.GetIgnoredUsers.SUCCESS:
			state.ignoredUsers = action.payload
			return state
		case TravelPolicyTypes.UpdateIgnoredUsers.SUCCESS:
			state.ignoredUsers = action.payload
			return state
		case TravelPolicyTypes.CreateCustomPolicy.SUCCESS:
		case TravelPolicyTypes.CreateOfficePolicy.SUCCESS:
			policy = action.payload
			state.policies = state.policies.set(policy.group_id, policy)
			return state
		case TravelPolicyTypes.GetPolicyForUsers.SUCCESS:
			for (const tripPolicy of (action.payload ?? []) as TripTravelPolicy[]) {
				state.byUser = state.byUser.set(tripPolicy.user.id!, tripPolicy)
			}
			return state
	}
	return state
}

export default travelPolicyReducer
