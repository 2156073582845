import { z } from 'zod'

import { SearchIdZ } from '@/types/search'

import { CurrencyObjectZ } from '../common'
import { CreateJobResponseZ, getJobResultZ } from '../job'
import { BSTTIdZ, LocationZ, TripDirectionZ, TripIdZ } from '../trip'
import { UserIdZ } from '../user'

const RailsType = z.literal('rails')

export const PageDirectionZ = z.enum(['next', 'previous'])

export type PageDirection = z.infer<typeof PageDirectionZ>

// Requests

export const RailSearchRequestRouteZ = z.object({
	departure_date: z.string().datetime(),
	from_location: z.string(),
	from_location_type: z.enum(['rail']),
	to_location: z.string(),
	to_location_type: z.enum(['rail']),
	connecting_locations: z
		.object({
			location_type: z.string(),
			location_id: z.string(),
		})
		.array(),
})

export type RailSearchRequestRoute = z.infer<typeof RailSearchRequestRouteZ>

export const RailSearchRequestDetailsZ = z.object({
	rails_type: TripDirectionZ,
	routes: RailSearchRequestRouteZ.array().min(1),
	card_id: z.string().optional(),
})

export type RailSearchRequestDetails = z.infer<typeof RailSearchRequestDetailsZ>

export const RailSearchRequestZ = z.object({
	type: RailsType,
	details: RailSearchRequestDetailsZ,
	travelers_ids: z.array(UserIdZ),
	trip_id: TripIdZ,
})

export type RailSearchRequest = z.infer<typeof RailSearchRequestZ>

export const RailSearchSetInwardRequestZ = z.object({
	search_id: SearchIdZ,
	outward_journey_id: z.string(),
	outward_alternative_ids: z.string().array(),
})

export type RailSearchSetInwardRequest = z.infer<typeof RailSearchSetInwardRequestZ>

export const RailSearchExtendPageRequestZ = z.object({
	search_id: SearchIdZ,
	type: z.enum(['outward', 'inward']),
	page_direction: z.enum(['next', 'previous']),
	inward_search_id: z.string().optional(),
})

export type RailSearchExtendPageRequest = z.infer<typeof RailSearchExtendPageRequestZ>

export const RailCancellationOfferRequestZ = z.object({
	booking_segment_to_travelers_ids: z.array(BSTTIdZ),
})

export const RailCancellationOfferResponseZ = CreateJobResponseZ

export const RailCancelSegmentRequestZ = z.object({
	booking_segment_to_travelers_ids: z.array(BSTTIdZ),
	reason: z.string(),
})

const RailCancellationOfferZ = z
	.strictObject({
		bstt_id: BSTTIdZ,
		refund_amounts: z
			.object({
				return_mode: z
					.object({
						name: z.string(),
						return_address: z.null(),
						description: z.string(),
					})
					.nullable(),
				type: z.enum(['fare-refund', 'inventory-fee']),
				amount: CurrencyObjectZ,
			})
			.array(),
		total_refund_amount: CurrencyObjectZ.nullable(),
		expires_at: z.string().datetime({ offset: true }).nullable(),
	})
	.array()

export const RailCancellationOfferResultZ = getJobResultZ(RailCancellationOfferZ.nullable())

export type RailCancellationOffer = z.infer<typeof RailCancellationOfferZ>

// Responses

export const RailSearchRouteZ = z.object({
	departure_date: z.string().datetime(),
	from_location: LocationZ.nullable(),
	to_location: LocationZ.nullable(),
	connecting_locations: z
		.object({
			location_type: z.string(),
			location: LocationZ,
		})
		.array(),
})

export type RailSearchRoute = z.infer<typeof RailSearchRouteZ>

export const RailSearchDetailsZ = z.object({
	rails_type: TripDirectionZ,
	routes: RailSearchRouteZ.array().min(1),
	card_id: z.string().optional(),
	search_id: SearchIdZ.optional(),
	inward_search_id: z.string().optional(),
	page_direction: PageDirectionZ.optional(),
})

export type RailSearchDetails = z.infer<typeof RailSearchDetailsZ>

export const RailSearchResponseZ = z.object({
	type: RailsType,
	details: RailSearchDetailsZ,
	travelers: UserIdZ,
	trip_id: SearchIdZ,
})

export type RailSearchResponse = z.infer<typeof RailSearchResponseZ>

export function mapRailSearchRequestRoute(route: RailSearchRoute): RailSearchRequestRoute {
	return {
		departure_date: route.departure_date,
		from_location: route.from_location!.external_id!,
		from_location_type: 'rail',
		to_location: route.to_location!.external_id!,
		to_location_type: 'rail',
		connecting_locations: route.connecting_locations.map((locationDetails) => ({
			location_type: locationDetails.location_type,
			location_id: locationDetails.location.external_id!,
		})),
	}
}
