import { css } from '@/styles'

export const hideScrollbar = () => css`
	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;
`

export const hideIfEmpty = () => css`
	:not(:has(> *)) {
		display: none;
	}
`
