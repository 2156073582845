import z from 'zod'

export const ApiErrorCodeZ = z.enum([
	// General
	'authentication_error',
	'error',
	'bad_request',
	'cancelled',
	'conflict',
	'invalid',
	'locked',
	'method_not_allowed',
	'not_found',
	'permission_denied',
	'timeout',
	// Business logic
	'booking_error',
	'car_booking_error',
	'car_get_location_details_error',
	'credit_card_expired',
	'fare_unavailable',
	'result_expired',
	'send_sabre_command_error',
])

export type ApiErrorCode = z.infer<typeof ApiErrorCodeZ>

export const ApiErrorZ = z.strictObject({
	/**
	 * The type of the error
	 * @example data_expired
	 */
	code: ApiErrorCodeZ,
	/**
	 * The human readable description of the error code
	 * @example The data (cache) you are trying to get is expired and no longer available
	 */
	message: z.string().optional(),
	/**
	 * The ID of the subsystem where the error have happened
	 * @example pricing
	 */
	reason: z.string().optional(),
	/**
	 * A key-value record with additional data about the error
	 * @example { companyId: 1234 }
	 */
	extra: z.record(z.string(), z.unknown()),
})

export type ApiError = z.infer<typeof ApiErrorZ>

export const ApiErrorResponseZ = ApiErrorZ
export type ApiErrorResponse = ApiError
