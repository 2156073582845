import { camelCase as loCamelCase } from 'lodash'

export function camelCase<TTarget, T extends {} = {}>(object: T): TTarget {
	let newObject, origKey, newKey, value
	if (object instanceof Array) {
		return object.map(function (value) {
			if (typeof value === 'object') {
				value = camelCase(value)
			}
			return value
		}) as unknown as TTarget
	} else {
		newObject = {}
		for (origKey in object) {
			if (object.hasOwnProperty(origKey)) {
				newKey = loCamelCase(origKey)
				// @ts-expect-error todo if you see this please remove this comment and fix the type error
				value = object[origKey]
				if (value instanceof Array || (value !== null && value.constructor === Object)) {
					value = camelCase(value)
				}
				// @ts-expect-error todo if you see this please remove this comment and fix the type error
				newObject[newKey] = value
			}
		}
	}
	return newObject as unknown as TTarget
}
