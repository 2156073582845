let isBrowserStorageEnabled = true

try {
	localStorage.getItem('')
} catch (e) {
	isBrowserStorageEnabled = false
}

export function isUsingStorageFallback() {
	return !isBrowserStorageEnabled
}

export type GlobalStorage = Pick<Storage, 'setItem' | 'removeItem' | 'getItem'>

class FallbackStorage implements GlobalStorage {
	private items = new Map<string, string>()

	setItem(key: string, value: string): void {
		this.items.set(key, value)
	}

	removeItem(key: string): void {
		this.items.delete(key)
	}

	getItem(key: string): string | null {
		return this.items.get(key) ?? null
	}
}

function initStorages() {
	if (isBrowserStorageEnabled) {
		return { local: localStorage, session: sessionStorage }
	}

	return {
		local: new FallbackStorage(),
		session: new FallbackStorage(),
	}
}

export const storages = initStorages()

export function getStorageValue<T>(storage: GlobalStorage, key: string): T | undefined {
	const json = storage.getItem(key)

	if (!json) {
		return undefined
	}
	return JSON.parse(json)
}

export function setStorageValue<T>(storage: GlobalStorage, key: string, value: T) {
	storage.setItem(key, JSON.stringify(value))
}
