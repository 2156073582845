import { getStoreState } from '@/redux/stores'
import { UserRole, UserRoleNames } from '@/travelsuit'

export function hasUserRole(userRoleName: string) {
	const myUserRoles = getStoreState().myUserRoles
	return Boolean(myUserRoles?.findIndex((r: UserRole) => Boolean(r?.role && r.role.name === userRoleName)) >= 0)
}

export function isSupportUser() {
	return hasUserRole(UserRoleNames.SupportUser)
}

export function isBookerForOthers() {
	return hasUserRole(UserRoleNames.ManageBookings)
}

export function isGivenUserCurrentUser(userId?: number) {
	return getStoreState().auth.get('internalUser').id === userId
}
