import React from 'react'

import { baseStyles, IProps, SvgIcon } from '@/atoms/SvgIcon'
import styled from '@/styles'

import { Colors, TextButton } from '../Button'

const ButtonWrapper = styled(TextButton)`
	${baseStyles}

	padding: 0;
	margin: 0;
	border: none;
	background-color: unset;
	width: unset;
`

interface SvgButtonProps extends IProps {
	disabled?: boolean
	color?: Colors
	hideMobileClickArea?: boolean
	fontColor?: string
}

export function SvgButton({
	onClick,
	className,
	disabled,
	color,
	hideMobileClickArea,
	fontColor,
	...rest
}: SvgButtonProps) {
	return (
		<ButtonWrapper
			onClick={onClick}
			className={className}
			disabled={disabled}
			color={color}
			hideMobileClickArea={hideMobileClickArea}
			fontColor={fontColor}
		>
			<SvgIcon {...rest} />
		</ButtonWrapper>
	)
}
