import { TrainDiscountCard } from '@/travelsuit/trains'

import { IAction } from '../actions/action-helpers'
import { TrainTicketDiscountTypes } from '../actions/actions.types'

export type TrainDiscountTicketsState = TrainDiscountCard[]

function trainTicketDiscountsReducer(
	state: TrainDiscountTicketsState = [],
	action: IAction,
): TrainDiscountTicketsState {
	switch (action.type) {
		case TrainTicketDiscountTypes.getTicketDiscounts.SUCCESS:
			return action.payload
	}
	return state
}

export default trainTicketDiscountsReducer
