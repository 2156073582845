import { spacing } from '@/_vars'
import Box from '@/atoms/Box/Box'
import { Carousel } from '@/atoms/Carousel'
import Page from '@/pages/Page/Page'
import { brightRed, linkBlue, mainBlack, yellow } from '@/refactor/colors'
import styled from '@/styles'

import { font, FontType } from '../../refactor/fonts'

export const LoginContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr min-content;
	grid-template-areas: 'login' 'footer';

	${(p) => p.theme.breakpoints.up('lg')} {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'login banner'
			'footer banner';
	}

	min-height: 100%;
	${font(FontType.Comfortaa)}
`

export const LoginImagesBox = styled(Box)`
	grid-area: banner;
	height: 100%;
	background: ${yellow};
	padding: 0;
	margin: 0;
	position: relative;
	overflow: hidden;
	width: 100%;

	${(p) => p.theme.breakpoints.down('md')} {
		display: none;
	}
`

export const LoginBox = styled.div`
	grid-area: login;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;
	gap: ${(p) => p.theme.spacing(3)}px;

	${(p) => p.theme.breakpoints.up('md')} {
		width: 420px;
		justify-self: center;
	}

	& a {
		color: ${linkBlue};
	}
`

export const LoginPage = styled(Page)`
	height: 100vh;
	padding: 0;
`

export const LoginPageContent = styled.div`
	min-height: 100%;
	background: white;
	padding: ${(p) => p.theme.spacing(3)}px;
	display: grid;

	${(p) => p.theme.breakpoints.up('sm')} {
		padding: ${(p) => p.theme.spacing(6)}px;
	}
`

export const Header = styled.div`
	color: ${mainBlack};
	padding-top: ${(p) => p.theme.spacing(5)}px;

	${(p) => p.theme.breakpoints.up('sm')} {
		padding-top: ${(p) => p.theme.spacing(6)}px;
	}

	${(p) => p.theme.breakpoints.up('lg')} {
		padding-top: ${(p) => p.theme.spacing(10)}px;
	}

	& h2 {
		${font(FontType.Comfortaa, { size: 32, weight: 'normal' })}
		margin-bottom: 10px;
	}
`

export const LoginForm = styled.form`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: ${(p) => p.theme.spacing(6)}px;

	& > .MuiTextField-root {
		margin-top: -${(p) => p.theme.spacing(3)}px;
	}
`

export const BackToLogin = styled.a`
	text-decoration: none;
`

export const RecoveryText = styled.div``

export const ResponseText = styled.div<{ variant?: 'alert' | 'normal' }>`
	margin: ${spacing}px 0;
	color: ${(props) => (props.variant && props.variant === 'alert' ? brightRed : mainBlack)};
	font-weight: bold;
	line-height: 1.5;
`

export const FormTitle = styled.div<{ marginTop?: number }>`
	color: ${mainBlack};
	${font(FontType.Comfortaa, { size: 20, weight: 'bold' })}
`

export const LoginImage = styled.div<{ backgroundColor: string }>`
	background: ${(props) => props.backgroundColor};
	color: white;
	height: 100% !important;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	overflow: hidden; /* Image inside is shifted down and pushes the page height */
	& h2 {
		${font(FontType.Comfortaa, { size: 20, weight: 'bold' })}
		position: absolute;
		top: 20%;
		align-self: center;
		max-width: 35%;
	}
`

export const StyledCarousel = styled(Carousel)`
	height: 100%;

	& .carousel.carousel-slider {
		border-radius: 20px;
		height: 100% !important;

		& .slider-wrapper {
			height: 100% !important;

			& > ul.slider {
				height: 100% !important;

				& > li.slide {
					height: 100%;
				}
			}
		}

		& .control-dots {
			position: absolute;
			top: 80px;

			& .dot {
				background-color: white;
				box-shadow: none;
				width: 10px;
				height: 10px;

				&:focus {
					outline: none;
				}
			}
		}
	}

	& .carousel-wrapper,
	& .carousel,
	& .carousel > & .slider-wrapper,
	& .carousel > & .slider-wrapper > & .slider {
		height: 100%;
	}
`

export const Image = styled.div`
	margin-bottom: -120px;
	padding-top: 200px;
	width: 100%;
`

export const LogoImage = styled.img`
	width: 100%;
	aspect-ratio: 8/3;
`

export const Copyright = styled.div`
	grid-area: footer;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	font-size: 0.875rem;
	padding-top: ${(p) => p.theme.spacing(6)}px;
`
