import { E2E } from '@/lib/e2e-utils'
import { DefaultProps } from '@/travelsuit'

export enum DOMTreePosition {
	self = 'self',
	root = 'root',
	custom = 'custom',
}

export const widthVariantToPx = {
	sm: 600,
	lg: 800,
	xl: 1024,
}

export type ModalVariant = 'success' | 'warning' | 'alert' | 'waiting'

export interface IPropsModal extends DefaultProps, E2E {
	title?: React.ReactNode
	mobileTitle?: string
	open?: boolean
	controlHeading?: React.ReactNode
	controlButtons?: Array<React.ReactElement<any>>
	bottomBarRemark?: React.ReactNode
	controlRowComponent?: React.ReactType
	contentClassName?: string
	contentComponent?: React.ComponentClass | React.FunctionComponent | keyof JSX.IntrinsicElements
	width?: number | string | keyof typeof widthVariantToPx
	onClose?(e: React.MouseEvent<HTMLElement>): void
	variant?: ModalVariant
	domTreePosition?: DOMTreePosition
	renderInElement?: HTMLElement
	zIndex?: number
}
