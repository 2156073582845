import { makeStyles } from '@material-ui/core'
import React from 'react'

import { useTranslation } from '@/lib/i18n/i18n'
import SCA3D from '@/refactor/assets/icons/sca-3d.svg'

import { SecureAuthIconBlock, StyledTooltip } from './CardVerificationHint.components'

interface IProps {
	mobile?: boolean
}

// TODO: rework when css modules will convert to styled components
const getTooltipStyles = makeStyles(() => ({
	tip: {
		maxWidth: '500px !important',
	},
}))

export const CardVerificationHint: React.FC<IProps> = ({ mobile = false }) => {
	const { t } = useTranslation()
	const { tip } = getTooltipStyles()

	return (
		<StyledTooltip
			title={t(
				'secure-customer-authentication-tooltip-text',
				'3D Secure credit card verification may be required for payment.',
			)}
			tipClassName={tip}
		>
			<SecureAuthIconBlock data-test="3dSecureAuthIcon" src={SCA3D} mobile={mobile} />
		</StyledTooltip>
	)
}
