import { TFunction } from 'i18next'
import moment from 'moment'
import { useCallback, useState } from 'react'

import { loadData } from '@/lib/requests'
import { entityGenerator } from '@/lib/utils'
import {
	HotelAmenityValues,
	HotelCitySearchResult,
	HotelData,
	HotelResult,
	HotelSearchResult,
	HotelSelectDetails,
} from '@/travelsuit'
import { Currency } from '@/types/common'

export const emptyHotel = entityGenerator<HotelResult>({
	res_id: '',
	result_index: 0,
	address: '',
	airport_code: '',
	property_amenities: [],
	room_amenities: [],
	id: '',
	currency: Currency.USD,
	is_available: true,
	min_avg_rate: 0,
	min_avg_rate_local_currency: 0,
	min_total: 0,
	min_total_local_currency: 0,
	min_rate_breakfast_included: false,
	in_policy: true,
	policy_deviations: [],
	promo_messages: [],
	tmc_preferred: false,
	tmc_preference_tier: null,
	client_preferred: false,
	client_preference_tier: null,
	warnings: [],
	country_code: '',
	direction: '',
	distance: 0,
	hotel_rating: null,
	iata_guarantee: false,
	latitude: 0,
	longitude: 0,
	name: '',
	phone: null,
	postal_code: '',
	regions: { neighborhood: [] },
	sort_metric: [],
	state_province_code: null,
	thumbnails: [],
	trustyou_rating: null,
	is_bcd_great_rate_hotel: false,
	is_excluded: false,
	chain_info: [],
	check_in_time: '',
	check_out_time: '',
	city: '',
	liked_users: [],
	search: {} as any,
	search_id: 0,
	recommendation_score: 0,
})

export const emptyHotelData = entityGenerator<HotelData>({
	address: '',
	property_amenities: [],
	room_amenities: [],
	country_code: '',
	star_rating: null,
	rating: null,
	name: '',
	thumbnails: [],
	checkin_time: '',
	checkout_time: '',
	city: '',
	liked_users: [],
	description: '',
	lat: 0,
	long: 0,
	policy: '',
	vendor_hotel_id: '',
	what_to_expect: '',
})

export function prepareRoomJson(hotelId: string, { room, ...details }: HotelSelectDetails, searchId: number) {
	return {
		...details,
		...room,
		hotel_id: hotelId,
		res_id: room.rate_key,
		search_id: searchId,
		cancellation_due_date: room.cancel_by ? moment(room.cancel_by!).format() : null,
	}
}

export function getAmenityTitle(am: HotelAmenityValues, t: TFunction): string {
	const amenityTitleTranslations: Record<HotelAmenityValues, string> = {
		ACND: t('hotels.hotel-amenities.ACND', 'Air conditioning'),
		AIRSH: t('hotels.hotel-amenities.AIRSH', 'Airport shuttle'),
		BAR: t('hotels.hotel-amenities.BAR', 'Bar'),
		BCNT: t('hotels.hotel-amenities.BCNT', 'Business center'),
		BFST: t('hotels.hotel-amenities.BFST', 'Breakfast (may be at additional cost)'),
		CCNT: t('hotels.hotel-amenities.CCNT', 'Conference center'),
		CLNG: t('hotels.hotel-amenities.CLNG', 'Dry cleaning'),
		FBFST: t('hotels.hotel-amenities.FBFST', 'Breakfast included'),
		FINTT: t('hotels.hotel-amenities.FINTT', 'Free internet (either wired or ambiguous)'),
		FPARK: t('hotels.hotel-amenities.FPARK', 'Free parking'),
		FWIFI: t('hotels.hotel-amenities.FWIFI', 'Free wifi'),
		GOLF: t('hotels.hotel-amenities.GOLF', 'Golf'),
		GYM: t('hotels.hotel-amenities.GYM', 'Gym'),
		INTT: t('hotels.hotel-amenities.INTT', 'Internet available (either wired or ambiguous)'),
		KIDAC: t('hotels.hotel-amenities.KIDAC', 'Kids activities'),
		KTCH: t('hotels.hotel-amenities.KTCH', 'Kitchen / Kitchenette'),
		LIFT: t('hotels.hotel-amenities.LIFT', 'Elevator'),
		LNDR: t('hotels.hotel-amenities.LNDR', 'Laundry'),
		MBAR: t('hotels.hotel-amenities.MBAR', 'Mini-bar'),
		MTNG: t('hotels.hotel-amenities.MTNG', 'Meeting rooms'),
		NSMK: t('hotels.hotel-amenities.NSMK', 'Non-smoking room'),
		PARK: t('hotels.hotel-amenities.PARK', 'Parking'),
		PETS: t('hotels.hotel-amenities.PETS', 'Pets allowed'),
		POOL: t('hotels.hotel-amenities.POOL', 'Swimming pool'),
		RFRG: t('hotels.hotel-amenities.RFRG', 'Refrigerator'),
		RSTR: t('hotels.hotel-amenities.RSTR', 'Restaurant'),
		RSVC: t('hotels.hotel-amenities.RSVC', 'Room service'),
		SAFE: t('hotels.hotel-amenities.SAFE', 'Safe in room'),
		SAUNA: t('hotels.hotel-amenities.SAUNA', 'Sauna'),
		TENN: t('hotels.hotel-amenities.TENN', 'Tennis court'),
		TV: t('hotels.hotel-amenities.TV', 'TV'),
		WCHR: t('hotels.hotel-amenities.WCHR', 'Wheelchair accessible'),
		WIFI: t('hotels.hotel-amenities.WIFI', 'Wifi available'),
	}

	return amenityTitleTranslations[am]!
}

export const searchHotelsByName = async (search: string, { include_hotels }: { include_hotels?: boolean } = {}) => {
	search = search.toLocaleLowerCase().trim()
	if (!search) {
		return []
	}

	try {
		const { data } = await loadData<(HotelSearchResult & { id: string })[]>({
			resourcePath: 'hotels/search',
			params: { search, include_hotels },
		})
		return data
	} catch (e) {
		console.error(e)
		return []
	}
}

export async function searchHotelCities(terms: string) {
	const { data } = await loadData<HotelCitySearchResult[]>({
		resourcePath: 'hotels/search_city',
		params: { search: terms },
	})
	return data
}

const hotelCache: Record<string, HotelSearchResult & { id: string }> = {}
const getHotelFactory =
	() =>
	(id: string | undefined): (HotelSearchResult & { id: string }) | undefined =>
		id ? hotelCache[id] || { id, label: id } : undefined

export const useHotelCachedQuery = () => {
	const [getHotelById, setHotelById] = useState(getHotelFactory)

	const queryHotels = useCallback(async (search: string) => {
		const locations = await searchHotelsByName(search, { include_hotels: true })
		const hotels = locations.filter((location) => location.location_type === 'hotel')
		let hasNew = false
		for (const hotel of hotels) {
			if (!hotelCache[hotel.id]) {
				hotelCache[hotel.id] = hotel
				hasNew = true
			}
		}

		if (hasNew) {
			setHotelById(getHotelFactory)
		}

		return hotels
	}, [])

	return {
		queryHotels,
		getHotelById,
	}
}

export const breakfastCodes: HotelAmenityValues[] = ['FBFST', 'BFST']

export enum AccommodationType {
	All = 'all',
	Apartment = 'apartment',
	BnB = 'bnb',
	ConventionCenter = 'convention_center',
	Economy = 'economy',
	Experience = 'experience',
	Hotel = 'hotel',
	Leisure = 'leisure',
	Other = 'other',
}

export type PolicyAccommodationType = Exclude<AccommodationType, AccommodationType.All>

export type AccommodationTypeRule = {
	allowed: PolicyAccommodationType[]
}

export const accommodationTypeSettings: Record<
	AccommodationType,
	{ order: number; getLabel: (t: TFunction) => string }
> = {
	[AccommodationType.All]: {
		order: 0,
		getLabel: (t) => t('hotel-policy-settings.accommodation-type.values.all', 'All Types'),
	},
	[AccommodationType.Apartment]: {
		order: 1,
		getLabel: (t) => t('hotel-policy-settings.accommodation-type.values.apartment', 'Apartment'),
	},
	[AccommodationType.BnB]: {
		order: 5,
		getLabel: (t) => t('hotel-policy-settings.accommodation-type.values.bnb', 'B&B'),
	},
	[AccommodationType.ConventionCenter]: {
		order: 6,
		getLabel: (t) => t('hotel-policy-settings.accommodation-type.values.convention-center', 'Convention Center'),
	},
	[AccommodationType.Economy]: {
		order: 7,
		getLabel: (t) => t('hotel-policy-settings.accommodation-type.values.economy', 'Economy'),
	},
	[AccommodationType.Experience]: {
		order: 8,
		getLabel: (t) => t('hotel-policy-settings.accommodation-type.values.experience', 'Experience'),
	},
	[AccommodationType.Hotel]: {
		order: 4,
		getLabel: (t) => t('hotel-policy-settings.accommodation-type.values.hotel', 'Hotel'),
	},
	[AccommodationType.Leisure]: {
		order: 3,
		getLabel: (t) => t('hotel-policy-settings.accommodation-type.values.leisure', 'Leisure'),
	},
	[AccommodationType.Other]: {
		order: 9,
		getLabel: (t) => t('hotel-policy-settings.accommodation-type.values.other', 'Other'),
	},
}
