import { createEntityCachedQueryHook, EntityCachedQueryHook } from '@/lib/react-hooks/entity-cached-query-hook'
import { loadData } from '@/lib/requests'

import { Airport } from './index'

export async function searchAirports(search: string) {
	search = search.toLocaleLowerCase().trim()
	if (!search) {
		return []
	}

	try {
		const { data } = await loadData<Airport[]>({ resourcePath: 'sites', params: { search } })
		return data
	} catch (e) {
		console.error(e)
		return []
	}
}

async function queryAirport(code: string) {
	const { data: airports } = await loadData<Airport[]>({ resourcePath: 'airports', params: { search: code } })

	const airport = airports.find((airport) => airport.code === code)
	if (!airport) {
		throw new Error('Not found')
	}

	return airport
}

const airportPlaceholder = (code: string) => ({ code, name: code }) as Airport

export const useAirportCachedQuery = createEntityCachedQueryHook(
	'airports',
	'code',
	queryAirport,
	async (query: string) => {
		const { data: locations } = await loadData<Airport[]>({ resourcePath: 'airports', params: { search: query } })
		const airports = locations.filter((location) => location.location_type === 'airport')
		return airports
	},
	({ getEntity, queryEntity, searchEntities }: EntityCachedQueryHook<string, Airport>) => ({
		getAirportByCode: (code?: string, placeholder: typeof airportPlaceholder = airportPlaceholder) =>
			getEntity(code, placeholder),
		queryAirport: queryEntity,
		queryAirports: searchEntities,
	}),
)
