import { Currency } from '@/types/common'

import { BillingCost, WithName } from './index'

export enum CarCategory {
	All = 'ALL',
	Mini = 'MINI',
	MiniElite = 'MINI_ELITE',
	Economy = 'ECONOMY',
	EconomyElite = 'ECONOMY_ELITE',
	Compact = 'COMPACT',
	CompactElite = 'COMPACT_ELITE',
	IntermediateS = 'INTERMEDIATE_S',
	IntermediateElite = 'INTERMEDIATE_ELITE',
	Standard = 'STANDARD',
	StandardElite = 'STANDARD_ELITE',
	Fullsize = 'FULLSIZE',
	FullsizeElite = 'FULLSIZE_ELITE',
	Premium = 'PREMIUM',
	PremiumElite = 'PREMIUM_ELITE',
	Luxury = 'LUXURY',
	LuxuryElite = 'LUXURY_ELITE',
	Oversize = 'OVERSIZE',
	Special = 'SPECIAL',
}

export enum CarBodyType {
	All = 'ALL',
	TwoOrThreeDoors = 'TWO_OR_THREE_DOORS',
	TwoOrFourDoors = 'TWO_OR_FOUR_DOORS',
	FourOrFiveDoors = 'FOUR_OR_FIVE_DOORS',
	Wagon = 'WAGON',
	Van = 'VAN',
	Limousine = 'LIMOUSINE',
	Sport = 'SPORT',
	Convertible = 'CONVERTIBLE',
	SUV = 'SUV',
	OpenAirAllTerrain = 'OPEN_AIR_ALL_TERRAIN',
	Special = 'SPECIAL',
	PickupRegularCab = 'PICKUP_REGULAR_CAB',
	PickupExtendedCab = 'PICKUP_EXTENDED_CAB',
	SpecialOfferCar = 'SPECIAL_OFFER_CAR',
	Coupe = 'COUPE',
	Monospace = 'MONOSPACE',
	MotorHome = 'MOTOR_HOME',
	TwoWheelVehicle = 'TWO_WHEEL_VEHICLE',
	Roadster = 'ROADSTER',
}

export enum CarTransmission {
	Manual = 'manual',
	Automatic = 'automatic',
}

export enum CarFuel {
	Unspecified = 'unspecified fuel power',
	Diesel = 'diesel',
	Electric = 'electric',
	Hybrid = 'hybrid',
	LPG = 'lpg compressed gas',
	Hydrogen = 'hydrogen',
	Multi = 'multi fuel power',
	Petrol = 'petrol',
	Ethanol = 'ethanol',
}

export interface CarType {
	category: CarCategory | 'UNKNOWN'
	body_type: CarBodyType | 'UNKNOWN'
	transmission: CarTransmission | 'UNKNOWN'
	fuel: CarFuel | 'UNKNOWN'
	friendly_type_name: string | 'UNKNOWN'
	car_example_name: string | 'UNKNOWN'
	have_air_cond: boolean
	num_of_doors: NumOfDoors | 'UNKNOWN'
	type: string
}

export enum CarGroup {
	Small = 'small',
	Medium = 'medium',
	Large = 'large',
	Premium = 'premium',
	Van = 'van',
	SUV = 'suv',
}

// export enum Mileage {
// 	Unlimited = 'unlimited',
// 	MileageCap = 'mileage_cap',
// }

export type Mileage = 'UNL' | string

type NumOfDoors = '2/3' | '2/4' | '4/5' | '2' | '4'

export interface CarCancellationPolicy {
	amount: number | null
	due_date: string | null
	policy: string | null
}

export type CarAdditionalDetailsName =
	| 'additional_service'
	| 'age'
	| 'driver'
	| 'drop_off'
	| 'fees'
	| 'fuel'
	| 'geographic_restriction'
	| 'insurance'
	| 'license'
	| 'surcharges'
	| 'taxes'
	| 'waivers'

export interface CarRateDescription {
	title: string | null
	description: string
}

export interface CarRate {
	important_information: CarRateDescription[]
	rental_terms: CarRateDescription[]
	paid_days: number
	paid_hours: number
	fees: CarFee[]
	special_equip: CarSpecialEquipment[]
	total_price: number
	total_price_with_equip: number
}

export interface CarFee {
	amount: number | null
	currency: Currency
	description: string
}

export interface CarSpecialEquipment {
	total_price: BillingCost | null
	status: string | null
	code: string
	short_description: string | null
	long_description: string
}

export interface CarAgencyLocation extends WithName {
	agency_code: string
	iata_or_code: string
	city: string
	state: string
	country: string
	address: string
	longitude: number
	latitude: number
}

export interface CarRentAgency extends WithName {
	code: string | null
	logo: string | null
}

export type CarVendor = 'OFRAN' | 'SABRE'
