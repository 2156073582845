import { AppError, ErrorCode } from '@/types/error'
import { CreateJobResponseZ, JobStatus } from '@/types/job'
import {
	CancelSelectedSeatsRequestZ,
	GetRailSeatMapsJobResultResponseZ,
	RailCancellationOfferRequestZ,
	RailCancellationOfferResponseZ,
	RailCancellationOfferResultZ,
	RailCancelSegmentRequestZ,
	RailSearchExtendPageRequest,
	RailSearchExtendPageRequestZ,
	RailSearchSetInwardRequest,
	RailSearchSetInwardRequestZ,
	RailSeatSelection,
	UpdateSelectedSeatsRequestZ,
} from '@/types/rails'
import { BSTTId, TripId } from '@/types/trip'

import { getGGClient, JobRequestOverrides, mapJobResult, RequestOverrides } from './GetGoingClient'

export async function setRailSearchInward(
	searchId: number,
	payload: RailSearchSetInwardRequest,
	overrides?: RequestOverrides,
) {
	return getGGClient()
		.request('POST', `rails/search/${searchId}/search_inward`, {
			requestSchema: RailSearchSetInwardRequestZ,
			data: payload,

			...overrides,
		})
		.then((r) => r.data)
}

export async function extendRailSearchPage(
	searchId: number,
	payload: RailSearchExtendPageRequest,
	overrides?: RequestOverrides,
) {
	return getGGClient()
		.request('POST', `rails/search/${searchId}/paginate`, {
			requestSchema: RailSearchExtendPageRequestZ,
			data: payload,
			...overrides,
		})
		.then((r) => r.data)
}

export async function getSeatMaps(bookingSegmentId: number, overrides?: JobRequestOverrides) {
	const url = `rails/${bookingSegmentId}/seat_map` as const

	const { job_id } = await getGGClient()
		.request('POST', url, {
			responseSchema: CreateJobResponseZ,
			signal: overrides?.signal,
			...overrides?.create,
		})
		.then((r) => r.data)

	return await getGGClient().pollJobResult(url, {
		params: { job_id },
		responseSchema: GetRailSeatMapsJobResultResponseZ,
		mapResult: mapJobResult,
		signal: overrides?.signal,
		...overrides?.poll,
	})
}

getSeatMaps.queryKey = (bookingSegmentId: number) => ['rail', bookingSegmentId, 'seatMap'] as const

export async function cancelSelectedSeats(
	bookingSegmentId: number,
	seats: RailSeatSelection[],
	overrides?: RequestOverrides,
) {
	return getGGClient()
		.request('DELETE', `rails/${bookingSegmentId}/seats`, {
			requestSchema: CancelSelectedSeatsRequestZ,
			data: {
				seats,
			},
			...overrides,
		})
		.then((r) => r.data)
}

export async function updateSelectedSeats(
	bookingSegmentId: number,
	seats: RailSeatSelection[],
	overrides?: RequestOverrides,
) {
	return getGGClient()
		.request('PUT', `rails/${bookingSegmentId}/seats`, {
			requestSchema: UpdateSelectedSeatsRequestZ,
			data: {
				seats,
			},
			...overrides,
		})
		.then((r) => r.data)
}

export async function getRailCancellationOffer(
	booking_segment_to_travelers_ids: BSTTId[],
	overrides?: RequestOverrides,
) {
	const { job_id, status } = await getGGClient()
		.request('POST', `rails/cancellation_offer`, {
			requestSchema: RailCancellationOfferRequestZ,
			responseSchema: RailCancellationOfferResponseZ,
			data: { booking_segment_to_travelers_ids },
			...overrides,
		})
		.then((r) => r.data)

	if (status === JobStatus.Failed) {
		throw AppError.from(ErrorCode.server_error)
	}

	return getGGClient().pollJobResult(`rails/cancellation_offer/results`, {
		params: { job_id },
		responseSchema: RailCancellationOfferResultZ,
		mapResult: mapJobResult,
		...overrides,
	})
}

getRailCancellationOffer.queryKey = (sortedBsttIds: number[]) =>
	['rails', 'cancellation_offer', ...sortedBsttIds] as const

export async function cancelRailSegments(
	tripId: TripId,
	booking_segment_to_travelers_ids: BSTTId[],
	reason: string,
	overrides?: RequestOverrides,
) {
	return getGGClient()
		.request('POST', `trips/${tripId}/cancel_trains`, {
			requestSchema: RailCancelSegmentRequestZ,
			data: {
				booking_segment_to_travelers_ids,
				reason,
			},
			...overrides,
		})
		.then((r) => r.data)
}
