import { isCancel } from 'axios'
import { isError } from 'lodash-es'

import { AppError } from '@/types/error/AppError'
import { ErrorCode } from '@/types/error/ErrorCode'

export function isAbortError(error: unknown) {
	return isCancel(error) || AppError.is(error, ErrorCode.cancelled)
}

export function isSpecificError<T extends new (...args: any[]) => AppError>(
	errorClass: T,
	error: unknown,
	code?: ErrorCode | ErrorCode[],
): error is T {
	return (
		error instanceof errorClass && (!code || (Array.isArray(code) ? code.includes(error.code) : error.code === code))
	)
}

export function* iterateError(error?: unknown) {
	let current = error
	while (current !== undefined) {
		if (current instanceof AggregateError) {
			yield* current.errors
		} else {
			yield current
		}

		current = isError(current) ? current.cause : undefined
	}
}
