import { Company } from '@/travelsuit'
import { CompanyZ } from '@/types/company'
import { GGClientError } from '@/types/error/GGClientError'

import { getGGClient, RequestOverrides } from './GetGoingClient'

export async function getMyCompany(overrides?: RequestOverrides) {
	return getGGClient()
		.request('GET', `companies`, {
			responseSchema: CompanyZ,
			...overrides,
		})
		.then((r) => r.data as Company)
		.catch(
			// An example for type checking, will be deleted later
			GGClientError.mapAxiosError({
				booking_error: 'invalid_response',
			}),
		)
}
