import { VoidCallback } from '@/travelsuit'
import { Locales } from '@/types/locale'

import { IAction } from './action-helpers'
import { CountryLanguagesTypes } from './actions.types'

export const Actions = {
	getContryLanguages(callback?: VoidCallback<Locales[]>, errCallback?: VoidCallback): IAction {
		return {
			type: CountryLanguagesTypes.GetCountryLanguages.REQUEST,
			callback,
			errCallback,
		}
	},
}
