import { white } from '@/refactor/colors'
import { font, styled } from '@/styles'

export const PillBadge = styled.span<{ color: string }>`
	display: inline-block;
	border-radius: 30px;
	padding: 2px 10px;
	${font({ size: 10, weight: 800 })}
	color: ${white};
	background-color: ${(props) => props.color};
	border: 1px solid ${(props) => props.color};
	white-space: nowrap;
	margin-top: 1px;
`
