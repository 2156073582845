import { addEntity } from '@/lib/entity/addEntity'
import { removeEntityById } from '@/lib/entity/removeEntityById'
import { replaceEntityById } from '@/lib/entity/replaceEntityById'
import { IAction } from '@/redux/actions/action-helpers'
import { CompanyFlightProgramsTypes } from '@/redux/actions/actions.types'
import { CompanyFlightProgram } from '@/travelsuit'

export type CompanyFlightProgramsState = CompanyFlightProgram[]

export function companyFlightProgramsReducer(state: CompanyFlightProgramsState = [], action: IAction) {
	switch (action.type) {
		case CompanyFlightProgramsTypes.GetCompanyFlightPrograms.SUCCESS:
			return action.payload
		case CompanyFlightProgramsTypes.CreateCompanyFlightProgram.SUCCESS:
			return addEntity(state, action.payload)
		case CompanyFlightProgramsTypes.UpdateCompanyFlightProgram.SUCCESS:
			return replaceEntityById(state, action.payload)
		case CompanyFlightProgramsTypes.DeleteCompanyFlightProgram.SUCCESS:
			return removeEntityById(state, action.requestPayload.id)
		default:
			return state
	}
}
