import MUICheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import React, { forwardRef } from 'react'

import { boxShadowFloat } from '@/_vars'
import { Asterisk } from '@/atoms/GenericComponents/GenericComponents'
import { IProps as IconProps, InlineSvgIcon } from '@/atoms/SvgIcon/SvgIcon'
import { E2E, passTestId } from '@/lib/e2e-utils'
import { eventStopper } from '@/lib/utils'
import { mainBlack } from '@/refactor/colors'
import { font } from '@/refactor/fonts'
import styled, { css } from '@/styles'
import { DefaultProps } from '@/travelsuit'

import CheckboxCircleOff from './form/checkbox-big-off.svg?raw'
import CheckboxCircleOn from './form/checkbox-big-on.svg?raw'
import CheckboxIntermediateState from './form/checkbox-intermediate-state.svg?raw'
import CheckboxOffDisabled from './form/checkbox-off-disabled.svg?raw'
import CheckboxOff from './form/checkbox-off.svg?raw'
import CheckboxOnDisabled from './form/checkbox-on-disabled.svg?raw'
import CheckboxOn from './form/checkbox-on.svg?raw'

type CheckboxProps = DefaultProps &
	React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export interface IProps extends CheckboxProps, E2E {
	checked?: boolean
	defaultChecked?: boolean
	label?: React.ReactNode
	variant?: 'normal' | 'circle'
	disabled?: boolean
	controlSize?: IconProps['size']
	onToggle?(value: boolean, e: React.ChangeEvent<HTMLInputElement>): void
	intermediateState?: boolean
}

const StyledFormControlLabel = styled(FormControlLabel)<{ variant?: string }>`
	${font({ size: 12 })}
	line-height: 16px;

	&.MuiFormControlLabel-root {
		color: ${mainBlack};
		align-items: start;
		margin-left: -5px;
	}

	.MuiIconButton-colorPrimary,
	.MuiIconButton-colorPrimary.Mui-checked {
		&:hover {
			background-color: transparent;
		}
	}

	.MuiIconButton-root {
		padding: 0 5px;
	}

	${(props) =>
		props.variant === 'normal' &&
		css`
			&:hover:not(.Mui-disabled) {
				.MuiIconButton-root {
					svg {
						box-shadow: ${boxShadowFloat};
					}
				}
			}
		`}

	${(p) => p.theme.breakpoints.down('md')}
		&:hover:not(.Mui-disabled) {
			.MuiIconButton-root {
				svg {
					box-shadow: none;
				}
			}
		}
	}
`

const getOffIcon = (props: IProps) => {
	if (props.variant === 'circle') {
		return CheckboxCircleOff
	}
	if (props.disabled) {
		return CheckboxOffDisabled
	} else {
		return CheckboxOff
	}
}

const getOnIcon = (props: IProps) => {
	if (props.variant === 'circle') {
		return CheckboxCircleOn
	}
	if (props.disabled) {
		return CheckboxOnDisabled
	} else {
		return CheckboxOn
	}
}

const Checkbox = forwardRef<HTMLButtonElement, IProps>(function Checkbox(props, ref) {
	const {
		className,
		checked,
		label,
		onToggle,
		disabled,
		defaultChecked,
		controlSize,
		intermediateState,
		required,
		variant = 'normal',
	} = props

	const labelToRender = required ? (
		<>
			{label} <Asterisk>*</Asterisk>
		</>
	) : (
		label
	)

	const iconProps = {
		height: controlSize ?? (variant === 'normal' ? 20 : undefined),
		size: controlSize ?? (variant === 'normal' ? 16 : 40),
	}

	return (
		<StyledFormControlLabel
			className={className}
			variant={props.variant}
			onClick={eventStopper.stopPropagation()}
			label={labelToRender}
			data-test={passTestId(props)}
			control={
				<MUICheckbox
					ref={ref}
					inputProps={{ 'data-test': 'Checkbox' }}
					icon={<InlineSvgIcon {...iconProps} src={getOffIcon(props)} />}
					checkedIcon={
						<InlineSvgIcon {...iconProps} src={intermediateState ? CheckboxIntermediateState : getOnIcon(props)} />
					}
					disableRipple={true}
					checked={checked}
					defaultChecked={defaultChecked}
					disabled={disabled}
					onClick={eventStopper.stopPropagation()}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => onToggle?.(e.target.checked, e)}
					color="primary"
				/>
			}
		/>
	)
})

export default Checkbox
