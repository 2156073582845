import React from 'react'

import { accentLight, accentPrimary, accentSecondary, fgContrast } from '@/_vars'
import { CenteredAbsolute, Flex } from '@/atoms/GenericComponents/GenericComponents'
import { E2E } from '@/lib/e2e-utils'
import { graySemiTransparent, hoverBlue, linkBlue } from '@/refactor/colors'
import { font, FontType } from '@/refactor/fonts'
import { css as addCss, keyframes } from '@/styles'
import styled, { padPx, size } from '@/styles'

const Colors = {
	primary: accentPrimary,
	light: accentLight,
	secondary: accentSecondary,
	contrast: fgContrast,
}

interface IProps {
	style?: React.CSSProperties
	className?: string
	size?: number
	desktopSize?: number
	label?: React.ReactNode
	color?: keyof typeof Colors
}

export const AnimationLabel = styled.h2`
	${font(FontType.Comfortaa, { size: 20 })}
	text-align: center;
	margin-top: -80px;

	${(p) => p.theme.breakpoints.down('md')} {
		max-width: 100vw;
		padding: 0 15px;
	}
`

export const LoaderContainer = styled.div<{ size: number; desktopSize?: number }>`
	overflow: hidden;

	${(p) => (p.size ? size(p.size) : '')}

	${(p) => p.theme.breakpoints.up('lg')} {
		${(p) => (p.desktopSize ? size(p.desktopSize) : '')}
	}
`

const CircularLoaderContainer = styled(LoaderContainer)<{ color: IProps['color'] }>`
	color: ${(p) => Colors[p.color ?? 'primary']};

	position: relative;
	margin: 0 auto;

	&:before {
		content: '';
		display: block;
		padding-top: 100%;
	}
`

const CircleSvg = styled.svg`
	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	animation: rotate 2s linear infinite;
	height: 100%;
	transform-origin: center center;
	width: 100%;

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Circle = styled.circle`
	@keyframes dash {
		0% {
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 89, 200;
			stroke-dashoffset: -35px;
		}
		100% {
			stroke-dasharray: 89, 200;
			stroke-dashoffset: -124px;
		}
	}

	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	animation: dash 1.5s ease-in-out infinite;
	stroke-linecap: round;
	stroke: currentColor;
`

export const CircularLoader: React.FunctionComponent<IProps> = (props) => {
	const { className, size = 64, color, label, desktopSize, style, ...rest } = props

	return (
		<div className={className} {...rest}>
			<CircularLoaderContainer className={className} color={color} size={size} desktopSize={desktopSize} style={style}>
				<CircleSvg viewBox="25 25 50 50">
					<Circle cx="50" cy="50" r={20} fill="none" strokeWidth={4} strokeMiterlimit={10} />
				</CircleSvg>
			</CircularLoaderContainer>
			{label ? <AnimationLabel style={{ marginTop: 20 }}>{label}</AnimationLabel> : null}
		</div>
	)
}

export const ButtonLoader = styled(CircularLoader).attrs(() => ({ size: 20 }))`
	display: inline-block;
	margin: -5px;
`
const c1 = linkBlue
const c2 = hoverBlue

export const LineLoader = styled.div<{ height?: number }>`
	height: ${(props) => padPx(props.height ?? 12)};
	background: linear-gradient(90deg, ${c1} 0%, ${c2} 33%, ${c1} 66%, ${c2} 100%);
	background-size: 400%;

	@keyframes loopGradient {
		0% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 15% 50%;
		}
	}

	animation: loopGradient 2s linear infinite;
`

export const PageLoader = () => (
	<CenteredAbsolute>
		<CircularLoader color="primary" />
	</CenteredAbsolute>
)

export default CircularLoader

interface ILoaderWrapper {
	position?: 'fixed'
	background?: 'transparent'
	size?: number
}

export const LoaderWrapper = styled(Flex).attrs(() => ({
	justify: 'center',
	align: 'center',
}))<ILoaderWrapper>`
	position: ${(props) => props.position ?? 'absolute'};
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: ${(props) => props.background ?? graySemiTransparent};
`

export function LoaderOverlay({ size, ...rest }: React.ComponentPropsWithoutRef<typeof LoaderWrapper>) {
	return (
		<LoaderWrapper {...rest}>
			<CircularLoader size={size} />
		</LoaderWrapper>
	)
}

export function ScreenLoader() {
	return <LoaderOverlay position="fixed" background="transparent" />
}

interface IProgressBar {
	className?: string
	progress: number
}

const hide = keyframes`
	0% {
		opacity: 100%
	}
	100% {
		opacity: 0%;
	}
`

const hideAnimation = () => addCss`
		animation: ${hide} 1000ms linear 1s forwards;
	`

const ProgressWrapper = styled.div<IProgressBar>`
	background: ${hoverBlue};
	width: 100%;
	height: 5px;
	${(props) => (props.progress === 100 ? hideAnimation : '')};
`

const ProgressBar = styled.div<IProgressBar>`
	background: linear-gradient(90deg, ${c1}, ${c2} 50%, ${c1});
	width: ${(props) => props.progress}%;
	transition: width 1500ms linear;
	height: 100%;
`

export function ProgressLoader({ className, progress, e2e }: IProgressBar & E2E) {
	return (
		<ProgressWrapper className={className} progress={progress} data-test={e2e} data-progress={progress}>
			<ProgressBar progress={progress} />
		</ProgressWrapper>
	)
}
