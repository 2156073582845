import { AxiosResponse } from 'axios'
import { memoize } from 'lodash-es'
import { SetRequired } from 'type-fest'
import { z } from 'zod'

import { appConfig } from '@/app-config/appConfig'
import { asyncWait } from '@/lib/asyncWait'
import { createGetGoingAuthFailedResponseInterceptor, createGetGoingAuthRequestInterceptor } from '@/lib/auth'
import { ErrorCode } from '@/types/error'
import { GGClientError } from '@/types/error/GGClientError'
import { CreateJobResponseZ, getJobResultZ, JobGroupResult, JobResult, JobStatus } from '@/types/job'

import { BaseApiClient, BaseRequestConfig, EnforceRelativeURL, RequestOverrides } from '../BaseApiClient'

export type { RequestOverrides } from '../BaseApiClient'

const POLL_ACTION = Symbol()

export type JobRequestOverrides = {
	signal?: AbortSignal
	create?: Omit<RequestOverrides, 'signal'>
	poll?: Omit<RequestOverrides, 'signal'>
}

export type JobResultMapper<T, R> = (job: AxiosResponse<T>, poll: typeof POLL_ACTION) => R | typeof POLL_ACTION

export function mapJobResult<T>(
	{ data }: AxiosResponse<JobResult<T>>,
	poll: typeof POLL_ACTION,
): T | typeof POLL_ACTION {
	if (data.status === JobStatus.Completed) {
		return data.result
	}
	if (data.status === JobStatus.Failed) {
		throw new Error(data.errors?.join(';') ?? 'Unknown error')
	}
	return poll
}

export function mapJobGroupResult<T>(
	{ data }: AxiosResponse<JobGroupResult<T>>,
	poll: typeof POLL_ACTION,
): (T | { errors: string[] })[] | typeof POLL_ACTION {
	if (data.status === JobStatus.Completed) {
		return data.result
	}
	if (data.status === JobStatus.Failed) {
		throw new Error(data.result.flatMap((r) => r.errors).join(';'))
	}
	return poll
}

const getCachedJobSchema = memoize(<S extends z.ZodTypeAny>(s: S) => getJobResultZ(s))

class GetGoingClient extends BaseApiClient {
	constructor() {
		super(appConfig.API_DOMAIN)

		this.client.interceptors.request.use(createGetGoingAuthRequestInterceptor())
		this.client.interceptors.response.use(undefined, createGetGoingAuthFailedResponseInterceptor(this.client))
	}

	/**
	 * Only polls. You need to create a job first by yourself.
	 * Defaults:
	 * - maxAttempts = 300
	 * - pollDelay = 2 sec
	 *
	 * Together they give 10 min of polling until an error.
	 */
	async pollJobResult<URL extends string, ResZ extends z.ZodTypeAny, R>(
		url: EnforceRelativeURL<URL>,
		config: Omit<SetRequired<BaseRequestConfig<z.ZodType<void>, ResZ>, 'responseSchema'>, 'params'> & {
			mapResult: JobResultMapper<z.infer<ResZ>, R>
			maxAttempts?: number
			params?: { [x: string]: any; job_id: string }
			pollDelay?: number
		},
	): Promise<R> {
		// 150 attempts of 2 secs sums up as 5 minutes.
		const { mapResult, maxAttempts = 150, pollDelay = 2000, ...reqConfig } = config
		const getResult = () => this.request('GET', url, reqConfig).then((job) => mapResult(job, POLL_ACTION))

		let attempt = 1
		let result = await getResult()

		while (result === POLL_ACTION) {
			if (attempt > maxAttempts) {
				throw GGClientError.from(ErrorCode.cancelled, 'Polling maxAttempts has been reached')
			}

			await asyncWait(pollDelay)
			result = await getResult()
			attempt++
		}

		return result
	}

	async simplePollingRequest<URL extends string, ReqZ extends z.ZodTypeAny, ResZ extends z.ZodTypeAny>(
		url: EnforceRelativeURL<URL>,
		config: SetRequired<BaseRequestConfig<ReqZ, ResZ>, 'requestSchema' | 'responseSchema'>,
	) {
		const { data, params, requestSchema, responseSchema, ...sharedConfig } = config

		const { job_id } = await getGGClient()
			.request('POST', url, {
				requestSchema,
				responseSchema: CreateJobResponseZ,
				data,
				params,
				...sharedConfig,
			})
			.then((r) => r.data)

		const jobResultSchema = getCachedJobSchema(responseSchema)

		return getGGClient().pollJobResult<URL, typeof jobResultSchema, z.infer<ResZ>>(url, {
			params: { job_id },
			responseSchema: getJobResultZ(responseSchema),
			mapResult: mapJobResult,
			...sharedConfig,
		})
	}
}

let client: GetGoingClient

export function getGGClient() {
	if (!client) {
		client = new GetGoingClient()
	}
	return client
}
