import { UserPermissionsTypes } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { AuthTypes, PermissionsTypes } from '../actions/actions.types'

export type UserPermissionsState = UserPermissionsTypes[]

function userPermissionsReducer(state: UserPermissionsState = [], action: IAction): UserPermissionsState {
	switch (action.type) {
		case PermissionsTypes.GetPermissions.SUCCESS:
			return action.payload.roles
		case AuthTypes.LogoutDone:
			return []
	}
	return state
}

export default userPermissionsReducer
