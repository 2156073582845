import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { Group } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { DepartmentsTypes } from '../actions/actions.types'

export type DepartmentsState = ImmutableMap<number, Group>

function departmentsReducer(state: DepartmentsState = new ImmutableMap(), action: IAction): DepartmentsState {
	switch (action.type) {
		case DepartmentsTypes.GetAllDepartments.SUCCESS:
			for (const department of action.payload ?? []) {
				state = state.set(Number(department.id), department)
			}
			return state
		case DepartmentsTypes.UpdateDepartment.SUCCESS:
		case DepartmentsTypes.CreateDepartment.SUCCESS:
			return state.set(Number(action.payload.id), action.payload)
		case DepartmentsTypes.DeleteDepartment.SUCCESS:
			return state.delete(Number(action.requestPayload.id))
	}
	return state
}

export default departmentsReducer
