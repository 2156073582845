import { isEmpty, isNumber, sortBy } from 'lodash'

import { CurrencyIcons } from '@/assets/currency'
import BigCheckIcon from '@/refactor/assets/icons/check-big.svg'
import { successGreen, yellow } from '@/refactor/colors'
import { CabinClassName, CabinClassNames, User } from '@/travelsuit'
import {
	ParsedSeatMap,
	POSITIONS,
	ROW_CHARACTERISTICS,
	SEAT_CHARACTERISTICS,
	SEAT_STATUSES,
	SeatMapColumn,
	SeatMapDeck,
	SeatMapInfoItem,
	SeatMapParams,
	SeatMapRow,
	SeatMapSeat,
	SeatMapType,
} from '@/travelsuit/seatmaps'

import { camelCase } from './object'

export const getSeatMapsResultIdentity = ({ fare_group_key, flight_option_keys, users_id }: SeatMapParams) => {
	return `fare_group:${fare_group_key};flight_option_keys: ${flight_option_keys?.join(',')};travelers:${users_id.join(
		',',
	)}`
}

export const getSeatMapParams = (
	travelers: User[],
	fare_group_key?: string,
	flight_option_keys?: string[],
	extraParams?: {
		searchId?: number
		flightIdentifier?: string
		bookingSegmentId?: number
	},
): SeatMapParams => ({
	fare_group_key: fare_group_key,
	flight_option_keys: flight_option_keys,
	users_id: travelers.map((traveler) => traveler.id),
	search_id: extraParams?.searchId,
	flight_identifier: extraParams?.flightIdentifier,
	booking_segment_id: extraParams?.bookingSegmentId,
})

export enum SeatSelectionType {
	Current,
	Temp,
	Auto,
}
export interface SeatSelections {
	seatKey?: string
	//TODO: Old params - review on checkout implmentation
	current?: string
	temporary?: string[]
	automatic?: string[]
	travelerName?: string
	number?: string
}

export type SeatMapSegmentsSelection = Record<number, SeatMapSeat[]>

export type SeatMapFlightResultSelection = Record<string, SeatMapSegmentsSelection>

const EMPTY_SEAT: SeatMapSeat = {
	amount: 0,
	converted_amount: 0,
	status: { code: SEAT_STATUSES.N, description: 'NONE' },
	column: '-',
	seatKey: '',
	characteristics: [],
	number: 'NONE',
	in_policy: true,
	policyDeviations: [],
}

export const NORMAL_SEAT: SeatMapSeat = {
	...EMPTY_SEAT,
	status: { code: SEAT_STATUSES.A, description: 'NONE' },
}

export function isSeatSelected(selectionType?: SeatSelectionType | null) {
	return selectionType === SeatSelectionType.Current
}

export function selectionType(cell: SeatMapSeat, selections?: SeatSelections): SeatSelectionType | null {
	const { seatKey, number } = selections ?? {}
	if (seatKey === cell.seatKey || number === cell.number) {
		return SeatSelectionType.Current
	}
	return null
}

export function seatIcon(cell: SeatMapSeat, type?: SeatSelectionType | null): string | null {
	if (!isEmptySeat(cell)) {
		if ([SeatSelectionType.Current, SeatSelectionType.Auto].includes(type!)) {
			return BigCheckIcon
		}
		if (isPaidSeat(cell)) {
			return CurrencyIcons.USD
		}
		return null
	}

	return null
}

export function isPaidSeat(seat: SeatMapSeat) {
	return isAvailableSeat(seat) && seat.amount
}

function getSeatIdentity(row: SeatMapRow, seat: SeatMapSeat) {
	return `${row?.number}${seat?.column}`
}

export function getSelectedSeatName(seat: SeatMapSeat) {
	return `${seat.number}${seat.column}`
}

export function isAvailableSeat(seat: SeatMapSeat) {
	return seat?.status?.code === SEAT_STATUSES.A
}

function isAislePosition(position: SeatMapInfoItem<POSITIONS>) {
	return [POSITIONS.A, POSITIONS.WA, POSITIONS.AW].includes(position?.code)
}

export function isEmptySeat(seat?: SeatMapSeat) {
	return Boolean(
		!seat ||
			seat.characteristics?.find((char) => char.code === SEAT_CHARACTERISTICS.E) ||
			seat.status?.code === SEAT_STATUSES.N,
	)
}
export function isNotEconomy(cabinClass?: CabinClassName): boolean {
	return cabinClass !== CabinClassNames.economy
}

export function isSeat(seat?: SeatMapSeat) {
	return !isEmptySeat(seat)
}

function isOverwingSeat(seat: SeatMapSeat) {
	return Boolean(seat?.characteristics?.find((char) => char.code === SEAT_CHARACTERISTICS.OW))
}

export function isExitRow(row: SeatMapRow) {
	const { characteristics } = row

	return Boolean(characteristics?.find((char) => char?.code === ROW_CHARACTERISTICS.E))
}

export function isRowExist(row: SeatMapRow) {
	const { characteristics, number, seats } = row

	return !characteristics?.some((char) => char?.code === ROW_CHARACTERISTICS.Z) && isNumber(number) && !!seats
}

export function isOverwingRow(row: SeatMapRow, deck?: SeatMapDeck) {
	const { characteristics, seats } = row

	return (
		Boolean(characteristics?.find((char) => char?.code === ROW_CHARACTERISTICS.K) || seats?.some(isOverwingSeat)) ||
		(deck &&
			isNumber(row.number) &&
			row.number >= deck?.overwingRowNumbers?.first &&
			row.number <= deck?.overwingRowNumbers?.last)
	)
}

function getExistRowByIndex(rows: SeatMapRow[], rowIndex: number): SeatMapRow | null {
	const row = rows[rowIndex]

	if (!row) {
		return null
	}

	return isRowExist(row) ? row : getExistRowByIndex(rows, rowIndex - 1)
}

export function isFirstOverwingRow(row: SeatMapRow, rowIndex: number, deck: SeatMapDeck) {
	const { number } = row
	const { overwingRowNumbers, rows } = deck
	const prevRow = getExistRowByIndex(rows, rowIndex - 1)
	const first = Number(overwingRowNumbers?.first)

	return isOverwingRow(row, deck) && (number === first || !prevRow || !isOverwingRow(prevRow, deck))
}

export function isLastOverwingRow(row: SeatMapRow, rowIndex: number, deck: SeatMapDeck) {
	const { number } = row
	const { overwingRowNumbers, rows } = deck
	const nextRow = getExistRowByIndex(rows, rowIndex + 1)
	const last = Number(overwingRowNumbers?.last)

	return isOverwingRow(row, deck) && (number === last || !nextRow || !isOverwingRow(nextRow, deck))
}

const cabinClassColors = {
	[CabinClassNames.economy]: successGreen,
	[CabinClassNames.premium_economy]: successGreen,
	[CabinClassNames.business]: '#31A4EE',
	[CabinClassNames.first]: yellow,
}

export function seatColor(cabinClass: CabinClassName): string {
	return cabinClassColors[cabinClass ?? CabinClassNames.economy]
}

function findSeatByColumn(seats: SeatMapSeat[], column: SeatMapColumn) {
	return seats.find((seat) => column.index === seat.column)
}

function createEmptyCellForColumn({ position: { code } }: SeatMapColumn): SeatMapSeat {
	return { ...EMPTY_SEAT, column: code }
}

export function parseRow(row: SeatMapRow, columns: SeatMapColumn[]) {
	const { seats, ...rest } = row
	let filledSeats: SeatMapSeat[] = []
	if (!isRowExist(row)) {
		filledSeats = columns.map(createEmptyCellForColumn)
	} else {
		filledSeats = columns.map((column) => {
			const seat = seats ? findSeatByColumn(seats, column) : undefined
			if (seat) {
				return { ...seat, number: getSeatIdentity(row, seat) }
			} else {
				return createEmptyCellForColumn(column)
			}
		})
	}
	return { ...rest, seats: filledSeats }
}

export function parseColumns(columns: SeatMapColumn[]) {
	const sortedColumns = sortBy(columns, (column) => column.index)
	return sortedColumns
		.map((column, index, columns): SeatMapColumn[] => {
			const { position } = column
			const nextPosition = columns[index + 1]?.position
			if (isAislePosition(position) && isAislePosition(nextPosition)) {
				return [column, { index: '-', position: { code: POSITIONS.N, description: 'None' } }]
			}
			return [column]
		})
		.reduce((res, columns) => res.concat(columns), [])
}

function trimEmptyRows(untrimmedRows: SeatMapRow[]) {
	let rows = [...untrimmedRows]
	const firstNonEmptyRowIndex = rows.findIndex(isRowExist)
	if (firstNonEmptyRowIndex > 0) {
		rows = rows.slice(firstNonEmptyRowIndex)
	}

	const lastNonEmptyRowIndex = rows.length - [...rows].reverse().findIndex(isRowExist)
	if (lastNonEmptyRowIndex < rows.length) {
		rows = rows.slice(0, lastNonEmptyRowIndex)
	}
	return rows
}

function parseRows(unparsedRows: SeatMapRow[], columns: SeatMapColumn[]): SeatMapRow[] {
	const parsedRows = unparsedRows.map((row) => parseRow(row, columns))
	const trimmedRows = trimEmptyRows(parsedRows)
	return trimmedRows
}

function parseDeck(deck: SeatMapDeck) {
	const { columns: unparsedColumns, rows: unparsedRows, ...rest } = deck

	const columns = parseColumns(unparsedColumns)
	const rows = parseRows(unparsedRows, columns)

	return { ...rest, columns, rows }
}

function parseSeatMap(seatMap: SeatMapType): ParsedSeatMap {
	const {
		decks: unparsedDecks,
		segment: { segmentIdRef },
		...rest
	} = camelCase<SeatMapType>(seatMap)
	return {
		...rest,
		segmentIndex: Number(segmentIdRef) - 1,
		decks: unparsedDecks?.map((deck) => parseDeck(deck)),
	}
}

export function parseSeatMaps(seatMaps: SeatMapType[] | null): ParsedSeatMap[] {
	return seatMaps?.map(parseSeatMap) || []
}

const emergencyRowCodes = ['E', 'EC', 'EL', 'ER', 'XC']

export const isEmergencySeat = (seat?: SeatMapSeat) => {
	return seat?.characteristics.some((characteristic) => emergencyRowCodes.some((code) => code === characteristic.code))
}

export function isObjectEmpty(obj: SeatMapSegmentsSelection) {
	return Object.values(obj).every((objValue) => isEmpty(objValue))
}

export function getNumberOfSeatMapSeatArr(targetArr: SeatMapSegmentsSelection) {
	return Object.values(targetArr).reduce((acc, arr) => {
		return acc + arr.length
	}, 0)
}

export function getCurrentSeatForDeletion(selectedSeat: SeatMapSeat) {
	return { seat_keys: [selectedSeat.seat_key ? selectedSeat.seat_key : selectedSeat.seatKey] }
}

export function removeEmptyArrFromObject(targetArr: SeatMapSegmentsSelection) {
	return Object.fromEntries(Object.entries(targetArr).filter(([_, arrOfSeats]) => arrOfSeats.length !== 0))
}
