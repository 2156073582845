import { getEnvVariables } from '@/getEnvVariables'

enum NodeEnv {
	develop = 'development',
	production = 'production',
}

const NODE_ENV = getEnvVariables().MODE

export const ENV = {
	NODE_ENV,
	IS_DEBUG: NODE_ENV === NodeEnv.develop,
	IS_PROD: NODE_ENV === NodeEnv.production,
}

export const CURRENT_VERSION_HASH = getEnvVariables().VITE_REACT_APP_CURRENT_VERSION_HASH || 'git'
