import { Severity } from '@sentry/react'
import { isAxiosError } from 'axios'
import { isFunction, isString } from 'lodash'

import { captureAndLogError } from '@/lib/error'
import { AppError, AppErrorOptions } from '@/types/error/AppError'
import { ErrorCode } from '@/types/error/ErrorCode'
import { ApiError, ApiErrorCode, ApiErrorZ } from '@/types/error/ErrorResponse'
import { isSpecificError } from '@/types/error/utils'

export class GGClientError extends AppError {
	static from(code: ErrorCode, message?: string) {
		return new GGClientError({ code, message })
	}

	static is(error?: unknown, code?: ErrorCode | ErrorCode[]): error is GGClientError {
		return isSpecificError(GGClientError, error, code)
	}

	static mapApiError<R = never>(errorMap: Partial<Record<ApiErrorCode | '_', (() => R) | ErrorCode>>) {
		return function apiErrorMapper(error: ApiError): R {
			const valueMapper = errorMap[error.code] ?? errorMap['_']
			if (isFunction(valueMapper)) {
				return valueMapper()
			}
			throw new GGClientError({
				code: isString(valueMapper) ? valueMapper : ErrorCode.unknown,
				message: error.message,
				reason: error.reason,
				extra: error.extra,
				cause: error,
			})
		}
	}

	static mapAxiosError<R = never>(errorMap: Partial<Record<ApiErrorCode | '_', (() => R) | ErrorCode>>) {
		const mapper = this.mapApiError(errorMap)
		return function axiosErrorMapper(error: unknown): R {
			if (!isAxiosError(error)) {
				throw error
			}
			if (error.response) {
				const result = ApiErrorZ.safeParse(error.response.data)
				if (result.success) {
					return mapper(result.data)
				} else if (error.response.status >= 500) {
					throw new GGClientError({
						code: ErrorCode.server_error,
						message: `${error.response.status} ${error.response.statusText}`,
						cause: error,
					})
				} else if (error.response.status === 429) {
					throw new GGClientError({
						code: ErrorCode.too_many_requests,
						message: `${error.response.status} ${error.response.statusText}`,
						cause: error,
					})
				} else if (error.response.status === 401) {
					throw new GGClientError({
						code: ErrorCode.not_authenticated,
						message: `${error.response.status} ${error.response.statusText}`,
						cause: error,
					})
				} else {
					captureAndLogError(result.error, {
						level: Severity.Warning,
						extra: {
							method: error.config?.method,
							source: 'response_validation',
						},
					})
					throw new GGClientError({
						code: ErrorCode.unknown,
						message: `${error.response.status} ${error.response.statusText}`,
						cause: new AggregateError([result.error, error]),
					})
				}
			} else if (error.request) {
				throw new GGClientError({
					code: ErrorCode.connection_error,
					message: 'Connection failed and no response has been received',
					cause: error,
				})
			} else {
				throw new GGClientError({
					code: ErrorCode.misconfiguration,
					message: 'The client failed to initiate the request',
					cause: error,
				})
			}
		}
	}

	reason?: string
	extra?: Record<string, unknown>

	constructor(options: AppErrorOptions & { reason?: string; extra?: Record<string, unknown> }) {
		super(options)
		this.reason = options.reason
		this.extra = options.extra
	}
}
