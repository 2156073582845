import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { FareRuleTypes, IAction } from '@/redux/actions'
import { FareComponent, FareRulesRequestJobParams, FareRulesResponse } from '@/travelsuit/fare-rules'

export type FareRulesState = ImmutableMap<string, FareComponent[]>

const emptyFareRulesState: FareRulesState = new ImmutableMap()

function fareRulesReducer(
	state: FareRulesState = emptyFareRulesState,
	action: IAction<FareRulesResponse, FareRulesRequestJobParams>,
): FareRulesState {
	switch (action.type) {
		case FareRuleTypes.GetFareRules.UPDATE:
		case FareRuleTypes.GetFareRules.SUCCESS:
			if (action.requestPayload && action.payload?.fare_components) {
				return state.set(action.requestPayload.fare_group_key, action.payload.fare_components)
			}
	}
	return state
}

export default fareRulesReducer
