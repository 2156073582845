import React from 'react'

import { accentLight, accentPrimary, bgMaterial } from '@/_vars'
import Tooltip from '@/atoms/Tooltip/Tooltip'
import { capitalize } from '@/lib/utils'
import styled from '@/styles'
import { DefaultProps, Role } from '@/travelsuit'

interface IProps extends DefaultProps {
	role: Role
}

const Badge = styled.div<IProps>`
	height: 20px;
	width: 20px;
	border-radius: 10px;
	font-weight: bold;
	font-size: 0.85em;
	position: absolute;
	right: 0;
	top: 0;
	text-transform: capitalize;
	transform: translate(50%, -50%);
	padding: 4px 5px;
	text-align: center;
	cursor: pointer;

	${(props) =>
		props.role === Role.Manager
			? `
		background-color: ${accentLight};
		color: ${accentPrimary};
	`
			: ''}
	${(props) =>
		props.role === Role.Executive
			? `
		background-color: ${accentPrimary};
		color: ${bgMaterial};
	`
			: ''}
	${(props) =>
		props.role === Role.Employee
			? `
		display: none;
	`
			: ''}
`

const RoleBadge: React.FunctionComponent<IProps> = (props) => {
	const { className, role } = props

	return role ? (
		<Tooltip title={capitalize(role)}>
			<Badge className={className} role={role}>
				{role.toString().slice(0, 1)}
			</Badge>
		</Tooltip>
	) : null
}

export default RoleBadge
