import React from 'react'
import { Trans as TransOriginal } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ApplicationState } from '@/redux/stores'

interface IProps {
	i18nKey: string
	count?: number
	tOptions?: any
}

const Trans: React.FunctionComponent<React.PropsWithChildren<IProps>> = (props) => {
	const { i18nKey, children, count, tOptions = {} } = props
	const user = useSelector((state: ApplicationState) => state.auth.get('internalUser'))

	const mergedOptions = { ...tOptions }

	if (!mergedOptions.context && user?.traveler_profile) {
		mergedOptions.context = user.traveler_profile.gender
	}

	return (
		<TransOriginal i18nKey={i18nKey} count={count} tOptions={mergedOptions}>
			{children}
		</TransOriginal>
	)
}

export default Trans
