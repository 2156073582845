import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { Country } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { CountriesTypes } from '../actions/actions.types'

export type CountriesCollection = ImmutableMap<string, Country>

export interface CountriesState {
	byName: CountriesCollection
	byISO: CountriesCollection
	countries: Country[]
	cached: boolean
}

const defaultState: CountriesState = {
	byName: new ImmutableMap(),
	byISO: new ImmutableMap(),
	countries: [],
	cached: false,
}

function mapCountriesCache(cache: Country[], state: CountriesState, save = false) {
	cache.forEach((country) => {
		state.byName = state.byName.set(country.name, country)
		state.byISO = state.byISO.set(country.iso, country)
	})
	state.countries = cache
	if (save && state.byISO.size) {
		try {
			state.cached = true
		} catch {
			state.cached = false
		}
	} else {
		state.cached = state.byISO.size > 0
	}
	return state
}

function countriesReducer(state: CountriesState = defaultState, action: IAction): CountriesState {
	switch (action.type) {
		case CountriesTypes.SetCountries:
			return mapCountriesCache(action.payload, state, true)
		case CountriesTypes.GetCountries.SUCCESS:
			return mapCountriesCache(action.payload, state, true)
	}
	return state
}

export default countriesReducer
