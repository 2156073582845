import SvgIcon from '@/atoms/SvgIcon/SvgIcon'
import Tooltip from '@/atoms/Tooltip/Tooltip'
import { secondaryBlack, white } from '@/refactor/colors'
import styled from '@/styles'

export const StyledTooltip = styled(Tooltip)`
	display: flex;
	margin-left: 5px;
`

export const SecureAuthIconBlock = styled(SvgIcon)<{ mobile: boolean }>`
	width: 16px;
	height: 16px;

	color: ${({ mobile }) => (mobile ? 'transparent' : secondaryBlack)};
	stroke: none;

	& path {
		fill: ${({ mobile }) => (mobile ? white : 'currentColor')};
	}
`
