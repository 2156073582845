import React, { useCallback, useMemo, useRef, useState } from 'react'
import { RouteProps, withRouter } from 'react-router'

import { removeEntityById } from '@/lib/entity/removeEntityById'

import { ModalContext, ModalsContainer } from './ModalApi.components'
import { ModalAction, ModalProps } from './ModalApi.types'

export function ModalApiProvider({ children, location }: React.PropsWithChildren<Pick<RouteProps, 'location'>>) {
	const [modalActions, setModalActions] = useState<ModalAction[]>([])
	// KILLME: BCDTDRCT-1400 Drawer backdrop zIndex is 10000
	// KILLME: Sidebar zIndex is 100000
	const zIndexRef = useRef(100500)

	const currentRouteCleanupsRef = useRef<(() => void)[]>([])
	React.useEffect(() => {
		const cleanups = currentRouteCleanupsRef.current
		return () => {
			cleanups.forEach((cleanup) => cleanup())
			currentRouteCleanupsRef.current = []
		}
	}, [location?.pathname])

	const show = useCallback((render: (modalProps: ModalProps) => React.Component, shouldBindToCurrentRoute = false) => {
		const zIndex = zIndexRef.current
		zIndexRef.current = zIndex + 1

		const id = `${new Date().getTime()}_${zIndex}`

		const onHide = () => {
			setModalActions((prevModalActions) => removeEntityById(prevModalActions, id))
		}

		if (shouldBindToCurrentRoute) {
			currentRouteCleanupsRef.current.push(onHide)
		}

		return new Promise<boolean>((resolve) => {
			const modalProps: ModalProps = {
				success: () => {
					onHide()
					resolve(true)
				},
				close: () => {
					onHide()
					resolve(false)
				},
				zIndex,
				key: 0,
			}
			const modalAction: ModalAction = {
				component: render(modalProps),
				props: modalProps,
				id,
			}

			setModalActions((prevActions) => prevActions.concat(modalAction))
		})
	}, [])

	const contextValue = useMemo(() => ({ show }), [show])

	return (
		<ModalContext.Provider value={contextValue}>
			{children}
			<ModalsContainer modalActions={modalActions} />
		</ModalContext.Provider>
	)
}

export const RouteAwareModalApiProvider = withRouter(ModalApiProvider)
