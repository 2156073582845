import { z } from 'zod'

import { BillingTypeZ, TripContentAllowanceZ } from '@/types/billing'

export const CompanyIdZ = z.number().brand<'CompanyId'>()

export type CompanyId = z.infer<typeof CompanyIdZ>

export const CompanyFeatureFlagsZ = z
	.object({
		enable_debug_ui: z.boolean(),
		enable_new_flight_search: z.boolean(),
		enable_new_flight_search_debug: z.boolean(),
		enable_non_traditional_accommodation: z.boolean(),
		enable_new_rail_search: z.boolean(),
	})
	.partial()

export type CompanyFeatureFlags = z.infer<typeof CompanyFeatureFlagsZ>

export enum CompanyPreferenceType {
	CcEmails = 'cc_emails',
}

export const CompanyPreferenceTypeZ = z.nativeEnum(CompanyPreferenceType)

export enum CompanyType {
	Normal = 'normal',
	Demo = 'demo',
	Restricted = 'restricted',
}

export const CompanyTypeZ = z.nativeEnum(CompanyType)

// TODO: There are missing pieces like the User schema
export const CompanyPreferenceZ = z.object({
	preference_type: CompanyPreferenceTypeZ,
	enabled: z.boolean(),
	description: z.string().nullable(),
	excluded_users: z.array(z.unknown()), // User[],
	included_users: z.array(z.unknown()), // User[],
})

// TODO: There are missing pieces like the User schema
export const CompanyZ = z.object({
	accepted_tos: z.boolean().optional(),
	billing_type: BillingTypeZ,
	bmis: z.string().nullable(),
	company_contact_person: z.unknown().nullable(), // User | null
	company_type: CompanyTypeZ,
	country: z.unknown().optional(), // POSCountry?,
	created_dt: z.string(),
	feature_flag: CompanyFeatureFlagsZ.nullable(),
	flights_allowance: TripContentAllowanceZ,
	gpo: z
		.object({
			id: z.number(),
			name: z.string(),
		})
		.nullable(),
	has_failed_fees: z.boolean().optional(),
	hotels_allowance: TripContentAllowanceZ,
	id: CompanyIdZ,
	is_after_onboarding: z.boolean(),
	logo_url: z.string().nullable(),
	name: z.string(),
	number_of_reward_programs: z.number(),
	preferences: z.array(CompanyPreferenceZ),
	recommended_package_id: z.number().nullable(),
	recommended_pricing_model_id: z.number().nullable(),
	reports_enabled: z.boolean(),
})
