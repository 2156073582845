import { storages } from '@/lib/browserStorage'
import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { AuthUser, SessionInfo, User } from '@/travelsuit'

export interface TStoreDef {
	user: AuthUser | null
	accessToken: string
	idToken: string
	expiresAt: number
	internalUser: User | null
	termsAccepted: boolean | null
	is_impersonation: boolean | null
	user_can_operate: boolean | null
	sessionInfo: SessionInfo
	showModal: Date | null
}

export type AuthState = ImmutableMap<keyof TStoreDef, any>

export function clearLocalStorage(): AuthState {
	storages.local.removeItem('access_token')
	storages.local.removeItem('refresh_token')
	storages.local.removeItem('id_token')
	storages.local.removeItem('expires_at')
	storages.local.removeItem('internal_user')
	storages.local.removeItem('terms_accepted')

	return new ImmutableMap()
}
