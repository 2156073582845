import moment from 'moment'

import { getRefreshTokenFromStorage } from '@/lib/getRefreshTokenFromStorage'
import { MyCompanyActions, MyUserRolesActions, PermissionsActions } from '@/redux/actions'
import { getStore, getStoreState } from '@/redux/stores'
import { User, UserPermissionsTypes } from '@/travelsuit/index'

export function isPermitted(permission: UserPermissionsTypes) {
	const state = getStoreState()
	if (!state?.userPermissions) {
		return false
	}
	const permissions = state.userPermissions
	return (permissions || []).includes(permission)
}

function loadUserPermissions() {
	const store = getStore()
	store.dispatch(PermissionsActions.getPermissions())
	store.dispatch(MyUserRolesActions.getMyUserRoles())
	store.dispatch(MyCompanyActions.getMyCompany())
}

function canLoadUserPermissions() {
	const state = getStoreState()
	return (
		state.auth.get('internalUser') &&
		state.auth.get('termsAccepted') &&
		state.auth.get('expiresAt') &&
		(moment(state.auth.get('expiresAt')).valueOf() > moment(new Date()).valueOf() || getRefreshTokenFromStorage())
	)
}

export async function setPermissionsWhenUserLogsIn() {
	if (canLoadUserPermissions()) {
		loadUserPermissions()
		return
	}

	const unsubscribe = getStore().subscribe(() => {
		if (canLoadUserPermissions()) {
			unsubscribe()
			loadUserPermissions()
		}
	})
}

export function getPermissionFromUser(user: User, permission: UserPermissionsTypes) {
	return user.user_permissions?.find((p) => p.permission === permission)
}

export function userHasPermission(user: User, permission: UserPermissionsTypes) {
	return Boolean(getPermissionFromUser(user, permission))
}

export function prepareUserPermissionJson(permission: UserPermissionsTypes, user: User) {
	return {
		permission,
		enabled: true,
		user_id: user.id,
	}
}

export function canEditTraveler(userToEdit: User, currentLoggedInUser?: User) {
	return (
		isPermitted(UserPermissionsTypes.Admin) ||
		isPermitted(UserPermissionsTypes.ManageUsers) ||
		(!!currentLoggedInUser && userToEdit.id === currentLoggedInUser.id)
	)
}
