import { IAction, MileageRatesTypes } from '@/redux/actions'
import { MileageRate, MileageRateApi } from '@/types/expenses'

export type MileageRatesState = MileageRate[]

function mileageRates(state: MileageRatesState = [], action: IAction) {
	switch (action.type) {
		case MileageRatesTypes.GetRates.SUCCESS:
			return action.payload.map(normalizeRate)
		default:
			return state
	}
}

export default mileageRates

export function normalizeRate(rate: MileageRateApi): MileageRate {
	return {
		...rate,
		unit_of_length: rate.unit_of_length.toUpperCase() as Uppercase<typeof rate.unit_of_length>,
	}
}

export function denormalizeRate(rate: MileageRate): MileageRateApi {
	return {
		id: rate.id,
		amount: rate.amount,
		country_code: rate.country_code,
		name: rate.name,
		is_default: rate.is_default,
		unit_of_length: rate.unit_of_length.toLowerCase() as Lowercase<typeof rate.unit_of_length>,
	} as MileageRateApi
}
