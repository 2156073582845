import { createMuiTheme } from '@material-ui/core/styles'

import { boxShadowFloat } from '@/_vars'
import { range } from '@/lib/array-utils'
import {
	backgroundGray,
	brightTurquoise,
	darkRed,
	disabledGray,
	fontBlack,
	fontSecondary,
	hoverBlue,
	secondaryBlack,
	white,
} from '@/refactor/colors'
import { defaultFontFamily, pixelFontSize } from '@/refactor/fonts'

import { DROPDOWN_Z_INDEX } from './constants'

const defaultTransition = 'all 150ms ease-in-out'

const paperShadow = {
	boxShadow: boxShadowFloat,
}

const paperElevations = range(1, 24).reduce((all, c) => ({ ...all, ['elevation' + c]: paperShadow }), {})

export const theme = createMuiTheme({
	typography: {
		fontFamily: defaultFontFamily,
		// h1 => 96px
		// h2 => 60px
		// h3 => 48px
		// h4 => 34px
		// h5 => 24px
		// h6 => 20px
		// subtitle1 => 16px
		// subtitle2 => 14px
		// body1 => 16px
		// body2 => 14px
		// button => 14px
		// caption => 12px
		// overline => 12px
	},
	spacing: 5,
	breakpoints: {
		values: {
			xs: 0,
			sm: 420,
			md: 480,
			lg: 1024,
			xl: 1280,
		},
	},
	palette: {
		text: {
			primary: fontBlack,
			secondary: secondaryBlack,
			hint: disabledGray,
		},
		primary: {
			main: brightTurquoise,
		},
	},
	overrides: {
		MuiFormControl: {
			root: {
				'&:focus': {
					outline: 'none',
				},
			},
		},
		MuiFormLabel: {
			root: {
				color: fontSecondary,
				fontSize: pixelFontSize(14),
				lineHeight: pixelFontSize(20),
				'&$focused': {
					color: fontSecondary,
				},
			},
		},
		MuiInputLabel: {
			root: {
				zIndex: 1,
				pointerEvents: 'none',
			},
			animated: {
				transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
			},
			formControl: {
				top: 6,
				left: 20,
			},
			shrink: {
				transform: 'translate(0, 1.5px)',
				fontSize: pixelFontSize(12),
				left: 0,
				top: -5,
				pointerEvents: 'all',
			},
			asterisk: {
				color: darkRed,
			},
		},
		MuiInputBase: {
			input: {
				fontFamily: defaultFontFamily,
				fontSize: pixelFontSize(14),
				lineHeight: pixelFontSize(20),
				background: backgroundGray,
				padding: '15px 20px',
				borderRadius: 5,
				transition: defaultTransition,
				border: '1px solid transparent',

				'&:hover:not(:disabled):not(.Mui-disabled)': {
					boxShadow: boxShadowFloat,
				},
				'&:focus': {
					background: white,
					boxShadow: boxShadowFloat,
				},
				'&:invalid:hover, &:invalid:focus': {
					boxShadow: boxShadowFloat,
				},
				'&:disabled, &.Mui-disabled': {
					color: fontSecondary,
					background: disabledGray,
				},
			},
			inputMultiline: {
				padding: '15px 20px',
			},
			root: {
				'&.Mui-error input, &.Mui-error textarea': {
					borderColor: darkRed,
				},
				'&::after': {
					display: 'none',
				},
				'&::before': {
					display: 'none',
				},
				'&.MuiInputBase-multiline': {
					padding: 0,
				},
			},
		},
		MuiFormHelperText: {
			root: {
				fontSize: pixelFontSize(12),
				lineHeight: pixelFontSize(18),
				marginTop: 0,
			},
		},
		MuiSelect: {
			select: {
				borderRadius: 5,
				transition: defaultTransition,
				border: '1px solid transparent',
				'&.Mui-disabled': {
					background: disabledGray,
				},
				'&:focus': {
					borderRadius: 5,
				},
			},
			icon: {
				right: 20,
				color: fontBlack,
				fontSize: pixelFontSize(16),
				top: '50%',
				transform: 'translateY(-50%)',
				transition: defaultTransition,
			},
			iconOpen: {
				transform: 'translateY(-50%) rotate(-180deg)',
			},
		},
		MuiMenu: {
			paper: {
				borderRadius: 5,
				marginTop: 5,
				boxShadow: boxShadowFloat,
			},
			list: {
				paddingTop: 0,
				paddingBottom: 0,
			},
		},
		MuiMenuItem: {
			root: {
				color: fontBlack,
				transition: defaultTransition,
				fontSize: pixelFontSize(14),
				paddingTop: 15,
				paddingBottom: 15,
				paddingLeft: 20,
				paddingRight: 20,
				display: 'block',
				'&&': {
					whiteSpace: 'normal',
				},
				'&$selected': {
					color: brightTurquoise,
					background: 'transparent',
					'&:hover': {
						background: hoverBlue,
					},
				},
				'&:hover': {
					background: hoverBlue,
				},
			},
		},
		MuiPaper: {
			root: paperShadow,
			...paperElevations,
			rounded: {
				borderRadius: 5,
			},
		},
		MuiCheckbox: {
			root: {
				'&, & + .MuiTypography-body1': {
					fontSize: pixelFontSize(12),
					lineHeight: pixelFontSize(18),
				},
			},
		},
	},
	zIndex: {
		// TODO: z-index layout system should be reworked
		modal: DROPDOWN_Z_INDEX,
	},
})
