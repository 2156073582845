import { createLoadOnceAPIHook, useStoreValue } from '@/lib/react-hooks/generic-hooks'
import { PackagesAndBillingSettingsActions, PackagesAndBillingSettingsTypes } from '@/redux/actions'
import { createLoadingSelector } from '@/redux/reducers/loading.reducer'
import { PackagesAndBillingSettingsState } from '@/redux/reducers/packages-and-billing-settings.reducer'
import { CompanyInvoiceStatus, FeatureScope, featureScopesAccessLevelOrder } from '@/travelsuit'

const useLoadPackagesAndBillingSettingsOnce = createLoadOnceAPIHook<{}, PackagesAndBillingSettingsState>({
	loadAction: (_, { onLoad, onError }) =>
		PackagesAndBillingSettingsActions.getPackagesAndBillingSettings(onLoad, onError),
	loadingSelectors: PackagesAndBillingSettingsTypes.GetPackagesAndBillingSettings,
	storeMapper: ({ packagesAndBillingSettings }) => packagesAndBillingSettings,
	isEmpty: (settings) => !settings.length,
})

const loadingSelector = createLoadingSelector([PackagesAndBillingSettingsTypes.GetPackagesAndBillingSettings.REQUEST])

export function useCheckFeaturePermit(requiredFeature?: FeatureScope) {
	return useStoreValue((state) =>
		state.packagesAndBillingSettings.some(
			({ package: { feature_scope } }) =>
				!requiredFeature ||
				featureScopesAccessLevelOrder.indexOf(feature_scope) >= featureScopesAccessLevelOrder.indexOf(requiredFeature),
		),
	)
}

export function useFeaturePermit(requiredFeature?: FeatureScope, refresh = true) {
	const isLoading = useStoreValue((state) => loadingSelector(state.loading))
	const settings = useLoadPackagesAndBillingSettingsOnce({}, { refresh })
	const isPermitted = useCheckFeaturePermit(requiredFeature)
	return {
		isPermitted,
		isLoading: isLoading || !settings.length,
	}
}

export function useIsOnboarding() {
	const paymentInfos = useLoadPackagesAndBillingSettingsOnce({})
	return !paymentInfos.some((pi) => pi.invoice_profile?.status === CompanyInvoiceStatus.Active)
}
