import { pickBy } from 'lodash'
import moment from 'moment'

import { getAllTimePeriod } from '@/molecules/TimeRangeController/getAllTimePeriod'
import { ReportsState as ReportsStateHelpers } from '@/redux/reducers/reports.reducer.helpers'
import { TimeOptions } from '@/travelsuit'

import { isDefinedValue } from './isDefinedValue'
import {
	ReportsFiltersBase,
	ReportsFilters as ReportsFiltersHelpers,
	TimePeriodDetailed as TimePeriodDetailedHelpers,
} from './reports-utils.helpers'
import { TimeFormatsLocaleUnsafe } from './utils'

export interface ReportsFilters extends ReportsFiltersHelpers {}
interface ReportsState extends ReportsStateHelpers {}
export interface TimePeriodDetailed extends TimePeriodDetailedHelpers {}

export type TimePeriod = string | TimePeriodDetailed

const year = new Date().getFullYear()

export const timeValueMap: Record<TimeOptions, TimePeriod> = {
	'past-3-months': {
		start_date: moment().add(-3, 'month').format(TimeFormatsLocaleUnsafe.DateOnly),
		end_date: moment().format(TimeFormatsLocaleUnsafe.DateOnly),
	},
	'current-year': year.toString(),
	'last-year': (year - 1).toString(),
	'past-month': {
		start_date: moment().add(-1, 'month').format(TimeFormatsLocaleUnsafe.DateOnly),
		end_date: moment().format(TimeFormatsLocaleUnsafe.DateOnly),
	},
	'past-year': {
		start_date: moment().add(-1, 'year').format(TimeFormatsLocaleUnsafe.DateOnly),
		end_date: moment().format(TimeFormatsLocaleUnsafe.DateOnly),
	},
	'all-time': getAllTimePeriod(),
}

export function getTimePeriod(input: TimeOptions | TimePeriodDetailed | string) {
	return handleYearTimePeriod(timeValueMap[input as TimeOptions] || input)
}

export function handleYearTimePeriod(input: TimePeriod): TimePeriodDetailed {
	if (typeof input === 'string') {
		return {
			start_date: moment({ year: parseInt(input, 10) })
				.startOf('year')
				.format(TimeFormatsLocaleUnsafe.DateOnly),
			end_date: moment({ year: parseInt(input, 10) })
				.endOf('year')
				.format(TimeFormatsLocaleUnsafe.DateOnly),
		}
	}
	return input as TimePeriodDetailed
}

export function periodKey(period: TimeOptions | TimePeriod) {
	const timePeriod = getTimePeriod(period)
	return [timePeriod.start_date, timePeriod.end_date].join(';')
}

export function overviewByKey(data: ReportsState['overview'], period: ReportsFilters['period']) {
	const key = periodKey(period)
	return data?.[key] ? data[key] : null
}

export function convertFiltersToPayload<T extends ReportsFiltersBase>({ period, ...filters }: T) {
	return {
		...getTimePeriod(period),
		...pickBy(filters, isDefinedValue),
	}
}
