import { boxShadowBig } from '@/_vars'
import { forceArray } from '@/lib/array-utils'
import { mainBlack, secondaryBlack, white } from '@/refactor/colors'
import { font, FontType } from '@/refactor/fonts'
import styled, { padPx } from '@/styles'
import { DefaultProps } from '@/travelsuit'

type BoxProps = DefaultProps & JSX.IntrinsicElements['div']

export interface IProps extends BoxProps {
	clickable?: boolean
	shouldHideShadow?: boolean
	useParentForHover?: boolean
	width?: number | string
	radius?: number | string | Array<number | string>
	padding?: number | number[]
	textColor?: string
	background?: string
	minWidth?: number | string
	maxWidth?: number | string
	noMargin?: boolean
}

const DEFAULT_RADIUS = 20

function isClickable({ onClick, clickable }: IProps): boolean {
	return Boolean(onClick || clickable)
}

export const transition = 'all 200ms ease-out'

/**
 * @deprecated use Card instead
 */
export const Box = styled.div<IProps>`
	color: ${(props) => props.textColor ?? mainBlack};
	background: ${(props) => props.background ?? white};
	padding: ${(props) =>
		forceArray(props.padding ?? 20)
			.map(padPx)
			.join(' ')};
	margin-bottom: ${(props) => (props.noMargin ? 0 : 20)}px;
	transition: ${transition};
	border-radius: ${(props) =>
		forceArray(props.radius ?? DEFAULT_RADIUS)
			.map((r) => padPx(typeof r === 'boolean' ? (r ? DEFAULT_RADIUS : 0) : r))
			.join(' ')};
	${(props) => (props.width ? `width: ${padPx(props.width)};` : '')}
	${(props) => (props.maxWidth ? `max-width: ${padPx(props.width)};` : '')}
	${(props) => (props.minWidth ? `min-width: ${padPx(props.width)};` : '')}

	:hover {
		box-shadow: ${(p) => (p.shouldHideShadow || !isClickable(p) ? '' : boxShadowBig)};
	}
	cursor: ${(p) => (isClickable(p) ? 'pointer' : '')};
`

interface BoxTitleProps {
	bottomMargin?: number | string
}

export const BoxTitle = styled.h3<BoxTitleProps>`
	${font(FontType.Comfortaa, { size: 20, weight: 'bold' })}
	color: ${mainBlack};
	margin-bottom: ${(props) => padPx(props.bottomMargin || 10)};
`

export const BoxSubtitle = styled.h4<BoxTitleProps>`
	${font({ size: 14 })}
	margin-bottom: ${(props) => padPx(props.bottomMargin || 40)};
	color: ${secondaryBlack};
`

export default Box
