import React from 'react'

import CurrencySelector from '@/atoms/CurrencySelector/CurrencySelector'
import { useTranslation } from '@/lib/i18n/i18n'
import { AppBarCollapse } from '@/organisms/AppBar/AppBar.components'
import { CurrencySigns } from '@/travelsuit'

import { useCurrencySelector } from './useCurrencySelector'

export const CurrencySelectorCollapse: React.FC = () => {
	const { t } = useTranslation()
	const { currency, onChange } = useCurrencySelector()

	return (
		<AppBarCollapse
			title={
				<>
					<span>{CurrencySigns[currency]}</span>
					<span>{t('locale-selector.currency', 'Currency')}</span>
				</>
			}
		>
			<CurrencySelector currency={currency} onChange={onChange} />
		</AppBarCollapse>
	)
}
