import { z } from 'zod'

export const RailPassengerIdZ = z.string().brand<'RailPassengerId'>()

export type RailPassengerId = z.infer<typeof RailPassengerIdZ>

export const RailPassengerPricePointIdZ = z.string().brand<'RailPassengerPricePointId'>()

export type RailPassengerPricePointId = z.infer<typeof RailPassengerPricePointIdZ>

/** Example: outward-TGV32953-wagon3-deck1-seat68-id */
export const RailSeatIdZ = z.string().brand<'RailSeatId'>()

export type RailSeatId = z.infer<typeof RailSeatIdZ>

export const RailSectionIdZ = z.string().brand<'RailSectionIdZ'>()

export type RailSectionId = z.infer<typeof RailSectionIdZ>

export const RailLegIdZ = z.string()

export type RailLegId = z.infer<typeof RailLegIdZ>

export const RailSeatPreferenceGroupIdZ = z.string().brand<'RailSeatPreferenceGroupId'>()

export type RailSeatPreferenceGroupId = z.infer<typeof RailSeatPreferenceGroupIdZ>

export enum RailTransportType {
	Train = 'train',
}

export const RailTransportTypeZ = z.nativeEnum(RailTransportType)
