import React from 'react'
import { useTranslation } from 'react-i18next'

import ErrorBannerMdSrc from '@/assets/error-banner-md.svg'
import ErrorBannerXsSrc from '@/assets/error-banner-xs.svg'
import ErrorBannerSrc from '@/assets/error-banner.svg'
import { Button } from '@/atoms/Button'
import { Font } from '@/atoms/GenericComponents'
import { Routes } from '@/lib/route-utils'
import { ErrorPageBase } from '@/molecules/ErrorPageBase'
import { backgroundGray, secondaryBlack } from '@/refactor/colors'
import styled, { FontType } from '@/styles'

interface GeneralErrorPageProps {
	errorId: string | null
}

const StyledErrorPage = styled(ErrorPageBase)`
	overflow-x: hidden;
	min-height: 100vh;
	background-color: ${backgroundGray};
`

const StyledButton = styled(Button)`
	${(p) => p.theme.breakpoints.down('sm')} {
		width: 100%;
	}
`

export function GeneralErrorPage({ errorId }: GeneralErrorPageProps) {
	const { t } = useTranslation()

	const reload = () => window.location.reload()

	return (
		<React.Suspense fallback={<></>}>
			<StyledErrorPage
				title={t('error-banner.title', 'Something went wrong')}
				subtitle={t('error-banner.subtitle', 'Sorry for the inconvenience. Please try again')}
				buttons={[
					<StyledButton
						key="goToHome"
						onClick={() => {
							window.location.href = Routes.Home
						}}
						color="blueOutline"
						minWidth={160}
					>
						{t('error-banner.go-to-home', 'Home Page')}
					</StyledButton>,
					<StyledButton key="reload" onClick={reload} color="bluePrimary" minWidth={160}>
						{t('error-banner.refresh', 'Refresh')}
					</StyledButton>,
				]}
				pageInfoContent={
					errorId ? (
						<Font as="div" weight={700} size={12} color={secondaryBlack} family={FontType.Comfortaa}>
							{t('error-banner.error-id', 'Error ID: {{errorId}}', { errorId })}
						</Font>
					) : null
				}
				pictureProps={{
					defaultSrc: ErrorBannerSrc,
					srcSets: { xs: ErrorBannerXsSrc, md: ErrorBannerMdSrc },
					width: '100%',
				}}
			/>
		</React.Suspense>
	)
}
