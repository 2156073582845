import styled from '@/styles'

export const MobileClickArea = styled.span`
	display: inline-block;

	width: 100%;
	height: 100%;
	min-width: 44px;
	min-height: 44px;

	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	${(p) => p.theme.breakpoints.up('lg')} {
		display: none;
	}
`
