import { currency } from '@/lib/number-utils'
import { Currency } from '@/types/common'

export function getPriceLabel({
	currency: priceCurrency,
	price,
	keepZeroes,
}: {
	currency: Currency
	price: number | null
	keepZeroes?: boolean
}) {
	return currency(price ?? 0, {
		removeZeroes: !keepZeroes,
		currency: priceCurrency,
		precision: 2,
	})
}
