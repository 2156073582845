import { z } from 'zod'

import {
	VirtualPaymentAutomationCreationPayload,
	VirtualPaymentAutomationCreationPayloadZ,
	VirtualPaymentAutomationId,
} from '@/types/virtualPaymentAutomation'

import { getGGClient } from './GetGoingClient'

const CreditCardPoolRequestPayloadZ = VirtualPaymentAutomationCreationPayloadZ.extend({
	invoice_profile_id: z.number(),
})

export function createVirtualPaymentAutomation({ data }: { data: z.infer<typeof CreditCardPoolRequestPayloadZ> }) {
	return getGGClient().request('POST', `virtual_payment_automation`, {
		requestSchema: CreditCardPoolRequestPayloadZ,
		data,
	})
}

export function updateVirtualPaymentAutomation({
	data,
	poolId,
}: {
	data: VirtualPaymentAutomationCreationPayload
	poolId: VirtualPaymentAutomationId
}) {
	return getGGClient().request('PUT', `virtual_payment_automation/${poolId}`, {
		requestSchema: VirtualPaymentAutomationCreationPayloadZ,
		data,
	})
}

export function deleteVirtualPaymentAutomation(poolId: VirtualPaymentAutomationId) {
	return getGGClient().request('DELETE', `virtual_payment_automation/${poolId}`)
}

export function activateVirtualPaymentAutomation({
	poolId,
	data,
}: {
	data: VirtualPaymentAutomationCreationPayload
	poolId: VirtualPaymentAutomationId
}) {
	return getGGClient().request('POST', `virtual_payment_automation/${poolId}/activate`, {
		requestSchema: VirtualPaymentAutomationCreationPayloadZ,
		data,
	})
}

export function deactivateVirtualPaymentAutomation(poolId: VirtualPaymentAutomationId) {
	return getGGClient().request('POST', `virtual_payment_automation/${poolId}/deactivate`)
}
