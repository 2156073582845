import i18next from 'i18next'
import moment from 'moment'

import { appConfig } from '@/app-config/appConfig'
import { User } from '@/travelsuit'
import { CountriesCode, countryByLanguage, Locales } from '@/types/locale'

import { storages } from '../browserStorage'

export const tryDefineLanguage = (input: string): Locales | undefined => {
	const locales = Object.values(Locales)

	return locales.find((locale) => locale.indexOf(input) !== -1)
}

export function getCurrentLocaleForUser(user: User | null) {
	const tempLocale: Locales = storages.local.getItem('defaultLocale')! as Locales

	const browserLanguage = navigator.language

	const priorities = [
		user ? user.language_code : tempLocale,
		user?.language_code ?? tempLocale,
		tryDefineLanguage(browserLanguage),
		getDefaultLocale(),
		Locales.enUS,
	] as Locales[]

	return priorities.find(Boolean)!
}

export function getCurrentLocale(): Locales {
	const user: User | null = JSON.parse(storages.local.getItem('internal_user') ?? 'null')

	return getCurrentLocaleForUser(user)
}

/**
 * Returns the default locale that was sent in the environment variables
 */
export function getDefaultLocale(): string {
	return appConfig.DEFAULT_LANGUAGE
}

export function getRegionFromLocale(locale: Locales): CountriesCode {
	return countryByLanguage[locale]
}

export async function changeLanguage(language: Locales) {
	await i18next.changeLanguage(language)
	moment.locale(i18next.languages)
	document.documentElement.lang = i18next.languages[0]
}
