import { configureScope } from '@sentry/react'

import { User } from '@/travelsuit'
import { fullName } from '@/travelsuit/users.utils'

export function setUserForErrorTracking(user: User) {
	configureScope((scope) => {
		scope.setUser({
			id: user.id!.toString(),
			email: user.email!,
			username: fullName(user),
		})
	})
}
