import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { redirectToLogin } from '@/lib/route-utils'
import { AuthActions } from '@/redux/actions'
import { track } from '@/travelsuit/analytics'

function DoLogout() {
	const dispatch = useDispatch()

	React.useEffect(() => {
		dispatch(AuthActions.finishLogout())
		redirectToLogin({ useReturnTo: false })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { t } = useTranslation()

	return <span>{t('do-logout.loading', 'Loading')}</span>
}

export default track({}, { dispatchOnMount: true })(DoLogout)
