import { hoursToMilliseconds } from 'date-fns'
import { Workbox } from 'workbox-window'

import { getEnvVariables } from '@/getEnvVariables'
import { captureAndLogError } from '@/lib/error'
import history from '@/lib/history'

async function checkForUpdates(registration: ServiceWorkerRegistration) {
	try {
		await registration.update()

		if (registration.waiting) {
			registration.waiting.postMessage({ type: 'SKIP_WAITING' })
		}
	} catch (error) {
		console.error('Error checking for ServiceWorker updates:', error)
	}
}

export function registerServiceWorker() {
	if (getEnvVariables().VITE_REACT_APP_SKIP_SW_REGISTRATION === 'true') {
		navigator.serviceWorker
			?.getRegistrations()
			.then((registrations) => Promise.all(registrations.map((r) => r.unregister())))
			.catch(captureAndLogError)
		return
	}

	const wb = new Workbox(getEnvVariables().BASE_URL + 'sw.js')

	wb.addEventListener('controlling', () => {
		history.listen(() => {
			window.location.reload()
		})
	})

	wb.register().then((registration) => {
		if (!registration) {
			return
		}

		checkForUpdates(registration).then(() => {
			setInterval(() => {
				checkForUpdates(registration)
			}, hoursToMilliseconds(1))
		})
	})
}
