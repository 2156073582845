import 'core-js/stable'
import 'rc-progress/assets/index.css'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { render } from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Userpilot } from 'userpilot'

import { createApplicationStore } from '@/redux/stores'

import { appConfig } from './app-config/appConfig'
import { ErrorBoundary, ErrorTrackingBoundary, initializeErrorTracking } from './error-tracking'
import { initializeI18n } from './i18nConfig'
import { handleUserReloadRequest, initializeAuthProvider, tryHashQueryLogin } from './lib/auth'
import { isMobile } from './lib/browser'
import { ENV } from './lib/env'
import { IOSEventType, isLaunchedAsIOSApp, subscribeForIOSEvent } from './lib/ios'
import { redirectTo, Routes } from './lib/route-utils'
import App from './pages/App/App'
import { GeneralErrorPage } from './pages/GeneralErrorPage'
import { registerServiceWorker } from './pwa/registerServiceWorker'
import { createDefaultState } from './redux/stores/createDefaultState'
import { StylesProvider } from './StylesProvider'
import { UserTrackingClient } from './tracking/types'
import { UserTrackingContext } from './tracking/UserTrackingContext'

function wrapWithErrorBoundary(node: React.ReactNode): React.ReactNode {
	if (!appConfig.SENTRY_ENABLED) {
		return <ErrorBoundary fallback={<GeneralErrorPage errorId="" />}>{node}</ErrorBoundary>
	}

	return (
		<ErrorTrackingBoundary fallback={({ eventId }) => <GeneralErrorPage errorId={eventId} />}>
			{node}
		</ErrorTrackingBoundary>
	)
}

document.addEventListener('DOMContentLoaded', async () => {
	if (appConfig.SENTRY_ENABLED) {
		initializeErrorTracking()
	}

	initializeI18n()
	initializeAuthProvider()

	if ('serviceWorker' in navigator) {
		registerServiceWorker()
	}

	if (isLaunchedAsIOSApp()) {
		subscribeForIOSEvent(IOSEventType.RedirectToTrip, ({ detail }) => {
			redirectTo(Routes.Itinerary, { id: Number(detail) })
		})
	}

	const googleMapsScriptElement = document.createElement('script')
	googleMapsScriptElement.id = 'google-maps'
	googleMapsScriptElement.async = true
	googleMapsScriptElement.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${appConfig.PLACES_API_KEY}&language=en`
	document.body.appendChild(googleMapsScriptElement)

	const trackingClient: UserTrackingClient | null = appConfig.USERPILOT_KEY
		? { reload: Userpilot.reload, identify: (userId, meta) => Userpilot.identify(String(userId), meta) }
		: null

	if (appConfig.USERPILOT_KEY) {
		Userpilot.initialize(appConfig.USERPILOT_KEY)
	}

	const store = createApplicationStore(createDefaultState(), ENV.IS_PROD ? undefined : composeWithDevTools)

	await tryHashQueryLogin()

	await handleUserReloadRequest()

	const queryClient = new QueryClient()

	render(
		<StylesProvider>
			{wrapWithErrorBoundary(
				<UserTrackingContext.Provider value={trackingClient}>
					<ReduxProvider store={store}>
						<QueryClientProvider client={queryClient}>
							<App />
							<ReactQueryDevtools initialIsOpen={false} />
						</QueryClientProvider>
					</ReduxProvider>
				</UserTrackingContext.Provider>,
			)}
		</StylesProvider>,
		document.getElementById('root'),
	)

	const _w: any = window
	if (isMobile() && _w.Intercom) {
		_w.Intercom('update', {
			hide_default_launcher: true,
		})
	}
})
