import { Severity } from '@sentry/react'
import axios from 'axios'
import { isFunction } from 'lodash'
import { Dispatch, MiddlewareAPI } from 'redux'
import { z } from 'zod'

import { appConfig } from '@/app-config/appConfig'
import { ggClient } from '@/clients/ggClient'
import { captureAndLogError } from '@/lib/error'
import { paramsSerializer } from '@/lib/utils'
import {
	AuthActions,
	DailyAllowancesPolicyTypes,
	ExpensePolicyTypes,
	IAction,
	MileageRatesTypes,
	PollingStatus,
	SearchesActions,
	TaxAndVatRateTypes,
} from '@/redux/actions'
import {
	AdminLocationsTypes,
	AdminUsersTypes,
	AuthTypes,
	CarRatesTypes,
	CompanyCarProgramsTypes,
	CompanyFlightProgramsTypes,
	CompanyHotelProgramsTypes,
	CompanyInvoiceProfilesTypes,
	CountriesTypes,
	CountryLanguagesTypes,
	CountryPOSList,
	CustomerSuccessConsultantTypes,
	DepartmentsTypes,
	EssentialTravelInformationTypes,
	ExpenseReportsTypes,
	ExpensesNewTypes,
	ExpensesTypes,
	FareRuleTypes,
	HomePageTypes,
	HotelsInfoTypes,
	HotelsRatesTypes,
	IndustriesTypes,
	ItineraryTypes,
	LiveUserLocationsTypes,
	MyCompanyTypes,
	MyUserRolesTypes,
	PackagesAndBillingSettingsTypes,
	PackagesTypes,
	PackagesUsagesTypes,
	PermissionsTypes,
	RecentSearchesTypes,
	ReportSettingsTypes,
	ReportsTypes,
	RolesTypes,
	SearchesTypes,
	SearchResultsTypes,
	SeatmapTypes,
	SupportTypes,
	TrainTicketDiscountTypes,
	TravelersTypes,
	TravelPolicyTypes,
	TripApprovalsTypes,
	TripCancellationStatus,
	TripGapsTypes,
	TripPaymentMethodsTypes,
	TripPurposeTypes,
	TripRequestsTypes,
	TripSupportRequestsTypes,
	TripTypes,
	UserPOS,
	UserTrackingTypes,
} from '@/redux/actions/actions.types'
import { HTTPMethod, Location } from '@/travelsuit'
import { CompanyZ } from '@/types/company'
import { TaxAndVatRateApiZ } from '@/types/expenses'

type Endpoint = {
	url: string | ((...args: any[]) => string)
	method?: HTTPMethod
	removePayload?: boolean
	responseSchema?: z.ZodTypeAny
}
type ClientFn = (action: IAction) => Promise<unknown>

const baseUrl = appConfig.API_DOMAIN

/** A simple helper to inherit the action creator types */
function fromAction<C extends (...args: any[]) => IAction, A extends ReturnType<C>>(
	_actionCreator: C,
): (mapActionToClient: (action: A) => Promise<unknown>) => (action: A) => Promise<unknown> {
	return (mapActionToClient) => mapActionToClient
}

const createEndpointsMap = (): Record<string, Endpoint | ClientFn> => ({
	/* Auth */
	[AuthTypes.InternalLogin.REQUEST]: { url: `${baseUrl}/login` },
	[AuthTypes.SessionInfo.REQUEST]: { url: `${baseUrl}/sessions` },
	[AuthTypes.UpdateSession.REQUEST]: { url: `${baseUrl}/sessions`, method: HTTPMethod.POST },

	[AuthTypes.SetIsMockEnabled.REQUEST]: fromAction(AuthActions.setIsMockEnabled)(({ payload }) =>
		ggClient.user.setIsMockEnabled(payload!.userId, payload!.value),
	),
	[AuthTypes.SetIsTokenizerMockEnabled.REQUEST]: fromAction(AuthActions.setIsTokenizerMockEnabled)(({ payload }) =>
		ggClient.user.setIsTokenizerMockEnabled(payload!.userId, payload!.value),
	),
	[AuthTypes.SetIsWorldlineMockEnabled.REQUEST]: fromAction(AuthActions.setIsWorldlineMockEnabled)(({ payload }) =>
		ggClient.user.setIsWorldlineMockEnabled(payload!.userId, payload!.value),
	),

	/* HomePage */
	[HomePageTypes.GetHomePage.REQUEST]: { url: `${baseUrl}/homepage` },

	/* Admin Locations */
	[AdminLocationsTypes.RemoveAdminLocation.REQUEST]: {
		url: (location: Location) => `${baseUrl}/offices/${location.id}`,
		method: HTTPMethod.DELETE,
	},
	[AdminLocationsTypes.UpdateAdminLocation.REQUEST]: {
		url: (location: Location) => `${baseUrl}/offices/${location.id}`,
		method: HTTPMethod.PUT,
	},
	[AdminLocationsTypes.AddAdminLocation.REQUEST]: {
		url: `${baseUrl}/offices`,
		method: HTTPMethod.POST,
	},
	[AdminLocationsTypes.GetAdminLocations.REQUEST]: { url: `${baseUrl}/offices` },

	/* Policy Rules */
	[TravelPolicyTypes.GetPolicy.REQUEST]: {
		url: (id) => `${baseUrl}/policies/${id}`,
		removePayload: true,
	},
	[TravelPolicyTypes.GetAllPolicies.REQUEST]: { url: `${baseUrl}/policies`, removePayload: true },
	[TravelPolicyTypes.GetPoliciesByType.REQUEST]: {
		url: (groupType) => `${baseUrl}/policies?type=${groupType}`,
		removePayload: true,
	},
	[TravelPolicyTypes.UpdatePolicy.REQUEST]: { url: `${baseUrl}/policies`, method: HTTPMethod.PUT },
	[TravelPolicyTypes.CreateCustomPolicy.REQUEST]: {
		url: `${baseUrl}/policies`,
		method: HTTPMethod.POST,
	},
	[TravelPolicyTypes.CreateOfficePolicy.REQUEST]: {
		url: `${baseUrl}/policies`,
		method: HTTPMethod.POST,
	},
	[TravelPolicyTypes.DeletePolicy.REQUEST]: {
		url: ({ groupIdForPolicy }) => `${baseUrl}/policies/${groupIdForPolicy}`,
		method: HTTPMethod.DELETE,
		removePayload: true,
	},
	[TravelPolicyTypes.GetIgnoredUsers.REQUEST]: { url: `${baseUrl}/policies/ignore_approvals` },
	[TravelPolicyTypes.UpdateIgnoredUsers.REQUEST]: {
		url: `${baseUrl}/policies/ignore_approvals`,
		method: HTTPMethod.PUT,
	},
	[TravelPolicyTypes.GetPolicyForUsers.REQUEST]: {
		url: `${baseUrl}/policies/for_users`,
		method: HTTPMethod.POST,
	},

	/* Users */
	[AdminUsersTypes.GetAllUsers.REQUEST]: { url: `${baseUrl}/admin/users` },
	[AdminUsersTypes.GetDeactivationInfo.REQUEST]: {
		url: (user_id: number) => `${baseUrl}/users/${user_id}/deactivate_info`,
	},
	[AdminUsersTypes.DeactivateUser.REQUEST]: {
		url: (user_id: number) => `${baseUrl}/users/${user_id}/deactivate`,
		method: HTTPMethod.POST,
	},
	[AdminUsersTypes.RemoveUser.REQUEST]: {
		url: (user_id: number) => `${baseUrl}/users/${user_id}`,
		method: HTTPMethod.DELETE,
	},
	[AdminUsersTypes.AddUser.REQUEST]: { url: `${baseUrl}/admin/users`, method: HTTPMethod.POST },
	[AdminUsersTypes.UpdateUser.REQUEST]: {
		url: ({ user }) => `${baseUrl}/users/${user.id}`,
		method: HTTPMethod.PUT,
	},
	[AdminUsersTypes.AddUserRole.REQUEST]: { url: `${baseUrl}/user_roles`, method: HTTPMethod.POST },
	[AdminUsersTypes.RemoveUserRole.REQUEST]: {
		url: ({ userRole }) => `${baseUrl}/user_roles/${userRole.id}`,
		method: HTTPMethod.DELETE,
	},
	[AdminUsersTypes.InviteUser.REQUEST]: {
		url: ({ user }) => `${baseUrl}/users/${user.id}/invite`,
		method: HTTPMethod.POST,
	},

	/* Roles */
	[RolesTypes.GetAllRoles.REQUEST]: { url: `${baseUrl}/roles` },

	/* Trips */
	[TripTypes.CreateTrip.REQUEST]: { url: `${baseUrl}/trips`, method: HTTPMethod.POST },
	[TripTypes.GetTrip.REQUEST]: { url: ({ tripId }) => `${baseUrl}/trips/${tripId}` },
	[TripTypes.GetTripRailRedirectLink.REQUEST]: {
		url: ({ tripId, lang }) => `${baseUrl}/trips/${tripId}/rails/redirect_link?lang=${lang ?? 'en'}`,
	},
	[TripTypes.AddTraveler.REQUEST]: {
		url: ({ tripId, userId }) => `${baseUrl}/trips/${tripId}/travelers/${userId}`,
		method: HTTPMethod.POST,
	},
	[TripTypes.RemoveTraveler.REQUEST]: {
		url: ({ tripId, userId }) => `${baseUrl}/trips/${tripId}/travelers/${userId}`,
		method: HTTPMethod.DELETE,
	},
	[TripTypes.AddNote.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/notes`,
		method: HTTPMethod.POST,
	},
	[TripTypes.EditNote.REQUEST]: {
		url: ({ tripId, noteId }) => `${baseUrl}/trips/${tripId}/notes/${noteId}`,
		method: HTTPMethod.PUT,
	},
	[TripTypes.RemoveNote.REQUEST]: {
		url: ({ tripId, noteId }) => `${baseUrl}/trips/${tripId}/notes/${noteId}`,
		method: HTTPMethod.DELETE,
	},
	[TripTypes.GetAllTrips.REQUEST]: { url: `${baseUrl}/trips` },
	[TripTypes.RemoveTrip.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}`,
		method: HTTPMethod.DELETE,
	},
	[TripTypes.UpdateTrip.REQUEST]: {
		url: ({ trip }) => `${baseUrl}/trips/${trip.id!}`,
		method: HTTPMethod.PUT,
	},
	[TripTypes.GetBookingStatus.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/status`,
		method: HTTPMethod.GET,
	},
	[TripTypes.SubmitTrip.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/submit_trip`,
		method: HTTPMethod.POST,
	},
	[TripTypes.SubmitTripForApproval.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/submit_for_approval`,
		method: HTTPMethod.POST,
	},
	[TripTypes.ApproveTrip.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/approve`,
		method: HTTPMethod.POST,
	},
	[TripTypes.RejectTrip.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/reject`,
		method: HTTPMethod.POST,
	},
	[TripTypes.NotifyTripPlannerPriceChange.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/notify_planner_price_change`,
		method: HTTPMethod.POST,
	},
	[TripTypes.NotifyTripPlannerRevert.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/notify_planner_reverted`,
		method: HTTPMethod.POST,
	},
	[TripTypes.MergeTrip.REQUEST]: { url: `${baseUrl}/trips/merge`, method: HTTPMethod.POST },
	[TripTypes.VerifyBookingPayment.REQUEST]: {
		url: ({ tripId, bookingSegmentToTravelerId }) => `${baseUrl}/trips/${tripId}/verify/${bookingSegmentToTravelerId}`,
		method: HTTPMethod.POST,
	},
	[TripTypes.CancelBookingVerificationForSegment.REQUEST]: {
		url: ({ tripId, bookingSegmentId }) => `${baseUrl}/trips/${tripId}/cancel_verification/${bookingSegmentId}`,
		method: HTTPMethod.POST,
	},
	[TripGapsTypes.GetTripGaps.REQUEST]: {
		url: ({ trip }) => `${baseUrl}/trips/${trip.id}/gaps`,
		method: HTTPMethod.POST,
	},

	/* Trip Requests */
	[TripRequestsTypes.GetTripRequests.REQUEST]: {
		url: `${baseUrl}/pre_trip_requests`,
		method: HTTPMethod.GET,
	},
	[TripRequestsTypes.CreateTripRequest.REQUEST]: {
		url: `${baseUrl}/pre_trip_requests`,
		method: HTTPMethod.POST,
	},
	[TripRequestsTypes.SubmitTripRequest.REQUEST]: {
		url: ({ id }) => `${baseUrl}/pre_trip_requests/${id}`,
		method: HTTPMethod.POST,
	},
	[TripRequestsTypes.ManageTripRequest.REQUEST]: {
		url: ({ id }) => `${baseUrl}/pre_trip_requests/${id}/manage`,
		method: HTTPMethod.POST,
	},
	[TripRequestsTypes.DeleteTripRequest.REQUEST]: {
		url: ({ id }) => `${baseUrl}/pre_trip_requests/${id}`,
		method: HTTPMethod.DELETE,
	},

	/* Itinerary */
	[ItineraryTypes.GetItinerary.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/itinerary`,
	},
	[ItineraryTypes.BookFlight.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/flights`,
		method: HTTPMethod.POST,
	},
	[ItineraryTypes.BookRail.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/rails`,
		method: HTTPMethod.POST,
	},
	[ItineraryTypes.CheckFlightBooking.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/flights/flights_status`,
		method: HTTPMethod.POST,
	},
	[ItineraryTypes.CancelFlightBooking.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/cancel_flights`,
		method: HTTPMethod.POST,
	},
	[ItineraryTypes.CancelTrainBooking.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/cancel_train`,
		method: HTTPMethod.POST,
	},
	[TripCancellationStatus.GetCancellationStatus.REQUEST]: {
		url: ({ tripId, bookingSegmentId }) =>
			`${baseUrl}/trips/${tripId}/booking_segments/${bookingSegmentId}/cancellation_status`,
		method: HTTPMethod.GET,
	},
	[ItineraryTypes.BookHotelRoom.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/hotels`,
		method: HTTPMethod.POST,
	},
	[ItineraryTypes.CheckHotelBooking.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/hotels/hotel_booking_status`,
		method: HTTPMethod.POST,
	},
	[ItineraryTypes.CancelHotelBooking.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/hotels`,
		method: HTTPMethod.DELETE,
	},
	[ItineraryTypes.AddFlightLuggage.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/flights/luggage`,
		method: HTTPMethod.POST,
	},
	[ItineraryTypes.RemoveFlightLuggage.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/flights/luggage`,
		method: HTTPMethod.DELETE,
	},
	[ItineraryTypes.BookCar.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/cars`,
		method: HTTPMethod.POST,
	},
	[ItineraryTypes.CheckCarBooking.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/cars/car_booking_status`,
		method: HTTPMethod.POST,
	},
	[ItineraryTypes.CancelCarBooking.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/cars`,
		method: HTTPMethod.DELETE,
	},

	[ItineraryTypes.ConfirmPriceChange.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/confirm_price_change`,
		method: HTTPMethod.POST,
	},

	[ItineraryTypes.RefusePriceChange.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/cancel_price_change`,
		method: HTTPMethod.POST,
	},

	/* Trip Approvals */
	[TripApprovalsTypes.GetTripApprovals.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/approvals_status`,
	},

	/* Trip Expenses */
	[ExpensesTypes.AddExpense.REQUEST]: {
		url: ({ trip_id }) => `${baseUrl}/trips/${trip_id}/expenses`,
		method: HTTPMethod.POST,
	},
	[ExpensesTypes.UpdateExpense.REQUEST]: {
		url: ({ trip_id, id }) => `${baseUrl}/trips/${trip_id}/expenses/${id}`,
		method: HTTPMethod.PUT,
	},
	[ExpensesTypes.RemoveExpense.REQUEST]: {
		url: ({ trip_id, id }) => `${baseUrl}/trips/${trip_id}/expenses/${id}`,
		method: HTTPMethod.DELETE,
	},
	[ExpensesTypes.DownloadExpenses.REQUEST]: {
		url: ({ id }) => `${baseUrl}/trips/${id}/expense_report.csv`,
		method: HTTPMethod.GET,
	},

	[ExpensesNewTypes.CreateExpense.REQUEST]: {
		url: () => `${baseUrl}/expenses`,
		method: HTTPMethod.POST,
	},
	[ExpensesNewTypes.GetExpense.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expenses/${id}`,
		method: HTTPMethod.GET,
	},
	[ExpensesNewTypes.GetExpenses.REQUEST]: {
		url: () => `${baseUrl}/expenses`,
		method: HTTPMethod.GET,
	},
	[ExpensesNewTypes.DeleteExpense.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expenses/${id}`,
		method: HTTPMethod.DELETE,
	},
	[ExpensesNewTypes.UpdateExpense.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expenses/${id}`,
		method: HTTPMethod.PUT,
	},
	[TaxAndVatRateTypes.GetRates.REQUEST]: {
		url: () => `${baseUrl}/expense_policies/vat_rates`,
		method: HTTPMethod.GET,
		responseSchema: z.array(TaxAndVatRateApiZ),
	},
	[TaxAndVatRateTypes.UpdateRate.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_policies/vat_rates/${id}`,
		method: HTTPMethod.PUT,
	},
	[TaxAndVatRateTypes.CreateRate.REQUEST]: {
		url: () => `${baseUrl}/expense_policies/vat_rates`,
		method: HTTPMethod.POST,
	},
	[TaxAndVatRateTypes.DeleteRate.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_policies/vat_rates/${id}`,
		method: HTTPMethod.DELETE,
	},

	[ExpensePolicyTypes.GetPolicy.REQUEST]: {
		url: () => `${baseUrl}/expense_policies`,
		method: HTTPMethod.GET,
	},
	[ExpensePolicyTypes.UpdatePolicy.REQUEST]: {
		url: () => `${baseUrl}/expense_policies`,
		method: HTTPMethod.PUT,
	},
	[ExpensePolicyTypes.UpdateApproversPolicy.REQUEST]: {
		url: () => `${baseUrl}/expense_policies/approvers`,
		method: HTTPMethod.PATCH,
	},
	[ExpensePolicyTypes.GetCategories.REQUEST]: {
		url: () => `${baseUrl}/expense_policies/categories`,
		method: HTTPMethod.GET,
	},

	[MileageRatesTypes.GetRates.REQUEST]: {
		url: () => `${baseUrl}/expense_policies/mileage_rates/`,
		method: HTTPMethod.GET,
	},
	[MileageRatesTypes.UpdateRate.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_policies/mileage_rates/${id}`,
		method: HTTPMethod.PUT,
	},
	[MileageRatesTypes.CreateRate.REQUEST]: {
		url: () => `${baseUrl}/expense_policies/mileage_rates/`,
		method: HTTPMethod.POST,
	},
	[MileageRatesTypes.DeleteRate.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_policies/mileage_rates/${id}`,
		method: HTTPMethod.DELETE,
	},
	[DailyAllowancesPolicyTypes.GetDestinations.REQUEST]: {
		url: () => `${baseUrl}/expense_policies/daily_allowances/destinations/`,
		method: HTTPMethod.GET,
	},
	[DailyAllowancesPolicyTypes.CreateDestination.REQUEST]: {
		url: () => `${baseUrl}/expense_policies/daily_allowances/destinations/`,
		method: HTTPMethod.POST,
	},
	[DailyAllowancesPolicyTypes.UpdateDestination.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_policies/daily_allowances/destinations/${id}`,
		method: HTTPMethod.PUT,
	},
	[DailyAllowancesPolicyTypes.DeleteDestination.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_policies/daily_allowances/destinations/${id}`,
		method: HTTPMethod.DELETE,
	},
	[DailyAllowancesPolicyTypes.CreateRate.REQUEST]: {
		url: () => `${baseUrl}/expense_policies/daily_allowances/rates/`,
		method: HTTPMethod.POST,
	},
	[DailyAllowancesPolicyTypes.UpdateRate.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_policies/daily_allowances/rates/${id}`,
		method: HTTPMethod.PUT,
	},
	[DailyAllowancesPolicyTypes.DeleteRate.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_policies/daily_allowances/rates/${id}`,
		method: HTTPMethod.DELETE,
	},

	[ExpenseReportsTypes.CreateReport.REQUEST]: {
		url: () => `${baseUrl}/expense_reports`,
		method: HTTPMethod.POST,
	},
	[ExpenseReportsTypes.GetReports.REQUEST]: {
		url: () => `${baseUrl}/expense_reports`,
		method: HTTPMethod.GET,
	},
	[ExpenseReportsTypes.GetReport.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_reports/${id}`,
		method: HTTPMethod.GET,
	},
	[ExpenseReportsTypes.UpdateReport.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_reports/${id}`,
		method: HTTPMethod.PUT,
	},
	[ExpenseReportsTypes.DeleteReport.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_reports/${id}`,
		method: HTTPMethod.DELETE,
	},
	[ExpenseReportsTypes.SubmitReport.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_reports/${id}/request_approve`,
		method: HTTPMethod.PUT,
	},
	[ExpenseReportsTypes.ApproveReport.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_reports/${id}/approve`,
		method: HTTPMethod.PUT,
	},
	[ExpenseReportsTypes.RejectReport.REQUEST]: {
		url: ({ id }) => `${baseUrl}/expense_reports/${id}/reject`,
		method: HTTPMethod.PUT,
	},
	[ExpenseReportsTypes.ExportReports.REQUEST]: {
		url: ({ fileType }) => `${baseUrl}/expense_reports/export/${fileType}`,
		method: HTTPMethod.POST,
	},

	/* Travelers */
	[TravelersTypes.GetUser.REQUEST]: { url: ({ userId }) => `${baseUrl}/users/${userId}` },
	[TravelersTypes.GetUserTitles.REQUEST]: { url: () => `${baseUrl}/user_titles` },
	[TravelersTypes.GetTraveler.REQUEST]: { url: ({ userId }) => `${baseUrl}/travel_profile/${userId}` },
	[TravelersTypes.CreateTraveler.REQUEST]: {
		url: `${baseUrl}/travel_profile`,
		method: HTTPMethod.POST,
	},
	[TravelersTypes.UpdateTraveler.REQUEST]: {
		url: ({ traveler }) => `${baseUrl}/travel_profile/${traveler.user_id}`,
		method: HTTPMethod.PUT,
	},

	/* Passports */
	[TravelersTypes.AddPassport.REQUEST]: {
		url: ({ traveler }) => `${baseUrl}/travel_profile/${traveler.user_id}/passports`,
		method: HTTPMethod.POST,
	},
	[TravelersTypes.UpdatePassport.REQUEST]: {
		url: ({ traveler, passport }) => `${baseUrl}/travel_profile/${traveler.user_id}/passports/${passport.id}`,
		method: HTTPMethod.PUT,
	},
	[TravelersTypes.RemovePassport.REQUEST]: {
		url: ({ traveler, passportId }) => `${baseUrl}/travel_profile/${traveler.user_id}/passports/${passportId}`,
		method: HTTPMethod.DELETE,
	},

	/* National IDs */
	[TravelersTypes.AddNationalId.REQUEST]: {
		url: ({ traveler }) => `${baseUrl}/travel_profile/${traveler.user_id}/national_ids`,
		method: HTTPMethod.POST,
	},
	[TravelersTypes.UpdateNationalId.REQUEST]: {
		url: ({ traveler, nationalId }) => `${baseUrl}/travel_profile/${traveler.user_id}/national_ids/${nationalId.id}`,
		method: HTTPMethod.PUT,
	},
	[TravelersTypes.RemoveNationalId.REQUEST]: {
		url: ({ traveler, nationalIdId }) => `${baseUrl}/travel_profile/${traveler.user_id}/national_ids/${nationalIdId}`,
		method: HTTPMethod.DELETE,
	},

	/* Frequent Flyer */
	[TravelersTypes.AddFrequentFlyer.REQUEST]: {
		url: ({ traveler }) => `${baseUrl}/travel_profile/${traveler.user_id}/frequent_flyer`,
		method: HTTPMethod.POST,
	},
	[TravelersTypes.UpdateFrequentFlyer.REQUEST]: {
		url: ({ traveler, frequentFlyer }) =>
			`${baseUrl}/travel_profile/${traveler.user_id}/frequent_flyer/${frequentFlyer.id}`,
		method: HTTPMethod.PUT,
	},
	[TravelersTypes.RemoveFrequentFlyer.REQUEST]: {
		url: ({ traveler, frequentFlyerId }) =>
			`${baseUrl}/travel_profile/${traveler.user_id}/frequent_flyer/${frequentFlyerId}`,
		method: HTTPMethod.DELETE,
	},

	/* Hotel Loyalty */
	[TravelersTypes.AddHotelLoyalty.REQUEST]: {
		url: ({ traveler }) => `${baseUrl}/travel_profile/${traveler.user_id}/hotel_loyalty`,
		method: HTTPMethod.POST,
	},
	[TravelersTypes.UpdateHotelLoyalty.REQUEST]: {
		url: ({ traveler, hotelLoyaltyId }) =>
			`${baseUrl}/travel_profile/${traveler.user_id}/hotel_loyalty/${hotelLoyaltyId}`,
		method: HTTPMethod.PUT,
	},
	[TravelersTypes.RemoveHotelLoyalty.REQUEST]: {
		url: ({ traveler, hotelLoyaltyId }) =>
			`${baseUrl}/travel_profile/${traveler.user_id}/hotel_loyalty/${hotelLoyaltyId}`,
		method: HTTPMethod.DELETE,
	},

	/* Car Loyalty */
	[TravelersTypes.AddCarLoyalty.REQUEST]: {
		url: ({ traveler }) => `${baseUrl}/travel_profile/${traveler.user_id}/car_loyalty`,
		method: HTTPMethod.POST,
	},
	[TravelersTypes.UpdateCarLoyalty.REQUEST]: {
		url: ({ traveler, carLoyaltyId }) => `${baseUrl}/travel_profile/${traveler.user_id}/car_loyalty/${carLoyaltyId}`,
		method: HTTPMethod.PUT,
	},
	[TravelersTypes.RemoveCarLoyalty.REQUEST]: {
		url: ({ traveler, carLoyaltyId }) => `${baseUrl}/travel_profile/${traveler.user_id}/car_loyalty/${carLoyaltyId}`,
		method: HTTPMethod.DELETE,
	},

	/* Rail Cards */
	[TravelersTypes.AddRailCard.REQUEST]: {
		url: ({ traveler }) => `${baseUrl}/travel_profile/${traveler.user_id}/rail_card`,
		method: HTTPMethod.POST,
	},
	[TravelersTypes.RemoveRailCard.REQUEST]: {
		url: ({ traveler, railCard }) => `${baseUrl}/travel_profile/${traveler.user_id}/rail_card/${railCard.id}`,
		method: HTTPMethod.DELETE,
	},
	[TravelersTypes.UpdateRailCard.REQUEST]: {
		url: ({ traveler, railCard }) => `${baseUrl}/travel_profile/${traveler.user_id}/rail_card/${railCard.id}`,
		method: HTTPMethod.PUT,
	},

	/* Permissions */
	[PermissionsTypes.GetPermissions.REQUEST]: { url: `${baseUrl}/user_roles/effective` },
	[PermissionsTypes.AddPermission.REQUEST]: {
		url: `${baseUrl}/user_permissions`,
		method: HTTPMethod.POST,
	},
	[PermissionsTypes.RemovePermission.REQUEST]: {
		url: ({ permission }) => `${baseUrl}/user_permissions/${permission.id}`,
		method: HTTPMethod.DELETE,
		removePayload: true,
	},

	/* User Roles */
	[MyUserRolesTypes.GetMyUserRoles.REQUEST]: { url: `${baseUrl}/user_roles/my` },

	/* My Company */
	[MyCompanyTypes.GetMyCompany.REQUEST]: () => ggClient.myCompany.getMyCompany(),
	[MyCompanyTypes.UpdateMyCompanyLogo.REQUEST]: {
		url: `${baseUrl}/companies`,
		method: HTTPMethod.PUT,
		responseSchema: CompanyZ,
	},
	[MyCompanyTypes.UpdateMyCompanyDetails.REQUEST]: {
		url: `${baseUrl}/companies`,
		method: HTTPMethod.PUT,
		responseSchema: CompanyZ,
	},
	[MyCompanyTypes.UpdateMyCompanyPrefernces.REQUEST]: {
		url: `${baseUrl}/companies/preferences`,
		method: HTTPMethod.PUT,
	},
	[MyCompanyTypes.SaveBmisNumberInMyCompany.REQUEST]: {
		url: `${baseUrl}/companies/bmis`,
		method: HTTPMethod.POST,
		responseSchema: CompanyZ,
	},
	[MyCompanyTypes.DeleteBmisNumberFromCompany.REQUEST]: {
		url: `${baseUrl}/companies/bmis`,
		method: HTTPMethod.DELETE,
	},
	[MyCompanyTypes.CreateCompany.REQUEST]: {
		url: `${baseUrl}/companies/create`,
		method: HTTPMethod.POST,
	},
	[MyCompanyTypes.AcceptTermsOfServices.REQUEST]: {
		url: `${baseUrl}/companies/accept_terms_of_services`,
		method: HTTPMethod.POST,
		responseSchema: CompanyZ,
	},

	[CompanyInvoiceProfilesTypes.GetCompanyInvoiceProfiles.REQUEST]: {
		url: `${baseUrl}/companies/invoice_profiles`,
	},
	[CompanyInvoiceProfilesTypes.GetCompanyInvoiceProfile.REQUEST]: {
		url: ({ id }) => `${baseUrl}/companies/invoice_profiles/${id}`,
	},
	[CompanyInvoiceProfilesTypes.CreateCompanyInvoiceProfile.REQUEST]: {
		url: `${baseUrl}/companies/invoice_profiles`,
		method: HTTPMethod.POST,
	},
	[CompanyInvoiceProfilesTypes.UpdateCompanyInvoiceProfile.REQUEST]: {
		url: ({ id }) => `${baseUrl}/companies/invoice_profiles/${id}`,
		method: HTTPMethod.PUT,
	},
	[CompanyInvoiceProfilesTypes.DeleteCompanyInvoiceProfile.REQUEST]: {
		url: ({ id }) => `${baseUrl}/companies/invoice_profiles/${id}`,
		method: HTTPMethod.DELETE,
	},
	[CompanyInvoiceProfilesTypes.ConfirmCompanyInvoiceProfile.REQUEST]: {
		url: ({ id }) => `${baseUrl}/companies/invoice_profiles/${id}/confirm`,
		method: HTTPMethod.PUT,
	},
	[CompanyInvoiceProfilesTypes.AcceptTermsAndConditionsForInvoiceProfile.REQUEST]: {
		url: ({ id }) => `${baseUrl}/companies/invoice_profiles/${id}/activate`,
		method: HTTPMethod.PUT,
	},

	/* Hotels  */
	[HotelsInfoTypes.GetHotelInfo.REQUEST]: { url: ({ hotelId }) => `${baseUrl}/hotels/${hotelId}` },
	[HotelsRatesTypes.GetHotelRates.REQUEST]: {
		url: ({ hotelId }) => `${baseUrl}/hotels/${hotelId}/hotel_rates`,
	},
	[SearchResultsTypes.LikeHotel.REQUEST]: {
		url: ({ id }) => `${baseUrl}/hotels/${id}/like`,
		method: HTTPMethod.POST,
		removePayload: true,
	},
	[SearchResultsTypes.UnlikeHotel.REQUEST]: {
		url: ({ id }) => `${baseUrl}/hotels/${id}/like`,
		method: HTTPMethod.DELETE,
		removePayload: true,
	},

	/* Cars */
	[CarRatesTypes.GetCarRates.REQUEST]: { url: `${baseUrl}/cars/get_rate`, method: HTTPMethod.POST },

	/* Reports */
	[ReportsTypes.GetOverview.REQUEST]: { url: `${baseUrl}/admin/reports/overview` },
	[ReportsTypes.GetMeta.REQUEST]: { url: `${baseUrl}/admin/reports/meta` },
	[ReportsTypes.GetFlightDetails.REQUEST]: { url: `${baseUrl}/admin/reports/flights` },
	[ReportsTypes.GetHotelDetails.REQUEST]: { url: `${baseUrl}/admin/reports/hotels` },
	[ReportsTypes.GetCarDetails.REQUEST]: { url: `${baseUrl}/admin/reports/cars` },
	[ReportsTypes.GetRailDetails.REQUEST]: { url: `${baseUrl}/admin/reports/rails` },
	[ReportsTypes.GetTravelerDetails.REQUEST]: { url: `${baseUrl}/admin/reports/travelers` },

	/* Countries */
	[CountriesTypes.GetCountries.REQUEST]: { url: `${baseUrl}/countries` },

	/* Support */
	[SupportTypes.GetAllCompanies.REQUEST]: {
		url: `${baseUrl}/system/companies`,
		method: HTTPMethod.GET,
	},
	[SupportTypes.ImpersonateSupportCompany.REQUEST]: {
		url: `${baseUrl}/system/companies/support`,
		method: HTTPMethod.POST,
	},
	[SupportTypes.ApproveTripOnBehalf.REQUEST]: {
		url: ({ tripId, userId }) => `${baseUrl}/system/trips/${tripId}/approve/${userId}`,
		method: HTTPMethod.POST,
	},

	/* Departments */
	[DepartmentsTypes.GetAllDepartments.REQUEST]: { url: () => `${baseUrl}/admin/groups` },
	[DepartmentsTypes.GetDepartment.REQUEST]: { url: ({ id }) => `${baseUrl}/admin/groups/${id}` },
	[DepartmentsTypes.CreateDepartment.REQUEST]: {
		url: () => `${baseUrl}/admin/groups`,
		method: HTTPMethod.POST,
	},
	[DepartmentsTypes.UpdateDepartment.REQUEST]: {
		url: ({ id }) => `${baseUrl}/admin/groups/${id}`,
		method: HTTPMethod.PUT,
	},
	[DepartmentsTypes.DeleteDepartment.REQUEST]: {
		url: ({ id }) => `${baseUrl}/admin/groups/${id}`,
		method: HTTPMethod.DELETE,
	},

	/* Searches */
	[SearchesTypes.GetSearch.REQUEST]: fromAction(SearchesActions.getSearch)(({ payload }) =>
		ggClient.search.getSearchParams(payload!.searchId),
	),
	[SearchesTypes.CreateSearch.REQUEST]: fromAction(SearchesActions.startSearch)(({ payload }) =>
		ggClient.search.startSearch(payload!),
	),
	[SearchesTypes.UpdateSearch.REQUEST]: {
		url: ({ searchId }) => `${baseUrl}/search/${searchId}`,
		method: HTTPMethod.PUT,
	},

	/* Recent Searches */
	[RecentSearchesTypes.GetRecentSearches.REQUEST]: {
		url: `${baseUrl}/search/recent_searches`,
		method: HTTPMethod.GET,
	},

	/* Flights */
	[SeatmapTypes.GetForFlight.REQUEST_JOB]: { url: `${baseUrl}/flights/seat_map`, method: HTTPMethod.POST },
	[SeatmapTypes.GetForFlight.REQUEST_JOB_RESULT]: {
		url: ({ jobId }) => `${baseUrl}/flights/seat_map?job_id=${jobId}`,
		method: HTTPMethod.GET,
	},
	[SeatmapTypes.DeleteSeats.REQUEST]: {
		url: `${baseUrl}/flights/seat`,
		method: HTTPMethod.DELETE,
	},
	[SeatmapTypes.ManageItinerarySeat.REQUEST]: {
		url: `${baseUrl}/flights/seat`,
		method: HTTPMethod.POST,
	},

	/* Fare Rules */
	[FareRuleTypes.GetFareRules.REQUEST_JOB]: {
		url: `${baseUrl}/flights/fare_rules`,
		method: HTTPMethod.POST,
	},
	[FareRuleTypes.GetFareRules.REQUEST_JOB_RESULT]: {
		url: ({ jobId }) => `${baseUrl}/flights/fare_rules?job_id=${jobId}`,
		method: HTTPMethod.GET,
	},

	/* Trip Payment Methods */
	[TripPaymentMethodsTypes.GetPaymentMethods.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/payment_methods`,
	},
	[TripPaymentMethodsTypes.UpdatePaymentMethod.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/payment_methods`,
		method: HTTPMethod.PUT,
	},

	/* Trip Support Requests */
	[TripSupportRequestsTypes.GetSupportRequests.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/support_requests`,
	},
	[TripSupportRequestsTypes.CreateSupportRequest.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/support_requests`,
		method: HTTPMethod.POST,
	},

	[TripPurposeTypes.GetTripPurposes.REQUEST]: {
		url: `${baseUrl}/trips/purpose_list`,
		method: HTTPMethod.GET,
	},

	/* Packages */
	[PackagesTypes.GetPackages.REQUEST]: () => ggClient.billing.getBillingPackages(),

	/* Usage Details */
	[PackagesUsagesTypes.GetUsageDetails.REQUEST]: {
		url: () => `${baseUrl}/companies/package_usage_details`,
	},

	/* Live User Locations */
	[LiveUserLocationsTypes.GetLiveUserLocations.REQUEST]: { url: `${baseUrl}/travel_map` },

	/* User Tracking */
	[UserTrackingTypes.UserTracking.REQUEST]: {
		url: ({ user_tracking_id }) => `${baseUrl}/user_tracking/${user_tracking_id}`,
		method: HTTPMethod.PUT,
	},

	/* Trains */
	[TrainTicketDiscountTypes.getTicketDiscounts.REQUEST]: {
		url: `${baseUrl}/rail/rail_cards`,
	},

	/* User POS */
	[UserPOS.GetUserPOS.REQUEST]: {
		url: `${baseUrl}/countries/pos`,
		method: HTTPMethod.GET,
	},

	[CountryPOSList.GetCountryPOSList.REQUEST]: {
		url: `${baseUrl}/countries/poses`,
		method: HTTPMethod.GET,
	},

	/* Country Languages */
	[CountryLanguagesTypes.GetCountryLanguages.REQUEST]: {
		url: `${baseUrl}/countries/languages`,
		method: HTTPMethod.GET,
	},

	[IndustriesTypes.GetIndustries.REQUEST]: {
		url: `${baseUrl}/industries`,
		method: HTTPMethod.GET,
	},

	[PackagesAndBillingSettingsTypes.GetPackagesAndBillingSettings.REQUEST]: {
		url: `${baseUrl}/companies/payment_info`,
		method: HTTPMethod.GET,
	},

	[PackagesAndBillingSettingsTypes.UpdatePaymentInfo.REQUEST]: {
		url: ({ id }) => `${baseUrl}/companies/payment_info/${id}`,
		method: HTTPMethod.PUT,
	},

	[PackagesAndBillingSettingsTypes.CreatePackagesAndBillingSettings.REQUEST]: {
		url: `${baseUrl}/companies/payment_info`,
		method: HTTPMethod.POST,
	},
	[PackagesAndBillingSettingsTypes.UpdatePackagesAndBillingSettings.REQUEST]: {
		url: `${baseUrl}/companies/payment_info`,
		method: HTTPMethod.PUT,
	},

	[EssentialTravelInformationTypes.GetEssentialTravelInformation.REQUEST]: {
		url: ({ tripId }) => `${baseUrl}/trips/${tripId}/essential_travel_information`,
		method: HTTPMethod.GET,
	},

	[ReportSettingsTypes.GetReportSettings.REQUEST]: {
		url: `${baseUrl}/admin/report_settings`,
		method: HTTPMethod.GET,
	},

	[ReportSettingsTypes.UpdateReportSettings.REQUEST]: {
		url: `${baseUrl}/admin/report_settings`,
		method: HTTPMethod.PATCH,
	},

	[CustomerSuccessConsultantTypes.GetCustomerSuccessConsultant.REQUEST]: {
		url: `${baseUrl}/companies/customer_success_consultant`,
		method: HTTPMethod.GET,
	},

	[CompanyFlightProgramsTypes.GetCompanyFlightPrograms.REQUEST]: {
		method: HTTPMethod.GET,
		url: `${baseUrl}/flight_rewards/company_airline_reward`,
	},

	[CompanyFlightProgramsTypes.CreateCompanyFlightProgram.REQUEST]: {
		method: HTTPMethod.POST,
		url: `${baseUrl}/flight_rewards/company_airline_reward`,
	},
	[CompanyFlightProgramsTypes.UpdateCompanyFlightProgram.REQUEST]: {
		method: HTTPMethod.PUT,
		url: `${baseUrl}/flight_rewards/company_airline_reward`,
	},
	[CompanyFlightProgramsTypes.DeleteCompanyFlightProgram.REQUEST]: {
		method: HTTPMethod.DELETE,
		url: ({ id }) => `${baseUrl}/flight_rewards/company_airline_reward/${id}`,
	},

	[CompanyHotelProgramsTypes.GetCompanyHotelPrograms.REQUEST]: {
		method: HTTPMethod.GET,
		url: `${baseUrl}/hotel_rewards/company_hotel_reward`,
	},
	[CompanyHotelProgramsTypes.CreateCompanyHotelProgram.REQUEST]: {
		method: HTTPMethod.POST,
		url: `${baseUrl}/hotel_rewards/company_hotel_reward`,
	},
	[CompanyHotelProgramsTypes.UpdateCompanyHotelProgram.REQUEST]: {
		method: HTTPMethod.PUT,
		url: ({ id }) => `${baseUrl}/hotel_rewards/company_hotel_reward/${id}`,
	},
	[CompanyHotelProgramsTypes.DeleteCompanyHotelProgram.REQUEST]: {
		method: HTTPMethod.DELETE,
		url: ({ id }) => `${baseUrl}/hotel_rewards/company_hotel_reward/${id}`,
	},

	[CompanyCarProgramsTypes.GetCompanyCarPrograms.REQUEST]: {
		method: HTTPMethod.GET,
		url: `${baseUrl}/car_rewards/company_car_reward`,
	},
	[CompanyCarProgramsTypes.CreateCompanyCarProgram.REQUEST]: {
		method: HTTPMethod.POST,
		url: `${baseUrl}/car_rewards/company_car_reward`,
	},
	[CompanyCarProgramsTypes.UpdateCompanyCarProgram.REQUEST]: {
		method: HTTPMethod.PUT,
		url: ({ id }) => `${baseUrl}/car_rewards/company_car_reward/${id}`,
	},
	[CompanyCarProgramsTypes.DeleteCompanyCarProgram.REQUEST]: {
		method: HTTPMethod.DELETE,
		url: ({ id }) => `${baseUrl}/car_rewards/company_car_reward/${id}`,
	},
})

export default function requestMiddleware(store: MiddlewareAPI) {
	return (next: Dispatch) => (action: IAction) => {
		if (!action.type.includes('_REQUEST')) {
			next(action)
			if (action.callback) {
				action.callback(action.payload)
			}
			return
		}

		const endpoint = createEndpointsMap()[action.type]

		if (!endpoint) {
			throw new Error(`No middleware provided for ${action.type}`)
		}

		if (isFunction(endpoint)) {
			const actionType = action.type.replace('_REQUEST', '')

			endpoint(action)
				.then((result) => {
					store.dispatch({
						type: `${actionType}_SUCCESS`,
						payload: result,
						requestPayload: action.payload,
						requestParams: action.params,
					})
					action.callback?.(result)
				})
				.catch((error) => {
					store.dispatch({
						type: `${actionType}_FAILED`,
						payload: action.payload,
						requestPayload: action.payload,
						requestParams: action.params,
					})
					action.errCallback?.(error)
				})

			return next(action)
		}

		if (action.type.includes('_REQUEST_JOB_RESULT')) {
			const actionType = action.type.replace('_REQUEST_JOB_RESULT', '')
			const { method = HTTPMethod.GET } = endpoint
			let { url } = endpoint
			if (typeof url === 'function') {
				url = url(action.polling)
			}
			axios
				.request({
					url,
					method,
					data: action.payload,
					params: action.params,
					paramsSerializer,
					responseType: action.responseType,
				})
				.then((result) => {
					const status = result.data.status
					if ([PollingStatus.Expired, PollingStatus.Failed].includes(result.data.status)) {
						store.dispatch({
							type: `${actionType}_FAILED`,
							payload: result?.data,
							requestPayload: action.payload,
							requestParams: action.params,
						} as IAction)
						if (action.errCallback) {
							const isAxiosError = axios.isAxiosError(result)
							const toJSON = result.data.toJSON
							action.errCallback({
								...result,
								response: result,
								name: status,
								message: 'Polling Error',
								isAxiosError,
								toJSON,
							})
						}
					} else if (status === PollingStatus.Completed || status === PollingStatus.Success) {
						store.dispatch({
							type: `${actionType}_SUCCESS`,
							payload: result.data.result,
							requestPayload: action.requestPayload,
							requestParams: action.params,
						})
						if (action.callback) {
							action.callback(result ? result.data : action.payload)
						}
					} else {
						setTimeout(() => store.dispatch({ ...action }), 500)
					}
				})
			return next(action)
		}

		if (action.type.includes('_REQUEST_JOB')) {
			const actionType = action.type.replace('_REQUEST_JOB', '')
			const { method = HTTPMethod.GET } = endpoint
			let { url } = endpoint
			if (typeof url === 'function') {
				url = url(action.payload)
			}

			axios
				.request({
					url,
					method,
					data: action.payload,
					params: action.params,
					paramsSerializer,
					responseType: action.responseType,
				})
				.then((result) => {
					const status = result.data.status
					if ([PollingStatus.Expired, PollingStatus.Failed].includes(status)) {
						store.dispatch({
							type: `${actionType}_FAILED`,
							payload: result?.data,
							requestPayload: action.payload,
							requestParams: action.params,
						} as IAction)
						if (action.errCallback) {
							const isAxiosError = axios.isAxiosError(result)
							const toJSON = result.data.toJSON
							action.errCallback({
								...result,
								response: result,
								name: status,
								message: 'Polling Error',
								isAxiosError,
								toJSON,
							})
						}
					} else {
						const jobId = result.data.job_id

						store.dispatch({
							type: `${actionType}_REQUEST_JOB_RESULT`,
							requestPayload: action.payload,
							polling: { jobId },
							callback: action.callback,
							errCallback: action.errCallback,
						})
					}
				})
			return next(action)
		}

		let { url } = endpoint
		const { method = HTTPMethod.GET, removePayload = false, responseSchema } = endpoint

		if (typeof url === 'function') {
			url = url(action.payload)
		}

		const actionType = action.type.replace('_REQUEST', '')
		if (url && method) {
			const data = !removePayload ? action.body ?? action.payload : undefined
			axios
				.request({
					url,
					method,
					data,
					params: action.params,
					paramsSerializer,
					responseType: action.responseType,
				})
				.then(
					(result) => {
						responseSchema?.parseAsync(result?.data).catch((e) =>
							captureAndLogError(e, {
								level: Severity.Warning,
								extra: {
									action_type: action.type,
									method,
									url,
									status_code: result.status,
								},
							}),
						)
						const stepsToSwap = [
							() => {
								store.dispatch({
									type: `${actionType}_SUCCESS`,
									payload: result?.data,
									requestPayload: action.payload,
									requestParams: action.params,
								})
							},
							() => {
								if (action.callback) {
									action.callback(result ? result.data : action.payload)
								}
							},
						]
						if (action.swapCallbackAndSuccessAction) {
							stepsToSwap.reverse()
						}
						for (const step of stepsToSwap) {
							step()
						}
					},
					(error) => {
						store.dispatch({
							type: `${actionType}_FAILED`,
							payload: action.payload,
							requestPayload: action.payload,
							requestParams: action.params,
						} as IAction)
						if (action.errCallback) {
							action.errCallback(error)
						}
					},
				)
		}

		return next(action)
	}
}
