import { z } from 'zod'

import { CurrencyObjectZ } from '@/types/common'
import { getJobResultZ } from '@/types/job'
import {
	RailPassengerIdZ,
	RailPassengerPricePointIdZ,
	RailSeatIdZ,
	RailSeatPreferenceGroupIdZ,
	RailSectionIdZ,
	RailTransportTypeZ,
} from '@/types/rails/common'
import { UserIdZ } from '@/types/user'

const MOCK = z.literal('__mock__')

export enum RailCarriageLayoutType {
	Map = 'map',
}

export const RailCarriageLayoutTypeZ = z.nativeEnum(RailCarriageLayoutType)

export enum RailSeatAvailability {
	Available = 'available',
	Unavailable = 'unavailable',
}

export const RailSeatAvailabilityZ = z.nativeEnum(RailSeatAvailability)

export enum RailDeckName {
	Downstairs = 'downstairs',
	Upstairs = 'upstairs',
}

export const RailDeckNameZ = z.nativeEnum(RailDeckName)

export enum RailCarriageLayoutItemTypeName {
	Corridor = 'Corridor',
	EmptyArea = 'Empty area',
	Exit = 'Exit',
	LuggageCompartment = 'Luggage compartment',
	Seat = 'Seat',
	StairsDown = 'Stairs (Down)',
	StairsUp = 'Stairs (Up)',
	Table = 'Table',
	TechnicalRoom = 'Technical room',
	Toilet = 'Toilet',
}

export const RailCarriageLayoutItemTypeNameZ = z.nativeEnum(RailCarriageLayoutItemTypeName)

export const RailPassengerPricePointZ = z.object({
	id: RailPassengerPricePointIdZ,
	passenger_ids: z.array(RailPassengerIdZ),
	price_subject_to_change: z.boolean(),
	price: CurrencyObjectZ,
})

export type RailPassengerPricePoint = z.infer<typeof RailPassengerPricePointZ>

export const RailCarriageLayoutItemTypeZ = z.object({
	/** Example: An individual seat */
	description: z.string(),
	is_overlappable: z.boolean(),
	is_reservable: z.boolean(),
	name: RailCarriageLayoutItemTypeNameZ,
})

export const RailCarriageLayoutItemZ = z.object({
	availability: RailSeatAvailabilityZ,
	available_passenger_price_points: z.array(RailPassengerPricePointIdZ),
	column: z.number(),
	/** Degrees: 0, 90, 180, 270 */
	direction: z.number(),
	id: RailSeatIdZ,
	layout_item_type: RailCarriageLayoutItemTypeZ,
	length: z.number(),
	/**
	 * Human visible ID of this item like a seat number. Will be `null` if it is not selectable (like a technical room)
	 * Example: 18
	 */
	position: z.string().nullable(),
	row: z.number(),
	sub_layout_item_types: z.array(MOCK),
	width: z.number(),
})

export type RailCarriageLayoutItem = z.infer<typeof RailCarriageLayoutItemZ>

export const RailDeckZ = z.object({
	carriage_layout_items: z.array(RailCarriageLayoutItemZ),
	columns: z.number(),
	features: z.array(MOCK),
	name: RailDeckNameZ,
	order: z.number(),
	rows: z.number(),
	total_available_seats: z.number(),
})

export type RailDeck = z.infer<typeof RailDeckZ>

export const RailCarriageLayoutZ = z.object({
	decks: z.array(RailDeckZ),
	layout_type: RailCarriageLayoutTypeZ,
	name: z.string(),
	order: z.number(),
	total_available_seats: z.number(),
})

export type RailCarriageLayout = z.infer<typeof RailCarriageLayoutZ>

export const RailCarriageGroupZ = z.object({
	carriage_layouts: z.array(RailCarriageLayoutZ),
	order: z.number(),
})

export const RailSeatMapZ = z.object({
	carriage_groups: z.array(RailCarriageGroupZ),
	/** Example: TGV32953 */
	model: z.string(),
	passenger_ids: z.array(RailPassengerIdZ),
	passenger_price_points: z.array(RailPassengerPricePointZ),
	seat_preference_id: RailSeatPreferenceGroupIdZ,
	section_id: RailSectionIdZ,
	total_carriages: z.number(),
	transport_type: RailTransportTypeZ,
})

export type RailSeatMap = z.infer<typeof RailSeatMapZ>

export const RailSeatSelectionZ = z.object({
	section_id: RailSectionIdZ,
	seat_preference_group_id: RailSeatPreferenceGroupIdZ,
	seats: z.array(
		z.object({
			seat_id: RailSeatIdZ,
			passenger_price_point_id: RailPassengerPricePointIdZ,
			traveler_id: UserIdZ,
		}),
	),
})

export type RailSeatSelection = z.infer<typeof RailSeatSelectionZ>

export const GetRailSeatMapsJobResultResponseZ = getJobResultZ(
	z.strictObject({
		seat_maps: z.array(RailSeatMapZ),
	}),
)

export const CancelSelectedSeatsRequestZ = z.strictObject({
	seats: z.array(RailSeatSelectionZ),
})

export const UpdateSelectedSeatsRequestZ = z.strictObject({
	seats: z.array(RailSeatSelectionZ),
})
