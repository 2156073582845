import { brightTurquoise } from '@/refactor/colors'
import { defaultFontFamily as defaultFont } from '@/refactor/fonts'

export const spacing = 15
export const spacingDouble = 40
export const headerHeight = 100
export const defaultFontFamily = defaultFont

export const wchatRightOffset = 15

export const minSm = 601

export const transparent = 'rgba(255, 255, 255, 0)'
export const currentColor = 'currentColor'

export const all200msEasyInOut = 'all 200ms ease-in-out'

export const white = 'rgb(255, 255, 255)'

export const black = 'rgb(0, 0, 0)'
export const black10 = 'rgba(0, 0, 0, 0.1)'
export const black30 = 'rgba(0, 0, 0, 0.3)'
export const black50 = 'rgba(0, 0, 0, 0.5)'
export const black70 = 'rgba(0, 0, 0, 0.7)'

export const accentDark = brightTurquoise
export const accentDark60 = 'rgba(2, 187, 170, 0.6)'

export const accentLight = '#90DFD7'
export const accentLight30 = 'rgba(144, 223, 215, 0.3)'
export const accentLight60 = 'rgba(144, 223, 215, 0.6)'

export const accentMid = '#068d9d'

export const accentPrimary = brightTurquoise
export const accentSecondary = 'rgb(65, 221, 149)'
export const accentLightShadow = accentLight
export const accentTertiary = 'rgb(36, 151, 235)'

export const bgBody = '#F0F1F6'

export const bgMaterial = 'white'

export const bgMaterial70 = 'rgba(255, 255, 255, 0.7)'

export const bgMaterialAlt = 'rgb(246, 247, 249)'
export const bgMaterialDark = 'rgb(227, 228, 232)'
export const bgMaterialAlt70 = 'rgb(246, 247, 249, 0.7)'

export const fgPrimary = 'rgb(0, 0, 0)'
export const fgPrimary10 = 'rgba(0, 0, 0, 0.1)'
export const fgPrimary20 = 'rgba(0, 0, 0, 0.2)'
export const fgPrimary30 = 'rgba(0, 0, 0, 0.3)'
export const fgPrimary40 = 'rgba(0, 0, 0, 0.4)'
export const fgPrimary50 = 'rgba(0, 0, 0, 0.5)'
export const fgPrimary60 = 'rgba(0, 0, 0, 0.6)'

export const fgContrast = 'rgb(255, 255, 255)'

export const fgDark = 'rgb(142, 142, 142)'
export const fgMedium = 'rgb(177, 186, 198)'

export const separatorLight = 'rgb(234, 231, 237)'
export const separator = 'rgba(185, 178, 196, 0.3)'
export const separatorMid = 'rgb(167, 167, 167)'

export const warning = 'rgb(255, 193, 7)'
export const warningDark = 'rgb(253, 168, 52)'

export const boxShadowSmall = '0 4px 5px 0 rgba(177, 186, 198, 0.3)'
export const boxShadowMedium = '0 5px 5px 0 rgba(54, 30, 79, 0.1)'
export const boxShadowDark = '0 5px 5px 0 rgba(129, 135, 144, 0.4)'
export const boxShadowPlain = '0 6px 10px 0 rgba(107, 121, 148, 0.1)'
export const boxShadowHover = '0 10px 15px 0 rgba(54, 30, 79, 0.1)'
export const boxShadowRaised = '0 7px 20px 0 rgba(28, 29, 52, 0.3)'

export const creditCardGradient = `linear-gradient(123.41deg, #A0CBE7 0.6%, #5398C5 47.65%, #164969 101.06%)`

export const fgMid = 'rgb(46, 46, 46)'

export const tripsProgressForeground = 'rgb(180, 180, 180)'
export const flightTicketEdgePoints = fgMid
export const flightTicketEdgePointsLight = 'rgb(90, 90, 90)'

export const outOfPolicy = 'rgb(208, 2, 27)'
export const warningColor = 'rgb(247, 170, 23)'
export const approvalProcess = 'rgb(239, 175, 89)'
export const overlayGradient = 'linear-gradient(to left, rgba(73, 80, 102, 0.9), rgba(114, 121, 145, 0.9))'

export const disabled = 'rgba(196, 196, 196, 1)'
export const extraChargeText = 'rgb(96, 93, 93)'
export const darkGray = 'rgb(97, 103, 105)'
export const preferredRatesOrange = 'rgb(255, 175, 2)'
export const preferredRatesOrangeDark = 'rgb(212, 130, 5)'

export const travelerChipPhotoBackground = 'rgba(21, 71, 117, 0.2)'
export const itinerarySectionTotal = '#7C7C7C'
export const mobileFormBG = '#e6e6e6'

export const veryLightGray = 'rgb(250, 250, 250)'
export const midGray = 'rgb(150, 150, 150)'
export const darkMidGray = 'rgb(180, 180, 180)'
export const lightMidGray = 'rgb(128, 128, 128)'

export const boxShadowFloat = '0px 5px 10px rgba(0, 0, 0, 0.18)'
export const boxShadowHeader = '0px 5px 10px rgba(0, 0, 0, 0.15)'
export const boxShadowBig = '0px 10px 20px rgba(0, 0, 0, 0.25)'
