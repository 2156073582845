import { TFunction } from 'i18next'
import React from 'react'

import SelectorButton from '@/atoms/SelectorButton/SelectorButton'
import { Stack } from '@/atoms/System'
import { useTranslation } from '@/lib/i18n/i18n'
import { fontSecondary } from '@/refactor/colors'
import { font } from '@/refactor/fonts'
import styled from '@/styles'
import { CurrencySigns, DefaultProps } from '@/travelsuit'
import { Currency } from '@/types/common'

interface IProps extends DefaultProps {
	currency: Currency
	horizontal?: boolean
	onChange?(currency: Currency): void
}

const Title = styled.div`
	${font({ size: 14, weight: 800 })}
	display: flex;
	gap: 10px;
`

const Label = styled.div`
	color: ${fontSecondary};
	${font({ size: 12 })}
`

const AVAILABLE_CURRENCIES_FOR_SELECT = [
	Currency.USD,
	Currency.EUR,
	// TODO: Add it when you need it or a new country will be added
	// Currency.GBP,
	// Currency.AUD,
	// Currency.SEK,
	// Currency.ILS,
]

const getCurrencyLabel = (t: TFunction) => ({
	[Currency.EUR]: t('currency-selector.currency-labels.EUR', 'Euro'),
	[Currency.USD]: t('currency-selector.currency-labels.USD', 'United States Dollar'),
	[Currency.GBP]: t('currency-selector.currency-labels.GBP', 'Pound Sterling'),
	[Currency.AUD]: t('currency-selector.currency-labels.AUD', 'Australian Dollar'),
	[Currency.SEK]: t('currency-selector.currency-labels.SEK', 'Swedish Krona'),
	[Currency.ILS]: t('currency-selector.currency-labels.ILS', 'Israeli New Shekel'),
})

const CurrencySelector: React.FunctionComponent<IProps> = (props) => {
	const { className, currency, onChange, horizontal } = props
	const { t } = useTranslation()

	const currencyLabel = React.useMemo(() => getCurrencyLabel(t), [t])

	return (
		<Stack className={className} direction={horizontal ? 'row' : 'column'} gap={2} data-test="CurrencyContainer">
			{AVAILABLE_CURRENCIES_FOR_SELECT.map((cur) => (
				<SelectorButton
					key={cur}
					selected={cur === currency}
					onClick={() => onChange?.(cur)}
					width={horizontal ? 180 : '100%'}
					data-test={`Currency-${cur}`}
				>
					<Title>
						<span>{cur}</span>
						<span>{CurrencySigns[cur]}</span>
					</Title>
					<Label>{currencyLabel[cur]}</Label>
				</SelectorButton>
			))}
		</Stack>
	)
}

export default CurrencySelector
