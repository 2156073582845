import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { IAction } from '@/redux/actions/action-helpers'
import { TripTypes } from '@/redux/actions/actions.types'
import { TripFeeStatusData } from '@/travelsuit/trips'

export type TripOrders = ImmutableMap<number, TripFeeStatusData[]>

export function tripOrdersReducer(state: TripOrders = new ImmutableMap(), action: IAction) {
	if (action.type !== TripTypes.GetBookingStatus.SUCCESS) {
		return state
	}

	return state.set(action.requestPayload.tripId, action.payload.trip_fees)
}
