import React from 'react'

import { clearVerificationRedirectData, getVerificationRedirectData } from '@/lib/redirect-utils'
import { redirectTo, Routes } from '@/lib/route-utils'
import { PageProps } from '@/travelsuit'

import { OrderVerificationDataState } from './order-verification-utils'

const STATUS = 'statusCode'

type RouteParams = {
	orderId: string
}

type WorldlineStatus = 'APPROVED' | 'ERROR'

type OrderCallbackVerificationProps = PageProps<RouteParams>

export function OrderCallbackVerification({ match, location, history }: OrderCallbackVerificationProps) {
	const { orderId } = match.params
	const { search } = location

	const redirectBack = () => {
		const redirectData = getVerificationRedirectData<{ trip_id?: number }>()

		const searchParams = new URLSearchParams(search)
		const status = searchParams.get(STATUS) as WorldlineStatus

		let verificationData: Partial<OrderVerificationDataState> = {}

		if (['APPROVED', 'CAPTURED'].includes(status)) {
			verificationData = {
				worldline_validated: true,
				order_id: orderId,
			}
			if (redirectData?.data?.trip_id) {
				verificationData.trip_id = redirectData.data.trip_id
			}
		} else {
			verificationData = { worldline_validated: false }
		}

		if (redirectData?.returnToUrl) {
			clearVerificationRedirectData()
			history.push(redirectData.returnToUrl, verificationData)
		} else {
			redirectTo(Routes.Home)
		}
	}

	React.useEffect(() => {
		redirectBack()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return null
}
