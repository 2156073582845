import clsx from 'clsx'
import React from 'react'
import ReactDOM from 'react-dom'

import CrossIcon from '@/assets/cross.svg'
import SvgIcon from '@/atoms/SvgIcon/SvgIcon'
import { blockScroll } from '@/block-scroll'
import { ZIndexContext } from '@/common/z-index'
import { passTestId } from '@/lib/e2e-utils'

import {
	BackColumn,
	BottomModalButtonsContainer,
	BottomModalContainer,
	BottomModalControls,
	BottomModalSeparator,
	ButtonsContainer,
	CloseButton,
	DesktopHeaderRow,
	DesktopTitle,
	MobileHeaderRow,
	ModalBase,
	ModalBox,
	ModalContent,
	ModalControlRow,
	ModalOverlay,
	ModalRoot,
	TitleColumn,
} from './Modal.components'
import { IPropsModal, ModalVariant, widthVariantToPx } from './Modal.types'
import { DOMTreePosition } from './Modal.types'

export { DOMTreePosition }

export interface IProps extends IPropsModal {
	hideBottomSeparator?: boolean
	containerClassName?: string
	closeOnOverlayClick?: boolean
}

const CompanyTypeAndColors: Record<ModalVariant, React.ComponentProps<typeof CloseButton>['color']> = {
	success: 'menuItem',
	warning: 'yellow',
	alert: 'menuItemError',
	waiting: 'transparent',
}

const ModalContext = React.createContext<HTMLElement | null>(null)

// TASK migrate to React.FunctionComponent OR remove this if not possible
class Modal extends React.Component<IProps> {
	public static defaultProps: Partial<IProps> = {
		open: true,
		contentComponent: ModalBox,
		variant: 'success',
		width: 'sm',
		domTreePosition: DOMTreePosition.root,
		closeOnOverlayClick: true,
	}

	public static contextType = ModalContext

	context!: React.ContextType<typeof Modal.contextType>

	private contentRef = React.createRef<HTMLDivElement>()

	public componentDidMount() {
		if (this.props.open && this.contentRef.current) {
			blockScroll(this.contentRef.current, true)
		}
	}

	public componentDidUpdate(prevProps: IProps) {
		if (this.props.open !== undefined && this.props.open !== prevProps.open && this.contentRef.current) {
			blockScroll(this.contentRef.current, this.props.open)
		}
	}

	public componentWillUnmount() {
		if (this.contentRef.current) {
			blockScroll(this.contentRef.current, false)
		}
	}

	public renderButton(el: React.ReactElement<any>) {
		const cls = clsx(el.props.className, { 'control-button': !el.props.hasOwnProperty('color') })
		return React.cloneElement(el, { className: cls })
	}

	public render() {
		const {
			className,
			children,
			onClose,
			open,
			controlButtons,
			contentComponent,
			variant,
			contentClassName,
			controlRowComponent,
			title,
			mobileTitle,
			width: propWidth = 'sm',
			domTreePosition,
			renderInElement,
			zIndex = 9999999,
			hideBottomSeparator,
			containerClassName,
			closeOnOverlayClick,
			bottomBarRemark,
		} = this.props
		// @ts-expect-error todo if you see this please remove this comment and fix the type error
		const width = typeof propWidth === 'string' ? widthVariantToPx[propWidth] : propWidth
		const boxProps = { width, variant }

		const closeButton = onClose ? (
			<CloseButton
				data-test="Modal.Button.Close"
				onClick={(e) => onClose?.(e)}
				color={variant ? CompanyTypeAndColors[variant] : 'transparent'}
				rounded="50%"
			>
				<SvgIcon src={CrossIcon} />
			</CloseButton>
		) : null

		const output = (
			<ModalRoot className={className} open={open} zIndex={zIndex} data-test={passTestId(this.props)}>
				<ModalOverlay open={Boolean(open)} onClick={(e) => closeOnOverlayClick && onClose?.(e)} />
				<ModalBase data-test="Modal.Block" open={Boolean(open)} maxWidth={width} className={containerClassName}>
					<ModalControlRow as={controlRowComponent} maxWidth={width} variant={variant} open={open!}>
						{title ? <DesktopTitle data-test="Modal.Title">{title}</DesktopTitle> : null}
						<DesktopHeaderRow>{closeButton}</DesktopHeaderRow>
						<MobileHeaderRow>
							<TitleColumn>{mobileTitle || title}</TitleColumn>
							<BackColumn>{closeButton}</BackColumn>
						</MobileHeaderRow>
					</ModalControlRow>
					<ModalContent
						className={contentClassName}
						maxWidth={width}
						onTouchStart={(e) => e.preventDefault()}
						ref={this.contentRef as any}
					>
						<ModalBox className="--scrollAuto" as={contentComponent} {...boxProps}>
							<ZIndexContext.Provider value={zIndex}>{children}</ZIndexContext.Provider>
						</ModalBox>
						{controlButtons?.length ? (
							<BottomModalContainer>
								{hideBottomSeparator ? null : <BottomModalSeparator />}
								<BottomModalControls>
									{bottomBarRemark}
									<BottomModalButtonsContainer>
										<ButtonsContainer>{controlButtons}</ButtonsContainer>
									</BottomModalButtonsContainer>
								</BottomModalControls>
							</BottomModalContainer>
						) : null}
					</ModalContent>
				</ModalBase>
			</ModalRoot>
		)

		// renderInElement overrides domTreePosition, so it should stay above it
		if (renderInElement) {
			return ReactDOM.createPortal(output, renderInElement)
		}

		if (domTreePosition === DOMTreePosition.root && this.context !== null) {
			return ReactDOM.createPortal(output, this.context as HTMLElement)
		}

		return output
	}
}

interface ModalContextProviderState {
	element: HTMLElement | null
}

// TASK migrate to React.FunctionComponent OR remove this if not possible
export class ModalContextProvider extends React.Component<React.PropsWithChildren<{}>, ModalContextProviderState> {
	public state: ModalContextProviderState = {
		element: null,
	}

	public render() {
		const { element } = this.state
		return (
			<ModalContext.Provider value={element}>
				{this.props.children}
				<div
					id="modal-dom-root"
					ref={(ref) => {
						if (ref && ref !== element) {
							this.setState({ element: ref })
						}
					}}
				/>
			</ModalContext.Provider>
		)
	}
}

export default Modal
