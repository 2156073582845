import { Company, CompanyDetails, CompanyPreferenceUpdateData, VoidCallback } from '@/travelsuit'

import { IAction } from './action-helpers'
import { MyCompanyTypes as Types } from './actions.types'

export const Actions = {
	getMyCompany(callback?: VoidCallback<Company>, errCallback?: VoidCallback<never>): IAction {
		return {
			type: Types.GetMyCompany.REQUEST,
			callback,
			errCallback,
		}
	},
	updateMyCompanyLogo(logoUrl: string): IAction {
		return {
			type: Types.UpdateMyCompanyLogo.REQUEST,
			payload: { logo_url: logoUrl },
		}
	},
	updateCompanyDetails(
		details: Partial<CompanyDetails>,
		callback?: VoidCallback<Company>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: Types.UpdateMyCompanyDetails.REQUEST,
			payload: details,
			callback,
			errCallback,
		}
	},
	updatePreferences(
		preferences: Array<
			Partial<Exclude<CompanyPreferenceUpdateData, 'preference_type'>> &
				Pick<CompanyPreferenceUpdateData, 'preference_type'>
		>,
		callback?: VoidCallback<Company>,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: Types.UpdateMyCompanyPrefernces.REQUEST,
			payload: { preferences },
			callback,
			errCallback,
		}
	},
	saveBmisNumberInMyCompany(
		details: Partial<CompanyDetails>,
		callback?: VoidCallback,
		errCallback?: VoidCallback<never>,
	): IAction {
		return {
			type: Types.SaveBmisNumberInMyCompany.REQUEST,
			payload: details,
			callback,
			errCallback,
		}
	},
	deleteBmisNumberFromCompany(callback?: VoidCallback, errCallback?: VoidCallback<never>): IAction {
		return {
			type: Types.DeleteBmisNumberFromCompany.REQUEST,
			callback,
			errCallback,
		}
	},
	createCompany(callback?: VoidCallback, errCallback?: VoidCallback<never>): IAction {
		return {
			type: Types.CreateCompany.REQUEST,
			callback,
			errCallback,
		}
	},
	acceptTermsOfServices(callback?: VoidCallback, errCallback?: VoidCallback<never>): IAction {
		return {
			type: Types.AcceptTermsOfServices.REQUEST,
			callback,
			errCallback,
		}
	},
}
