import { defaults } from 'lodash'
import { OmitIndexSignature } from 'type-fest'

import { bgBody, separator, wchatRightOffset } from './_vars'
import { BLOCK_SCROLL_CLASSNAME } from './block-scroll'
import { animationDefs } from './css-helpers/animated'
import { FontFamily } from './refactor/fonts'
import styled, { createGlobalStyle, css } from './styledComponents'

export default styled
export * from './styledComponents'

export const iconColor = (color: any) => css`
	color: ${color};
`

export const iconFill = (color: any) => css`
	${iconColor(color)}
	& svg {
		&,
		& path {
			fill: ${color};
		}
	}
`

export * from './css-helpers/units'
export * from './css-helpers/animated'
export * from './css-helpers/positioning'
export * from './css-helpers/media-query'
export * from './css-helpers/flex'
export * from './css-helpers/animated'
export * from './css-helpers/mui-theme'
export * from './refactor/fonts'

const resetCss = () => css`
	html,
	body,
	div,
	span,
	applet,
	object,
	iframe,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	pre,
	a,
	abbr,
	acronym,
	address,
	big,
	cite,
	code,
	del,
	dfn,
	em,
	img,
	ins,
	kbd,
	q,
	s,
	samp,
	small,
	strike,
	strong,
	sub,
	sup,
	tt,
	var,
	b,
	u,
	i,
	center,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	fieldset,
	form,
	label,
	legend,
	table,
	caption,
	tbody,
	tfoot,
	thead,
	tr,
	th,
	td,
	article,
	aside,
	canvas,
	details,
	embed,
	figure,
	figcaption,
	footer,
	header,
	hgroup,
	menu,
	nav,
	output,
	ruby,
	section,
	summary,
	time,
	mark,
	audio,
	video,
	input,
	textarea {
		margin: 0;
		padding: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
		-webkit-font-smoothing: antialiased;
	}
	pre {
		font-family: monospace;
	}
	html,
	body,
	div,
	span,
	applet,
	object,
	iframe,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	pre,
	a,
	abbr,
	acronym,
	address,
	big,
	cite,
	code,
	del,
	dfn,
	em,
	img,
	ins,
	kbd,
	q,
	s,
	samp,
	small,
	strike,
	strong,
	sub,
	sup,
	tt,
	var,
	b,
	u,
	i,
	center,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	fieldset,
	form,
	label,
	legend,
	table,
	caption,
	tbody,
	tfoot,
	thead,
	tr,
	th,
	td,
	article,
	aside,
	canvas,
	details,
	embed,
	figure,
	figcaption,
	footer,
	header,
	hgroup,
	menu,
	nav,
	output,
	ruby,
	section,
	summary,
	time,
	mark,
	audio,
	video {
		border: 0;
	}
	/* HTML5 display-role reset for older browsers */
	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	hgroup,
	menu,
	nav,
	section {
		display: block;
	}
	body {
		line-height: 1;
	}
	ol,
	ul {
		list-style: none;
	}
	blockquote,
	q {
		quotes: none;
	}
	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	* {
		box-sizing: border-box;
	}

	i {
		font-style: italic;
	}
	b {
		font-weight: bold;
	}
	p {
		padding: 1ex 0;
	}
`

export const GlobalStyle = createGlobalStyle`
	${resetCss()}
	${animationDefs}

	:root {
		--wchat-right: ${wchatRightOffset}px;
	}

	body {
		margin: 0;
		padding: 0;
		font-family: ${FontFamily.OpenSans};
		font-size: 13px;
		background: ${bgBody};
		min-height: 100vh;
	}

	.${BLOCK_SCROLL_CLASSNAME} {
		-webkit-overflow-scrolling: touch !important;
		overflow: hidden;
	}

	${(p) => p.theme.breakpoints.down('md')} {
		body,
		html {
			min-height: 100vh;
		}
	}

	button,
	input,
	textarea {
		font-family: ${FontFamily.OpenSans};
	}

	button {
		color: currentColor;
	}

	hr {
		color: ${separator};
		background: currentColor;
		height: 1px;
		border: none;
		margin: ${(p) => p.theme.spacing(3)}px 0;
	}

	#fc_frame {
		right: ${wchatRightOffset}px !important;
		z-index: 999999 !important;
		left: unset !important;
	}

	@media screen and (max-width: 600px) {
		#fc_frame {
			display: none !important;
		}

		#fc_frame.fc-open {
			display: block !important;
		}
	}

	*::-webkit-scrollbar {
		height: 8px;
		width: 8px;
		background: white;
		box-shadow: inset 3px 0 3px -3px rgba(0, 0, 0, 0.3);
	}

	*::-webkit-scrollbar-thumb {
		height: 8px;
		width: 8px;
		border-radius: 5px;
		background-color: #c7c7c7;
	}

	canvas {
		&,
		&:active,
		&:focus {
			outline: none;
		}
	}

	${(p) => p.theme.breakpoints.down('md')} {
		*::-webkit-scrollbar {
			height: 4px;
			width: 4px;
			background: transparent;
			box-shadow: none;
		}

		*::-webkit-scrollbar-thumb {
			height: 4px;
			width: 4px;
			border-radius: 5px;
			background-color: #c7c7c7;
		}
	}
`

export function defaultAttrs<T extends Record<string, unknown>>(
	props: T,
	defaultValues: Partial<OmitIndexSignature<NoInfer<T>>>,
): T {
	return defaults(props, defaultValues)
}
