export const BLOCK_SCROLL_CLASSNAME = 'gg-block-scroll'

export function blockScroll(element: HTMLElement, disableScroll: boolean) {
	if (!element) {
		console.warn('No element for `blockScroll`')
		return
	}

	if (disableScroll) {
		element.classList.add(BLOCK_SCROLL_CLASSNAME)
	} else {
		element.classList.remove(BLOCK_SCROLL_CLASSNAME)
	}
}

export function blockBodyScroll(disableScroll: boolean) {
	blockScroll(document.body, disableScroll)
}
