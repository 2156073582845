import { objDiff } from '@/lib/utils'
import { getStoreState } from '@/redux/stores'
import { User, UserCreationPayload, UserRole, VoidCallback } from '@/travelsuit'
import { prepareUserRoleJson } from '@/travelsuit/user-roles'
import { notNulllableFields, UserSearchParams } from '@/travelsuit/users'
import { Currency } from '@/types/common'
import { Locales } from '@/types/locale'

import { IAction } from './action-helpers'
import { AdminUsersTypes as Types } from './actions.types'

export const Actions = {
	getAllUsers(params: UserSearchParams = {}, callback?: VoidCallback<User[]>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.GetAllUsers.REQUEST,
			params,
			callback,
			errCallback,
		}
	},
	createUser(user: Partial<UserCreationPayload>, callback?: VoidCallback<User>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.AddUser.REQUEST,
			payload: user,
			callback,
			errCallback,
		}
	},
	updateUser(user: User, callback?: VoidCallback<User>, errCallback?: VoidCallback): IAction {
		let existingTraveler = getStoreState().adminUsers.get(user.id!)
		if (!existingTraveler && getStoreState().auth.get('internalUser').id === user.id) {
			existingTraveler = getStoreState().auth.get('internalUser')
		}
		const changes = objDiff(existingTraveler, user)
		notNulllableFields.forEach((k) => {
			if (changes[k] === null) {
				delete changes[k]
			}
		})

		const { invoice_profile, ...changesToSend } = changes

		return {
			type: Types.UpdateUser.REQUEST,
			payload: { user },
			body: { ...changesToSend, invoice_profile_id: invoice_profile?.id },
			callback,
			errCallback,
		}
	},
	updateUserLanguage(language: Locales, callback?: VoidCallback<User>, errCallback?: VoidCallback): IAction {
		const user = getStoreState().auth.get('internalUser')
		return {
			type: Types.UpdateUser.REQUEST,
			payload: { user: { ...user, language_code: language } },
			body: { language_code: language },
			callback,
			errCallback,
		}
	},
	updateUserCurrency(currency: Currency, callback?: VoidCallback<User>, errCallback?: VoidCallback): IAction {
		const user = getStoreState().auth.get('internalUser')
		return {
			type: Types.UpdateUser.REQUEST,
			payload: { user: { ...user, currency_code: currency } },
			body: { currency_code: currency },
			callback,
			errCallback,
		}
	},
	getDeactivateInfo(userId: number, callback?: VoidCallback, errCallback?: VoidCallback): IAction {
		return {
			type: Types.GetDeactivationInfo.REQUEST,
			payload: userId,
			callback,
			errCallback,
		}
	},
	deactivateUser(user: User, callback?: VoidCallback, errCallback?: VoidCallback): IAction {
		return {
			type: Types.DeactivateUser.REQUEST,
			payload: user.id,
			callback,
			errCallback,
		}
	},
	removeUser(user: User, callback?: VoidCallback, errCallback?: VoidCallback): IAction {
		return {
			type: Types.RemoveUser.REQUEST,
			payload: user.id,
			callback,
			errCallback,
		}
	},
	addUserRole(user: User, userRole: UserRole, callback?: VoidCallback<UserRole>) {
		return {
			type: Types.AddUserRole.REQUEST,
			payload: { user, userRole },
			body: prepareUserRoleJson(userRole),
			callback,
		}
	},
	removeUserRole(user: User, userRole: UserRole, callback?: VoidCallback) {
		return {
			type: Types.RemoveUserRole.REQUEST,
			payload: { user, userRole },
			callback,
		}
	},
	invite(user: User, callback?: VoidCallback, errCalback?: VoidCallback) {
		return {
			type: Types.InviteUser.REQUEST,
			payload: { user },
			callback,
			errCalback,
		}
	},
}
