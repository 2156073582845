import { Callback } from '@/travelsuit'

import { IAction } from './action-helpers'
import { TripApprovalsTypes as Types } from './actions.types'

export const Actions = {
	getTripApprovals(tripId: number, callback?: Callback, errCallback?: Callback): IAction {
		return {
			type: Types.GetTripApprovals.REQUEST,
			payload: { tripId },
			callback,
			errCallback,
		}
	},
}
