import { addEntity } from '@/lib/entity/addEntity'
import { removeEntityById } from '@/lib/entity/removeEntityById'
import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { AllTripStatuses, Trip, TripStatus, TripStatusVirtual } from '@/travelsuit'
import { SupportRequest } from '@/travelsuit/support-request'
import { calculateTripStatus, emptyTrip } from '@/travelsuit/trips'

import { IAction } from '../actions/action-helpers'
import { ItineraryTypes, SeatmapTypes, TripSupportRequestsTypes, TripTypes } from '../actions/actions.types'
import { TimeFrame } from '../actions/trips.actions'

export type TripsById = ImmutableMap<number, Trip>
export type TripsByStatus = ImmutableMap<AllTripStatuses, Trip[]>

export interface TripsState {
	byId: TripsById
	byStatus: TripsByStatus
}

export const createDefaultState = (): TripsState => ({
	byId: new ImmutableMap(),
	byStatus: new ImmutableMap(),
})

function getTripStatusGroup({ status }: Trip): AllTripStatuses {
	if (
		[
			TripStatus.Draft,
			TripStatus.DraftBySupport,
			TripStatus.Reverted,
			TripStatus.Booking,
			TripStatus.PriceChanged,
			TripStatus.WaitingForUserSubmission,
		].includes(status)
	) {
		return TripStatus.Draft
	}
	if ([TripStatus.Booked, TripStatus.Approved].includes(status)) {
		return TripStatus.Booked
	}
	return status
}

function updateTripById(state: TripsState, id: number, updater: (trip: Trip) => Trip) {
	return {
		...state,
		byId: state.byId.updatePart(id, (trip) => {
			return enrichTripValue(updater(trip))
		}),
	}
}

function tripsReducer(state: TripsState = createDefaultState(), action: IAction): TripsState {
	let supportRequest: SupportRequest
	let statusGroup: AllTripStatuses
	let trip: Trip
	switch (action.type) {
		/* Trip */
		case TripTypes.ClearTrips:
			if (action.payload?.length) {
				for (const id of action.payload ?? []) {
					trip = state.byId.get(id)!
					const statusGroup = getTripStatusGroup(trip)
					state = {
						...state,
						byId: state.byId.delete(id),
						byStatus: state.byStatus.set(
							statusGroup,
							state.byStatus.getWithFallback(statusGroup, []).filter((t) => t.id !== id),
						),
					}
				}
				return state
			}
			return createDefaultState()
		case TripTypes.GetAllTrips.SUCCESS:
			for (const trip of action.payload ?? []) {
				statusGroup =
					action.requestParams.time_frame === TimeFrame.Past ? TripStatusVirtual.PastTrip : getTripStatusGroup(trip)

				state.byId = state.byId.set(trip.id, enrichTripValue(trip))
				state.byStatus = state.byStatus.set(
					statusGroup,
					state.byStatus
						.getWithFallback(statusGroup, [])
						.filter((t) => t.id !== trip.id)
						.concat(enrichTripValue(trip)),
				)
			}
			return state

		case TripTypes.GetTrip.SUCCESS:
			return { ...state, byId: state.byId.set(Number(action.payload.id), enrichTripValue(action.payload)) }

		case TripTypes.CreateTrip.SUCCESS:
			if (!action.payload?.id) {
				return state
			}
			return { ...state, byId: state.byId.set(Number(action.payload.id), enrichTripValue(action.payload)) }

		case TripTypes.UpdateTrip.REQUEST:
			return { ...state, byId: state.byId.set(Number(action.payload.trip.id), enrichTripValue(action.payload.trip)) }

		case TripTypes.UpdateTrip.SUCCESS:
			return { ...state, byId: state.byId.set(Number(action.payload.id), enrichTripValue(action.payload)) }
		case TripTypes.UpdateTrip.FAILED:
			return {
				...state,
				byId: state.byId.set(
					Number(action.requestPayload.trip.id),
					enrichTripValue(action.requestPayload.originalTrip),
				),
			}

		case TripTypes.RemoveTrip.SUCCESS: {
			const tripId = Number(action.requestPayload.tripId)
			const trip = state.byId.get(tripId)
			const statusGroup = getTripStatusGroup(trip!)
			return {
				...state,
				byId: state.byId.delete(tripId),
				byStatus: state.byStatus.update(statusGroup, (trips) =>
					trips.filter((tripByStatus) => tripByStatus.id !== tripId),
				),
			}
		}

		case TripTypes.GetBookingStatus.SUCCESS:
		case TripTypes.SubmitTripForApproval.SUCCESS:
		case TripTypes.SubmitTrip.SUCCESS:
			return updateTripById(state, action.requestPayload.tripId, (trip) => ({
				...trip,
				status: action.payload.status,
			}))
		/* Travelers */
		case TripTypes.AddTraveler.SUCCESS:
			return updateTripById(state, action.requestPayload.tripId, (trip) => ({
				...trip,
				travelers: addEntity(trip.travelers, action.payload),
				travelers_count: trip.travelers_count + 1,
			}))
		case TripTypes.RemoveTraveler.SUCCESS:
			return updateTripById(state, action.requestPayload.tripId, (trip) => ({
				...trip,
				travelers: removeEntityById(trip.travelers, action.requestPayload.userId),
				travelers_count: trip.travelers_count - 1,
			}))
		/* Approval */

		case TripTypes.RejectTrip.SUCCESS:
			return updateTripById(state, action.requestPayload.tripId, (trip) => ({
				...trip,
				status: TripStatus.NotApproved,
			}))
		case ItineraryTypes.GetItinerary.SUCCESS:
			if (!action.payload?.trip_name || !action.requestPayload?.tripId) {
				return state
			}
			return updateTripById(state, action.requestPayload.tripId, (trip) => ({
				...trip,
				name: action.payload.trip_name,
			}))
		case TripSupportRequestsTypes.CreateSupportRequest.SUCCESS:
			return updateTripById(state, action.requestPayload.tripId, (trip) => ({
				...trip,
				support_requests: [...trip.support_requests, action.payload],
			}))
		case SeatmapTypes.SelectSeats.SUCCESS:
			supportRequest = action.payload.support_request
			if (supportRequest) {
				return updateTripById(state, action.requestPayload.tripId, (trip) => ({
					...trip,
					support_requests: [...trip.support_requests, supportRequest],
				}))
			}
			return state
	}
	return state
}

function enrichTripValue(trip: Trip) {
	return {
		...emptyTrip(trip),
		calculatedStatus: calculateTripStatus(trip),
	}
}
export default tripsReducer
