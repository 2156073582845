import { SearchData } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { RecentSearchesTypes } from '../actions/actions.types'

export type RecentSearchesState = SearchData[]

function recentSearchesReducer(state: RecentSearchesState = [], action: IAction): RecentSearchesState {
	switch (action.type) {
		case RecentSearchesTypes.GetRecentSearches.SUCCESS:
			return action.payload.searches || state
	}
	return state
}

export default recentSearchesReducer
