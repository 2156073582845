import i18n, { BackendModule, ReadCallback, ResourceKey } from 'i18next'
import { first, last } from 'lodash'
import moment from 'moment'
import { initReactI18next } from 'react-i18next'

import { captureAndLogError } from '@/lib/error'
import { Locales } from '@/types/locale'

import { getCurrentLocale } from './lib/i18n/locale-utils'

const transLocales = new Map(
	Object.entries(import.meta.glob(`./locales/*/*.json`)).map(([url, fn]) => [
		last(url.split('/'))!.replace('.json', ''),
		fn,
	]),
)
const momentLocales = new Map(
	Object.entries(import.meta.glob(`../node_modules/moment/src/locale/*.js`)).map(([url, fn]) => [
		last(url.split('/'))!.replace('.js', ''),
		fn,
	]),
)

const languagesBackendModule: BackendModule = {
	type: 'backend',
	init: function () {
		return undefined
	},
	read: function (language: string, namespace: string, callback: ReadCallback): void {
		// TODO: the English file should be called just `en`. The loading order is `en-US > en > de-DE > de`
		const transLang = first(language.split('-'))!.toLowerCase() === 'en' ? Locales.enUS : language
		if (transLocales.has(transLang)) {
			transLocales.get(transLang)!()
				.then((r: { default: ResourceKey }) => callback(null, r.default))
				.catch((e) => callback(e, null))
		} else {
			callback(new Error(`Unknown language: ${language} (${transLang})`), null)
		}

		const momentLocale = language.toLowerCase()
		if (momentLocales.has(momentLocale)) {
			momentLocales.get(momentLocale)!()
				// `._config` is because the exported value is a processed locale, but we need the spec to call `moment.locale()`.
				// Moment relies on side-effect, which do not work in a dynamic import.
				.then((r: { default: moment.LocaleSpecification }) => moment.locale(momentLocale, r.default._config))
				.catch(captureAndLogError)
		} else {
			moment.locale(i18n.languages)
		}
	},
}

export function initializeI18n() {
	const language = getCurrentLocale()

	i18n
		.use(languagesBackendModule)
		.use(initReactI18next)
		.init(
			{
				lng: language,
				fallbackLng: Locales.enUS,
				interpolation: {
					escapeValue: false,
				},
			},
			() => {
				moment.locale(i18n.languages)
				document.documentElement.lang = i18n.languages[0]
			},
		)
}
