import { ValueOf } from 'type-fest'
import { z } from 'zod'

import { ApiErrorCodeZ } from '@/types/error/ErrorResponse'

export const ErrorCode = {
	...z.enum([
		'connection_error',
		'invalid_response',
		'misconfiguration',
		'not_authenticated',
		'server_error',
		'timeout',
		'too_many_requests',
		'unknown', // If nothing else suites use this one
	]).enum,
	...ApiErrorCodeZ.extract([
		'booking_error',
		'cancelled', // Use for AbortSignal, etc.
		'car_booking_error',
		'car_get_location_details_error',
		'credit_card_expired',
		'fare_unavailable',
		'result_expired',
		'send_sabre_command_error',
	]).enum,
} as const

export type ErrorCode = ValueOf<typeof ErrorCode>
