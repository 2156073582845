import { omit } from 'lodash'
import React, { forwardRef } from 'react'

export type ComponentRef<C> = C extends React.Component
	? C
	: C extends new (props: any) => React.Component
		? C
		: (
					C extends React.JSXElementConstructor<{ ref?: infer R }>
						? R
						: C extends keyof JSX.IntrinsicElements
							? JSX.IntrinsicElements[C]['ref']
							: unknown
			  ) extends React.Ref<infer T> | string | undefined
			? T
			: unknown

export const generateComponentKey = (pre = '') => {
	return `${pre}_${new Date().getTime()}`
}

export interface Controlled<T> {
	value: T
	onChange(value: T): void
}

function setValueToRef<T>(ref: React.Ref<T>, value: T) {
	if (!ref) {
		return
	}
	if (typeof ref === 'function') {
		return ref(value)
	}
	const mutableRef = ref as React.MutableRefObject<T>
	mutableRef.current = value
}
export function createMergedRef<T>(...refs: React.Ref<T>[]) {
	return (value: T) => {
		refs.forEach((ref) => setValueToRef(ref, value))
	}
}

/** Allows to remove props while passing the ref and not loosing component types */
export function removeProps<C extends React.ComponentType<any>>(Component: C) {
	return function <ExtraProps extends {}>(...propNames: (keyof ExtraProps)[]) {
		return forwardRef<ComponentRef<C>, Omit<React.ComponentProps<C>, keyof ExtraProps> & ExtraProps>(
			function RemoveProps(props, ref) {
				return React.createElement(Component, { ref, ...omit(props, propNames) }) as React.ReactElement<
					React.ComponentProps<C>,
					C
				>
			},
		)
	}
}
