import React from 'react'

import { accentDark, accentLight, accentPrimary, bgMaterial, boxShadowDark, boxShadowSmall, fgContrast } from '@/_vars'
import ChevronDown from '@/assets/chevron-down.svg'
import Loader from '@/atoms/Loader/Loader'
import SvgIcon, { IProps as IconProps } from '@/atoms/SvgIcon/SvgIcon'
import { isEdge, isIE } from '@/lib/browser'
import { white } from '@/refactor/colors'
import { css } from '@/styles'
import styled, { animations, flex } from '@/styles'
import { SortDirection } from '@/travelsuit'

type Variants = 'normal' | 'light' | 'lighter' | 'dark' | 'spacer-xs' | 'spacer-sm' | 'spacer-lg'

export const Th = styled.th<{ bold?: boolean; sortable?: boolean }>`
	vertical-align: bottom;
	font-size: 0.65rem;
	padding: 0 4px 10px 4px;
	${(props) => (props.bold ? `font-weight: bold;` : '')}
	${(props) => (props.sortable ? `cursor: pointer;` : '')}
`

export const Td = styled.td<{ align?: React.CSSProperties['textAlign']; bold?: boolean; noWrap?: boolean }>`
	padding: 13px 5px;
	text-align: ${(props) => props.align ?? 'center'};
	vertical-align: middle;

	&:first-child {
		text-align: left;
		padding-left: 12px;
	}

	&:last-child {
		text-align: right;
		padding-right: 12px;
	}

	${(props) => (props.bold ? `font-weight: bold;` : '')}
	${(props) => (props.noWrap ? `white-space: nowrap;` : '')}
`

export const Table = styled.table`
	width: 100%;
`
export const THead = styled.thead``
export const TBody = styled.tbody``

export const Counter = styled.span`
	${flex({ justify: 'center', align: 'center' })}
	align-items: center;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	background: ${accentLight};
	font-weight: bold;
	font-size: 0.65rem;
	margin: 0 auto;
	color: ${fgContrast};
`

export const ExpandIcon = styled(SvgIcon).attrs(() => ({ src: ChevronDown }))<IconProps & { expanded?: boolean }>`
	transition: transform 150ms ease-in-out;
	transform: rotate(${(props) => (props.expanded ? 180 : 0)}deg);
	color: ${accentDark};
`

const spacers: Variants[] = ['spacer-lg', 'spacer-sm', 'spacer-xs']

export const TRow = styled.tr<{
	variant?: Variants
	animate?: boolean
	delay?: number
	expandable?: boolean
	borderBottom?: boolean
	borderBottomColor?: string | null
}>`
	transition: all 150ms ease-in-out;

	${(props) =>
		!spacers.includes(props.variant!) &&
		css`
			& ${Td}, & ${Th} {
				margin: 5px 0;
			}

			${TBody} & {
				box-shadow: ${boxShadowSmall};
			}
		`}

	${(props) =>
		!['normal', ...spacers].includes(props.variant!)
			? `
		& ${Td} {
			background: ${props.variant === 'dark' ? accentDark : props.variant === 'light' ? white : 'rgba(2,187,170,0.1)'};
			${props.variant === 'dark' ? `color: ${fgContrast};` : ''}
		}

		& ${Counter} {
			color: ${
				props.variant === 'dark'
					? accentDark
					: props.variant === 'light' || props.variant === 'lighter'
						? bgMaterial
						: accentLight
			};
		}
	`
			: ''}

	${(props) =>
		['normal'].includes(props.variant!)
			? `
		& ${Td} {
			padding-top: 15px;
			background: ${bgMaterial};
			&:first-child {
				color: ${accentPrimary};
			}
		}
	`
			: ''}

	${(props) =>
		props.variant === 'dark'
			? `
		& ${ExpandIcon} {
			color: ${accentLight};
		}
	`
			: ''}

	${(props) =>
		!isIE && !isEdge && props.animate
			? `
		animation: ${animations.slideInLeft} 600ms ease-out forwards;
		${animations.slideInLeft.startingStyle}

		${props.delay ? `animation-delay: ${props.delay}ms;` : ''}
	`
			: ''}

	${(props) =>
		spacers.includes(props.variant!)
			? `
		& > ${Td} {
			padding: 0;
			height: ${props.variant === 'spacer-sm' ? 6 : props.variant === 'spacer-xs' ? 2 : 30}px;
		}
	`
			: ''}

	${(props) =>
		props.expandable
			? `
		cursor: pointer;
		${TBody} &:hover {
			box-shadow: ${boxShadowDark};
		}
	`
			: ''}

	${(props) =>
		props.borderBottom ? `& ${Td} { border-bottom: 1px solid ${props.borderBottomColor ?? bgMaterial}; }` : ''}
`

const SortIcon = styled(SvgIcon).attrs(() => ({ src: ChevronDown }))<{ direction: SortDirection | null } & IconProps>`
	${(props) =>
		props.direction !== null
			? `
		transform: rotate(${props.direction === SortDirection.Desc ? 0 : 180}deg);
	`
			: 'display: none;'}
`

export const SortableTh: React.FC<{ bold?: boolean; direction?: SortDirection | null } & JSX.IntrinsicElements['th']> =
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	({ children, className, key, direction, ref, ...props }) => {
		return (
			<Th className={className} sortable bold={direction !== null} {...props}>
				{children} <SortIcon direction={direction !== undefined ? direction : null} />
			</Th>
		)
	}

export const TRLoader: React.FunctionComponent = () => {
	return (
		<tr>
			<td colSpan={12} style={{ padding: 3 }}>
				<Loader size={20} />
			</td>
		</tr>
	)
}
