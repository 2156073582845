import React from 'react'

import DownloadIconFile from '@/assets/download-bold.svg'
import Back from '@/refactor/assets/actions/back.svg'
import TrashIcon from '@/refactor/assets/actions/delete.svg'
import Edit from '@/refactor/assets/actions/edit.svg'
import ChevronDownIcon from '@/refactor/assets/actions/open.svg'
import CrossRoundIcon from '@/refactor/assets/icons/cross-round.svg'
import CrossIcon from '@/refactor/assets/icons/cross.svg'
import { disabledGray, linkBlue } from '@/refactor/colors'
import styled, { css, padPx } from '@/styles'

export type IconSize = 8 | 10 | 12 | 14 | 16 | 18 | 24 | 32 | 40 | 48 | 64

export interface IProps {
	color?: string
	className?: string
	style?: React.CSSProperties
	onClick?: React.MouseEventHandler
	size?: IconSize
	src?: string
	height?: number
	middle?: boolean
	noStroke?: boolean
}

export const baseStyles = css<{ size?: number; height?: number; middle?: boolean; noStroke?: boolean }>`
	fill: currentColor;
	stroke: ${(props) => (props.noStroke ? '' : 'currentColor')};
	display: inline-block;
	width: ${(props) => (props.size ? padPx(props.size) : '1em')};
	height: ${(props) => (props.height ? padPx(props.height) : props.size ? padPx(props.size) : 'auto')};
	${(props) => (props.middle ? `vertical-align: middle;` : '')}
	flex-shrink: 0;
`

export const IconBase = styled.span`
	${baseStyles}
`

export const DEFAULT_ICON_SIZE = 16

export const InlineSvgIcon = React.forwardRef<HTMLSpanElement, IProps>(
	({ src = '', size = DEFAULT_ICON_SIZE, ...rest }: IProps, ref) => {
		return <IconBase dangerouslySetInnerHTML={{ __html: src }} ref={ref} size={size} {...rest} />
	},
)

export const Icon = styled(IconBase)<{ iconSrc: string; color?: string }>`
	background-color: ${(props) => props.color || 'currentColor'};
	mask-image: url(${(props) => props.iconSrc});
	mask-size: contain;
	mask-repeat: no-repeat;
	mask-position: center;
`

const _SvgIcon = React.forwardRef<HTMLSpanElement, IProps>(
	({ src = '', size = DEFAULT_ICON_SIZE, ...rest }: IProps, ref) => {
		return <Icon ref={ref} {...rest} size={size} iconSrc={src} />
	},
)

export const SvgIcon = styled(_SvgIcon)``

export const ButtonIcon = styled(SvgIcon)`
	vertical-align: middle;
	margin-right: 6px;
`

const ChevronDown = styled(SvgIcon).attrs({ src: ChevronDownIcon })`
	vertical-align: middle;
`

export const GoBack = styled(SvgIcon).attrs({ src: Back, size: 24 })``

export const ChevronRight = styled(ChevronDown)`
	transform: rotate(-90deg);
`

export const expandIconTransitionCss = css`
	transition: all 150ms ease-in-out;
`

export const ExpandIcon = styled(ChevronDown)<{ expanded?: boolean }>`
	${expandIconTransitionCss};
	line-height: 1;
	${(props) => (props.expanded ? `transform: rotate(180deg);` : '')}
`

export const CloseIcon = styled(SvgIcon).attrs({ src: CrossIcon })``

export const closeIconStyles = () => css`
	${expandIconTransitionCss};
	color: ${disabledGray};
`

export const RoundCloseIcon = styled(SvgIcon).attrs({ src: CrossRoundIcon })`
	${closeIconStyles()};

	cursor: pointer;
	&:hover {
		color: ${linkBlue};
	}
`

export const EditIcon = styled(SvgIcon).attrs({ src: Edit })``

export const DeleteIcon = styled(SvgIcon).attrs({ src: TrashIcon })``

export const DownloadIcon = styled(SvgIcon).attrs({ src: DownloadIconFile })``

export default SvgIcon
