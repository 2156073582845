import { init, reactRouterV5Instrumentation } from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { appConfig } from '@/app-config/appConfig'
import { getAccessToken, isUnauthorizedError } from '@/lib/auth'
import { CURRENT_VERSION_HASH, ENV } from '@/lib/env'
import { matchPath, Routes } from '@/lib/route-utils'
import { isAbortError } from '@/types/error'

export function initializeErrorTracking() {
	init({
		dsn: appConfig.SENTRY_DSN,
		release: `travelsuit-webapp@${CURRENT_VERSION_HASH}`,
		environment: appConfig.ENV_NAME,
		integrations: [
			new Integrations.BrowserTracing({
				routingInstrumentation: reactRouterV5Instrumentation(
					history,
					// @ts-expect-error todo if you see this please remove this comment and fix the type error
					Object.keys(Routes).map((e) => ({ path: Routes[e] })),
					matchPath,
				),
			}),
		],
		debug: ENV.IS_DEBUG,
		sampleRate: appConfig.SENTRY_SAMPLE_RATE,
		beforeSend(event, hint) {
			const error = hint?.originalException
			if ((isUnauthorizedError(error) && !getAccessToken()) || (error instanceof Error && isAbortError(error))) {
				return null
			}

			return event
		},
	})
}
