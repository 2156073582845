import { useMemo } from 'react'

import { isActiveInvoiceProfile } from '@/lib/isActiveInvoiceProfile'
import { createApiHookWithLoadingState } from '@/lib/react-hooks/generic-hooks'
import { PackagesAndBillingSettingsActions, PackagesAndBillingSettingsTypes } from '@/redux/actions'
import { PackagesAndBillingSettingsState } from '@/redux/reducers/packages-and-billing-settings.reducer'

export const useLoadPackagesAndBillingSettings = createApiHookWithLoadingState<{}, PackagesAndBillingSettingsState>({
	loadAction: (_, { onLoad, onError }) =>
		PackagesAndBillingSettingsActions.getPackagesAndBillingSettings(onLoad, onError),
	loadingSelectors: PackagesAndBillingSettingsTypes.GetPackagesAndBillingSettings,
	storeMapper: ({ packagesAndBillingSettings }) => packagesAndBillingSettings,
})

export function useActivePosCountries() {
	const packagesAndBillingSettings = useLoadPackagesAndBillingSettings({})

	const posCountries = useMemo(
		() =>
			packagesAndBillingSettings.data
				.filter(({ invoice_profile }) => !!invoice_profile && isActiveInvoiceProfile(invoice_profile))
				.map(({ country_pos }) => country_pos),
		[packagesAndBillingSettings.data],
	)

	return { isLoading: packagesAndBillingSettings.isLoading, data: posCountries }
}
