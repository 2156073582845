import { WithTranslation } from 'react-i18next'

import { black70, separatorLight } from '@/_vars'
import { Button as ButtonBase } from '@/atoms/Button'
import { Card as CardBase } from '@/atoms/Card/Card'
import FloatingControlToolbar from '@/atoms/FloatingControlToolbar/FloatingControlToolbar'
import { Flex, Grid as GridBase } from '@/atoms/GenericComponents'
import { Td as TdBase, THead as THeadBase } from '@/atoms/Table/Table'
import { routeFor, Routes } from '@/lib/route-utils'
import { backgroundGray, secondaryBlack, white } from '@/refactor/colors'
import styled from '@/styles'
import { CurrencySigns, GroupTypes, PageProps } from '@/travelsuit'
import { CategoryRate } from '@/travelsuit/expenses'
import { MileageRate } from '@/types/expenses'

export type Props = PageProps &
	WithTranslation & {
		groupType: GroupTypes
	}
export const getRoute = ({ type, category }: { type: string; category?: CategoryRate }) => {
	return [routeFor(Routes.ManagePolicy, { type }), category].filter(Boolean).join('/')
}

export const getRate = (mileageRate: MileageRate) => {
	return `${CurrencySigns[mileageRate.currency]}${mileageRate.amount}/${mileageRate.unit_of_length.toLowerCase()}`
}

export const Card = styled(CardBase)`
	padding: 30px;
	width: 100%;
	margin-bottom: 20px;
	background: ${white};
	color: ${black70};
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
`
export const Button = styled(ButtonBase)`
	align-self: flex-end;
`
export const FlexFullWidth = styled(Flex)`
	width: 100%;
`

export const GridFullWidth = styled(GridBase)`
	width: 100%;
`

export const Grid = styled(GridBase)`
	width: 100%;
	padding: 25px 30px;

	&:not(:last-child) {
		border-bottom: 1px solid ${separatorLight};
	}
`

export const Td = styled(TdBase)`
	text-align: left;
`

export const StyledFloatingControlToolbar = styled(FloatingControlToolbar)`
	position: static;
`

export const THead = styled(THeadBase)`
	color: ${secondaryBlack};
	border-bottom: 1px solid ${backgroundGray};
`
