import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { appConfig } from '@/app-config/appConfig'
import { isNil } from '@/lib/isNil'
import { currency } from '@/lib/number-utils'
import { useUser } from '@/lib/react-hooks/generic-hooks'
import { TimePeriodDetailed } from '@/lib/reports-utils'
import { redirectTo, Routes } from '@/lib/route-utils'
import { capitalize, entityGenerator, mapSelectOptions } from '@/lib/utils'
import { getRate } from '@/organisms/ExpensePolicySettings/utils'
import { darkBlue, darkRed, linkBlue, purple, successGreen, yellow } from '@/refactor/colors'
import { User as UserBase } from '@/travelsuit'
import { fullName } from '@/travelsuit/users'
import { Currency } from '@/types/common'
import {
	DailyAllowance,
	DailyAllowanceResponse,
	ExpenseReportBasic,
	Mileage,
	MileageResponseApi,
	TaxAndVatRateApi,
} from '@/types/expenses'
import { CountriesCode } from '@/types/locale'

import { CountryName, ExpenseType as ExpenseTypeLegacy, NewExpense, TimeOptions } from './index'

export const emptyExpense = entityGenerator<NewExpense>({
	trip_id: 0,
	expense_date: null,
	expense_type: null,
	amount: 0,
	paid_by: null,
	note: '',
	receipt_url: null,
})

const expenseMandatoryFields: Array<keyof NewExpense> = ['expense_date', 'expense_type', 'amount', 'paid_by']

/** @deprecated */
export function isExpenseValidOld(expense: NewExpense) {
	return expenseMandatoryFields.every((k) => Boolean(expense[k]))
}

export const ExpenseTypeLabels: Record<ExpenseTypeLegacy, string> = {
	[ExpenseTypeLegacy.Baggage]: 'expenses.type-labels.baggage',
	[ExpenseTypeLegacy.CarRelated]: 'expenses.type-labels.car-related',
	[ExpenseTypeLegacy.DryCleaning]: 'expenses.type-labels.dry-cleaning',
	[ExpenseTypeLegacy.Laundry]: 'expenses.type-labels.laundry',
	[ExpenseTypeLegacy.Meals]: 'expenses.type-labels.meals',
	[ExpenseTypeLegacy.Other]: 'expenses.type-labels.other',
	[ExpenseTypeLegacy.ShuttleOrCar]: 'expenses.type-labels.shuttle-or-car',
	[ExpenseTypeLegacy.Taxi]: 'expenses.type-labels.taxi',
	[ExpenseTypeLegacy.WifiOrCellular]: 'expenses.type-labels.wifi-or-cellular',
}

export const getExpenseTypeLabelsTranslations = (t: TFunction): Record<ExpenseTypeLegacy, string> => ({
	[ExpenseTypeLegacy.Baggage]: t('expenses.type-labels.baggage', 'Baggage'),
	[ExpenseTypeLegacy.CarRelated]: t('expenses.type-labels.car-related', 'Car Related'),
	[ExpenseTypeLegacy.DryCleaning]: t('expenses.type-labels.dry-cleaning', 'Dry Cleaning'),
	[ExpenseTypeLegacy.Laundry]: t('expenses.type-labels.laundry', 'Laundry'),
	[ExpenseTypeLegacy.Meals]: t('expenses.type-labels.meals', 'Meals'),
	[ExpenseTypeLegacy.Other]: t('expenses.type-labels.other', 'Other'),
	[ExpenseTypeLegacy.ShuttleOrCar]: t('expenses.type-labels.shuttle-or-car', 'Shuttle / Car Service'),
	[ExpenseTypeLegacy.Taxi]: t('expenses.type-labels.taxi', 'Taxis'),
	[ExpenseTypeLegacy.WifiOrCellular]: t('expenses.type-labels.wifi-or-cellular', 'Wifi / Cellular'),
})

export const expenseReportStatuses = ['draft', 'submitted', 'approved', 'reimbursed', 'rejected'] as const
export type ExpenseReportStatus = (typeof expenseReportStatuses)[number]

export type ExpenseReportRejectReason = { reason: string; rejecter: User; id: number; created_at: Date }

export type ExpenseReport = {
	id: number
	name: string
	status: ExpenseReportStatus
	expenses: Array<Expense>
	submitter: ExpenseReportSubmitter
	created_at: Date
	approver?: User
	reasons?: Array<ExpenseReportRejectReason>
	start_date?: string
	end_date?: string
	trip_name?: string
	description?: string
	confirmed?: boolean
	modified_at?: Date | null
	modified_status_at?: Date | null
}

export type ExpenseReportApi = {
	id: number
	name: string
	status: ExpenseReportStatus
	expenses: Array<ExpenseApi | MileageResponseApi | DailyAllowanceResponse>
	submitter?: User
	submitter_id: number
	created_dt: string
	approver?: User
	reasons?: (Pick<ExpenseReportRejectReason, 'reason' | 'rejecter' | 'id'> & { created_dt: string })[]
	start_date?: string
	end_date?: string
	trip_name?: string
	description?: string
	last_modified_dt?: string | null
	last_status_modified_dt?: string | null
}

export type ExpenseReportApiWithExpenseNumbers = {
	id: number
	name: string
	status: ExpenseReportStatus
	expenses: Array<number>
	submitter_id: number
	created_dt: string
	start_date?: string
	end_date?: string
	trip_name?: string
	description?: string
	last_modified_dt?: string | null
}

export type ExpenseReportSubmitter = User

export function isExpenseReadonly(expense: Expense) {
	const excludedStatuses: (typeof expense.status)[] = ['open', 'rejected']
	return !excludedStatuses.includes(expense.status)
}

export function getAttendees(expenseReport: ExpenseReport) {
	return Array.from(
		expenseReport.expenses
			.reduce(
				(attendees, expense) =>
					// @ts-expect-error todo if you see this please remove this comment and fix the type error
					expense.attendees.reduce((attendees, attendee) => attendees.set(attendee.id, attendee), attendees),
				new Map<number, ExpenseAttendee>(),
			)
			.values(),
	)
}

export type ExpenseReportsFilterSet = {
	timePeriod: TimeOptions | TimePeriodDetailed
	invoiceProfiles?: Record<string, boolean | undefined>
	submitters?: Record<string, boolean | undefined>
	statuses?: Partial<Record<ExpenseReportStatus, boolean | undefined>>
	assignedToMe?: boolean
	searchQuery?: string
}

export type ExpenseReportsExportApi = Partial<{
	start_date: string
	end_date: string
	invoice_profile_display_name: string
	submitter_id: number
	status: ExpenseReportStatus
	searchQuery: string
	assigned_to_me: boolean
}>

export const ExpenseReportStatusColorMap: Record<ExpenseReportStatus, string> = {
	draft: darkBlue,
	submitted: yellow,
	approved: successGreen,
	reimbursed: purple,
	rejected: darkRed,
}

export const getExpenseReportStatusLabel: (t: TFunction) => Record<ExpenseReportStatus, string> = (t) => ({
	draft: t('expenses-new.status-draft'),
	submitted: t('expenses-new.status-submitted'),
	approved: t('expenses-new.status-approved'),
	rejected: t('expenses-new.status-rejected'),
	reimbursed: t('expenses-new.status-reimbursed'),
})

export const ExpenseStatusColorMap: Record<ExpenseStatus, string> = {
	open: linkBlue,
	submitted: yellow,
	approved: successGreen,
	reimbursed: purple,
	rejected: darkRed,
}

export const getExpenseStatusLabel: (t: TFunction) => Record<ExpenseStatus, string> = (t) => ({
	open: t('expenses-new.status-open'),
	submitted: t('expenses-new.status-submitted'),
	approved: t('expenses-new.status-approved'),
	rejected: t('expenses-new.status-rejected'),
	reimbursed: t('expenses-new.status-reimbursed'),
})

export function useExpenseReportStatus(expenseReport?: ExpenseReport) {
	const { t } = useTranslation()

	const formatDateTime = useFormatDateTime()

	if (!expenseReport?.modified_status_at) {
		return ''
	}

	switch (expenseReport.status) {
		case 'draft':
			return ''
		case 'submitted':
			return t('expenses-new.report-submitted-details', { date: formatDateTime(expenseReport.modified_status_at) })
		case 'approved':
			return t('expenses-new.report-approved-details', {
				approver: fullName(expenseReport.approver),
				date: formatDateTime(expenseReport.modified_status_at),
			})
		case 'reimbursed':
			return t('expenses-new.report-reimbursed-details', { date: formatDateTime(expenseReport.modified_status_at) })
		case 'rejected': {
			const rejecter = expenseReport.reasons ? fullName(expenseReport.reasons.at(-1)?.rejecter) : ''
			return t('expenses-new.report-rejected-details', {
				rejecter,
				date: formatDateTime(expenseReport.modified_status_at),
			})
		}
		default:
			throw new Error(`Unknown expense report status: ${expenseReport.status}`)
	}
}

export const getExpenseTypeLabels: (t: TFunction) => Record<ExpenseType, string> = (t) => ({
	standard: t('expenses-new.standard-expense'),
	mileage: t('expenses-new.mileage'),
	daily_allowance: t('expenses-new.daily-allowance'),
})

export const expenseTypeOrder: Record<ExpenseType, number> = { standard: 0, mileage: 1, daily_allowance: 2 }

// todo: fix circular: User => TravelProfile => Group => User
type User = Omit<UserBase, 'traveler_profile'>

export type ExpenseSubmitter = User

export type ExpenseAttendee = User

export const expenseStatuses = ['open', 'submitted', 'approved', 'rejected', 'reimbursed'] as const
export type ExpenseStatus = (typeof expenseStatuses)[number]

export type ExpenseOcrStatus = 'processing' | 'analysed' | 'error'

export type ExpenseOcrResultData = Partial<
	Pick<StandardExpense, 'merchant' | 'merchant_country' | 'date' | 'category' | 'currency' | 'amount' | 'vat_rate'>
>
export type ExpenseOcrResult = ExpenseOcrResultData & { ocr_status: ExpenseOcrStatus }

export type ExpenseType = 'standard' | 'mileage' | 'daily_allowance'

export type File = {
	name: string
	url: string
	size: number
}

export type Category = 'Equipment' | 'Meals & entertainment' | 'Other' | 'Professional services' | 'Travel'

export type FullExpenseBase = {
	merchant: string
	merchant_country: { name: z.infer<typeof CountryName>; code: CountriesCode | null }
	date: string
	category?: Category
	currency: Currency
	amount: number
	vat_rate: TaxAndVatRateApi
	attendees: Array<ExpenseAttendee>
	report_amount: number
	report?: ExpenseReportBasic
	description?: string
	file?: File
	ocr_status?: ExpenseOcrStatus
	ocr_results?: ExpenseOcrResult
}
export type FullExpense = FullExpenseBase & {
	id: number
	type: ExpenseType
	status: ExpenseStatus
	submitter: ExpenseSubmitter
	created_at: Date
}

const expenseRequiredFields: Record<keyof FullExpense, boolean> = {
	id: true,
	type: true,
	status: true,
	submitter: true,
	created_at: true,
	merchant: true,
	merchant_country: true,
	date: true,
	category: false,
	currency: true,
	amount: true,
	vat_rate: true,
	attendees: false,
	report_amount: false,
	report: false,
	description: false,
	file: true,
	ocr_status: false,
	ocr_results: false,
}

export function isExpenseValid(expense: Expense) {
	if (isMileage(expense) || isDailyAllowance(expense)) {
		return true
	}

	return Object.keys(expenseRequiredFields)
		.filter((key: keyof FullExpense) => expenseRequiredFields[key])
		.every((key: keyof FullExpense) => Boolean(expense[key]))
}

export function isExpenseInReportValid(expense: Expense) {
	return isExpenseValid(expense) && (!('file' in expense) || expense.file)
}

export function hasExpenseDiscrepancy(expense: StandardExpense) {
	const ocrResults = expense.ocr_results

	return ocrResults
		? Object.keys(ocrResults)
				.filter((key) => key !== 'ocr_status')
				.some((key: keyof ExpenseOcrResult) => ocrResults[key] && ocrResults[key] !== expense[key])
		: false
}

export type ExpensesFilterSet = {
	timePeriod?: TimeOptions | TimePeriodDetailed
	types?: Record<ExpenseType, boolean | undefined>
	statuses?: Record<ExpenseStatus, boolean | undefined>
	submitters?: Record<string, boolean | undefined>
	attendees?: Record<string, boolean | undefined>
	unassigned?: boolean
	searchQuery?: string
}

type QuickExpenseOptional =
	| 'merchant'
	| 'merchant_country'
	| 'date'
	| 'category'
	| 'currency'
	| 'attendees'
	| 'report_amount'
	| 'amount'
	| 'vat_rate'
	| 'report'
	| 'description'
	| 'ocr_status'
	| 'ocr_results'
export type QuickExpense = Omit<FullExpense, QuickExpenseOptional> & Partial<Pick<FullExpense, QuickExpenseOptional>>

type ExpenseAttendeeApi = ExpenseAttendee | { id: number }

export type ExpenseApi = {
	id: string
	merchant: string
	merchant_country_name: string
	merchant_country_code: CountriesCode
	date: string
	category?: Category
	amount: number
	currency: Currency
	vat_rate: TaxAndVatRateApi
	attendees: Array<ExpenseAttendeeApi>
	expense_report?: string
	report_id?: number
	description?: string
	file_data: Pick<File, 'name' | 'size'>
	receipt_url: string
	expense_type: ExpenseType
	expense_status: ExpenseStatus
	ocr_status?: ExpenseOcrStatus
	ocr_results?: ExpenseOcrResult
	submitter: ExpenseSubmitter
	submitter_id: number
	created_dt: string
	report_amount: number
}

export type QuickExpenseApi = Partial<
	Pick<
		ExpenseApi,
		| 'merchant'
		| 'date'
		| 'category'
		| 'currency'
		| 'amount'
		| 'attendees'
		| 'description'
		| 'report_amount'
		| 'merchant_country_name'
		| 'merchant_country_code'
	>
> & {
	id: string
	file_data: File
	receipt_url: string
	expense_type: ExpenseType
	expense_status: ExpenseStatus
	ocr_status?: ExpenseOcrStatus
	ocr_results?: ExpenseOcrResult
	submitter?: ExpenseSubmitter
	submitter_id: number
	created_dt: string
}

export type StandardExpense = FullExpense | QuickExpense

export type ExpensePolicy = {
	expense_approver?: User
	fallback_approver?: User
	categories_by_country?: ExpenseCategories
}

export function isExpenseReportReadonly(expenseReport: Partial<ExpenseReport>, user: User) {
	const excludedStatuses: (typeof expenseReport.status)[] = ['draft', 'rejected']
	return !excludedStatuses.includes(expenseReport.status) || user.id !== expenseReport.submitter?.id
}

export function getExpenseReportStartEndDate(expenses: Array<Expense>): [string, string] | null {
	if (!expenses.length) {
		return null
	}

	const minDate = expenses.reduce(
		(minDate, expense) =>
			isDailyAllowance(expense)
				? expense.start_date < minDate
					? expense.start_date
					: minDate
				: expense.date && expense.date < minDate
					? expense.date
					: minDate,
		'2999-12-31',
	)
	const maxDate = expenses.reduce(
		(maxDate, expense) =>
			isDailyAllowance(expense)
				? expense.end_date > maxDate
					? expense.end_date
					: maxDate
				: expense.date && expense.date > maxDate
					? expense.date
					: maxDate,
		'',
	)

	return [minDate === '2999-12-31' ? '' : minDate, maxDate]
}

export function isDailyAllowance(expense: Expense): expense is DailyAllowance {
	return expense.type === 'daily_allowance'
}

export function isMileage(expense: Expense): expense is Mileage {
	return expense.type === 'mileage'
}

export function isStandardExpense(expense: Expense): expense is StandardExpense {
	return expense.type === 'standard'
}

export function mapCapitalizeLabels(data: ReturnType<typeof mapSelectOptions<string, string, string>>) {
	return data.map(({ label, value }) => ({ label: capitalize(label), value }))
}

export type CategoryRate = 'categories' | 'mileage' | 'tax-and-vat' | 'daily-allowances'

export type ExpenseCategory = string
export type ExpenseCategories = Partial<Record<CountriesCode, Array<ExpenseCategory>>>

export function useFormatDateTime() {
	const user = useUser()
	if (!user) {
		return () => ''
	}

	return (date: Date) => formatDateTime(date, user)
}

export function formatDateTime(date: Date, user: User) {
	return new Intl.DateTimeFormat(user.language_code || undefined, {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	}).format(new Date(date))
}

export type Expense = StandardExpense | Mileage | DailyAllowance

export type ValidExpense = FullExpense | Mileage | DailyAllowance

export const taxAndVatRateNone = { rate: 0, name: 'None', id: -1 }

export function getExpenseTax(expense: Expense, amount: number | undefined, t: TFunction) {
	if (
		isNil(amount) ||
		!('vat_rate' in expense) ||
		isNil(expense.vat_rate) ||
		expense.vat_rate.id === taxAndVatRateNone.id
	) {
		return t('Not applicable')
	}
	return parseFloat((amount - amount / (1 + expense.vat_rate.rate / 100)).toFixed(2))
}
export function gotoEditExpenseInReport(expense: Expense) {
	if (expense.type === 'standard') {
		redirectTo(Routes.EditExpenseInReport, { id: expense.id })
		return
	}
	if (expense.type === 'mileage') {
		redirectTo(Routes.EditMileageInReport, { id: expense.id })
		return
	}
	if (expense.type === 'daily_allowance') {
		redirectTo(Routes.EditDailyAllowanceInReport, { id: expense.id })
		return
	}
}

export const getExpenseAmount = (expense: Expense) => {
	const mapTypeToAmount: Record<typeof expense.type, (expense: Expense) => string> = {
		standard: (expense: StandardExpense) =>
			expense.amount ? currency(expense.amount, { currency: expense.currency, precision: 2 }) : '',
		mileage: (mileage: Mileage) => currency(mileage.amount, { currency: mileage.rate.currency, precision: 2 }),
		daily_allowance: (dailyAllowance: any) => currency(dailyAllowance.amount, { currency: Currency.EUR, precision: 2 }),
	}

	return mapTypeToAmount[expense.type](expense)
}

export const getMerchant = (expense: Expense, t: TFunction) => {
	const mapTypeToMerchant: Record<typeof expense.type, (expense: Expense) => string> = {
		standard: (expense: StandardExpense) => expense.merchant || t('Unknown'),
		mileage: (mileage: Mileage) =>
			`${mileage.distance}${mileage.rate.unit_of_length.toLowerCase()} (${getRate(mileage.rate)})`,
		daily_allowance: (da: DailyAllowance) => da.destination.name,
	}

	return mapTypeToMerchant[expense.type](expense)
}

export const normalizeUrl = (url?: string) => {
	if (!url) {
		return ''
	}
	if (url.startsWith('/') || url.toLowerCase().startsWith('http')) {
		return url
	}

	// todo: it could be just window.location but won't work locally - refactor it
	let origin = ''
	try {
		origin = new URL(appConfig.API_DOMAIN).origin
	} catch (e) {
		;({ origin } = window.location)
	}
	return `${origin.replace('api-', '')}/${url}`
}
