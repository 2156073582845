import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { SearchData } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { SearchesTypes } from '../actions/actions.types'

export type SearchesState = ImmutableMap<number, SearchData>

function searchesReducer(state: SearchesState = new ImmutableMap(), action: IAction): SearchesState {
	switch (action.type) {
		case SearchesTypes.GetSearch.SUCCESS:
			return state.set(action.requestPayload.searchId, action.payload)
	}
	return state
}

export default searchesReducer
