import {
	ContentDistribution,
	ContentPosition,
	DisplayInside,
	DisplayInternal,
	DisplayLegacy,
	DisplayOutside,
	Globals,
	SelfPosition,
} from 'csstype'

import { ArrayOr } from '@/travelsuit'

import { mediaQuery } from './media-query'
import { padPx } from './units'

export interface FlexProps {
	justify?: Globals | ContentDistribution | ContentPosition | 'left' | 'normal' | 'right'
	justifySelf?: Globals | SelfPosition | 'auto' | 'baseline' | 'left' | 'normal' | 'right' | 'stretch'
	align?: Globals | SelfPosition | 'baseline' | 'normal' | 'stretch'
	alignSelf?: Globals | SelfPosition | 'auto' | 'baseline' | 'normal' | 'stretch'
	direction?: React.CSSProperties['flexDirection']
	display?:
		| Globals
		| DisplayOutside
		| DisplayInside
		| DisplayInternal
		| DisplayLegacy
		| 'contents'
		| 'list-item'
		| 'none'
	wrap?: React.CSSProperties['flexWrap']
	gap?: React.CSSProperties['gap']
}

export interface GridProps extends Omit<FlexProps, 'direction' | 'gap'> {
	columns?: ArrayOr<string | number>
	rows?: ArrayOr<string | number>
	gap?: ArrayOr<string | number>
	areas?: string
	template?: string
	templateAreas?: string | string[] | string[][]
	collapse?: boolean
	autoColumns?: ArrayOr<string | number>
	autoRows?: ArrayOr<string | number>
	justifyItems?: 'start' | 'end' | 'center' | 'stretch'
	alignItems?: 'start' | 'end' | 'center' | 'stretch' | 'baseline'
}

export function flexLike({
	justify,
	align,
	direction = 'row',
	display,
	justifySelf,
	alignSelf,
	wrap,
	gap,
}: FlexProps & { display?: React.CSSProperties['display'] } = {}) {
	return `
		${display ? `display: ${display};` : ''}
		${display === 'flex' ? `flex-direction: ${direction};` : ''}
		${justify ? `justify-content: ${justify};` : ''}
		${align ? `align-items: ${align};` : ''}
		${justifySelf ? `justify-self: ${justifySelf};` : ''}
		${alignSelf ? `align-self: ${alignSelf};` : ''}
		${alignSelf ? `-webkit-align-self: ${alignSelf};` : ''}
		${wrap ? `flex-wrap: ${wrap};` : ''}
		${gap ? `gap: ${padPx(gap)}` : ``}
	`
}

export function flex(options: FlexProps = {}) {
	return flexLike({ display: 'flex', ...options })
}

export function grid({ gap: flexGap, ...options }: GridProps = {}) {
	const columns = options.columns
		? options.columns instanceof Array
			? options.columns.map(padPx).join(' ')
			: typeof options.columns === 'number'
				? new Array(options.columns).fill('1fr').join(' ')
				: padPx(options.columns)
		: null
	const rows = options.rows
		? options.rows instanceof Array
			? options.rows.map(padPx).join(' ')
			: padPx(options.rows)
		: null
	const gap = flexGap ? (flexGap instanceof Array ? flexGap.map(padPx).join(' ') : padPx(flexGap)) : null
	const templateAreas = options.templateAreas
		? options.templateAreas instanceof Array
			? (options.templateAreas as any[])
					.map((tmp: string | string[]) => (tmp instanceof Array ? `"${tmp.join(' ')}"` : `"${tmp}"`))
					.join(' ')
			: options.templateAreas
		: null
	const autoColumns = options.autoColumns
		? options.autoColumns instanceof Array
			? options.autoColumns.map(padPx).join(' ')
			: padPx(options.autoColumns)
		: null
	const autoRows = options.autoRows
		? options.autoRows instanceof Array
			? options.autoRows.map(padPx).join(' ')
			: padPx(options.autoRows)
		: null
	return `
		${flexLike({ ...options, display: 'grid' })}
		${columns ? `grid-template-columns: ${columns};` : ''}
		${rows ? `grid-template-rows: ${rows};` : ''}
		${gap ? `grid-gap: ${gap};` : ''}
		${options.collapse ? mediaQuery.mobileOnly`grid-template-columns: 1fr;`.join('') : ''}
		${options.template ? `grid-template: ${options.template};` : ''}
		${templateAreas ? `grid-template-areas: ${templateAreas};` : ''}
		${autoColumns ? `grid-auto-columns: ${autoColumns};` : ''}
		${autoRows ? `grid-auto-rows: ${autoRows};` : ''}
		${
			options.justifyItems
				? `
						justify-items: ${options.justifyItems};
					`
				: ''
		}
		${
			options.alignItems
				? `
						align-items: ${options.alignItems};
					`
				: ''
		}
	`
}
