import React from 'react'
import { Link } from 'react-router-dom'

import { bgMaterial, boxShadowMedium } from '@/_vars'
import Tooltip from '@/atoms/Tooltip/Tooltip'
import { forceArray } from '@/lib/array-utils'
import { exclude } from '@/lib/utils'
import { font, FontType } from '@/refactor/fonts'
import { CSSProperties } from '@/styles'
import styled from '@/styles'
import { DefaultProps } from '@/travelsuit'

import { LinkLike } from '../GenericComponents/GenericComponents'
import Tab from '../Tab/Tab'

export type IProps<TabKey extends string = string> = DefaultProps & {
	active: TabKey
	variant?: 'normal' | 'condensed'
	alignment?: CSSProperties['textAlign']
	disableRouting?: boolean
	boxShadow?: string
	onChange?(key: TabKey): void
	e2eForTabStrips?: string
	noTabContentWrapper?: boolean
	noWrap?: boolean
}

export const TabStrip = styled.div<Pick<IProps, 'alignment' | 'boxShadow' | 'noWrap'>>`
	${font(FontType.Comfortaa)}
	background: ${bgMaterial};
	text-align: ${(props) => props.alignment ?? 'center'};
	box-shadow: ${(props) => props.boxShadow ?? boxShadowMedium};
	border-radius: ${(p) => p.theme.spacing(1)}px;
	z-index: 60;
	position: relative;
	margin: 0 auto;
	max-width: 1024px;
	padding: ${(p) => p.theme.spacing(1, 2, 0, 2)};

	${(p) => (p.noWrap ? p.theme.breakpoints.up('xs') : p.theme.breakpoints.down('md'))} {
		white-space: nowrap;
		overflow-x: auto;

		scrollbar-width: none;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			display: none;
		}

		${Tab} {
			margin-bottom: 2px;
		}
	}

	${Tab} {
		padding: ${(p) => p.theme.spacing(2)}px;
	}
`

export const TabContent = styled.div``

const Tabs = <TabKey extends string = string>(props: IProps<TabKey>) => {
	const {
		className,
		variant,
		onChange,
		active,
		disableRouting,
		boxShadow,
		alignment,
		children: _children,
		e2eForTabStrips,
		noTabContentWrapper,
		noWrap,
	} = props
	const children = forceArray(_children).filter(Boolean)
	// @ts-expect-error todo if you see this please remove this comment and fix the type error
	const activeTabChild = children.find((c) => c.props.tabKey === active)?.props.children

	return (
		<div className={className}>
			<TabStrip alignment={alignment} boxShadow={boxShadow} data-test={e2eForTabStrips} noWrap={noWrap}>
				{children.map((c, i) => {
					const DefaultTabComponentType = disableRouting ? LinkLike : Link
					const Component =
						// @ts-expect-error todo if you see this please remove this comment and fix the type error
						c.props.component !== Link ? c.props.component ?? DefaultTabComponentType : DefaultTabComponentType
					// @ts-expect-error todo if you see this please remove this comment and fix the type error
					const key = c.props.tabKey
					const isActive = active === key

					const onClick = (e: React.MouseEvent<any>) => {
						if (e.target instanceof HTMLElement) {
							e.target.scrollIntoView()
						}

						// @ts-expect-error todo if you see this please remove this comment and fix the type error
						if (c.props.onTabClick) {
							// @ts-expect-error todo if you see this please remove this comment and fix the type error
							c.props.onTabClick(e)
						} else {
							if (!isActive && onChange) {
								onChange(key)
							}
						}
					}

					const extras = {
						// @ts-expect-error todo if you see this please remove this comment and fix the type error
						to: Component === Link ? c.props.linkTo ?? key : undefined,
					}

					const tab = (
						<Tab
							key={i}
							as={Component}
							// @ts-expect-error todo if you see this please remove this comment and fix the type error
							className={c.props.className}
							onClick={onClick}
							variant={variant}
							active={isActive}
							// @ts-expect-error todo if you see this please remove this comment and fix the type error
							{...(exclude(c.props, ['children']) as any)}
							{...(disableRouting ? {} : extras)}
						>
							{/*@ts-expect-error todo if you see this please remove this comment and fix the type error*/}
							{c.props.label}
						</Tab>
					)

					// @ts-expect-error todo if you see this please remove this comment and fix the type error
					if (c.props.title) {
						return (
							// @ts-expect-error todo if you see this please remove this comment and fix the type error
							<Tooltip key={i} title={c.props.title}>
								{tab}
							</Tooltip>
						)
					}

					return tab
				})}
			</TabStrip>

			{noTabContentWrapper ? activeTabChild : <TabContent>{activeTabChild}</TabContent>}
		</div>
	)
}

export default Tabs
