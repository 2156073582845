import { User, UserPermission, UserPermissionsTypes, VoidCallback } from '@/travelsuit'
import { prepareUserPermissionJson } from '@/travelsuit/permissions'

import { IAction } from './action-helpers'
import { PermissionsTypes as Types } from './actions.types'

export const Actions = {
	getPermissions(callback?: VoidCallback<UserPermission>, errCallback?: VoidCallback): IAction {
		return {
			type: Types.GetPermissions.REQUEST,
			callback,
			errCallback,
		}
	},
	addUserPermission(
		user: User,
		permission: UserPermissionsTypes,
		callback?: VoidCallback<UserPermissionsTypes>,
		errCallback?: VoidCallback,
	) {
		return {
			type: Types.AddPermission.REQUEST,
			payload: { user, permission },
			body: prepareUserPermissionJson(permission, user),
			callback,
			errCallback,
		}
	},
	removeUserPermission(user: User, permission: UserPermission, callback?: VoidCallback, errCallback?: VoidCallback) {
		return {
			type: Types.RemovePermission.REQUEST,
			payload: { user, permission },
			callback,
			errCallback,
		}
	},
}
