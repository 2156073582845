import React from 'react'

import { Stack } from '@/atoms/System'
import { brightTurquoise, darkBlue } from '@/refactor/colors'
import styled, { font, FontType } from '@/styles'

const Content = styled.div`
	padding: ${(p) => p.theme.spacing(4)}px;
	text-align: center;
`

const Heading = styled(Stack)`
	text-align: center;
`

const Title = styled.h1`
	color: ${brightTurquoise};

	${font(FontType.Comfortaa, { size: 20, weight: 700 })}

	${(p) => p.theme.breakpoints.up('lg')} {
		${font(FontType.Comfortaa, { size: 32, weight: 700 })}
	}
`

const Subtitle = styled.div`
	color: ${darkBlue};

	${font(FontType.Comfortaa, { size: 14, weight: 600 })}

	${(p) => p.theme.breakpoints.up('lg')} {
		${font(FontType.Comfortaa, { size: 18, weight: 600 })}
	}
`

export const ButtonsContainer = styled(Stack)`
	margin-top: ${(p) => p.theme.spacing(4)}px;

	${(p) => p.theme.breakpoints.up('sm')} {
		flex-direction: row;
		justify-content: center;
	}

	${(p) => p.theme.breakpoints.up('lg')} {
		gap: ${(p) => p.theme.spacing(4)}px;
		margin-top: ${(p) => p.theme.spacing(10)}px;
	}
`

interface UnavailablePageInfoProps {
	buttons?: React.ReactNode
	title: React.ReactNode
	subtitle: React.ReactNode
	className?: string
}

export function UnavailablePageInfo({
	children,
	title,
	subtitle,
	buttons,
	className,
}: React.PropsWithChildren<UnavailablePageInfoProps>) {
	return (
		<Content className={className}>
			<Heading gap={3}>
				<Title>{title}</Title>
				<Subtitle>{subtitle}</Subtitle>
				{children}
			</Heading>

			<ButtonsContainer gap={2}>{buttons}</ButtonsContainer>
		</Content>
	)
}
