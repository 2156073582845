import { createLoadOnceAPIHook } from '@/lib/react-hooks/generic-hooks'
import { CountryPOSList, UserPOS, UserPOSActions } from '@/redux/actions'
import { countryPosState } from '@/redux/reducers/country-pos.reducer'
import { userPosState } from '@/redux/reducers/user-pos.reducer'

export const useUserPOS = createLoadOnceAPIHook<{}, userPosState>({
	loadAction: (_, { onLoad, onError }) => UserPOSActions.getUserPOS(onLoad, onError),
	isEmpty: (userPOS) => !userPOS,
	loadingSelectors: UserPOS.GetUserPOS,
	storeMapper: ({ userPOS }) => userPOS,
})

export const useCountryPOS = createLoadOnceAPIHook<[], countryPosState>({
	loadAction: (_, { onLoad, onError }) => UserPOSActions.getCountryPOSList(onLoad, onError),
	isEmpty: (POSList) => !POSList.length,
	loadingSelectors: CountryPOSList.GetCountryPOSList,
	storeMapper: ({ posList }) => posList,
})
