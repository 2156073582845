import React, { forwardRef } from 'react'

import { ComponentRef } from '@/lib/react'

import { WithTranslation as WithTranslationProps } from './i18n.helper'
import useTranslation from './UseTranslation'

// The function is wrapped with another function just so it will be consistent
// with their original API
const withTranslation =
	() =>
	<C extends React.ComponentType<WithTranslationProps>>(Component: C) =>
		forwardRef<ComponentRef<C>, Omit<React.ComponentProps<C>, keyof WithTranslationProps>>(
			function WithTranslation(props, ref) {
				const { t, i18n, ready } = useTranslation()
				// forwardRef type doesn't work well with generics, https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forward_and_create_ref/#option-2---redeclare-forwardref
				return React.createElement(Component, { ref, t, i18n, tReady: ready, ...props }) as React.ReactElement<
					WithTranslationProps,
					C
				>
			},
		)

export default withTranslation
