import React from 'react'

import CompanyTooltipText from '@/atoms/CompanyTooltipText/CompanyTooltipText'
import { mainBlack } from '@/refactor/colors'
import styled, { font } from '@/styles'
import { DefaultProps, UserRoleNames } from '@/travelsuit'
import { hasUserRole } from '@/travelsuit/my-user-roles'

interface IProps extends DefaultProps {
	companyType: string
}

const CompanyTooltip = styled.div`
	position: absolute;
	right: calc(100% + 8px);
	top: -20px;
	background-color: #ffffff;
	border-radius: 5px;
	box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.17);
	box-sizing: border-box;
	padding: 15px 20px;
	width: 100%;
	min-width: 400px;

	p {
		color: ${mainBlack};
		${font({ size: 14 })};
	}
`

const CompanyTooltipContainer = ({ companyType }: IProps) => {
	const isAdmin = hasUserRole(UserRoleNames.Admin)
	const isSupportUser = hasUserRole(UserRoleNames.SupportUser)

	return (
		<CompanyTooltip data-test="CompanyTooltipContainer">
			<CompanyTooltipText isAdmin={isAdmin} isSupportUser={isSupportUser} companyType={companyType} />
		</CompanyTooltip>
	)
}

export default CompanyTooltipContainer
