import { ImmutableMap } from '@/lib/immutable/ImmutableMap'
import { User } from '@/travelsuit'

import { IAction } from '../actions/action-helpers'
import { AdminUsersTypes, PermissionsTypes, TravelersTypes } from '../actions/actions.types'

export type AdminUsersState = ImmutableMap<number, User>

function adminUsersReducer(state: AdminUsersState = new ImmutableMap(), action: IAction): AdminUsersState {
	switch (action.type) {
		// Payload contains the updated User entity
		case AdminUsersTypes.InviteUser.SUCCESS:
		case TravelersTypes.GetUser.SUCCESS:
			return state.set(action.payload.id, action.payload)
		case AdminUsersTypes.GetAllUsers.SUCCESS:
			state = new ImmutableMap()
			for (const u of action.payload ?? []) {
				state = state.set(u.id, u)
			}
			return state
		case AdminUsersTypes.AddUser.SUCCESS:
			return state.set(action.payload.id, action.payload)
		case AdminUsersTypes.UpdateUser.SUCCESS:
			return state.set(action.payload.id, { ...(state.get(action.payload.id) ?? {}), ...action.payload })
		case AdminUsersTypes.RemoveUser.SUCCESS:
			return state.delete(action.requestPayload.id)
		case TravelersTypes.CreateTraveler.SUCCESS:
		case TravelersTypes.UpdateTraveler.SUCCESS:
			return state.updatePart(action.payload.user_id, (user) => ({
				traveler_profile: { ...user.traveler_profile, ...action.payload },
			}))
		case AdminUsersTypes.AddUserRole.SUCCESS:
			return state.updatePart(action.requestPayload.user.id, (user) => {
				return {
					user_roles: [
						...(user.user_roles ?? []).filter((ur) => ur.id !== action.requestPayload.userRole.id),
						action.payload,
					],
				}
			})
		case AdminUsersTypes.RemoveUserRole.SUCCESS:
			return state.updatePart(action.requestPayload.user.id, (user) => {
				return {
					user_roles: (user.user_roles ?? []).filter((ur) => ur.id !== action.requestPayload.userRole.id),
				}
			})
		case PermissionsTypes.AddPermission.SUCCESS:
			return state.updatePart(action.requestPayload.user.id, (user) => {
				return {
					user_permissions: [
						...(user.user_permissions ?? []).filter((up) => up !== action.requestPayload.permission),
						action.payload,
					],
				}
			})
		case PermissionsTypes.RemovePermission.SUCCESS:
			return state.updatePart(action.requestPayload.user.id, (user) => {
				return {
					user_permissions: (user.user_permissions ?? []).filter((up) => up !== action.requestPayload.permission),
				}
			})
	}
	return state
}

export default adminUsersReducer
