import React from 'react'

import { createLazyComponentWithSuspense } from '@/lib/createLazyComponentWithSuspense'
import { usePreviousValue } from '@/lib/react-hooks/generic-hooks'
import { useTravelPolicyForUsers } from '@/lib/react-hooks/travel-policy-hooks'
import { eventStopper } from '@/lib/utils'
import ShieldIcon from '@/refactor/assets/icons/shield.svg'
import styled, { flex } from '@/styles'
import { ProductType, User } from '@/travelsuit'
import { track } from '@/travelsuit/analytics'
import { TripTravelPolicy } from '@/travelsuit/policy'

import { LinkButton } from '../Button'
import SvgIcon from '../SvgIcon/SvgIcon'

export const PolicyPreviewSidebar = createLazyComponentWithSuspense(
	() => import('@/organisms/PolicyPreviewSidebar/PolicyPreviewSidebar'),
)

interface IProps {
	className?: string
	policies?: TripTravelPolicy[]
	users?: User[]
	label?: React.ReactNode
	productType?: ProductType
	onClick?(e: React.MouseEvent<HTMLElement>): void
	render?(props: { onClick(e: React.MouseEvent<HTMLElement>): void }): React.ReactNode
}

const LinkContainer = styled(LinkButton)`
	${flex({ justify: 'flex-start', align: 'center', display: 'inline-flex', gap: 8 })}
`

/** At least one of `label` and `render` must be specified */
const PolicyPreviewLink: React.FunctionComponent<IProps> = track({})((props) => {
	const { className, label, policies: _policies, users, productType, render, onClick: _onClick, ...rest } = props
	const [open, toggle] = React.useState(false)
	const wasOpen = usePreviousValue(open)
	const statePolicies = useTravelPolicyForUsers(users ?? [], { refresh: open && !wasOpen })
	const policies = _policies ?? statePolicies

	if (!policies.length && !users?.length) {
		console.warn(new Error('Either policies or users must be supplied'))
		return null
	}

	function onClick(e: React.MouseEvent<HTMLElement>) {
		toggle(true)
		_onClick?.(e)
	}

	const sidebar = open && (
		<PolicyPreviewSidebar open onClose={() => toggle(false)} policies={policies} productType={productType} />
	)

	if (render) {
		return (
			<div className={className} {...rest}>
				{render({ onClick: eventStopper.stopPropagation(onClick) })}
				{sidebar}
			</div>
		)
	}

	return (
		<div className={className} {...rest}>
			<LinkContainer data-test={'ViewTravelPolicy'} onClick={eventStopper.stopPropagation(onClick)}>
				<SvgIcon src={ShieldIcon} />
				{label}
			</LinkContainer>
			{sidebar}
		</div>
	)
})

export default PolicyPreviewLink
